import React from 'react';
import PropTypes from 'prop-types';

class PanelContent extends React.Component {
  render() {
    return (
      <div
        className={`fui-html-content subtitle-2 is-text-${this.props.color === 'darkgray' ? 'darkgray50' : 'black50'}`}
        dangerouslySetInnerHTML={{ __html: this.props.content }}></div>
    );
  }
}

PanelContent.propTypes = {
  color: PropTypes.string,
  content: PropTypes.string.isRequired,
};

export default PanelContent;
