import React from 'react';
import Helmet from 'react-helmet';
import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import Formsy from 'formsy-react';
import Slider from 'react-slick';
import { setFlowCartData, trackPageViewStart } from '../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import NavTab from '../components/partials/NavTab';
import NavContentTab2 from '../components/partials/NavContentTab2';
import AdaptHeightBanner from '../components/partials/banner/AdaptHeightBanner';
import Breadcrumb from '../components/Breadcrumb';
import Link from '../components/Link';
import LinkItem from '../components/item/LinkItem';
import Button from '../components/Button';
import Cart from '../components/FlowCart';
import CompareBar from '../components/CompareBar';
import EstoreModal from '../components/EstoreModal';
import Tab from '../components/tab/Tab';
import TabPane from '../components/tab/TabPane';
import FormBanner from '../components/partials/banner/FormBanner';

import ColorSelector from '../components/form/ColorSelector';
import RadioButtons from '../components/form/RadioButtons';
import RadioGroup from '../components/form/RadioGroup';
import NumberSelector from '../components/form/NumberSelector';
import RadioCard from '../components/form/RadioCard';
import RadioCarousel from '../components/form/RadioCarousel';
import CheckboxCardCarousel from '../components/form/CheckboxCardCarousel';
import LabelInput from '../components/form/LabelInput';

import * as Validation from '../utils/validation';
import formatNumber from '../utils/numberFormatter';

import axios from 'axios';
import * as Mock from '../mock/RatePlan';

class RatePlan extends React.Component {
  constructor(props) {
    super(props);
    this.flowRef = React.createRef();
    this.state = {
      modalOpen: false,
      inputValidationValid: false,
      cartUpdate: false,
      canEdit: true,
      isBackward: false,
      currentModal: {
        type: '',
        title: '',
        content: '',
      },
      currentNav: 0,
      currentCheckboxTab: 0,
      currentCarryTab: 0,
      currentImage: [],
      identityPass: false,
      sldierSetting: {
        dots: true,
        infinite: false,
        arrows: true,
        slidesToShow: 1,
        draggable: true,
        adaptiveHeight: true,
      },
      installmentCount: 74,
      installmentModal: {
        title: '信用卡分期付款',
        content: [
          '分期資訊',
          {
            '3期0利率': '$10,000',
            '5期0利率': '$6,000',
            '6期0利率': '$5,050',
            '12期0利率': '$2,525',
          },
          [
            '手機分期付款僅提供宅配取貨，並使用線上刷卡供銷費者使用。',
            '分期價除不盡餘額部分，將於第一期帳單收取。',
            '各產品可供分期期數不盡相同，依結帳時系統提供分期數為主；如一張訂單有多項商品，只會出現共同皆可使用之分期數。',
          ],
        ],
      },
      welfareModal: {
        title: '備註',
        content: [
          '福利AA品',
          '本產品為拆封九成五新或原廠整新非全新品，機身可能有輕微細紋，不影響功能。外盒可能有些許磨損或使用痕跡，規格及盒內配件以實際出貨為主，請不介意之消費者再進行購買。',
          '請您耐心等候，為保障您退換貨權益，請於安裝SIM卡前先行檢查商品外觀及隨盒配件是否完整。同一筆訂單中的商品會一起出貨，故可能因為訂單中加購的商品缺貨而導致訂單延遲出貨，下單前請務必再次確認商品型號/顏色/資費方案及加購商品是否正確。',
        ],
      },
      originFlow: Mock.planStep,
      applyFlow: [...Mock.planStep, ...Mock.newFlow],
      deliverySubmit: false,
      reservationSubmit: false,
      deliveryForm: {
        type: { value: '新申辦' },
        name: { value: '', required: true },
        contact: { value: '', required: true },
      },
      reservationForm: {
        reserve_no: { value: '', required: true },
        id_number: { value: '', required: true },
      },
      // 使用既有的表單組件，再根據表單的值修改帶入購物車的內容
      form: {
        compare: { value: [] },
        with_plan: { value: window.location.hash === '#device-alone' ? '0' : '1', required: true },
        input_validation: { value: '', required: true },
        id_number: { value: '', required: true },
        scid: { value: '', required: true },
        apply_method: { value: '' },
        number: { value: 1, required: true },
        plan: { value: '', required: true },
        phone_number: { value: '', required: true },
        gift: { value: '', required: true },
        extra_product: { value: '', required: true },
        extra_plan: { value: '', required: true },
      },
      cart: {
        apply_method: '',
        plan: null,
        product: null,
        identity_validation: null,
        input_validation: null,
        phone_number: null,
        gift: [],
        extra_product: [],
        extra_plan: [],
      },
      step: 0,
      editStep: -1,
    };
  }

  componentDidMount = () => {
    if (this.props.location.state && this.props.location.state.themeNumber) {
      this.setState({
        canEdit: false,
        applyFlow: [...Mock.planStep, ...Mock.themeNumberFlow],
        step: 2,
        form: {
          ...this.state.form,
          apply_method: { value: '申辦新門號' },
          phone_number: { value: this.props.location.state.themeNumber },
        },
        cart: {
          ...this.state.cart,
          apply_method: '申辦新門號',
          phone_number: this.props.location.state.themeNumber,
        },
      });

      this.forceUpdate();
      setTimeout(() => {
        this.scrollToPosition();
      }, 1000);
    }

    if (!!this.props.flowCart.cart) {
      this.setState({
        isBackward: true,
        cart: this.props.flowCart.cart,
        applyFlow: this.props.flowCart.applyFlow,
        step: this.props.flowCart.step,
        form: this.props.flowCart.form,
      });

      this.forceUpdate();

      setTimeout(() => {
        this.scrollToPosition();
      }, 1500);
    } else {
      this.setState({
        cart: {
          ...this.state.cart,
        },
      });
    }

    let unboxing = ['#intro', '#spec-compare', '#internal-function', '#comment'].indexOf(window.location.hash);
    this.setState({
      currentNav: unboxing > -1 ? unboxing : 0,
    });

    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', (e) => this.detectStep());

      if (window.innerWidth > 768) window.addEventListener('mousewheel', (e) => this.detectSection(e));
      else window.addEventListener('scroll', (e) => this.detectSection(e));
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('popstate', (e) => this.detectStep());
    if (window.innerWidth > 768) window.removeEventListener('mousewheel', (e) => this.detectSection(e));
    else window.removeEventListener('scroll', (e) => this.detectSection(e));
  };

  detectStep = (e) => {
    let step = this.state.editStep !== -1 ? this.state.editStep : this.state.step;

    if (
      (this.props.location.pathname.indexOf('/estore/rateplan') > -1 ||
        this.props.location.pathname.indexOf('/estore/rateplan/theme-number/plan-select') > -1) &&
      this.flowRef.current
    ) {
      console.log(this.props.location.pathname, 'in pathname');
      if (step - 1 >= 0) {
        this.changeFormSection(step - 1);
      } else {
        window.history.go(-1);
      }
    }

    return false;
  };

  detectSection = (e) => {
    if (this.state.form.with_plan.value === 0 || this.state.currentNav !== 0 || this.state.step > 0) return;

    if (this.flowRef.current) {
      let fistSec = this.flowRef.current.getElementsByTagName('section')[0];
      let fistRect = fistSec.getBoundingClientRect();
      let scrollY = window.scrollY || document.documentElement.scrollTop;

      if (scrollY > fistRect.top) {
        // debugger;
        this.props.history.push('#' + fistSec.id);
      }
    }
  };

  CheckboxTabChange = (idx) => {
    this.setState({
      currentCheckboxTab: idx,
    });
  };

  CarryTabChange = (idx) => {
    this.setState({
      currentCarryTab: idx,
    });
  };

  changeNavTab = (index) => {
    this.setState({
      currentNav: index,
    });
  };

  inputChange = (n, v, content) => {
    let form = Object.assign(this.state.form);

    // 如果已進行申辦流程，切換申辦方式時跳出 modal
    if (n === 'input_validation') {
      this.setState({
        inputValidationValid: /^09\d{2}-?\d{3}-?\d{3}$/.test(v),
      });
    }
    if (n === 'with_plan') {
      this.props.history.push(v === '0' ? '#device-alone' : this.props.location.pathname);
    }

    if (n === 'apply_method' && this.state.editStep !== -1) {
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'change-alert',
          originSelect: form[n].value,
          originFlow: this.state.applyFlow,
          title: '組合方案選項將被清除',
          content: '提醒你，剛才你選擇的組合方案將被清除，請再次確認是否要重新選擇？',
        },
      });
    }

    form[n].value = v;
    // 根據申辦方式切換申辦流程
    if (n === 'apply_method') {
      // 申辦新門號
      let flow = [];
      if (v === '申辦新門號') {
        flow = [...this.state.originFlow, ...Mock.newFlow];
      }
      // 攜碼到遠傳
      if (v === '攜碼') {
        flow = [...this.state.originFlow, ...Mock.carryFlow];
      }
      // 遠傳門號續約
      if (v === '遠傳門號續約') {
        flow = [...this.state.originFlow, ...Mock.renewFlow];
      }
      // 表單內容更新，購物車內容同步更新
      this.setState({
        cart: {
          ...this.state.cart,
          apply_method: v,
        },
        applyFlow: flow,
      });

      this.forceUpdate();
    }

    // 將完整資料帶入購物車
    if (!!content) {
      let cart = { ...this.state.cart };

      cart[n] = content;

      this.setState({
        cart: cart,
      });

      this.forceUpdate();
    }

    this.setState({
      form: form,
      cartUpdate: true,
    });

    this.forceUpdate();
  };

  openModal = (modal) => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'notice',
        ...modal,
      },
    });
  };

  // 取消切換申辦方式，還原為既有狀態
  cancelSelect = () => {
    let form = Object.assign(this.state.form);
    let cart = { ...this.state.cart };

    form.apply_method.value = this.state.currentModal.originSelect;
    cart.apply_method = this.state.currentModal.originSelect;

    this.setState({
      modalOpen: false,
      applyFlow: this.state.currentModal.originFlow,
      cart: { ...cart, apply_method: this.state.currentModal.originSelect },
      form: {
        ...form,
        apply_method: { value: this.state.currentModal.originSelect },
      },
    });
  };

  resetFormset = () => {
    // 重置 cart 與 form
    this.setState({
      cart: {
        ...this.state.cart,
        plan: null,
        input_validation: { value: '', required: true },
        id_number: { value: '', required: true },
        scid: { value: '', required: true },
        gift: [],
        extra_product: [],
        extra_plan: [],
        product: null,
      },
      form: {
        ...this.state.form,
        identity_validation: null,
        input_validation: null,
        number: { value: 1, required: true },
        plan: { value: '', required: true },
        phone_number: { value: '', required: true },
        gift: { value: '', required: true },
        extra_product: { value: '', required: true },
        extra_plan: { value: '', required: true },
      },
      compare: false,
      editStep: -1,
      step: 0,
      modalOpen: false,
    });
  };

  stepChange = (step) => {
    this.setState({
      editStep: -1,
      step: step,
    });

    this.forceUpdate();
    setTimeout(() => {
      this.scrollToPosition();
    }, 500);
  };

  scrollToPosition = () => {
    // 捲動到對應位置
    let elem = window || document.documentElement;
    if (!this.flowRef.current) {
      if (window.isIE) {
        elem.scroll(0, 0);
      } else {
        elem.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
          cartUpdate: true,
        });
      }
      return;
    }

    let step = this.state.editStep !== -1 ? this.state.editStep : this.state.step;
    let flows = this.flowRef.current.getElementsByClassName('fui-plan-flow');
    let lastFlow = flows[step];
    let prevFlow =
      flows[
        this.state.editStep !== -1 ? (this.state.editStep === 0 ? 0 : this.state.editStep - 1) : this.state.step - 1
      ];
    let rect = lastFlow.getBoundingClientRect();
    let prevRect = prevFlow.getBoundingClientRect();
    let scrollY = window.scrollY || document.documentElement.scrollTop;
    // console.log('scrollToPosition', scrollY + rect.top - prevRect.height - 110);
    if (window.isIE) {
      elem.scroll(0, scrollY + rect.top - prevRect.height - 50);
    } else {
      elem.scrollTo({
        left: 0,
        top: scrollY + rect.top - prevRect.height - 50,
        behavior: 'smooth',
        cartUpdate: true,
      });
    }

    this.props.history.push('#' + this.state.applyFlow[step].id);
  };

  phoneCheck = () => {
    // 為顯示不同狀態的畫面，不同次點擊切換不同狀態
    if (this.state.identityPass) {
      this.setState({
        currentModal: {
          type: 'discount',
          title: '限定好康優惠',
          content: `<p>恭喜您！<br/>
          獲得今日限定 300 元商品購物金，不限申辦資費方案，趕快記下您的折扣碼，在結帳時使用吧！</p>
          
          <p class="is-text-error">優惠折扣碼 fet6666</p>`,
        },
        modalOpen: true,
      });

      this.setState({
        cartUpdate: true,
        cart: {
          ...this.state.cart,
          input_validation: {
            phone_number: this.state.form.input_validation.value,
            isPass: true,
          },
        },
      });
    } else
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'alert',
          title: '還未符合續約資格',
          content: `很抱歉，你的身份條件暫時無法續約。請洽遠傳門市或手機直撥 <a href='tel:123' target='_blank'>123客服專線</a> 查詢。`,
          action: [
            {
              btnStyle: 'primary',
              text: '可續約時通知我',
              event: this.doModalEvent,
            },
            {
              btnStyle: 'secondary',
              text: '查看原合約',
              event: this.doModalEvent,
            },
          ],
        },
      });

    this.setState({
      identityPass: !this.state.identityPass,
    });
  };

  doModalEvent = () => {
    this.setState({
      modalOpen: false,
    });
  };

  identityCheck = () => {
    // doValidation
    // 驗證成功
    if (this.state.identityPass)
      this.setState({
        cart: {
          ...this.state.cart,
          identity_validation: {
            id_number: this.state.form.id_number.value,
            scid: this.state.form.scid.value,
            isPass: true,
          },
        },
      });
    // 如果驗證失敗
    else
      this.setState({
        modalOpen: true,
        currentModal: {
          title: '還未符合續約資格',
          content: `很抱歉，你的身份條件暫時無法續約。請洽遠傳門市或手機直撥 <a href='tel:123' target='_blank'>123客服專線</a> 查詢。`,
          action: [
            {
              btnStyle: 'primary',
              text: '可續約時通知我',
              event: this.doModalEvent,
            },
            {
              btnStyle: 'secondary',
              text: '查看原合約',
              event: this.doModalEvent,
            },
          ],
        },
      });

    this.setState({
      identityPass: !this.state.identityPass,
    });
  };

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  changeFormSection = (index) => {
    if (this.state.applyFlow[index].type === 'identity-validation') {
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'change-alert',
          flowIndex: index,
          flowType: this.state.applyFlow[index].type,
          originFlow: this.state.applyFlow,
          title: '組合方案選項將被清除',
          content: '提醒你，剛才你選擇的組合方案將被清除，請再次確認是否要重新選擇？',
        },
      });
    } else {
      this.setState({
        editStep: index,
      });

      this.forceUpdate();
      setTimeout(() => {
        this.scrollToPosition();
        this.props.history.push(`#${this.state.applyFlow[index].id}`);
      }, 500);
    }
  };

  valuePreview = (flow) => {
    // 根據區塊名稱顯示個別內容
    if (flow.tabs) {
      return '';
    } else if (flow.name === 'identity_validation') {
      return this.state.form.scid.value;
    } else if (flow.name === 'input_validation') {
      return this.state.form.input_validation.value;
    } else if (flow.name === 'gift') {
      return this.state.cart[flow.name].length ? '贈品 x' + this.state.cart[flow.name].length : '';
    } else {
      return this.state.form[flow.name].value;
    }
  };

  enableDeliveryButton = () => {
    this.setState({
      deliverySubmit: true,
    });
  };

  disableDeliveryButton = () => {
    this.setState({
      deliverySubmit: false,
    });
  };

  enableReservationButton = () => {
    this.setState({
      reservationSubmit: true,
    });
  };

  disableReservationButton = () => {
    this.setState({
      reservationSubmit: false,
    });
  };

  deliverySubmit = () => {
    // 貨到通知送出
    console.info('貨到通知送出', this.state.deliveryForm);
  };

  reservationSubmit = () => {
    // 預約取貨送出
    console.info('預約取貨送出', this.state.reservationForm);
  };

  compareChange = (compare) => {
    this.setState({
      compare: compare,
    });
  };

  doCompare = () => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'compare',
        title: '資費比比看',
        compareList: this.state.form.compare.value,
      },
    });
  };

  cancelCompare = () => {
    this.setState({
      form: {
        ...this.state.form,
        compare: { value: [] },
      },
      compare: false,
    });
  };

  submit = () => {
    this.props.setFlowCartData({
      cart: this.state.cart,
      form: this.state.form,
      applyFlow: this.state.applyFlow,
      step: this.state.step,
    });

    this.props.history.push('/estore/order/orderlist');
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalConfirm = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    if (type === 'change-alert') {
      const { currentModal } = this.state;
      if (currentModal.flowType && currentModal.flowType === 'identity-validation') {
        this.setState({
          step: currentModal.flowIndex,
          editStep: -1,
          form: {
            ...this.state.form,
            input_validation: { value: '', required: true },
            id_number: { value: '', required: true },
            scid: { value: '', required: true },
            phone_number: { value: '', required: true },
            gift: { value: '', required: true },
            extra_product: { value: '', required: true },
            extra_plan: { value: '', required: true },
          },
          cart: {
            ...this.state.cart,
            phone_number: null,
            identity_validation: null,
            input_validation: null,
            gift: [],
            extra_product: [],
            extra_plan: [],
            product: null,
          },
        });
        this.props.history.push(`#rn`);
      } else {
        this.resetFormset();
      }
    }
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalCancel = (type) => {
    // 預約取貨送出
    console.info('MODAL CANCEL', type);
    if (type === 'change-alert') {
      this.cancelSelect();
    }

    if (type === 'compare') {
      this.cancelCompare();
    }

    this.setState({
      modalOpen: false,
    });

    this.forceUpdate();
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { form, deliveryForm, reservationForm } = this.state;

    return (
      <main>
        <Helmet>
          <title></title>
        </Helmet>
        <Breadcrumb
          color='white'
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '網路門市', link: '/estore' },
            { text: '找方案', link: '/estore/rateplan' },
          ]}
        />
        {/* <NavTab
          pageTitle='網路門市'
          buttons
          ={[
            { btnStyle: 'primary', text: '新申辦', link: '#' },
            { btnStyle: 'secondary', text: '推薦續約', link: '#' },
          ]}
        /> */}
        <AdaptHeightBanner
          bgImg={{
            md: '/resources/cbu/estore-rate-plan/cbu-findplan-banner-1920x360.jpg',
            sm: '/resources/cbu/estore-rate-plan/estore-banner-m-4@2x.jpg',
          }}
          title='線上熱銷資費方案'
          height={{
            desktop: 360,
            mobile: 180,
          }}
        />
        <NavContentTab2
          className='is-scroller'
          tabs={{
            name: 'rate-plan-tab',
            default: 0,
            list: [
              {
                label: '單辦門號',
                link: '/estore/rateplan',
              },
              {
                label: '門號搭商品',
                link: '/estore/product',
              },
              {
                label: '找續約優惠',
                link: '/estore/renewal',
              },
              {
                label: '找個好門號',
                link: '/estore/rateplan/theme-number',
              },
            ],
          }}
        />

        <Formsy
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          className={`has-compare ${this.state.compare ? 'is-compare' : ''}`}>
          {/* 申辦步驟 */}
          {form.with_plan.value === '1' ? (
            <div className='fui-plan-flow-container' ref={this.flowRef}>
              {this.state.applyFlow.map((flow, i) =>
                this.state.step >= i ? (
                  <section
                    id={flow.id}
                    className={`fui-plan-flow 
                    ${flow.type === 'radio-card' && this.state.compare ? 'is-compare' : ''} 
                    ${
                      (this.state.step === i && this.state.editStep === -1) ||
                      (this.state.editStep > -1 && this.state.editStep === i)
                        ? 'is-active'
                        : ''
                    }`}
                    key={`fui-plan-flow-${i}`}>
                    <div className='fui-container'>
                      <div className='fui-flow-title'>
                        <div className='content'>
                          <h2>
                            {flow.type === 'radio-card' && this.state.compare ? (
                              <span>資費比比看</span>
                            ) : (
                              <span>
                                {i + 1}.{flow.title}
                              </span>
                            )}
                            <span className='selected-value'>{this.valuePreview(flow)}</span>
                          </h2>
                        </div>
                        {this.state.canEdit ? (
                          <div className='extra'>
                            <Button btnStyle='secondary' size='small' onClick={(e) => this.changeFormSection(i)}>
                              {this.state.cart[flow.name] === '' ||
                              !this.state.cart[flow.name] ||
                              (Array.isArray(this.state.cart[flow.name]) && !this.state.cart[flow.name].length)
                                ? '尚未選擇'
                                : '修改'}
                            </Button>
                          </div>
                        ) : null}
                      </div>
                      <div className='fui-flow-body'>
                        {flow.type === 'radio-card' && this.state.compare ? (
                          <h6 className='fui-flow-description is-text-error'>
                            勾選以下方案可以比比看資費內容，一次最多比三個！
                          </h6>
                        ) : (
                          <h6 className='fui-flow-description'>
                            {flow.name === 'apply_method' ? ' ' : flow.description}
                          </h6>
                        )}

                        {/* 根據類別切換表單內容 */}
                        {/* 有 Tab 的產品牌卡，用於加購 */}
                        {flow.type === 'tab-checkbox-card' ? (
                          <div>
                            <Tab name={flow.tabs.name} list={flow.tabs.list} onChange={this.CheckboxTabChange} />
                            {flow.tabs.content.map((tab, i) => (
                              <TabPane key={`tab-checkbox-${i}`} active={i === this.state.currentCheckboxTab}>
                                <CheckboxCardCarousel
                                  {...tab}
                                  value={form[tab.name].value}
                                  onChange={this.inputChange}
                                  modalOpen={this.openModal}
                                />
                              </TabPane>
                            ))}
                          </div>
                        ) : null}
                        {/* 門號驗證 */}
                        {flow.type === 'identity-validation' ? (
                          <div className='paper fui-validation-input'>
                            <Grid container>
                              <Grid item xs={12} sm={8} md={6}>
                                <LabelInput
                                  label='遠傳門號'
                                  placeholder='輸入門號'
                                  validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
                                  required={form.scid.required}
                                  name='scid'
                                  validationErrors={{
                                    isDefaultRequiredValue: '請輸入原門號號碼',
                                    matchRegexp: '您輸入了錯誤資訊，請再檢查！',
                                  }}
                                  withValidCheck={
                                    this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                  }
                                  value={form.scid.value}
                                  onChange={this.inputChange}
                                />
                                <LabelInput
                                  label='身分證字號'
                                  placeholder='輸入身分證字號'
                                  required={form.id_number.required}
                                  name='id_number'
                                  validations={{
                                    idNumberValid: Validation.idNumberValid,
                                  }}
                                  validationErrors={{
                                    isDefaultRequiredValue: '請輸入身分證字號',
                                    idNumberValid: '您輸入了錯誤資訊，請再檢查！',
                                  }}
                                  withValidCheck={
                                    this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                  }
                                  value={form.id_number.value}
                                  onChange={this.inputChange}
                                />
                                {!(
                                  this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                ) ? (
                                  <Button
                                    btnStyle='primary'
                                    size='large'
                                    disabled={form.scid.value === '' || form.id_number.value === ''}
                                    onClick={this.optVarify}>
                                    查詢可續約方案
                                  </Button>
                                ) : null}
                              </Grid>
                            </Grid>
                          </div>
                        ) : null}
                        {/* 攜入門號驗證 */}
                        {flow.type === 'input-validation' ? (
                          <div className='fui-validation-input'>
                            <Grid container>
                              <Grid item xs={12}>
                                {flow.tabs ? (
                                  <div>
                                    <Tab name={flow.tabs.name} list={flow.tabs.list} onChange={this.CarryTabChange} />
                                    {flow.tabs.content.map((tab, i) => (
                                      <TabPane key={`tab-inputvalid-${i}`} active={i === this.state.currentCarryTab}>
                                        <div
                                          className={`fui-cards is-simple-step ${
                                            tab.length === 3 ? 'three' : 'four'
                                          }-card no-scrollbar`}>
                                          {tab.map((card, c) => (
                                            <div className='fui-card' key={`input-validation-step-${c}`}>
                                              <div className='fui-card-action'>
                                                <div className='fui-card-caption'>
                                                  <div className='fui-card-content'>
                                                    <div className='circle-number'>{card.no}</div>
                                                    <h3
                                                      className='fui-card-title'
                                                      dangerouslySetInnerHTML={{ __html: card.title }}></h3>
                                                    <p
                                                      className='fui-card-description'
                                                      dangerouslySetInnerHTML={{ __html: card.description }}></p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </TabPane>
                                    ))}
                                  </div>
                                ) : (
                                  <div className='fui-cards three-card no-scrollbar'>
                                    {flow.cards.map((card, c) => (
                                      <div className='fui-card' key={`input-validation-step-${c}`}>
                                        <div className='fui-card-action'>
                                          <div className='fui-card-caption'>
                                            <div className='fui-card-content'>
                                              <div className='circle-number'>{card.no}</div>
                                              <h3 className='fui-card-title'>{card.title}</h3>
                                              <p className='fui-card-description'>{card.description}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={8} md={6}>
                                <LabelInput
                                  label='輸入攜入門號'
                                  placeholder='輸入非遠傳門號'
                                  validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
                                  required={form.input_validation.required}
                                  name='input_validation'
                                  validationErrors={{
                                    isDefaultRequiredValue: '您輸入了錯誤資訊，請再檢查！',
                                    matchRegexp: '您輸入了錯誤資訊，請再檢查！',
                                  }}
                                  value={form.input_validation.value}
                                  withValidCheck={
                                    this.state.cart.input_validation && this.state.cart.input_validation.isPass
                                  }
                                  onChange={this.inputChange}
                                />
                                <div
                                  className='text-sm is-text-gray100'
                                  dangerouslySetInnerHTML={{ __html: flow.note }}></div>
                                {!(this.state.cart.input_validation && this.state.cart.input_validation.isPass) ? (
                                  <Button
                                    btnStyle='primary'
                                    size='large'
                                    disabled={!this.state.inputValidationValid}
                                    onClick={this.phoneCheck}>
                                    驗證號碼
                                  </Button>
                                ) : null}
                              </Grid>
                            </Grid>
                          </div>
                        ) : null}
                        {/* 產品牌卡，用於好禮 */}
                        {flow.type === 'checkbox-card' ? (
                          <CheckboxCardCarousel
                            name={flow.name}
                            {...flow}
                            value={form[flow.name].value}
                            onChange={this.inputChange}
                            modalOpen={this.openModal}
                          />
                        ) : null}
                        {/* 單選 Radio Button，選擇門號 */}
                        {flow.type === 'radio-carousel' ? (
                          <RadioCarousel
                            search={true}
                            name={flow.name}
                            {...flow}
                            disabled={this.state.isBackward}
                            value={form[flow.name].value}
                            onChange={this.inputChange}
                          />
                        ) : null}
                        {/* 單選牌卡，選擇資費方案 */}
                        {flow.type === 'radio-card' ? (
                          <RadioCard
                            name={flow.name}
                            tags={flow.tags}
                            options={flow.options}
                            value={this.state.compare ? form.compare.value : form[flow.name].value}
                            onChange={this.inputChange}
                            modalOpen={this.openModal}
                            hasCompare={true}
                            isCompare={this.state.compare}
                            onCompareChange={this.compareChange}
                          />
                        ) : null}
                        {/* 單純的 Radio Button，用於申請方式 */}
                        {flow.type === 'radio' ? (
                          <RadioGroup
                            label=''
                            name={flow.name}
                            options={flow.options}
                            description={flow.description}
                            default={form[flow.name].value}
                            value={form[flow.name].value}
                            onChange={this.inputChange}
                            modalOpen={this.openModal}
                          />
                        ) : null}
                      </div>
                    </div>
                  </section>
                ) : null
              )}
            </div>
          ) : null}
          {/* 購物車 */}
          <Cart
            canRollback={this.state.canEdit}
            afterUpdate={(e) => this.setState({ cartUpdate: false })}
            update={this.state.cartUpdate}
            steps={this.state.applyFlow}
            currentStep={this.state.step}
            openStep={this.state.editStep === -1 ? this.state.step : this.state.editStep}
            cart={this.state.cart}
            submit={(e) => this.submit()}
            stepChange={(e) => this.stepChange(e)}
            editStep={(e) => this.changeFormSection(e)}
          />

          <CompareBar
            list={form.compare.value}
            open={this.state.compare}
            doCompare={this.doCompare}
            cancelCompare={this.cancelCompare}
          />
        </Formsy>

        <EstoreModal
          {...this.state.currentModal}
          open={this.state.modalOpen}
          onSubmit={(e, f) => this.modalSubmit(e, f)}
          onConfirm={(e, f) => this.modalConfirm(e, f)}
          onCancel={(e, f) => this.modalCancel(e, f)}
          onClose={(e, f) => this.modalClose(e, f)}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    analyticsInitialState: state.analyticsInitialState, // 可加可不加
    flowCart: state.flowCartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      trackPageViewStart,
      setFlowCartData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RatePlan);
