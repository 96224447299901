import React from 'react';

import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setFlowCartData, trackPageViewStart } from '../../stores/action';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import Link from '../../components/Link';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import Tab from '../../components/tab/Tab';
import TabPane from '../../components/tab/TabPane';

import ColorSelector from '../../components/form/ColorSelector';
import RadioButtons from '../../components/form/RadioButtons';
import RadioGroup from '../../components/form/RadioGroup';
import NumberSelector from '../../components/form/NumberSelector';
import Switch from '../../components/Switch';
import RadioCard from '../../components/form/RadioCard';
import RadioCarousel from '../../components/form/RadioCarousel';
import CheckboxCardCarousel from '../../components/form/CheckboxCardCarousel';
import LabelInput from '../../components/form/LabelInput';
import EstoreModal from '../../components/EstoreModal';
import Cart from '../../components/FlowCart';
import CompareBar from '../../components/CompareBar';
import ProductCard from '../../components/card/ProductCard';
import PromotionCard from '../../components/card/PromotionCard';
import ProdPromoCard from '../../components/card/ProdPromoCard';
import CombineCard from '../../components/card/CombineCard';
import ExclusiveRadioCard from '../../components/card/ExclusiveRadioCard';
import PlanCard from '../../components/card/PlanCard';
import LoadMore from '../../components/LoadMore';

import * as Validation from '../../utils/validation';
import formatNumber from '../../utils/numberFormatter';
import * as Mock from '../../mock/Renewal';

class RenewalIndex extends React.Component {
  constructor(props) {
    super(props);

    this.usageSec = React.createRef();
    this.flowRef = React.createRef();
    this.state = {
      currentTab:
        this.props.location.pathname.indexOf('rateplan') > -1
          ? 1
          : this.props.location.pathname.indexOf('phone') > -1
          ? 2
          : 0,
      showPhoneFilter: false,
      showThreeCFilter: false,
      cuurentCheckboxTab: 0,
      currentPlan: {
        price: 1399,
        scid: '0923-456-789',
        end_at: '2020/02/15',
        usage: {
          internet: 12,
          intranet: 10,
          citytalk: 20,
          transport: 238,
        },
        usageSecHeight: 0,
      },
      recommandPlan: Mock.recommandPlan,
      isProdLoading: false,
      currentProduct: 'phone',
      compare: false,
      exclusiveForm: {
        exclusive: { value: '' },
      },
      form: {
        compare: { value: [] },
        plan: { value: '' },
        gift: { value: '' },
        extra_product: { value: '', required: true },
        extra_plan: { value: '', required: true },
      },
      exclusiveFlow: [...Mock.exclusiveFlow],
      applyFlow: [...Mock.newFlow],
      usageOpen: false,
      modalOpen: false,
      inputValidationValid: false,
      cartUpdate: false,
      currentModal: {
        type: '',
        title: '',
        content: '',
      },
      sldierSetting: {
        dots: true,
        infinite: false,
        arrows: true,
        slidesToShow: 1,
        draggable: true,
        adaptiveHeight: true,
      },
      welfareModal: {
        type: 'notice',
        title: '備註',
        content: [
          '本產品為拆封九成五新或原廠整新非全新品，機身可能有輕微細紋，不影響功能。外盒可能有些許磨損或使用痕跡，規格及盒內配件以實際出貨為主，請不介意之消費者再進行購買。',
          '請您耐心等候，為保障您退換貨權益，請於安裝SIM卡前先行檢查商品外觀及隨盒配件是否完整。同一筆訂單中的商品會一起出貨，故可能因為訂單中加購的商品缺貨而導致訂單延遲出貨，下單前請務必再次確認商品型號/顏色/資費方案及加購商品是否正確。',
        ],
      },
      searchProduct: {
        is3C: { value: false },
        brand: { value: '全部品牌' },
        keyword: { value: '' },
      },
      phoneBrading: [
        { text: '全部品牌', value: '全部品牌' },
        { text: 'APPLE', value: 'APPLE' },
        { text: 'SAMSUNG', value: 'SAMSUNG' },
        { text: 'LG', value: 'LG' },
      ],
      search3C: {
        brand: { value: '全部品牌' },
        keyword: { value: '' },
      },
      threeCBranding: [
        { text: '全部品牌', value: '全部品牌' },
        { text: 'APPLE', value: 'APPLE' },
        { text: 'SAMSUNG', value: 'SAMSUNG' },
        { text: 'LG', value: 'LG' },
      ],
      productList: {
        total: 0,
        list: [],
        current: 0,
        perPage: 12,
        pages: 0,
      },
      exclusiveCart: {
        product: null,
        plan: null,
      },
      cart: {
        apply_method: '',
        plan: null,
        product: null,
        identity_validation: null,
        input_validation: null,
        phone_number: null,
        gift: [],
        extra_product: [],
        extra_plan: [],
      },
      step: 0,
      editStep: -1,
    };
  }

  componentDidMount = () => {
    this.getMorePage();

    if (!!this.props.flowCart.cart) {
      this.setState({
        cart: this.props.flowCart.cart,
        applyFlow: this.props.flowCart.applyFlow,
        step: this.props.flowCart.step,
        form: this.props.flowCart.form,
      });

      this.forceUpdate();

      setTimeout(() => {
        this.scrollToPosition();
      }, 1500);
    } else {
      this.setState({
        cart: {
          ...this.state.cart,
        },
      });
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', (e) => this.detectStep());

      if (window.innerWidth > 768) window.addEventListener('mousewheel', (e) => this.detectSection(e));
      else window.addEventListener('scroll', (e) => this.detectSection(e));
    }

    // console.log(this.state.applyFlow[this.state.step]);
    if (this.state.currentTab === 1 && this.state.applyFlow[this.state.step].name === 'plan') {
      setTimeout(() => {
        this.setState({
          currentModal: {
            type: 'discount',
            title: '限定好康優惠',
            content: `<p>恭喜您！<br/>
          獲得今日限定 300 元商品購物金，不限申辦資費方案，趕快記下您的折扣碼，在結帳時使用吧！</p>
          
          <p class="is-text-error">優惠折扣碼 fet6666</p>`,
          },
          modalOpen: true,
        });
      }, 1000);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('popstate', (e) => this.detectStep());
    if (window.innerWidth > 768) window.removeEventListener('mousewheel', (e) => this.detectSection(e));
    else window.removeEventListener('scroll', (e) => this.detectSection(e));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.currentTab !== this.state.currentTab) {
      this.setState({
        step: 0,
      });

      // console.log(this.state.applyFlow[this.state.step]);
      if (this.state.currentTab === 1 && this.state.applyFlow[this.state.step].name === 'plan') {
        setTimeout(() => {
          this.setState({
            currentModal: {
              type: 'discount',
              title: '限定好康優惠',
              content: `<p>恭喜您！<br/>
            獲得今日限定 300 元商品購物金，不限申辦資費方案，趕快記下您的折扣碼，在結帳時使用吧！</p>
            
            <p class="is-text-error">優惠折扣碼 fet6666</p>`,
            },
            modalOpen: true,
          });
        }, 1000);
      }
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        currentTab:
          this.props.location.pathname.indexOf('rateplan') > -1
            ? 1
            : this.props.location.pathname.indexOf('phone') > -1
            ? 2
            : 0,
      });
    }
  };

  detectStep = (e) => {
    let step = this.state.editStep !== -1 ? this.state.editStep : this.state.step;

    if (this.props.location.pathname.indexOf('/estore/renewal/rateplan') > -1 && this.flowRef.current) {
      if (step - 1 >= 0) {
        this.changeFormSection(step - 1);
      } else {
        window.history.go(-1);
      }
    }

    return false;
  };

  CheckboxTabChange = (idx) => {
    this.setState({
      cuurentCheckboxTab: idx,
    });
  };

  getMorePage = () => {
    let type = this.state.searchProduct.is3C.value;
    let prods = this.state.productList;

    let list = this.searchList(Object.assign(!type ? Mock.phone : Mock.threeC), !type ? 'phone' : 'threeC');
    let pages = Math.ceil(list.length / prods.perPage);

    this.setState({
      productList: {
        perPage: prods.perPage,
        current: prods.current + 1,
        total: list.length,
        list: [...prods.list, ...list.slice(prods.current * prods.perPage, (prods.current + 1) * prods.perPage)],
        pages: pages,
      },
      isProdLoading: false,
    });

    this.forceUpdate();
  };

  searchList = (list, type) => {
    const form = this.state.searchProduct;
    let brandEmpty = form.brand.value === '' || form.brand.value === '全部品牌';
    let keywordEmpty = form.keyword.value === '';

    let result = list.reduce((accr, value, index) => {
      if (brandEmpty && form.keyword.value === '') {
        accr.push(value);
      } else {
        if (value.type === 'product' && value.meta === form.brand.value) {
          accr.push(value);
        } else if (!keywordEmpty && value.title.indexOf(form.keyword.value) > -1) {
          accr.push(value);
        }
      }

      return accr;
    }, []);

    return result;
  };

  detectSection = (e) => {
    if (this.state.step > 0 || this.state.currentTab > 0) return;

    if (this.flowRef.current) {
      let fistSec = this.flowRef.current.getElementsByTagName('section')[0];
      let fistRect = fistSec.getBoundingClientRect();
      let scrollY = window.scrollY || document.documentElement.scrollTop;

      if (scrollY > fistRect.top) {
        // debugger;
        this.props.history.push('#' + fistSec.id);
      }
    }
  };

  toggleUsage = () => {
    this.setContentHeight();
    this.setState({ usageOpen: !this.state.usageOpen });
  };

  setContentHeight = () => {
    this.setState({
      usageSecHeight: this.usageSec.current.clientHeight,
    });
  };

  stepChange = (step) => {
    this.setState({
      editStep: -1,
      step: step,
    });

    this.forceUpdate();
    setTimeout(() => {
      this.scrollToPosition();
    }, 500);
  };

  searchInputChange = (n, v, form) => {
    let newForm = Object.assign(this.state[form]);
    let isDiffProd = n === 'is3C' && this.state[form][n].value !== v;

    console.log(v);
    newForm[n].value = v;

    this.setState({
      [form]: newForm,
      isProdLoading: isDiffProd,
      productList: isDiffProd
        ? {
            total: 0,
            list: [],
            current: 0,
            perPage: 12,
            pages: 0,
          }
        : this.state.productList,
    });
    this.forceUpdate();

    if (isDiffProd) {
      setTimeout(() => {
        this.getMorePage();
      }, 200);
    }
  };

  resetSearch = () => {
    this.setState({
      searchProduct: {
        is3C: { value: false },
        brand: { value: '全部品牌' },
        keyword: { value: '' },
      },
    });
  };

  searchProduct = () => {
    this.setState({
      showThreeCFilter: false,
    });
  };

  exclusiveChange = (n, v, content) => {
    let form = Object.assign(this.state.exclusiveForm);
    form[n].value = v;
    this.setState({
      exclusiveCart: {
        ...this.state.exclusiveCart,
        product:
          content.type === 'combine'
            ? {
                ...content.product,
                color: content.product.option.selected,
              }
            : null,
        plan: content.type === 'combine' ? content.plan : content,
      },
      exclusiveForm: form,
    });

    this.forceUpdate();
  };

  inputChange = (n, v, content) => {
    let form = Object.assign(this.state.form);

    // 如果已進行申辦流程，切換申辦方式時跳出 modal
    if (n === 'with_plan') {
      this.props.history.push(v === '0' ? '#device-alone' : this.props.location.pathname);
    }
    if (
      this.state.applyFlow[this.state.editStep > -1 ? this.state.editStep : this.state.step].type === 'input-validation'
    ) {
      this.setState({
        inputValidationValid: /^09\d{2}-?\d{3}-?\d{3}$/.test(v),
        cart: {
          ...this.state.cart,
          input_validation: {
            phone_number: v,
            isPass: false,
          },
        },
      });
    }
    if (this.state.applyFlow[this.state.step].type === 'identity-validation') {
      this.setState({
        inputValidationValid:
          this.state.form.phone_number.value !== '' &&
          this.state.form.scid.value !== '' &&
          /^09\d{2}-?\d{3}-?\d{3}$/.test(this.state.form.scid.value),
        cart: {
          ...this.state.cart,
          identity_validation: {
            id_number: this.state.form.phone_number.value,
            scid: this.state.form.scid.value,
            isPass: false,
          },
        },
      });
    }

    if (n === 'apply_method' && this.state.editStep !== -1) {
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'change-alert',
          originSelect: form[n].value,
          originFlow: this.state.applyFlow,
          title: '組合方案選項將被清除',
          content: '提醒你，剛才你選擇的組合方案將被清除，請再次確認是否要重新選擇？',
        },
      });
    }

    form[n].value = v;

    // 切換顏色，圖片跟著切換內容
    if (n === 'color') {
      this.setState({
        currentImage: this.state.productInfo.images[v],
        cart: {
          ...this.state.cart,
          apply_method: '',
          plan: null,
          product: null,
          identity_validation: null,
          input_validation: null,
          phone_number: null,
          gift: [],
          extra_product: [],
          extra_plan: [],
        },
      });

      form = {
        ...form,
        input_validation: { value: '', required: true },
        id_number: { value: '', required: true },
        scid: { value: '', required: true },
        apply_method: { value: '' },
        plan: { value: '', required: true },
        phone_number: { value: '', required: true },
        gift: { value: '', required: true },
        extra_product: { value: '', required: true },
        extra_plan: { value: '', required: true },
      };
    }

    // 將完整資料帶入購物車
    if (!!content) {
      let cart = { ...this.state.cart };

      cart[n] = content;

      this.setState({
        cart: cart,
      });

      this.forceUpdate();
    }

    this.setState({
      form: form,
      cartUpdate: true,
    });

    this.forceUpdate();
  };

  scrollToPosition = () => {
    // 捲動到對應位置
    let elem = window || document.documentElement;

    if (!this.flowRef.current) {
      if (window.isIE) {
        elem.scroll(0, 0);
      } else {
        elem.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
          cartUpdate: true,
        });
      }
      return;
    }

    let index =
      this.state.editStep !== -1 ? (this.state.editStep === 0 ? 0 : this.state.editStep - 1) : this.state.step - 1;
    let flows = this.flowRef.current.getElementsByClassName('fui-plan-flow');
    let lastFlow = flows[this.state.editStep !== -1 ? this.state.editStep : this.state.step];
    let prevFlow = flows[index];
    let rect = lastFlow.getBoundingClientRect();
    let prevRect = prevFlow.getBoundingClientRect();
    let scrollY = window.scrollY || document.documentElement.scrollTop;

    if (window.isIE) {
      elem.scroll(0, index <= 0 ? scrollY + rect.top - 100 : rect.top - prevRect.height + scrollY - 50);
    } else {
      elem.scrollTo({
        left: 0,
        top: index <= 0 ? scrollY + rect.top - 100 : scrollY - prevRect.height + rect.top - 50,
        behavior: 'smooth',
        cartUpdate: true,
      });
    }

    this.props.history.push('#' + this.state.applyFlow[this.state.step].id);
  };

  openModal = (modal) => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'notice',
        ...modal,
      },
    });
  };

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  openNoticeModal = () => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'notice',
        title: '活動小叮嚀',
        content: [
          '活動小叮嚀',
          '活動小叮嚀活動小叮嚀活動小叮嚀活動小叮嚀活動小叮嚀活動小叮嚀活動小叮嚀活動小叮嚀活動小叮嚀活動小叮嚀活動小叮嚀',
          '活動小叮嚀活動小叮嚀活動小叮嚀活動小叮嚀活動小叮嚀',
        ],
      },
    });
  };

  compareChange = (compare) => {
    this.setState({
      compare: compare,
    });
  };

  doCompare = () => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'compare',
        title: '資費比比看',
        compareList: this.state.form.compare.value,
      },
    });
  };

  cancelCompare = () => {
    this.setState({
      form: {
        ...this.state.form,
        compare: { value: [] },
      },
      compare: false,
    });
  };

  changeFormSection = (index) => {
    this.setState({
      editStep: index,
    });

    this.forceUpdate();

    setTimeout(() => {
      this.scrollToPosition();
      this.props.history.push(`#${this.state.applyFlow[index].id}`);
    }, 500);
  };

  triggerCard = (link) => {
    this.props.history.push(link, {
      applyMethod: 'renewal',
      phoneNumber: '0923-456-789',
      idNumber: 'A123456789',
    });
    this.forceUpdate();
  };

  valuePreview = (flow) => {
    // 根據區塊名稱顯示個別內容
    if (flow.tabs) {
      return '';
    } else if (flow.name === 'identity_validation') {
      return this.state.form.scid.value;
    } else if (flow.name === 'input_validation') {
      return this.state.form.input_validation.value;
    } else if (flow.name === 'gift') {
      return this.state.cart[flow.name].length ? '贈品 x' + this.state.cart[flow.name].length : '';
    } else {
      return this.state.form[flow.name].value;
    }
  };

  productColorChange = (selected, i) => {
    let list = Object.assign(Mock.recommandPlan);
    list[i].product.option.selected = selected;

    this.setState({
      recommandPlan: list,
    });
  };

  changeNumber = () => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'notice',
        title: '想換門號續約？',
        content: `
          <p>
          【電腦版用戶】請先至”右上方選單”，進行帳號登出後，再使用新的續約門號重新登入
          </p>
          <br/>
          <p>
          【手機版用戶】請先至”左上方選單”，進行帳號登出後，再使用新的續約門號重新登入
          </p>
        `,
      },
    });
  };

  submit = () => {
    this.props.setFlowCartData({
      cart: this.state.cart,
      form: this.state.form,
      applyFlow: this.state.applyFlow,
      step: this.state.step,
    });

    this.props.history.push('/estore/order/orderlist');
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalConfirm = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalCancel = (type) => {
    // 預約取貨送出
    console.info('MODAL CANCEL', type);
    if (type === 'compare') {
      this.cancelCompare();
    }

    this.setState({
      modalOpen: false,
    });

    this.forceUpdate();
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { form, currentPlan, currentTab, searchProduct, productList } = this.state;
    return (
      <main>
        {/* <NavAnchor pageTitle='老客戶續約' /> */}
        <Breadcrumb
          breadcrumb={[
            {
              text: '個人首頁',
              link: '/',
            },
            {
              text: '網路門市',
              link: '/estore',
            },
            {
              text: '推薦續約',
              link: '/estore/renewal',
            },
          ]}
        />
        <section className='login-recommend pb-0'>
          <div className='fui-container'>
            <h2 className='mb-1'>早安！</h2>
            <h4 className='number-preview is-text-regular mb-3'>
              <span>
                門號：<b>{currentPlan.scid}</b>
              </span>
              <span className='divider'></span>
              <span>
                月付：<b>${formatNumber(currentPlan.price)}</b>{' '}
                <span className='is-text-light'>({currentPlan.end_at} 到期)</span>
              </span>
              <span className='divider'></span>
              <span
                role='button'
                className='is-underline-link is-text-underline is-text-black50 m-0'
                onClick={this.changeNumber}>
                <small className='is-text-medium'>想換個門號續約?</small>
              </span>
              <span className='divider'></span>
              <span role='button' onClick={this.toggleUsage}>
                <small className='is-text-medium'>查看我的平均用量</small>
                <i className={`icon-${!this.state.usageOpen ? 'plus' : 'minus'}`}></i>
              </span>
            </h4>
          </div>
          <section
            className={`usage-rate with-collapse
        `}
            style={{ height: this.state.usageOpen ? this.state.usageSecHeight : 0 }}>
            <div className='fui-container align-center' ref={this.usageSec}>
              <h2 className='is-life-circle-gray-70'>平均月用量 (近3個月)</h2>
              <div className='rate-group mb-0'>
                <div className='rate-item'>
                  <div className='number'>
                    <h1>{currentPlan.usage.internet}</h1>
                    <h6 className='usage'>分鐘</h6>
                  </div>
                  <div>網內通話</div>
                </div>
                <div className='rate-item'>
                  <div className='number'>
                    <h1>{currentPlan.usage.intranet}</h1>
                    <h6 className='usage'>分鐘</h6>
                  </div>
                  <div>網外通話</div>
                </div>
                <div className='rate-item'>
                  <div className='number'>
                    <h1>{currentPlan.usage.citytalk}</h1>
                    <h6 className='usage'>分鐘</h6>
                  </div>
                  <div>市話通話</div>
                </div>
                <div className='rate-item'>
                  <div className='number'>
                    <h1>{currentPlan.usage.transport}</h1>
                    <h6 className='usage'>MB</h6>
                  </div>
                  <div>網路傳輸量</div>
                </div>
              </div>
            </div>
          </section>
          <div className='fui-container mt-lg-9 mt-md-3 mt-4'>
            <h2>專屬續約優惠及商品</h2>
            <Tab
              name='renewal-tab'
              default={currentTab}
              list={[
                { label: '專屬推薦', link: '/estore/renewal/exclusive-offer' },
                { label: '單辦門號', link: '/estore/renewal/rateplan' },
                { label: '搭手機/商品', link: '/estore/renewal/phone' },
              ]}
            />
          </div>
        </section>
        <section className='pt-0'>
          <TabPane active={currentTab === 0}>
            <div className='fui-container'>
              <Formsy>
                <div className='fui-plan-flow-container pt-2'>
                  <div className='fui-plan-flow is-active'>
                    <div className='fui-flow-title'>
                      <div className='content'>
                        <h2>
                          <span>1.專屬推薦方案</span>
                        </h2>
                      </div>
                    </div>
                    <div className='fui-flow-body'>
                      <h6 className='fui-flow-description'>老客戶獨享方案，專屬於你</h6>
                      <ExclusiveRadioCard
                        className='exclusive-radio'
                        name='exclusive'
                        value={this.state.exclusiveForm.exclusive.value}
                        options={Mock.recommandOptions}
                        onChange={this.exclusiveChange}
                        modalOpen={this.openModal}
                      />
                    </div>
                  </div>
                </div>
              </Formsy>
            </div>
          </TabPane>
          <TabPane active={currentTab === 1}>
            <Formsy>
              <div className='fui-container'>
                <div className='paper mt-2 mb-0'>
                  <h5 className='my-2'>續約用戶即刻升級5G</h5>
                  <p>
                    搭手機最高送 $3,000 商品購物金，再抽全聯萬元禮券，月付 $1399 以上送 VR 眼鏡。
                    <Link
                      onClick={(e) => this.openNoticeModal()}
                      className='is-text-underline is-underline-link is-text-black50'>
                      活動小叮嚀
                    </Link>
                  </p>
                </div>
              </div>
              <div className='fui-plan-flow-container pt-2' ref={this.flowRef}>
                {this.state.applyFlow.map((flow, i) =>
                  this.state.step >= i ? (
                    <section
                      id={flow.id}
                      className={`fui-plan-flow 
                        ${flow.type === 'radio-card' && this.state.compare ? 'is-compare' : ''} 
                        ${
                          (this.state.step === i && this.state.editStep === -1) ||
                          (this.state.editStep > -1 && this.state.editStep === i)
                            ? 'is-active'
                            : ''
                        }`}
                      key={`fui-plan-flow-${i}`}>
                      <div className='fui-container'>
                        <div className='fui-flow-title'>
                          <div className='content'>
                            <h2>
                              {flow.type === 'radio-card' && this.state.compare ? (
                                <span>資費比比看</span>
                              ) : (
                                <span>
                                  {i + 1}.{flow.title}
                                </span>
                              )}
                              <span className='selected-value'>{this.valuePreview(flow)}</span>
                            </h2>
                          </div>
                          <div className='extra'>
                            <Button btnStyle='secondary' size='small' onClick={(e) => this.changeFormSection(i)}>
                              {this.state.cart[flow.name] === '' ||
                              !this.state.cart[flow.name] ||
                              (Array.isArray(this.state.cart[flow.name]) && !this.state.cart[flow.name].length)
                                ? '尚未選擇'
                                : '修改'}
                            </Button>
                          </div>
                        </div>
                        <div className='fui-flow-body'>
                          {flow.type === 'radio-card' && this.state.compare ? (
                            <h6 className='fui-flow-description is-text-error'>
                              勾選以下方案可以比比看資費內容，一次最多比三個！
                            </h6>
                          ) : (
                            <h6 className='fui-flow-description'>
                              {flow.name === 'apply_method'
                                ? form.apply_method.value === '申辦新門號'
                                  ? flow.description[0]
                                  : form.apply_method.value === '攜碼到遠傳'
                                  ? flow.description[1]
                                  : form.apply_method.value === '遠傳門號續約'
                                  ? flow.description[2]
                                  : ' '
                                : flow.description}
                            </h6>
                          )}

                          {/* 根據類別切換表單內容 */}
                          {/* 有 Tab 的產品牌卡，用於加購 */}
                          {flow.type === 'tab-checkbox-card' ? (
                            <div>
                              <Tab name={flow.tabs.name} list={flow.tabs.list} onChange={this.CheckboxTabChange} />
                              {flow.tabs.content.map((tab, i) => (
                                <TabPane key={`tab-checkbox-${i}`} active={i === this.state.cuurentCheckboxTab}>
                                  <CheckboxCardCarousel
                                    {...tab}
                                    value={form[tab.name].value}
                                    onChange={this.inputChange}
                                    modalOpen={this.openModal}
                                  />
                                </TabPane>
                              ))}
                            </div>
                          ) : null}
                          {/* 門號驗證 */}
                          {flow.type === 'identity-validation' ? (
                            <div className='paper fui-validation-input'>
                              <Grid container>
                                <Grid item xs={12} sm={8} md={6}>
                                  <LabelInput
                                    label='遠傳門號'
                                    placeholder='輸入門號'
                                    validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
                                    required={form.scid.required}
                                    name='scid'
                                    validationErrors={{
                                      isDefaultRequiredValue: '請輸入原門號號碼',
                                      matchRegexp: '您輸入了錯誤資訊，請再檢查！',
                                    }}
                                    withValidCheck={
                                      this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                    }
                                    value={form.scid.value}
                                    onChange={this.inputChange}
                                  />
                                  <LabelInput
                                    label='身分證字號'
                                    placeholder='輸入身分證字號'
                                    required={form.id_number.required}
                                    name='id_number'
                                    validations={{
                                      idNumberValid: Validation.idNumberValid,
                                    }}
                                    validationErrors={{
                                      isDefaultRequiredValue: '請輸入身分證字號',
                                      idNumberValid: '您輸入了錯誤資訊，請再檢查！',
                                    }}
                                    withValidCheck={
                                      this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                    }
                                    value={form.id_number.value}
                                    onChange={this.inputChange}
                                  />
                                  {!(
                                    this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                  ) ? (
                                    <Button
                                      btnStyle='primary'
                                      size='large'
                                      disabled={form.scid.value === '' || form.id_number.value === ''}
                                      onClick={this.optVarify}>
                                      查詢可續約方案
                                    </Button>
                                  ) : null}
                                </Grid>
                              </Grid>
                            </div>
                          ) : null}
                          {/* 攜入門號驗證 */}
                          {flow.type === 'input-validation' ? (
                            <div className='fui-validation-input'>
                              <Grid container>
                                <Grid item xs={12}>
                                  <div className='fui-cards three-card no-scrollbar'>
                                    {flow.cards.map((card, c) => (
                                      <div className='fui-card' key={`input-validation-step-${c}`}>
                                        <div className='fui-card-action'>
                                          <div className='fui-card-caption'>
                                            <div className='fui-card-content'>
                                              <div className='circle-number'>{card.no}</div>
                                              <h3 className='fui-card-title'>{card.title}</h3>
                                              <p className='fui-card-description'>{card.description}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={8} md={6}>
                                  <LabelInput
                                    label='輸入攜入門號'
                                    placeholder='輸入原門號號碼'
                                    required={form.input_validation.required}
                                    name='input_validation'
                                    validations={{
                                      matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/,
                                    }}
                                    validationErrors={{
                                      isDefaultRequiredValue: '您輸入了錯誤資訊，請再檢查！',
                                      matchRegexp: '您輸入了錯誤資訊，請再檢查！',
                                    }}
                                    value={form.input_validation.value}
                                    withValidCheck={
                                      this.state.cart.input_validation && this.state.cart.input_validation.isPass
                                    }
                                    onChange={this.inputChange}
                                  />
                                  <div
                                    className='text-sm is-text-gray100'
                                    dangerouslySetInnerHTML={{ __html: flow.note }}></div>
                                  <Button
                                    btnStyle='primary'
                                    size='large'
                                    disabled={!this.state.inputValidationValid}
                                    onClick={this.phoneCheck}>
                                    驗證號碼
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                          ) : null}
                          {/* 產品牌卡，用於好禮 */}
                          {flow.type === 'checkbox-card' ? (
                            <CheckboxCardCarousel
                              name={flow.name}
                              {...flow}
                              value={form[flow.name].value}
                              onChange={this.inputChange}
                              modalOpen={this.openModal}
                            />
                          ) : null}
                          {/* 單選 Radio Button，選擇門號 */}
                          {flow.type === 'radio-carousel' ? (
                            <RadioCarousel
                              name={flow.name}
                              {...flow}
                              value={form[flow.name].value}
                              onChange={this.inputChange}
                            />
                          ) : null}
                          {/* 單選牌卡，選擇資費方案 */}
                          {flow.type === 'radio-card' ? (
                            <RadioCard
                              name={flow.name}
                              tags={flow.tags}
                              options={flow.options}
                              value={this.state.compare ? form.compare.value : form[flow.name].value}
                              onChange={this.inputChange}
                              modalOpen={this.openModal}
                            />
                          ) : null}
                          {/* 單純的 Radio Button，用於申請方式 */}
                          {flow.type === 'radio' ? (
                            <RadioGroup
                              label=''
                              name={flow.name}
                              options={flow.options}
                              default={form[flow.name].value}
                              value={form[flow.name].value}
                              onChange={this.inputChange}
                              modalOpen={this.openModal}
                            />
                          ) : null}
                        </div>
                      </div>
                    </section>
                  ) : null
                )}
              </div>
            </Formsy>
          </TabPane>
          <TabPane active={currentTab === 2}>
            <div className='fui-container mt-2 '>
              <div className='paper mt-2 mb-6'>
                <h5 className='my-2'>健康新生活，在家自己煮</h5>
                <p>
                  月租$199起，居家時尚新煮意。
                  <Link
                    onClick={(e) => this.openNoticeModal()}
                    className='is-text-underline is-underline-link is-text-black50'>
                    活動小叮嚀
                  </Link>
                </p>
              </div>
              <div className='search-bar'>
                <div className='fui-container'>
                  <Formsy
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                    className={`${this.state.showThreeCFilter ? 'with-shadow' : ''}`}>
                    <div className='search-head'>
                      <div className='form-group'>
                        <Switch
                          className='ml-0'
                          checked={this.state.searchProduct.is3C.value}
                          name='is_business'
                          onChange={(e, checked) => this.searchInputChange('is3C', checked, 'searchProduct')}
                          off='搭手機'
                          on='搭商品'
                        />
                      </div>
                      <div
                        role='button'
                        className='fui-button is-text m-0 d-block d-sm-none'
                        onClick={(e) => this.setState({ showThreeCFilter: !this.state.showThreeCFilter })}>
                        {this.state.showThreeCFilter ? (
                          <i className='icon-close m-0 mr-1'></i>
                        ) : (
                          <img
                            src={process.env.PUBLIC_URL + '/resources/common/images/filter.svg'}
                            alt='篩選'
                            width='24'
                            className='mr-1'
                          />
                        )}
                        <span className='text'>篩選</span>
                      </div>
                    </div>
                    <div className={`search-body ${this.state.showThreeCFilter ? 'd-flex' : 'd-none'} d-sm-flex`}>
                      <Grid container justify='space-between'>
                        <Grid item xs={12} sm={4} md={4}>
                          <Grid container item xs={12} sm={12} md={12}>
                            <Grid item xs={12} sm={4} md={6}>
                              <div className='form-group'>
                                <Dropdown
                                  label={searchProduct.brand.value}
                                  className={this.state.isMobile ? 'is-button' : ''}
                                  arrow={true}
                                  value={searchProduct.brand.value}
                                  list={
                                    this.state.searchProduct.is3C.value
                                      ? this.state.threeCBranding
                                      : this.state.phoneBrading
                                  }
                                  placeholder='請選擇'
                                  onChange={(val) => this.searchInputChange('brand', val.value, 'searchProduct')}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <div className='form-group search-group'>
                            <LabelInput
                              label={''}
                              placeholder='輸入商品名稱'
                              name='keyword'
                              value={searchProduct.keyword.value}
                              onChange={(n, v) => this.searchInputChange(n, v, 'searchProduct')}
                            />
                            <button className='fui-button is-text m-0 d-none d-md-block' onClick={this.searchProduct}>
                              <i className='icon-search'></i>
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                      <div className='d-block d-md-none fui-buttons two-buttons'>
                        <Button btnStyle='primary' onClick={this.searchProduct}>
                          確認
                        </Button>
                        <Button btnStyle='secondary' onClick={this.resetSearch}>
                          清除
                        </Button>
                      </div>
                    </div>
                  </Formsy>
                </div>
              </div>

              <div
                className={`fui-cards four-card is-product no-scrollbar py-0 mb-1 ${
                  this.state.isProdLoading ? 'is-api-loading' : ''
                }`}>
                {productList.list.map((prod, i) =>
                  prod.type === 'product' || prod.type === 'wireless' ? (
                    <ProductCard {...prod} key={`find-prod-${i}`} onClick={this.triggerCard} />
                  ) : prod.type === 'promo-product' ? (
                    <ProdPromoCard {...prod} key={`find-prod-${i}`} />
                  ) : prod.type === 'promotion' ? (
                    <PromotionCard {...prod} key={`find-prod-${i}`} />
                  ) : null
                )}
              </div>

              <LoadMore
                className='mb-0'
                moreLabel='看更多'
                noMoreLabel={'沒有更多商品'}
                load={productList.current < productList.pages}
                click={(e) => this.getMorePage()}
              />
            </div>
          </TabPane>
        </section>
        {currentTab !== 2 ? (
          <Cart
            afterUpdate={(e) => this.setState({ cartUpdate: false })}
            update={this.state.cartUpdate}
            steps={currentTab === 0 ? this.state.exclusiveFlow : this.state.applyFlow}
            currentStep={this.state.step}
            openStep={this.state.editStep === -1 ? this.state.step : this.state.editStep}
            cart={currentTab === 0 ? this.state.exclusiveCart : this.state.cart}
            submit={(e) => this.submit(e)}
            stepChange={(e) => this.stepChange(e)}
            editStep={(e) => this.changeFormSection(e)}
          />
        ) : null}

        {currentTab === 1 ? (
          <CompareBar
            list={form.compare.value}
            open={this.state.compare}
            doCompare={this.doCompare}
            cancelCompare={this.cancelCompare}
          />
        ) : null}

        <EstoreModal
          {...this.state.currentModal}
          open={this.state.modalOpen}
          onSubmit={(e, f) => this.modalSubmit(e, f)}
          onConfirm={(e, f) => this.modalConfirm(e, f)}
          onCancel={(e, f) => this.modalCancel(e, f)}
          onClose={(e, f) => this.modalClose(e, f)}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    analyticsInitialState: state.analyticsInitialState, // 可加可不加
    flowCart: state.flowCartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      trackPageViewStart,
      setFlowCartData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RenewalIndex);
