import { TYPES } from '../action';
import { flowCartInitialState } from '../state';

export default function flowCartReducer(state = flowCartInitialState, action) {
  switch (action.type) {
    case TYPES.SET_FLOWCART_DATA:
      // debugger;
      return Object.assign({}, state, {
        ...action.data,
      });

    default:
      return state;
  }
}
