import React from 'react';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import NavAnchor from '../components/partials/NavAnchor';
import Button from '../components/Button';
import Breadcrumb from '../components/Breadcrumb';
import FindItemBanner from '../components/partials/banner/FindItemBanner';
import SearchBoxList from '../components/SearchBoxList';
import Dropdown from '../components/Dropdown';
import LabelInput from '../components/form/LabelInput';
import RadioCarousel from '../components/form/RadioCarousel';
import EstoreModal from '../components/EstoreModal';

import { setCartData, removeCartData, updateCartData } from '../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as Mock from '../mock/ThemeSelect';

class ThemeNumber extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canSubmit: false,
      showFilter: false,
      isMobile: window.innerWidth < 768,
      category: props.match.params.category || '',
      tab: 0,
      showTag: false,
      numberTags: ['免費門號', '黃金門號', '一路發168', '66大順', '幸福99', 'Lucky77', '真愛520', '發發88', '123門號'],
      currentTab: { label: '', link: '' },
      showMore: false,
      searchForm: {
        recommand: { value: '價格由高到低' },
        keyword: { value: '' },
      },
      form: {
        theme_number: { value: '' },
      },
      currentModal: {
        type: '',
        title: '',
        content: '',
      },
    };
  }

  inputChange = (n, v, form) => {
    let newForm = Object.assign(this.state[form]);
    newForm[n].value = v;

    this.setState({
      [form]: newForm,
    });

    if (form === 'form' && newForm.theme_number.value !== '') {
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'number-alert',
          title: '申辦主題門號',
          content: `你已選擇門號：<span class='is-text-accent'>${newForm.theme_number.value}</span>，前往選擇資費方案。`,
          confirmText: '下一步：選方案',
          cancelText: '取消',
        },
      });
    }
  };

  getSelected = (selected) => {
    this.setState({
      category: selected,
    });
  };

  setKeyword = (item) => {
    setTimeout(() => {
      this.setState({
        showTag: false,
        searchForm: {
          ...this.state.searchForm,
          keyword: item,
        },
      });
    }, 500);
  };
  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  numberFilter = () => {
    let keyword = this.state.searchForm.keyword.value;
    let category = this.state.category;
    let word = keyword !== '' ? keyword : category;

    if (word === '') {
      return Mock.phoneList;
    } else {
      return Mock.phoneList.filter((number) => {
        return number.value.indexOf(word) > -1 || number.tag.indexOf(word) > -1;
      });
    }
  };

  modalConfirm = (type) => {
    if (type === 'number-alert') {
      this.props.history.push('/estore/rateplan/theme-number/plan-select', {
        themeNumber: this.state.form.theme_number.value,
      });
    }

    this.setState({
      modalOpen: false,
    });
  };

  modalCancel = (type) => {
    this.setState({
      modalOpen: false,
    });
  };

  toggleTag = (toggle) => {
    if (toggle) {
      this.setState({
        showTag: toggle,
      });
    } else {
      setTimeout(() => {
        this.setState({
          showTag: toggle,
        });
      }, 500);
    }
  };

  searchNumber = (e) => {
    this.setState({
      searchForm: { ...this.state.searchForm, keyword: { value: e.currentTarget.value } },
    });
  };

  render() {
    const { form, searchForm } = this.state;

    return (
      <main className='theme-select'>
        <NavAnchor pageTitle='網路門市' />
        <Breadcrumb
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '網路門市', link: '/estore/' },
            { text: '找方案', link: '/estore/rateplan' },
            { text: '主題門號', link: '/estore/rateplan/theme-select' },
          ]}
          color='white'
        />
        <FindItemBanner {...Mock.banner} />
        <SearchBoxList searchList={Mock.searchList} onSelect={this.getSelected} />
        <div className='search-bar'>
          <div className='fui-container'>
            <Formsy
              autocomplete='off'
              onValid={this.enableButton}
              onInvalid={this.disableButton}
              className={this.state.showFilter ? 'with-shadow' : ''}>
              <Grid container justify='space-between'>
                <Grid item xs={12} sm={4} md={3}>
                  <div className='form-group'>
                    <Dropdown
                      label={searchForm.recommand.value}
                      className={this.state.isMobile ? 'is-button' : ''}
                      arrow={true}
                      value={searchForm.recommand.value}
                      list={[
                        { text: '熱門推薦', value: '熱門推薦' },
                        { text: '最新上架', value: '最新上架' },
                        { text: '價格由低到高', value: '價格由低到高' },
                        { text: '價格由高到低', value: '價格由高到低' },
                      ]}
                      placeholder='請選擇'
                      onChange={(val) => this.inputChange('recommand', val.value, 'searchForm')}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className='form-group search-group theme-number mb-0'>
                    <input
                      type='text'
                      placeholder='輸入喜愛的關鍵門號號碼'
                      onFocus={(e) => this.toggleTag(true)}
                      onBlur={(e) => this.toggleTag(false)}
                      onChange={(e) => this.searchNumber(e)}
                      onKeyDown={(e) => this.searchNumber(e)}
                      ref={this.searchInput}
                    />
                    <button className='fui-button is-text m-0 d-none d-md-block' onClick={this.searchProduct}>
                      <i className='icon-search'></i>
                    </button>
                    <div className={`dropdown-wrapper ${this.state.showTag ? 'd-block' : 'd-none'}`}>
                      <p className='mt-0'>主題號碼</p>
                      {this.state.numberTags.map((item, idx) => (
                        <Button
                          btnStyle='label'
                          size='small'
                          className={`${this.props.selected && this.props.selected === item ? 'active' : ''} `}
                          key={'menu-item' + idx}
                          onClick={(e) => this.setKeyword(item)}>
                          {item}
                        </Button>
                      ))}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Formsy>
          </div>
        </div>
        <div className='ribbon-bg'>
          <section>
            <div className='fui-container'>
              <h1>
                主題門號
                <small className='ml-2 is-text-gray100'>{Mock.phoneList.length} 個門號</small>
              </h1>
              <Formsy>
                <RadioCarousel
                  keyword={searchForm.keyword.value}
                  name='theme_number'
                  options={this.numberFilter()}
                  search={false}
                  value={form.theme_number.value}
                  onChange={(n, v) => this.inputChange(n, v, 'form')}
                />
              </Formsy>
            </div>
          </section>
        </div>

        <EstoreModal
          open={this.state.modalOpen}
          {...this.state.currentModal}
          onConfirm={this.modalConfirm}
          onCancel={this.modalCancel}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCartData,
      removeCartData,
      updateCartData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ThemeNumber);
