import React from 'react';
import Slider from 'react-slick';
import Link from '../Link';
import PropTypes from 'prop-types';

import VisibilitySensor from 'react-visibility-sensor';

const BubbleIcon = (props) => {
  return (
    <Link to={props.link} target={props.target} className='bubble is-icon'>
      <div className='icon'>
        <img src={props.icon.default} srcSet={props.icon.retina || props.icon.default} alt={props.text} />
      </div>
      <div className='text'>{props.text}</div>
    </Link>
  );
};

const BubbleCard = (props) => {
  return (
    <div id={props.id} className='bubble is-card'>
      {props.image ? (
        <div className='image'>
          <img src={props.image.default} srcSet={props.image.retina || props.image.default} alt={props.title} />
        </div>
      ) : null}
      <div className='bubble-card-content'>
        {props.title ? <div className='subtitle'>{props.title}</div> : null}
        {props.description ? <div className='body'>{props.description}</div> : null}
      </div>
      <div className='bubble-card-action'>
        {props.actions.map((act, i) => (
          <Link
            to={act.link}
            key={`bubble-card-action-${i}`}
            target={act.target}
            data-aa-tracking-id={props.trackingTitle ? `${props.title}` : `${act.text}`}
            onClick={(event) => {
              // [AA Tracking]
              if (props.trackingTitle) {
                if (window && typeof window.ctrf === 'function') window.ctrf(event || {}, `${props.title}`);
              } else {
                if (window && typeof window.ctrf === 'function') window.ctrf(event || {}, `${act.text}`);
              }
            }}>
            <span>{act.text}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

class GreetingGuide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      now: new Date(),
      hour: 9,
      greeting: '早安',
      time: '早上',
      visibleActive: false,
    };

    this.slideSetting = {
      dots: false,
      infinite: false,
      arrows: true,
      slidesToShow: 3,
      draggable: true,
      adaptiveHeight: true,
      centerPadding: '6px',
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: true,
            slidesToShow: 1,
            variableWidth: true,
          },
        },
      ],
    };

    this.iconSlideSetting = {
      dots: false,
      infinite: false,
      arrows: true,
      slidesToShow: 6,
      draggable: true,
      adaptiveHeight: true,
      centerPadding: '6px',
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: true,
            slidesToShow: 5,
            variableWidth: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 4,
            variableWidth: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 3,
            variableWidth: true,
          },
        },
      ],
    };
  }

  componentDidMount() {
    let { now } = this.state;
    let hours = now.getHours();

    this.setState({
      hour: hours > 12 ? hours - 12 : hours,
    });

    console.log(hours);

    if (hours >= 4 && hours < 12) {
      this.setState({
        greeting: '早安',
        time: '早上',
      });
    }
    if (hours >= 12 && hours < 18) {
      this.setState({
        greeting: '午安',
        time: '下午',
      });
    }
    if (hours >= 18 || hours < 4) {
      this.setState({
        greeting: '晚安',
        time: '晚上',
      });
    }

    // [AA Tracking]
    let arrowBtns = document.querySelectorAll('.fui-greeting-guide .slick-arrow');
    if (arrowBtns.length > 0 && arrowBtns[0] && arrowBtns[1]) {
      arrowBtns[0].addEventListener('click', (event) => {
        // [AA Tracking]
        if (window && typeof window.ctrf === 'function') window.ctrf(event || {}, `Tab_左`);
      });
      arrowBtns[1].addEventListener('click', (event) => {
        // [AA Tracking]
        if (window && typeof window.ctrf === 'function') window.ctrf(event || {}, `Tab_右`);
      });
    }
  }

  visibleChange = (visible, index) => {
    if (visible && index === this.props.bubbles.length - 1) {
      this.setState({
        visibleActive: true,
      });
    }
  };

  renderBubble = (bubble, isVisible, i) => {
    if (bubble.type === 'text')
      return (
        <div className={`fui-emma-chat ${!this.state.visibleActive && !isVisible ? '' : 'fade-in'}`}>
          <div className='emma'>
            <img
              src={process.env.PUBLIC_URL + '/resources/cbu/cbu-index/cbu-emma.png'}
              srcSet={process.env.PUBLIC_URL + '/resources/cbu/cbu-index/cbu-emma@2x.png'}
              alt='emma-avatar'
            />
          </div>
          <div className='content'>
            <div className='bubble is-text' dangerouslySetInnerHTML={{ __html: bubble.text }}></div>
            <div className='fui-bubble-time text-mini is-text-gray100'>
              {this.state.time}
              {this.state.hour > 12 ? this.state.hour - 12 : this.state.hour}:00
            </div>
          </div>
        </div>
      );

    if (bubble.type === 'cards')
      return (
        <div className={`fui-emma-chat ${!this.state.visibleActive && !isVisible ? '' : 'fade-in'}`}>
          <div className='emma'>
            <img
              src={process.env.PUBLIC_URL + '/resources/cbu/cbu-index/cbu-emma.png'}
              srcSet={process.env.PUBLIC_URL + '/resources/cbu/cbu-index/cbu-emma@2x.png'}
              alt='emma-avatar'
            />
          </div>
          <div className='content'>
            {bubble.cards.length === 1 ? (
              <BubbleCard {...bubble.cards[0]} id={`pers-${this.props.identityType}-1`} />
            ) : (
              <div id={`pers-${this.props.identityType}-2-0`}>
                <Slider {...this.slideSetting}>
                  {bubble.cards.map((card, j) => (
                    <BubbleCard
                      {...card}
                      key={`fui-emma-bubble-${i}-card-${j}`}
                      id={`pers-${this.props.identityType}-2-${j + 1}`}
                      trackingTitle={true}
                    />
                  ))}
                </Slider>
              </div>
            )}
            <div className='fui-bubble-time text-mini is-text-gray100'>
              {this.state.time}
              {this.state.hour > 12 ? this.state.hour - 12 : this.state.hour}:00
            </div>
          </div>
        </div>
      );

    if (bubble.type === 'icons')
      return (
        <div className={`fui-emma-chat ${!this.state.visibleActive && !isVisible ? '' : 'fade-in'}`}>
          <div className='emma'>
            <img
              src={process.env.PUBLIC_URL + '/resources/cbu/cbu-index/cbu-emma.png'}
              srcSet={process.env.PUBLIC_URL + '/resources/cbu/cbu-index/cbu-emma@2x.png'}
              alt='emma-avatar'
            />
          </div>
          <div className='content'>
            <Slider {...this.iconSlideSetting} className='is-icon-slide'>
              {bubble.icons.map((icon, j) => (
                <BubbleIcon {...icon} key={`fui-emma-bubble-${i}-card-${j}`} />
              ))}
            </Slider>
            <div className='fui-bubble-time text-mini is-text-gray100'>
              {this.state.time}
              {this.state.hour > 12 ? this.state.hour - 12 : this.state.hour}:00
            </div>
          </div>
        </div>
      );
    return null;
  };

  render() {
    return (
      <section className='fui-greeting-guide'>
        <div className='fui-container'>
          <div className='paper'>
            <h2>{this.state.greeting + ' ' + this.props.greetingMsg}</h2>
            <div className='fui-bubbles-container'>
              {this.props.bubbles.map((bubble, i) => (
                <VisibilitySensor key={`fui-emma-bubble-${i}`} onChange={(e) => this.visibleChange(e, i)}>
                  {({ isVisible }) => this.renderBubble(bubble, isVisible, i)}
                </VisibilitySensor>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

GreetingGuide.propTypes = {
  greetingMsg: PropTypes.string,
  bubbles: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string,
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.shape({
            default: PropTypes.string,
            retina: PropTypes.string,
          }),
          title: PropTypes.string,
          description: PropTypes.string,
          actions: PropTypes.arrayOf(
            PropTypes.shape({
              text: PropTypes.string,
              link: PropTypes.string,
            })
          ),
        })
      ),
    })
  ),
};

export default GreetingGuide;
