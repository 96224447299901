import React from 'react';

import { setCartData, trackPageViewStart } from '../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import Cart from '../components/Cart';
import Button from '../components/Button';
import LoadMore from '../components/LoadMore';
import Select from '../components/form/Select';
import ProductCard from '../components/card/ProductCard';
import NavAnchor from '../components/partials/NavAnchor';
import Breadcrumb from '../components/Breadcrumb';
import EStoreBanner from '../components/partials/banner/EStoreBanner';
import EstoreModal from '../components/EstoreModal';
import SectionCollapseInfo from '../components/partials/collapse/SectionCollapseInfo';

import * as Mock from '../mock/PhoneList';
import { setMobile } from '../components/util';

class PhoneList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 768,
      showPhoneFilter: false,
      perPage: 16,
      pages: 0,
      currentPage: 0,
      modalOpen: false,
      phoneList: [],
      currentModal: {
        type: 'notice',
        title: '',
        content: '',
      },
      searchForm: {
        category: { value: null },
        type: { value: null },
        brand: { value: null },
        sorting: { value: null },
      },
      form: {
        accessory: { value: '' },
      },
      cart: {
        list: [],
      },
    };
  }

  componentDidMount() {
    this.getMorePage();
    window.addEventListener('resize', (e) => setMobile);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', (e) => setMobile);
  };

  inputChange = (n, v, form, obj) => {
    let newForm = Object.assign(this.state[form]);
    newForm[n].value = v;

    this.setState({
      [form]: newForm,
    });

    if (form === 'form') {
      this.props.setCartData(obj);
    }
  };

  getMorePage = () => {
    let newList = this.filterList([...Mock.Phone]);

    this.setState({
      pages: Math.ceil(newList.length / this.state.perPage),
      currentPage: this.state.currentPage + 1,
      phoneList: [
        ...this.state.phoneList,
        ...newList.splice(
          this.state.currentPage * this.state.perPage,
          (this.state.currentPage + 1) * this.state.perPage
        ),
      ],
    });

    this.forceUpdate();
  };

  filterList = (list) => {
    console.log(list);

    // 條件篩選，不確定有哪些參數，目前無法判斷
    return list;
  };

  resetSearch = () => {
    this.setState({
      searchForm: {
        category: { value: null },
        type: { value: null },
        brand: { value: null },
        sorting: { value: null },
      },
    });
  };

  searchProduct = () => {};

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);

    this.setState({
      modalOpen: false,
    });

    if (type === 'otpForm') {
      this.identityCheck();
    }
    // 貨到通知送出
  };

  modalConfirm = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    if (type === 'change-alert') {
      this.resetFormset();
    }
    if (type === 'phoneCheck-alert') {
      // this.phoneCheck();
    }
    if (type === 'identityCheck-alert') {
      // this.identityCheck();
    }
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalCancel = (type) => {
    // 預約取貨送出
    console.info('MODAL CANCEL', type);
    if (type === 'change-alert') {
      this.canscelSelect();
    }
    this.setState({
      modalOpen: false,
    });
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { searchForm } = this.state;
    return (
      <main>
        <NavAnchor pageTitle='網路門市' />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '網路門市', link: '/estore' },
            { text: '促銷活動商品列表', link: '/' },
          ]}
        />

        <EStoreBanner
          slides={[
            {
              image: {
                md: '/resources/cbu/estore-event-product/cbu-ebustore-1920x500.jpg',
                sm: '/resources/cbu/estore-event-product/cbu-ebustore-banner-750x900.jpg',
              },
              title: '單辦門號大標題文字最多十六個字元',
              description: [
                '一二三四五六七八九十一二三四五六',
                '一二三四五六七八九十一二三四五六',
                '一二三四五六七八九十一二三四五六',
              ],
              actions: [
                {
                  text: 'Primary',
                  btnStyle: 'primary',
                  link: '#',
                  target: '_self',
                },
                {
                  text: 'Secondary',
                  btnStyle: 'secondary',
                  link: '#',
                  target: '_blank',
                },
              ],
            },
          ]}
        />

        <div className='search-bar'>
          <div className='fui-container'>
            <Formsy>
              <div className='search-head'>
                <div
                  role='button'
                  className='fui-button is-text m-0 d-block d-md-none'
                  onClick={(e) => this.setState({ showPhoneFilter: !this.state.showPhoneFilter })}>
                  {this.state.showPhoneFilter ? (
                    <i className='icon-close m-0 mr-1'></i>
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + '/resources/common/images/filter.svg'}
                      alt='篩選'
                      width='24'
                      className='mr-1'
                    />
                  )}
                  <span className='text'>篩選</span>
                </div>
              </div>
              <div className={`search-body ${this.state.showPhoneFilter ? 'd-flex' : 'd-none'} d-md-flex`}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={3} lg={2}>
                    <div className='form-group'>
                      <Select
                        label={searchForm.category.value || '選擇商品種類'}
                        className={this.state.isMobile ? 'is-button' : ''}
                        arrow={true}
                        name='category'
                        options={[
                          { text: '種類1', value: '種類1' },
                          { text: '種類2', value: '種類2' },
                          { text: '種類3', value: '種類3' },
                        ]}
                        value={searchForm.category.value}
                        onChange={(name, val) => this.inputChange(name, val, 'searchForm')}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={2}>
                    <div className='form-group'>
                      <Select
                        label={searchForm.type.value || '選擇商品分類'}
                        className={this.state.isMobile ? 'is-button' : ''}
                        arrow={true}
                        name='type'
                        disabled={!searchForm.category.value || searchForm.category.value === ''}
                        options={[
                          { text: '種類1', value: '種類1' },
                          { text: '種類2', value: '種類2' },
                          { text: '種類3', value: '種類3' },
                        ]}
                        value={searchForm.type.value}
                        onChange={(name, val) => this.inputChange(name, val, 'searchForm')}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <div className='form-group'>
                      <Select
                        label={searchForm.brand.value || '選擇品牌'}
                        className={this.state.isMobile ? 'is-button' : ''}
                        arrow={true}
                        name='brand'
                        options={[
                          { text: '種類1', value: '種類1' },
                          { text: '種類2', value: '種類2' },
                          { text: '種類3', value: '種類3' },
                        ]}
                        value={searchForm.brand.value}
                        onChange={(name, val) => this.inputChange(name, val, 'searchForm')}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <div className='form-group'>
                      <Select
                        label={searchForm.sorting.value || '選擇排序'}
                        className={this.state.isMobile ? 'is-button' : ''}
                        arrow={true}
                        name='sorting'
                        options={[
                          { text: '種類1', value: '種類1' },
                          { text: '種類2', value: '種類2' },
                          { text: '種類3', value: '種類3' },
                        ]}
                        value={searchForm.sorting.value}
                        onChange={(name, val) => this.inputChange(name, val, 'searchForm')}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className='d-block d-md-none fui-buttons two-buttons'>
                  <Button btnStyle='primary' onClick={this.searchProduct}>
                    確認
                  </Button>
                  <Button btnStyle='secondary' onClick={this.resetSearch}>
                    清除
                  </Button>
                </div>
              </div>
            </Formsy>
          </div>
        </div>

        <section className='ribbon-bg'>
          <div className='fui-container'>
            <div className='fui-cards four-card is-product no-scrollbar py-0 mb-1'>
              {this.state.phoneList.map((prod, i) => (
                <ProductCard {...prod} key={`find-prod-${i}`} />
              ))}
            </div>
            <LoadMore
              className='mb-0'
              moreLabel='看更多'
              noMoreLabel={'沒有更多商品'}
              load={this.state.currentPage < this.state.pages}
              click={this.getMorePage}
            />
          </div>
        </section>

        <SectionCollapseInfo title='貼心小叮嚀' content='貼心小叮嚀' />

        <EstoreModal
          {...this.state.currentModal}
          open={this.state.modalOpen}
          onSubmit={this.modalSubmit}
          onConfirm={this.modalConfirm}
          onCancel={this.modalCancel}
          onClose={this.modalClose}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    analyticsInitialState: state.analyticsInitialState, // 可加可不加
    cart: state.cartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      trackPageViewStart,
      setCartData,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PhoneList));
