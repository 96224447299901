import React from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';
import Slider from 'react-slick';
import Link from '../../components/Link';
import Button from '../../components/Button';
import NavAnchor from '../../components/partials/NavAnchor';
import LabelInput from '../../components/form/LabelInput';
import EstoreModal from '../../components/EstoreModal';
import * as Validation from '../../utils/validation';

const promotion = [
  {
    title: '續約用戶即刻升級5G',
    description: '搭手機最高送 $3,000 商品購物金，再抽全聯萬元禮券，月付 $1399 以上送 VR 眼鏡。',
    action: {
      text: '活動小叮嚀',
      link: '#',
      target: '_blank',
    },
    detail: {
      title: '活動小叮嚀',
      content: [
        '文字描述文字描述文字描述文字描述文字描述文字描述文字描述。',
        '文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述。',
        '文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述。',
      ],
    },
  },
  {
    title: '續約用戶即刻升級5G',
    description: '搭手機最高送 $3,000 商品購物金，再抽全聯萬元禮券，月付 $1399 以上送 VR 眼鏡。',
    action: {
      text: '活動小叮嚀',
      link: '#',
      target: '_blank',
    },
    detail: {
      title: '活動小叮嚀',
      content: [
        '文字描述文字描述文字描述文字描述文字描述文字描述文字描述。',
        '文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述。',
        '文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述。',
      ],
    },
  },
  {
    title: '續約用戶即刻升級5G',
    description: '搭手機最高送 $3,000 商品購物金，再抽全聯萬元禮券，月付 $1399 以上送 VR 眼鏡。',
    action: {
      text: '活動小叮嚀',
      link: '#',
      target: '_blank',
    },
    detail: {
      title: '活動小叮嚀',
      content: [
        '文字描述文字描述文字描述文字描述文字描述文字描述文字描述。',
        '文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述。',
        '文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述。',
      ],
    },
  },
];

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      alertSubmit: false,
      form: {
        scid: { value: '' },
        id_number: { value: '' },
      },
      sliderSetting: {
        dots: true,
        infinite: false,
        arrows: false,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        draggable: true,
        adaptiveHeight: false,
        centerMode: false,
      },
      promotionList: [],
      modalOpen: false,
      currentModal: {
        type: 'notice',
        title: '',
        content: '',
      },
    };
  }

  componentDidMount = () => {
    // 條件達成
    // if (this.props.memberState.canRenewal) {
    //   this.props.history.push('/renewal/ratepan');
    // } else {
    //   this.setState({
    //     currentModal: {
    //       type: 'renewal-alert',
    //       title: '續約資格不符合',
    //       content: `<img src="${process.env.PUBLIC_URL}/resources/common/images/failed@2x.png" width="140" /> <div>很抱歉！你目前還無法續約喔，你可以再次<a href='#' class='is-text-accent is-underline-link'>查看原合約</a>。</div>`,
    //       confirmText: '可續約時通知我',
    //       cancelText: '取消',
    //     },
    //   });
    // }

    this.setState({
      promotionList: promotion,
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  inputChange = (n, v) => {
    let newForm = Object.assign(this.state.form);

    newForm[n].value = v;

    this.setState({
      form: newForm,
    });

    this.forceUpdate();
  };

  beforeSubmit = () => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'otpForm',
        title: '驗證手機號碼',
        phone: this.state.form.scid.value,
      },
    });
  };

  setModal = (modal) => {
    this.setState({
      modalOpen: true,
      currentModal: {
        ...this.state.currentModal,
        title: modal.title,
        content: modal.content,
      },
    });
  };

  confirmModal = (type) => {
    if (type === 'renewal-alert') {
      this.setState({
        modalOpen: false,
        alertSubmit: !this.state.alertSubmit,
      });
      this.forceUpdate();
    }
  };

  submitModal = (type) => {
    if (type === 'otpForm') {
      if (this.state.alertSubmit) {
        this.setState({
          modalOpen: false,
        });
        this.props.history.push('/estore/renewal/rateplan');
      } else {
        this.setState({
          currentModal: {
            type: 'renewal-alert',
            title: '續約資格不符合',
            content: `<img src="${process.env.PUBLIC_URL}/resources/common/images/failed@2x.png" width="140" /> <div>很抱歉！你目前還無法續約喔，你可以再次<a href='#' class='is-text-accent is-underline-link'>查看原合約</a>。</div>`,
            confirmText: '可續約時通知我',
            cancelText: '取消',
          },
        });
      }
    } else {
      this.setState({ modalOpen: false });
    }
  };

  cancelModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  closeModal = (type) => {
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { form } = this.state;

    return (
      <main>
        <NavAnchor
          pageTitle='推薦續約'
          button={[
            { text: '新申辦', btnStyle: 'primary' },
            { text: '推薦續約', btnStyle: 'secondary' },
          ]}
        />
        <section className='login-renewal'>
          <div className='fui-container'>
            <h2 className='mb-0'>我的專屬續約優惠</h2>
            <h4 className='mt-0 is-text-darkgray50'>
              為確保個人資訊安全，請輸入欲續約的手機門號，遠傳電信將為你驗證身份。
            </h4>
            <div className='fui-panel'>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={6}>
                  <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                    <LabelInput
                      label='遠傳門號'
                      name='scid'
                      placeholder='輸入你的手機門號'
                      required={true}
                      validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
                      validationErrors={{
                        isDefaultRequiredValue: '請輸入原門號號碼',
                        matchRegexp: '您輸入了錯誤資訊，請再檢查！',
                      }}
                      value={form.scid.value}
                      onChange={this.inputChange}
                    />
                    <LabelInput
                      label='身份證字號'
                      name='id_number'
                      placeholder='輸入身份證字號'
                      validations={{
                        idNumberValid: Validation.idNumberValid,
                      }}
                      validationErrors={{
                        isDefaultRequiredValue: '請輸入身分證字號',
                        idNumberValid: '您輸入了錯誤資訊，請再檢查！',
                      }}
                      required={true}
                      value={form.id_number.value}
                      onChange={this.inputChange}
                    />
                    <div className='privacy-note is-text-gray100 text-sm'>
                      This site is protected by reCAPTCHA and the Google <Link to='#'>Privacy Policy</Link> and{' '}
                      <Link to='#'>Terms of Service</Link> apply.
                    </div>
                    <div className='page-action'>
                      <Button
                        btnStyle='primary'
                        size='large'
                        disabled={!this.state.canSubmit}
                        onClick={this.beforeSubmit}>
                        查詢可續約方案
                      </Button>
                    </div>
                  </Formsy>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Slider className='is-promotion-slider' {...this.state.sliderSetting}>
                    {this.state.promotionList.map((promo, i) => (
                      <div className='fui-card is-promotion' key={`promotion-card-${i}`}>
                        <div className='fui-card-action'>
                          <div className='fui-card-content'>
                            <h2 className='my-0'>{promo.title}</h2>
                            <h5 className='mb-0' dangerouslySetInnerHTML={{ __html: promo.description }}></h5>
                            <div className='action'>
                              <Link onClick={(e) => this.setModal(promo.detail)}>{promo.action.text}</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </Grid>
              </Grid>
            </div>
          </div>
        </section>
        <section className='fui-content is-sectin-feature'>
          <div className='fui-container mb-0'>
            <h2 className='align-center mb-md-0'>線上申請超簡單</h2>
            <div className='d-flex justify-between'>
              <div>
                <img src={`${process.env.PUBLIC_URL}/resources/cbu/estore-renewal/pick-phone.svg`} height='48' alt='' />
                <h5>挑手機/選方案</h5>
              </div>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/resources/cbu/estore-renewal/shipping-doc.svg`}
                  height='48'
                  alt=''
                />
                <h5>填寫資料/取貨方式</h5>
              </div>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/resources/cbu/estore-renewal/complete-order.svg`}
                  height='48'
                  alt=''
                />
                <h5>完成申請</h5>
              </div>
            </div>
          </div>
        </section>

        <EstoreModal
          open={this.state.modalOpen}
          {...this.state.currentModal}
          onConfirm={this.confirmModal}
          onSubmit={this.submitModal}
          onCancel={this.cancelModal}
          onClose={this.closeModal}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    memberState: state.memberReducer,
  };
};

export default connect(mapStateToProps)(Login);
