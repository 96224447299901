import React from 'react';
import { setFlowCartData } from '../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import formatNumber from '../utils/numberFormatter';

class OrderDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cart: this.props.cart,
    };
  }

  componentDidUpdate = (prevPops) => {
    if (this.props.cart !== prevPops.cart) {
      this.setState({
        cart: this.props.cart,
      });
      this.forceUpdate();
    }
  };

  removeCartItem = (type, index) => {
    let newCart = Object.assign(this.state.cart);
    newCart[type].splice(index, 1);

    this.setState({
      cart: newCart,
    });

    this.props.setFlowCartData(newCart);
  };

  countAmount = (cart) => {
    let total = 0;
    for (var i in cart) {
      if (cart[i]) {
        switch (i) {
          case 'plan':
            total += cart[i].prepay;
            break;

          case 'gift':
            if (cart[i].length) {
              let giftCount = cart[i].reduce((accr, val) => accr + val.productPrice, 0);
              total += giftCount;
            }
            break;

          case 'product':
            total += cart[i].projectPrice;
            break;

          case 'discount':
            total -= cart[i].amount;
            break;

          case 'extra_product':
            if (cart[i].length) {
              let extraProductCount = cart[i].reduce((accr, val) => accr + val.productPrice * val.number, 0);
              total += extraProductCount;
            }
            break;
        }
      }
    }

    if (cart.coupon && cart.coupon.code !== '') {
      total -= cart.coupon.amount;
    }

    if (cart.happygo && cart.happygo.number > 0) {
      total -= (cart.happygo.number / 200) * 50;
    }

    return total;
  };

  render() {
    const { cart } = this.state;

    return (
      <div>
        {cart.plan && !this.props.canDel ? (
          <div className='gray-block'>
            <div className='is-text-darkgray50'>專案名稱</div>
            <div className='fui-order-item'>
              <div className='pname'>{cart.plan.value}</div>
            </div>
          </div>
        ) : null}

        {cart.avaliable_at && cart.avaliable_at !== 'undefined' ? (
          <div className='gray-block'>
            <div className='is-text-darkgray50'>門號預計生效日</div>
            <div className='fui-order-item'>
              <div className='pname'>{cart.avaliable_at}</div>
            </div>
          </div>
        ) : null}

        {cart.plan.prepay !== 'undefined' ? (
          <div className='gray-block'>
            <div className='is-text-darkgray50'>預繳金</div>
            <div className='fui-order-item'>
              <div className='pname'>預繳金會依您的資格調整,請依最終結帳訂單金額為主</div>
              <div className='price'>
                <span>${formatNumber(cart.plan.prepay)}</span>
              </div>
              {this.props.canDel ? (
                <div className='del'>
                  {/* <div role='button' onClick={(e) => this.removeCartItem('gift', i)}>
                      <img src={'/resources/common/images/trashcan.svg'} alt='del' />
                    </div> */}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {cart.phone_number && !this.props.canDel ? (
          <div className='gray-block'>
            <div className='is-text-darkgray50'>申辦門號</div>
            <div className='fui-order-item'>
              <div className='pname'>{cart.phone_number.value}</div>
            </div>
          </div>
        ) : null}

        <div className='gray-block'>
          <div className='is-text-darkgray50'>專案商品</div>
          <div className='fui-order-item'>
            <div className='pname'>{cart.product.name}</div>
            <div className='color'>
              {cart.product.color ? <span className='d-inline-block d-md-none'>顏色：</span> : null}
              <span>{cart.product.color || '-'}</span>
            </div>
            <div className='numberic'>
              <span className='d-inline-block d-md-none'>數量：</span>
              <span>{cart.product.number || 1}</span>
            </div>
            <div className='price'>
              <del>${formatNumber(cart.product.originPrice)}</del>
              <span>${formatNumber(cart.product.projectPrice)}</span>
            </div>
            {this.props.canDel ? (
              <div className='del'>
                {/* <div role='button' onClick={(e) => this.removeCartItem('product', 1)}>
                    <img src={'/resources/common/images/trashcan.svg'} alt='del' />
                  </div> */}
              </div>
            ) : null}
          </div>
        </div>

        {cart.extra_product.length ? (
          <div className='gray-block'>
            <div className='is-text-darkgray50'>加購商品</div>
            {cart.extra_product.map((ex_prod, i) => (
              <div className='fui-order-item' key={`fui-extra_product-item-${i}`}>
                <div className='pname'>{ex_prod.name}</div>
                <div className='color'>
                  {ex_prod.color ? <span className='d-inline-block d-md-none'>顏色：</span> : null}
                  <span>{ex_prod.color || '-'}</span>
                </div>
                <div className='numberic'>
                  <span className='d-inline-block d-md-none'>數量：</span>
                  <span>{ex_prod.number || 1}</span>
                </div>
                <div className='price'>
                  {ex_prod.originPrice ? <del>${formatNumber(ex_prod.originPrice)}</del> : null}
                  <span>${formatNumber(ex_prod.productPrice)}</span>
                </div>
                {this.props.canDel ? (
                  <div className='del'>
                    <div role='button' onClick={(e) => this.removeCartItem('extra_product', i)}>
                      <img src={process.env.PUBLIC_URL + '/resources/common/images/trashcan.svg'} alt='del' />
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}

        {cart.extra_plan.length ? (
          <div className='gray-block'>
            <div className='is-text-darkgray50'>加值服務</div>
            {cart.extra_plan.map((ex_plan, i) => (
              <div className='fui-order-item' key={`fui-extra_plan-item-${i}`}>
                <div className='pname'>{ex_plan.name}</div>
                <div className='color'>
                  {ex_plan.color ? <span className='d-inline-block d-md-none'>顏色：</span> : null}
                  <span>{ex_plan.color || '-'}</span>
                </div>
                <div className='numberic'>
                  <span className='d-inline-block d-md-none'>數量：</span>
                  <span>{ex_plan.number || 1}</span>
                </div>
                <div className='price'>
                  {ex_plan.originPrice ? <del>${formatNumber(ex_plan.originPrice)}</del> : null}
                  <span>${formatNumber(ex_plan.productPrice)}</span>
                </div>
                {this.props.canDel ? (
                  <div className='del'>
                    <div role='button' onClick={(e) => this.removeCartItem('extra_plan', i)}>
                      <img src={process.env.PUBLIC_URL + '/resources/common/images/trashcan.svg'} alt='del' />
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}

        <div className='gray-block'>
          <div className='is-text-darkgray50'>贈品</div>
          {cart.gift.map((gift, i) => (
            <div className='fui-order-item' key={`fui-gift-item-${i}`}>
              <div className='pname'>{gift.name}</div>
              <div className='color'>
                {gift.color ? <span className='d-inline-block d-md-none'>顏色：</span> : null}
                <span>{gift.color || '-'}</span>
              </div>
              <div className='numberic'>
                <span className='d-inline-block d-md-none'>數量：</span>
                <span>{gift.number || 1}</span>
              </div>
              <div className='price'>
                {gift.originPrice ? <del>${formatNumber(gift.originPrice)}</del> : null}
                <span>${formatNumber(gift.productPrice)}</span>
              </div>
              {this.props.canDel ? (
                <div className='del'>
                  {/* <div role='button' onClick={(e) => this.removeCartItem('gift', i)}>
                      <img src={'/resources/common/images/trashcan.svg'} alt='del' />
                    </div> */}
                </div>
              ) : null}
            </div>
          ))}
        </div>

        <div className='gray-block is-bg-white py-0'>{this.props.fcoin}</div>

        <div className={`fui-order-count ${this.props.canDel ? 'with-trashcan' : ''}`}>
          {cart.discount ? (
            <div className='fui-order-count-item is-text-blue100'>
              <div className='label'>{cart.discount.name}</div>
              <div className='price'>-${formatNumber(cart.discount.amount)}</div>
            </div>
          ) : null}
          <div className='fui-order-count-item'>
            <div className='label'>{this.props.countTitle || '結帳金額'}</div>
            <div className='price'>
              <h1 className='is-text-error'>${formatNumber(this.countAmount(cart))}</h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OrderDetail.propTypes = {
  cart: PropTypes.object,
  countTitle: PropTypes.string,
  canDel: PropTypes.bool,
  fcoin: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    flowCart: state.flowCartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFlowCartData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
