import React from 'react';
import Link from '../Link';
import PropTypes from 'prop-types';

import store from '../../stores';
import { trackActionTrigger, trackPageViewEnd } from '../../stores/action';

class Tab extends React.Component {
  constructor(props) {
    super(props);

    this.tabElem = React.createRef();
    this.rand = Math.round(Math.random() * 100);
    this.state = {
      visible: false,
      tabItemWidth: 0,
      hasLink: null !== this.props.list[0].link,
      currentPath: typeof window !== 'undefined' ? window.location.pathname : '',
      currentTab: this.props.default || 0,
      styles: {
        width: 0,
        left: 0,
      },
      tabId: '',
      isIndexTab: '',
      pageLoadTabIdMatched: false,
    };

    this.setBarWidth = this.setBarWidth.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const query = new URLSearchParams(window.location.search);
      this.setState(
        {
          tabId: query.get('tabId'),
        },
        () => {
          this.setTabByURLSearchParams();
        }
      );
      if (this.state.hasLink && this.props.list && this.props.list.length) {
        this.setCurrentLink(window.location.pathname);
      }

      if (typeof window !== 'undefined') {
        window.addEventListener('resize', () => {
          this.setBarWidth();
          return false;
        });
      }
    }

    this.setState({
      isIndexTab: document.getElementById('CbuBannerTab'),
    });

    if (this.props.onChange) this.props.onChange(this.state.currentTab, this.props.list[this.state.currentTab]);
  }

  componentDidUpdate(prevProps) {
    if (typeof window === 'undefined') return;

    if (window.location.pathname !== this.state.currentPath) {
      this.setState({
        currentPath: window.location.pathname,
      });
      this.setCurrentLink(window.location.pathname);
    }

    if (prevProps.default !== this.props.default) {
      this.setState({
        currentTab: this.props.default || 0,
      });
      this.forceUpdate();
      setTimeout(() => {
        this.setBarWidth();
      }, 300);
    }
    if (!this.isHidden(this.tabElem.current) !== this.state.visible && !this.state.visible) {
      this.setState({ visible: true });
      this.setBarWidth();
    }
  }

  setCurrentLink(path) {
    this.props.list.map((item, i) => {
      if (path === item.link) {
        this.setState({
          currentTab: i,
        });
      }
    });

    this.forceUpdate();
    setTimeout(() => {
      this.setBarWidth();
    }, 100);
  }

  setBarWidth() {
    if (!this.tabElem.current) return;

    let item = this.tabElem.current.getElementsByClassName('is-active')[0];

    this.setState({
      styles: {
        ...this.state.styles,
        left: item.offsetLeft,
        width: Math.ceil(item.clientWidth),
      },
    });
  }

  isHidden = (el) => {
    return el.offsetParent === null;
  };

  setScroller() {
    let container = this.tabElem.current;
    let target = container.getElementsByClassName('is-active')[0];
    if (container.scrollLeft > target.offsetLeft) {
      if (typeof window !== 'undefined' && window.isIE) container.scrollLeft = target.offsetLeft - 30;
      else
        container.scrollTo({
          left: target.offsetLeft - 30,
          top: 0,
          behavior: 'smooth',
        });
    } else if (target.offsetLeft - (container.scrollLeft + container.clientWidth) < target.clientWidth) {
      if (typeof window !== 'undefined' && window.isIE)
        container.scrollLeft = target.offsetLeft + target.clientWidth - container.clientWidth + 30;
      else
        container.scrollTo({
          left: target.offsetLeft + target.clientWidth - container.clientWidth + 30,
          top: 0,
          behavior: 'smooth',
        });
    }
  }

  handleChange(e, tab, newValue) {
    e.preventDefault();

    this.setState({
      currentTab: newValue,
      styles: {
        ...this.state.styles,
        width: this.tabElem.current.children[newValue + 1].clientWidth,
        left: this.tabElem.current.children[newValue + 1].offsetLeft,
      },
    });

    if (this.props.onChange) {
      this.props.onChange(newValue, tab);
    }

    this.forceUpdate();

    if (typeof window !== 'undefined' && this.props.scroll && window.innerWidth < 960) {
      setTimeout(() => {
        this.setScroller();
      }, 50);
    }

    if (this.state.tabId && this.state.pageLoadTabIdMatched) {
      this.setState({ pageLoadTabIdMatched: false });
      return;
    }
    store.dispatch(
      trackActionTrigger({
        tabId: e.currentTarget.getAttribute('data-tab-id') || '', // Tab Id
        tabName: e.currentTarget.getAttribute('data-tab-name') || '', // 頁籤名稱
      })
    );
  }

  setTabByURLSearchParams = () => {
    const children = [...this.tabElem.current.children];

    for (let _el of children) {
      if (_el.dataset.tabId === this.state.tabId) {
        this.setState({ pageLoadTabIdMatched: true }, () => {
          _el.click();

          // Call AA Method
          // store.dispatch(
          //   trackPageViewEnd({
          //     tabId: this.state.tabId,
          //     tabName: _el ? _el.getAttribute('data-tab-name') : '',
          //   })
          // );
        });
        break;
      }
    }
  };

  render() {
    return (
      <div
        id={this.props.name}
        role='tablist'
        ref={this.tabElem}
        className={`fui-tab ${this.props.icon ? `with-icon` : ''} ${this.props.title ? `with-title` : ''} ${
          this.props.className ? this.props.className : ''
        } ${this.props.scroll ? 'with-scroller' : ''}`}>
        <div className='active-bar' style={this.state.styles}></div>
        {this.props.list.map((tab, idx) =>
          tab.link ? (
            <Link
              to={tab.link}
              role='tab'
              aria-selected={this.state.currentTab === idx}
              className={`fui-tab-item ${this.state.currentTab === idx ? 'is-active' : ''}`}
              key={`tab-${idx}`}
              id={`tab-${this.rand}-${idx}`}
              target={tab.target}>
              {tab.icon ? (
                <div>
                  <div className='icon'>
                    <img src={process.env.PUBLIC_URL + tab.icon} className='default' alt={tab.icon} />
                    <img src={process.env.PUBLIC_URL + tab.focusIcon} className='focus' alt={tab.icon} />
                  </div>
                  <div className='text'>{tab.label}</div>
                </div>
              ) : (
                <div>
                  {tab.title ? <div className='title'>{tab.title}</div> : null}
                  {tab.meta ? <small>{tab.meta}</small> : null}
                  <div className='text'>{tab.label}</div>
                </div>
              )}
            </Link>
          ) : (
            <div
              role='tab'
              aria-selected={this.state.currentTab === idx}
              onClick={(e) => this.handleChange(e, tab, idx)}
              className={`fui-tab-item ${this.state.currentTab === idx ? 'is-active' : ''}`}
              key={`tab-${idx}`}
              id={
                tab.label === '最新消息'
                  ? 'newsArea'
                  : tab.label === '服務公告'
                  ? 'announceArea'
                  : this.state.isIndexTab && tab.label
                  ? `mainView-${idx + 1}-0`
                  : `tab-${this.rand}-${idx}`
              }
              data-tab-id={`${this.props.name}-${idx}`}
              data-tab-name={tab.label}>
              <div
                data-aa-tracking-id={`Tab_${tab.label}`}
                onClick={(e) => {
                  // [AA Tracking]
                  if (window && typeof window.ctrf === 'function') {
                    window.ctrf(e || {}, `Tab_${tab.label}`);
                  }
                }}>
                {tab.icon ? (
                  <div>
                    <div className='icon'>
                      <img src={process.env.PUBLIC_URL + tab.icon} className='default' alt={tab.icon} />
                      <img src={process.env.PUBLIC_URL + tab.focusIcon} className='focus' alt={tab.icon} />
                    </div>
                    <div className='text'>{tab.label}</div>
                  </div>
                ) : (
                  <div>
                    {tab.meta ? <small>{tab.meta}</small> : null}
                    <div className='text'>{tab.label}</div>
                  </div>
                )}
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}

Tab.propTypes = {
  scroll: PropTypes.bool,
  icon: PropTypes.bool,
  title: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  default: PropTypes.number,
  target: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      focusIcon: PropTypes.string,
      meta: PropTypes.string,
      label: PropTypes.string.isRequired,
      link: PropTypes.string,
    })
  ),
};

export default Tab;
