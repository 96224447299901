export const CompareData = [
  {
    title: '899',
    unit: '月租',
    detail: {
      price: 899,
      duration: ['24個月'],
      throughput: '2GB<br/>超量後 降速1Mbps',
      intranet: '免費',
      offnet: '1.8/分鐘',
      offter: '電信帳單回饋 HAPPY GO 點數',
    },
    action: {
      link: '#',
      target: '_blank',
      text: '申辦方案',
    },
  },
  {
    title: '599',
    unit: '月租',
    detail: {
      duration: ['24個月'],
      throughput: '3.2G',
      intranet: '200分鐘',
      offnet: '20分鐘',
      offter: '電信帳單回饋 HAPPY GO 點數',
    },
    action: {
      link: '#',
      target: '_blank',
      text: '申辦方案',
    },
  },
  {
    title: '399',
    unit: '月租',
    detail: {
      duration: ['30個月'],
      throughput: '2GB，周末吃到飽<br/>超量後 降速1Mbps',
      intranet: '免費',
      offnet: '20分鐘<br/>(超過 $1.8/分鐘)',
      offter: '電信帳單回饋 HAPPY GO 點數',
    },
    action: {
      link: '#',
      target: '_blank',
      text: '申辦方案',
    },
  },
];
