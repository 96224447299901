import React from 'react';
import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import Formsy from 'formsy-react';
import { setFlowCartData } from '../../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Button from '../../components/Button';
import Link from '../../components/Link';
import Item from '../../components/item/Item';
import OrderDetail from '../../components/OrderDetail';
import FcoinMsg from '../../components/FcoinMsg';

import formatNumber from '../../utils/numberFormatter';
import * as Mock from '../../mock/Identity';

class Complete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: Mock.Cart,
      completeText: {
        title: '完成申辦',
        content: '你已在網路門市完成申請。溫馨提醒，若未於48小時內完成證件上傳，本訂單將會自動取消。',
      },
    };
  }

  countAmount = (cart) => {
    let total = 0;
    for (var i in cart) {
      if (cart[i]) {
        switch (i) {
          case 'plan':
            total += cart[i].prepay;
            break;

          case 'gift':
            if (cart[i].length) {
              let giftCount = cart[i].reduce((accr, val) => accr + val.productPrice, 0);
              total += giftCount;
            }
            break;

          case 'product':
            total += cart[i].projectPrice;
            break;

          case 'discount':
            total -= cart[i].amount;
            break;

          case 'extra_product':
            if (cart[i].length) {
              let extraProductCount = cart[i].reduce((accr, val) => accr + val.productPrice * val.number, 0);
              total += extraProductCount;
            }
            break;
        }
      }
    }

    if (cart.coupon && cart.coupon.code !== '') {
      total -= cart.coupon.amount;
    }

    if (cart.happygo && cart.happygo.number > 0) {
      total -= (cart.happygo.number / 200) * 50;
    }

    return total;
  };

  render() {
    const { cart } = this.state;

    return (
      <main>
        <section className='ribbon-bg fui-order-container'>
          <div className='fui-container'>
            <Grid container justify='center'>
              <Grid item xs={12} sm={12} md={6}>
                <div className='fui-order-result'>
                  <img
                    src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/completed.png'}
                    srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/completed@2x.png'}
                    alt=''
                  />
                  <h4 className=''>{this.state.completeText.title}</h4>
                  <p>{this.state.completeText.content}</p>
                  <div className='page-action'>
                    <Button btnStyle='secondary' size='large'>
                      查詢訂單
                    </Button>
                    {/* <Button btnStyle='secondary' size='large'>
                      返回網路門市首頁
                    </Button> */}
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className='paper'>
              <h4>{Mock.howToCarry.title}</h4>
              {Mock.howToCarry.content.map((item, i) => (
                <Item prefix='bulleted' className='is-text-darkgray50'>
                  {item}
                </Item>
              ))}

              <Grid container spacing={6} className='mt-5'>
                <Grid item xs={12} sm={12} md={6}>
                  <h4>訂單資訊</h4>
                  <table>
                    <tr>
                      <th>訂單編號</th>
                      <td>{Mock.Order.order_number}</td>
                    </tr>
                    <tr>
                      <th>付款方式</th>
                      <td>{Mock.Order.payment}</td>
                    </tr>
                    <tr>
                      <th>取貨方式</th>
                      <td>{Mock.Order.receive}</td>
                    </tr>
                    <tr>
                      <th>聯絡電話</th>
                      <td>{Mock.Order.mobile}</td>
                    </tr>
                    <tr>
                      <th>收貨地址</th>
                      <td>{Mock.Order.address}</td>
                    </tr>
                    <tr>
                      <th>發票格式</th>
                      <td>{Mock.Order.receive}</td>
                    </tr>
                  </table>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className='fui-card is-promotion'>
                    <Link to='#' target='_blank' className='fui-card-action'>
                      <div className='fui-card-content'>
                        <h2 className='mt-0 mb-3'>網路門市為你想更多，手機保險月付只要$199</h2>
                        <h5 className='mt-0 mb-5'>新用戶首購上網服務，最高可享雙倍送</h5>
                        <div className='action'>
                          <Button btnStyle='primary' reverse={true}>
                            了解更多
                          </Button>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className='paper is-promotion'>
              <div className='fui-card is-horizontal-promotion'>
                <Link to='https://fetnet.tw/uYYSRL0C9Z' className='fui-card-action' target='_blank'>
                  <div className='fui-card-image'>
                    <img src={'/resources/cbu/order/friendship-coupon-share@2x.jpg'} alt='' />
                  </div>
                  <div className='fui-card-content'>
                    <h3>推薦親友申辦享優惠</h3>
                    <div className='subtitle'>推薦越多賺越多，自己的薪水自己加！</div>
                    <div>
                      <Button btnStyle='secondary' reverse={true}>
                        了解優惠詳情
                      </Button>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className='paper'>
              <h4>專案明細</h4>
              <OrderDetail
                cart={cart}
                fcoin={
                  <FcoinMsg
                    content={`
                    回饋 <strong class='is-text-error'>VIP會員</strong> 遠傳幣 <strong class='is-text-error'>1%</strong> 起， 詳細請見
                    <a class='is-text-underline is-text-black50' href='//fetnet.net/estore/info/lifecircle.html' target="_blank">
                      遠傳幣指用指南
                    </a>
                  `}
                  />
                }
              />
            </div>
            <div className='page-action'>
              <Button btnStyle='primary' size='large' link='/estore'>
                返回網路門市首頁
              </Button>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.flowCartReducer || state.cartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFlowCartData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Complete);
