import React from 'react';
import Link from '../../Link';
import Tab from '../../tab/Tab';
import TabPane from '../../tab/TabPane';
import ProductCard from '../../card/ProductCard';

import PropTypes from 'prop-types';
import ProductCardWithMultiAction from '../../card/ProductCardWithMultiAction';

class SectionProductTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: this.props.tabs.default || 0,
    };
  }
  render() {
    return (
      <section className='fui-promo-prod-section'>
        <div className='fui-container'>
          <div className='fui-section-header'>
            <h2 className='section-title'>{this.props.title}</h2>
            {this.props.more ? (
              <Link to={this.props.more.link} target={this.props.more.target} className='fui-button is-text'>
                {this.props.more.text}
                <i className='icon-chevron-right'></i>
              </Link>
            ) : null}
          </div>
          <Tab {...this.props.tabs} onChange={(index) => this.setState({ currentTab: index })} scroll={true} />
          {this.props.tabContent.map((content, i) => (
            <TabPane key={`fui-promo-prod-section-content-${i}`} active={this.state.currentTab === i}>
              <div className='fui-cards is-product four-card no-scrollbar'>
                {content.map((card, j) => (
                  <ProductCard {...card} key={`fui-promo-prod-section-content-${i}-card-${j}`} />
                ))}
              </div>
            </TabPane>
          ))}
        </div>
      </section>
    );
  }
}

SectionProductTab.propTypes = {
  title: PropTypes.string,
  more: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
    target: PropTypes.string,
  }),
  tabs: PropTypes.shape({
    name: PropTypes.string,
    default: PropTypes.number,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      })
    ),
  }),
  tabContent: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
        target: PropTypes.string,
        image: PropTypes.string,
        ribbon: PropTypes.string,
        tag: PropTypes.string,
        meta: PropTypes.string,
        title: PropTypes.string,
        originalPrice: PropTypes.string,
        projectPrice: PropTypes.string,
        salePrice: PropTypes.string,
      })
    )
  ),
};

export default SectionProductTab;
