import React from 'react';

import { setCartData, trackPageViewStart } from '../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import { Grid } from '@material-ui/core';
import NavAnchor from '../components/partials/NavAnchor';
import Breadcrumb from '../components/Breadcrumb';
import Button from '../components/Button';
import Item from '../components/item/Item';
import Panel from '../components/panel/Panel';
import PanelList from '../components/panelContent/PanelList';
import ListLayout from '../components/panelContent/ListLayout';
import EStoreBanner from '../components/partials/banner/EStoreBanner';
import CardMultiSteps from '../components/card/CardMultiSteps';
import EstoreModal from '../components/EstoreModal';
import ServiceEntry from '../components/partials/card/ServiceEntry';

import * as Mock from '../mock/RedAndGreenB';

class SecretSpecialoffersShopCom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      currentModal: {
        type: 'notice',
        title: '',
        content: '',
      },
      stepCards: [
        {
          name: '線上申辦',
          content:
            '線上申辦指定專案（含付款及上傳證件），訂單完成後，訂單編號將透過E-Mail及簡訊發送予您。後續可透過訂單編號查詢申辦進度。',
        },
        {
          name: '訂單確認',
          content: '訂單送出後2-3個工作天內，客服專員與客戶本人聯繫確認申請內容，完成訂單確認。',
        },
        {
          name: '證件回收',
          content:
            '物流中心宅配出貨（ 同步發送簡訊至客戶指定行動門號 ），請客戶本人準備相關證件之正本及影本，供宅配人員確認回收，並完成申請書親簽。',
        },
        {
          name: '完成啟用',
          content: '客戶申辦之行動門號，於完成親簽申請書並繳交相關證件後3小時完成啟用。',
        },
      ],
    };
  }

  componentDidMount() {
    window.addEventListener('resize', (e) => {
      this.setState({ isMobile: window.innerWidth <= 768 });
    });
  }

  inputChange = (n, v, obj) => {
    let newForm = Object.assign(this.state.form);
    let list = Object.assign(this.state.cart.list);

    newForm[n].value = v;
    list[n] = obj;

    // console.log(list.phone.length, list.extra_product.length);
    // debugger;
    this.setState({
      form: newForm,
      cartUpdate: true,
      cartValid: list.phone.length > 0,
      cart: {
        list: list,
      },
    });

    this.forceUpdate();

    setTimeout(() => {
      console.log('cartUpdate', this.state.cartUpdate);
      this.props.setCartData(this.state.cart.list);
    }, 300);
  };

  updateFormFromCart = (cart) => {
    for (var i in cart.list) {
      let item = cart.list[i];
      let value = this.state.form[i].value.split(/,/g);
      if (item.length) {
        let newVal = [];
        item.forEach((prod, j) => {
          if (value.indexOf(prod.value) > -1) {
            newVal.push(prod.value);
          }
        });
        this.setState({
          form: {
            ...this.state.form,
            [i]: { value: newVal.toString() },
          },
        });
      } else {
        this.setState({
          form: {
            ...this.state.form,
            [i]: { value: '' },
          },
        });
      }
    }
    this.setState({ cart: cart });
  };

  openModal = (modal) => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'notice',
        ...modal,
      },
    });
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);

    this.setState({
      modalOpen: false,
    });

    if (type === 'otpForm') {
      this.identityCheck();
    }
    // 貨到通知送出
  };

  modalConfirm = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    if (type === 'change-alert') {
      this.resetFormset();
    }
    if (type === 'phoneCheck-alert') {
      // this.phoneCheck();
    }
    if (type === 'identityCheck-alert') {
      // this.identityCheck();
    }
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalCancel = (type) => {
    // 預約取貨送出
    console.info('MODAL CANCEL', type);
    if (type === 'change-alert') {
      this.canscelSelect();
    }
    this.setState({
      modalOpen: false,
    });
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    return (
      <main className='is-estore'>
        <NavAnchor pageTitle='網路門市' />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '網路門市', link: '/estore' },
            { text: '企業用戶專屬行動優惠', link: '/' },
            { text: '美安專屬優惠', link: '/' },
          ]}
        />

        {/* 可直接使用 BannerPromotionBasic */}
        <EStoreBanner
          slides={[
            {
              image: {
                md: '/resources/cbu/estore-event-product/cbu-ebustore-1920x500.jpg',
                sm: '/resources/cbu/estore-event-product/cbu-ebustore-banner-750x900.jpg',
              },
              title: '單辦門號大標題文字最多十六個字元',
              description: [
                '一二三四五六七八九十一二三四五六',
                '一二三四五六七八九十一二三四五六',
                '一二三四五六七八九十一二三四五六',
              ],
              actions: [
                {
                  text: 'Primary',
                  btnStyle: 'primary',
                  link: '#',
                  target: '_self',
                },
                {
                  text: 'Secondary',
                  btnStyle: 'secondary',
                  link: '#',
                  target: '_blank',
                },
              ],
            },
          ]}
        />

        <div className='ribbon-bg'>
          <section className='pb-0'>
            <div className='fui-container'>
              <h2>優惠方案說明</h2>
              <Panel className='px-4'>
                <h3>身為遠傳企業客戶，你可以享有：</h3>
                <PanelList
                  prefix='check'
                  list={[
                    { text: '單門號月租499，網內互打免費/上網吃到飽不限速' },
                    { text: '網路門市獨享！手機案申辦免預繳' },
                    { text: '企業員工及三等親：開放線上辦理' },
                    { text: '申辦檢附員工名片或識別證影本及相關親屬證明文件' },
                  ]}
                />
                <div className='fui-buttons mt-3'>
                  <Button btnStyle='primary' className='w-100'>
                    立即申辦
                  </Button>
                  <Button
                    btnStyle='secondary'
                    onClick={(e) =>
                      this.openModal({
                        type: 'notice',
                        title: '下載申請書',
                      })
                    }>
                    方案說明
                  </Button>
                  <Button btnStyle='secondary'>下載申請書</Button>
                </div>
              </Panel>
            </div>
          </section>

          <section className='pb-0'>
            <div className='fui-container'>
              <h2>熱門優惠</h2>
              <Panel className='px-4 py-1'>
                <ListLayout
                  listPosition='right'
                  image={'/resources/ebu/ebu-store/ebustore-landing@2x.jpg'}
                  title='國泰金控員工眷專屬，限時申辦享好禮！'
                  listPrefix='check'
                  list={[
                    { text: '辦就送10000mAH行動電源' },
                    { text: '加碼抽Honeywell抗敏空氣清淨機' },
                    { text: '加碼抽Honeywell抗敏空氣清淨機' },
                  ]}
                  actions={[
                    { text: '立即申辦', btnStyle: 'primary' },
                    { text: '查看詳細資費', btnStyle: 'secondary' },
                  ]}
                />
              </Panel>
            </div>
          </section>

          <section className='pb-0'>
            <div className='fui-container'>
              <h2>線上申辦驗證碼</h2>
              <Panel className='px-4'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} md={8}>
                    線上申辦需輸入驗證碼：企業群組代碼 MVPN 或 GMVPN CID
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} className='align-right'>
                    <Button btnStyle='secondary' className='m-0'>
                      企業驗證碼查詢
                    </Button>
                  </Grid>
                </Grid>
              </Panel>
            </div>
          </section>

          {/* CardMultiSteps */}
          <CardMultiSteps title='簡單申辦4步驟' list={this.state.stepCards} />

          <ServiceEntry
            title='常用功能'
            cards={[
              { title: '合約查詢', link: '/' },
              { title: '訂單查詢', link: '/' },
              { title: '特殊申辦表單下載', link: '/' },
              { title: '常見問題', link: '/' },
            ]}
          />
        </div>

        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-notice-modal'>
          <div className='fui-modal-content'>
            <div className='fui-modal-head'>
              <h5>下載申請書</h5>
            </div>
            <div className='fui-modal-body'>
              <p className='body is-text-medium'>單門號</p>
              <div className='fui-buttons'>
                <Button btnStyle='secondary'>一二三四五六七八</Button>
                <Button btnStyle='secondary'>一二三四五六七八</Button>
                <Button btnStyle='secondary'>一二三四五六七八</Button>
              </div>
              <p className='body is-text-medium'>手機案</p>
              <div className='fui-buttons'>
                <Button btnStyle='secondary'>一二三四五六七八</Button>
                <Button btnStyle='secondary'>一二三四五六七八</Button>
                <Button btnStyle='secondary'>一二三四五六七八</Button>
              </div>
              <p className='body is-text-medium'>平板案</p>
              <div className='fui-buttons'>
                <Button btnStyle='secondary'>一二三四五六七八</Button>
                <Button btnStyle='secondary'>一二三四五六七八</Button>
                <Button btnStyle='secondary'>一二三四五六七八</Button>
              </div>
            </div>
            <div className='fui-modal-foot'>
              <Button btnStyle='primary' onClick={(e) => this.setState({ modalOpen: false })}>
                確定
              </Button>
            </div>
          </div>
        </Modal>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    analyticsInitialState: state.analyticsInitialState, // 可加可不加
    cart: state.cartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      trackPageViewStart,
      setCartData,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SecretSpecialoffersShopCom));
