import React from 'react';
import Phone from './product/Phone';
import Accessory from './product/Accessory';
import SimCard from './product/SimCard';

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const pname = this.props.match.params.pname;
    return pname === 'iphone-11-pro-max-256gb' || pname === '3c-product-name' ? (
      <Phone {...this.props} />
    ) : pname === 'vantec-qi-red-wireless-chargers' ? (
      <Accessory {...this.props} />
    ) : pname === 'japan-5days-internet-card' ? (
      <SimCard {...this.props} />
    ) : null;
  }
}

export default Product;
