import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import NumberSelector from './NumberSelector';
import Dropdown from '../Dropdown';
import formatNumber from '../../utils/numberFormatter';

class RadioProductCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTag: '全部',
      selected: this.props.value ? this.props.value.split(/,/g) : [],
      defaultChecked: this.props.value ? this.props.value.split(/,/g) : [],
      options: [],
      sliderSetting: {
        dots: false,
        infinite: false,
        arrows: true,
        draggable: true,
        adaptiveHeight: true,
        slidesToShow: 4,
        centerMode: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              arrows: true,
              slidesToShow: 3,
              variableWidth: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              arrows: true,
              slidesToShow: 2,
              variableWidth: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              arrows: true,
              slidesToShow: 1,
              variableWidth: true,
            },
          },
        ],
      },
    };
  }

  componentDidMount() {
    if (this.props.default) {
      this.props.setValue(this.props.value);
    }

    if (this.props.withNumberController) {
      this.setState({
        options: this.props.options.reduce((accr, val, idx, arr) => {
          accr.push({
            ...val,
            number: 1,
          });
          return accr;
        }, []),
      });
    } else {
      if (!this.props.product) {
        this.setState({
          options: this.props.options.reduce((accr, val, idx, arr) => {
            accr.push({
              ...val,
              option: {
                ...val.option,
                selected: val.option.list[0].value,
              },
            });
            return accr;
          }, []),
        });
      } else {
        this.setState({
          options: this.props.product.options,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        defaultChecked: this.props.value ? this.props.value : '',
      });
    }
  }

  optionChange = (item, idx, opt, i, e) => {
    let options = Object.assign(this.state.options);

    options[i].option.selected = item.value;

    this.setState({
      options: options,
    });

    if (this.state.defaultChecked === item.value) {
      this.props.onChange(this.props.name, this.state.selected, options[i]);
    }
  };

  numberChange = (i, v) => {
    let opts = Object.assign(this.state.options);
    opts[i].number = v;
    this.setState({
      options: opts,
    });

    let selected = Object.assign(this.state.selected);
    let objects = opts.reduce((accr, obj, index, array) => {
      if (selected.indexOf(obj.value) > -1) accr.push(obj);

      return accr;
    }, []);

    this.props.onChange(this.props.name, selected.toString(), objects);
  };

  handleChange = (event, option) => {
    this.setState({
      selected: event.currentTarget.value,
    });
    this.props.setValue(event.currentTarget.value);
    this.props.onChange(this.props.name, event.currentTarget.value, option);
  };

  getDefaultCheck = (option) => {
    let isCheck = this.state.defaultChecked.indexOf(option.value) > -1;
    // console.log(this.state.defaultChecked, option, isCheck);
    return isCheck;
  };

  render() {
    return (
      <div className='fui-radio-cards-container'>
        {this.props.tag ? (
          <div className='tag-filter'>
            <div className='content'>
              <div className='tag-list'>
                <label
                  className={`fui-button is-label ${this.state.currentTag === '全部' ? 'is-active' : ''}`}
                  role='button'
                  onClick={(e) =>
                    this.setState({
                      currentTag: '全部',
                    })
                  }>
                  <span className='text'>全部({this.state.options.length})</span>
                </label>
                {this.props.tag.map((tag, i) => (
                  <label
                    className={`fui-button is-label ${this.state.currentTag === tag ? 'is-active' : ''}`}
                    role='button'
                    key={`menu-tag-${i}`}
                    onClick={(e) =>
                      this.setState({
                        currentTag: tag,
                      })
                    }>
                    <span className='text'>{tag}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        ) : null}

        <Slider {...this.state.sliderSetting} className='fui-checkbox-cards-slider'>
          {this.state.options.map((opt, i) => (
            <div
              className={`fui-card is-${opt.type === 'product' ? 'product' : 'plan'} is-radio ${
                this.getDefaultCheck(opt) ? 'is-checked' : ''
              }`}
              key={`checkcard-${opt.value}`}>
              <label className='fui-card-action'>
                <input
                  type='radio'
                  name={this.props.name}
                  value={opt.value}
                  onChange={(e) => this.handleChange(e, opt)}
                  defaultChecked={this.getDefaultCheck(opt)}
                />
                {opt.type === 'product' ? (
                  <div className='fui-card-image'>
                    <img
                      src={opt.image || process.env.PUBLIC_URL + '/resources/common/images/no-image@2x.png'}
                      alt={opt.title || opt.name}
                    />
                  </div>
                ) : null}
                {opt.type === 'friday' ? (
                  <div className='fui-card-plan is-friday'>
                    <div>
                      <h1>${formatNumber(opt.productPrice)}</h1>
                      <h4>{opt.meta}</h4>
                    </div>
                  </div>
                ) : null}
                {opt.type === 'music' ? (
                  <div className='fui-card-plan is-music'>
                    <div>
                      <h1>${formatNumber(opt.productPrice)}</h1>
                      <h4>{opt.meta}</h4>
                    </div>
                  </div>
                ) : null}
                <div className='fui-card-caption'>
                  {opt.type === 'product' ? <div className='fui-card-meta'>{opt.meta}</div> : null}
                  <div className='fui-card-title'>{opt.name || opt.title}</div>
                  {opt.option ? (
                    <div className='option-selector'>
                      <span className='label'>{opt.option.label}</span>
                      <Dropdown
                        className='is-button'
                        list={opt.option.list}
                        label={opt.option.selected}
                        arrow={true}
                        hasCheck={false}
                        onChange={(item, idx, e) => this.optionChange(item, idx, opt, i, e)}
                      />
                    </div>
                  ) : null}
                  <div className='fui-card-price'>
                    {opt.combinePrice ? (
                      <div>
                        <span className='label'>組合商品價</span>
                        <b className={`price`}>${formatNumber(opt.combinePrice)}</b>
                      </div>
                    ) : (
                      <div>
                        <span className='label'>{opt.type === 'product' ? '商品' : '加購'}價</span>
                        {opt.originPrice ? <del>${formatNumber(opt.originPrice)}</del> : null}
                        <b className={`price ${opt.originPrice ? 'is-text-error' : ''}`}>
                          ${formatNumber(opt.productPrice)}
                          {opt.day || opt.unit ? '/' : ''}
                          {opt.day || ''}
                          {opt.unit || ''}
                        </b>
                      </div>
                    )}
                  </div>
                </div>
              </label>

              {opt.modal ? (
                <div className='fui-card-extra'>
                  <div role='button' className='is-text-underline' onClick={(e) => this.props.modalOpen(opt.modal)}>
                    規格說明
                  </div>
                </div>
              ) : null}
              {this.props.withNumberController ? (
                <NumberSelector
                  name={this.props.name + '-number'}
                  min={1}
                  value={opt.number}
                  onChange={(n, v) => this.numberChange(i, v)}
                />
              ) : null}
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

RadioProductCard.propTypes = {
  tag: PropTypes.array,
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  modalOpen: PropTypes.func,
  withNumberController: PropTypes.bool,
};

export default withFormsy(RadioProductCard);
