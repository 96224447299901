import React from 'react';
import Link from '../Link';
import formatNumber from '../../utils/numberFormatter';
import { withRouter } from 'react-router-dom';

class ProductCard extends React.Component {
  doClick = (link) => {
    if (this.props.onClick) {
      this.props.onClick(link);
    } else {
      this.props.history.push(link);
    }
  };
  render() {
    return (
      <div className={`fui-card is-product ${this.props.type === 'wireless' ? 'is-wireless' : ''}`}>
        <Link role='button' to={this.props.link} target={this.props.target} className='fui-card-action'>
          {this.props.ribbon ? <div className='ribbon'>{this.props.ribbon}</div> : null}
          <div className='fui-card-image'>
            <img
              className='lazyload'
              data-src={this.props.image || process.env.PUBLIC_URL + '/resources/common/images/no-image@2x.png'}
              src={this.props.image || process.env.PUBLIC_URL + '/resources/common/images/no-image@2x.png'}
              alt={this.props.title}
            />
          </div>
          <div className='fui-card-caption'>
            <div className='fui-card-meta'>{this.props.meta}</div>
            <div className='body'>
              {this.props.tag ? <span className='tag'>{this.props.tag}</span> : null}
              {this.props.title}
            </div>
            <div className='fui-card-price'>
              {this.props.salePrice ? (
                <div>
                  <span className='label'>月付</span>
                  {this.props.originPrice ? <del>${formatNumber(this.props.originPrice)}</del> : null}
                  <b className='price'>{this.props.salePrice}</b>
                </div>
              ) : null}
              {this.props.productPrice ? (
                <div>
                  <span className='label'>商品價</span>
                  {this.props.originPrice ? <del>${formatNumber(this.props.originPrice)}</del> : null}
                  <b className={`price ${this.props.originPrice ? 'is-text-error' : ''}`}>
                    ${formatNumber(this.props.productPrice)}
                  </b>
                </div>
              ) : null}
              {this.props.projectPrice ? (
                <div>
                  <span className='label'>專案價</span>
                  <b className='price is-text-error'>${formatNumber(this.props.projectPrice)} 起</b>
                </div>
              ) : null}
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default withRouter(ProductCard);
