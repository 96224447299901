import React from 'react';

import { setFlowCartData, trackPageViewStart } from '../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import Button from '../components/Button';
import Tab from '../components/tab/Tab';
import TabPane from '../components/tab/TabPane';

import LabelInput from '../components/form/LabelInput';
import RadioCard from '../components/form/RadioCard';
import RadioGroup from '../components/form/RadioGroup';
import RadioCarousel from '../components/form/RadioCarousel';
import CheckboxCardCarousel from '../components/form/CheckboxCardCarousel';

import Cart from '../components/FlowCart';
import NavAnchor from '../components/partials/NavAnchor';
import Breadcrumb from '../components/Breadcrumb';
import EStoreBanner from '../components/partials/banner/EStoreBanner';
import SectionCollapseInfo from '../components/partials/collapse/SectionCollapseInfo';

import EstoreModal from '../components/EstoreModal';

import * as Mock from '../mock/Deals';
import * as Validation from '../utils/validation';

class Deals extends React.Component {
  constructor(props) {
    super(props);

    this.flowRef = React.createRef();
    this.state = {
      modalOpen: false,
      inputValidationValid: false,
      canEdit: true,
      isBackward: false,
      cartUpdate: false,
      currentModal: {
        type: '',
        title: '',
        content: '',
      },
      currentNav: window.location.hash === '#comment' ? 2 : 0,
      cuurentCheckboxTab: 0,
      currentImage: [],
      identityPass: false,
      sldierSetting: {
        dots: true,
        infinite: false,
        arrows: true,
        slidesToShow: 1,
        draggable: true,
        adaptiveHeight: true,
      },
      installmentCount: 74,
      installmentModal: {
        type: 'notice',
        title: '信用卡分期付款',
        content: [
          '分期資訊',
          '結帳金額 $30,300',
          {
            '3期0利率': '$10,000',
            '5期0利率': '$6,000',
            '6期0利率': '$5,000',
            '12期0利率': '$2,520',
          },
          [
            '手機分期付款僅提供宅配取貨，並使用線上刷卡供銷費者使用。',
            '分期價除不盡餘額部分，將於第一期帳單收取。',
            '各產品可供分期期數不盡相同，依結帳時系統提供分期數為主；如一張訂單有多項商品，只會出現共同皆可使用之分期數。',
          ],
        ],
      },
      welfareModal: {
        type: 'notice',
        title: '備註',
        content: [
          '本產品為拆封九成五新或原廠整新非全新品，機身可能有輕微細紋，不影響功能。外盒可能有些許磨損或使用痕跡，規格及盒內配件以實際出貨為主，請不介意之消費者再進行購買。',
          '請您耐心等候，為保障您退換貨權益，請於安裝SIM卡前先行檢查商品外觀及隨盒配件是否完整。同一筆訂單中的商品會一起出貨，故可能因為訂單中加購的商品缺貨而導致訂單延遲出貨，下單前請務必再次確認商品型號/顏色/資費方案及加購商品是否正確。',
        ],
      },
      originFlow: Mock.planStep,
      applyFlow: [...Mock.planStep, ...Mock.newFlow],
      deliverySubmit: false,
      reservationSubmit: false,
      deliveryForm: {
        type: { value: '新申辦' },
        name: { value: '', required: true },
        contact: { value: '', required: true },
      },
      reservationForm: {
        reserve_no: { value: '', required: true },
        id_number: { value: '', required: true },
      },
      // 使用既有的表單組件，再根據表單的值修改帶入購物車的內容
      form: {
        with_plan: { value: window.location.hash === '#device-alone' ? '0' : '1', required: true },
        input_validation: { value: '', required: true },
        id_number: { value: '', required: true },
        scid: { value: '', required: true },
        apply_method: { value: '' },
        number: { value: 1, required: true },
        plan: { value: '', required: true },
        phone_number: { value: '', required: true },
        gift: { value: '', required: true },
        extra_product: { value: '', required: true },
        extra_plan: { value: '', required: true },
      },
      cart: {
        apply_method: '',
        plan: null,
        product: null,
        identity_validation: null,
        input_validation: null,
        phone_number: null,
        gift: [],
        extra_product: [],
        extra_plan: [],
      },
      step: 0,
      editStep: -1,
    };
  }

  componentDidMount = () => {
    if (!!this.props.flowCart.cart) {
      this.setState({
        isBackward: true,
        cart: this.props.flowCart.cart,
        applyFlow: this.props.flowCart.applyFlow,
        step: this.props.flowCart.step,
        form: this.props.flowCart.form,
      });

      setTimeout(() => {
        this.scrollToPosition();

        if (this.state.step === 0) {
          this.initModal();
        }
      }, 1500);
    } else {
      this.setState({
        cart: {
          ...this.state.cart,
        },
      });
      this.initModal();
    }

    this.forceUpdate();

    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', (e) => this.detectStep());

      if (window.innerWidth > 768) window.addEventListener('mousewheel', (e) => this.detectSection(e));
      else window.addEventListener('scroll', (e) => this.detectSection(e));
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('popstate', (e) => this.detectStep());
    if (window.innerWidth > 768) window.removeEventListener('mousewheel', (e) => this.detectSection(e));
    else window.removeEventListener('scroll', (e) => this.detectSection(e));
  };

  initModal = () => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'activityForm',
        title: '請輸入活動驗證碼',
      },
    });
  };

  detectStep = (e) => {
    let step = this.state.editStep !== -1 ? this.state.editStep : this.state.step;

    if (this.props.location.pathname.indexOf('/estore/deals') > -1 && this.flowRef.current) {
      if (step - 1 >= 0) {
        this.changeFormSection(step - 1);
      } else {
        window.history.go(-1);
      }
    }
    return false;
  };

  detectSection = () => {
    if (this.state.form.with_plan.value === 0 || this.state.currentNav !== 0 || this.state.step > 0) return;

    if (this.flowRef.current) {
      let fistSec = this.flowRef.current.getElementsByTagName('section')[this.state.step];
      let fistRect = fistSec.getBoundingClientRect();
      let scrollY = window.scrollY || document.documentElement.scrollTop;

      if (scrollY > fistRect.top) {
        this.props.history.push('#' + fistSec.id);
      }
    }
  };

  inputChange = (n, v, content) => {
    let form = Object.assign(this.state.form);

    // 如果已進行申辦流程，切換申辦方式時跳出 modal
    if (n === 'with_plan') {
      this.props.history.push(v === '0' ? '#device-alone' : this.props.location.pathname);
    }
    if (
      this.state.applyFlow[this.state.editStep > -1 ? this.state.editStep : this.state.step].type === 'input-validation'
    ) {
      this.setState({
        inputValidationValid: /^09\d{2}-?\d{3}-?\d{3}$/.test(v),
        cart: {
          ...this.state.cart,
          input_validation: {
            phone_number: v,
            isPass: false,
          },
        },
      });
    }
    if (this.state.applyFlow[this.state.step].type === 'identity-validation') {
      this.setState({
        inputValidationValid:
          this.state.form.phone_number.value !== '' &&
          this.state.form.scid.value !== '' &&
          /^09\d{2}-?\d{3}-?\d{3}$/.test(this.state.form.scid.value),
        cart: {
          ...this.state.cart,
          identity_validation: {
            id_number: this.state.form.phone_number.value,
            scid: this.state.form.scid.value,
            isPass: false,
          },
        },
      });
    }

    if (n === 'apply_method' && this.state.editStep !== -1) {
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'change-alert',
          originSelect: form[n].value,
          originFlow: this.state.applyFlow,
          title: '組合方案選項將被清除',
          content: '提醒你，剛才你選擇的組合方案將被清除，請再次確認是否要重新選擇？',
        },
      });
    }

    form[n].value = v;

    // 切換顏色，圖片跟著切換內容
    if (n === 'color') {
      this.setState({
        currentImage: this.state.productInfo.images[v],
        cart: {
          ...this.state.cart,
          apply_method: '',
          plan: null,
          product: null,
          identity_validation: null,
          input_validation: null,
          phone_number: null,
          gift: [],
          extra_product: [],
          extra_plan: [],
        },
      });

      form = {
        ...form,
        input_validation: { value: '', required: true },
        id_number: { value: '', required: true },
        scid: { value: '', required: true },
        apply_method: { value: '' },
        plan: { value: '', required: true },
        phone_number: { value: '', required: true },
        gift: { value: '', required: true },
        extra_product: { value: '', required: true },
        extra_plan: { value: '', required: true },
      };
    }
    // 根據申辦方式切換申辦流程
    if (n === 'apply_method') {
      // 申辦新門號
      let flow = [];
      if (v === '申辦新門號') {
        flow = [...this.state.originFlow, ...Mock.newFlow];
      }
      // 攜碼到遠傳
      if (v === '攜碼') {
        flow = [...this.state.originFlow, ...Mock.carryFlow];
      }
      // 遠傳門號續約
      if (v === '遠傳門號續約') {
        flow = [...this.state.originFlow, ...Mock.renewFlow];
      }
      // 表單內容更新，購物車內容同步更新
      this.setState({
        cart: {
          ...this.state.cart,
          apply_method: v,
        },
        applyFlow: flow,
      });
      this.forceUpdate();
    }

    // 將完整資料帶入購物車
    if (!!content) {
      let cart = { ...this.state.cart };

      cart[n] = content;

      this.setState({
        cart: cart,
      });

      this.forceUpdate();
    }

    this.setState({
      form: form,
      cartUpdate: true,
    });

    this.props.setFlowCartData(this.state.cart);

    this.forceUpdate();
  };

  phoneCheck = () => {
    // 為顯示不同狀態的畫面，不同次點擊切換不同狀態
    if (this.state.identityPass) {
      this.setState({
        cart: {
          ...this.state.cart,
          input_validation: {
            phone_number: this.state.form.input_validation.value,
            isPass: /^09\d{2}-?\d{3}-?\d{3}$/.test(this.state.form.input_validation.value),
          },
        },
      });
    } else
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'phoneCheck-alert',
          title: '還未符合續約資格',
          content: `很抱歉，你的身份條件暫時無法續約。請洽遠傳門市或手機直撥 <a href='tel:123' target='_blank'>123客服專線</a> 查詢。`,
          confirmText: '可續約時通知我',
          cancelText: '可續約時通知我',
        },
      });

    this.setState({
      identityPass: !this.state.identityPass,
    });
  };

  openModal = (modal) => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'notice',
        ...modal,
      },
    });
  };

  // 取消切換申辦方式，還原為既有狀態
  canscelSelect = () => {
    let form = Object.assign(this.state.form);
    let cart = { ...this.state.cart };

    form.apply_method.value = this.state.currentModal.originSelect;
    cart.apply_method = this.state.currentModal.originSelect;

    this.setState({
      modalOpen: false,
      applyFlow: this.state.currentModal.originFlow,
      cart: { ...cart, apply_method: this.state.currentModal.originSelect },
      form: {
        ...form,
        apply_method: { value: this.state.currentModal.originSelect },
      },
    });
  };

  resetFormset = () => {
    // 重置 cart 與 form
    this.setState({
      cart: {
        ...this.state.cart,
        plan: null,
        input_validation: { value: '', required: true },
        id_number: { value: '', required: true },
        scid: { value: '', required: true },
        gift: [],
        extra_product: [],
        extra_plan: [],
        product: null,
      },
      form: {
        ...this.state.form,
        identity_validation: null,
        input_validation: null,
        number: { value: 1, required: true },
        plan: { value: '', required: true },
        phone_number: { value: '', required: true },
        gift: { value: '', required: true },
        extra_product: { value: '', required: true },
        extra_plan: { value: '', required: true },
      },
      editStep: -1,
      step: 0,
      modalOpen: false,
    });
  };

  CheckboxTabChange = (idx) => {
    this.setState({
      cuurentCheckboxTab: idx,
    });
  };

  stepChange = (step) => {
    this.setState({
      editStep: -1,
      step: step,
    });

    this.forceUpdate();
    setTimeout(() => {
      this.scrollToPosition();
    }, 500);
  };

  scrollToPosition = () => {
    // 捲動到對應位置
    let elem = window || document.documentElement;
    if (!this.flowRef.current) {
      if (window.isIE) {
        elem.scroll(0, 0);
      } else {
        elem.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
          cartUpdate: true,
        });
      }
      return;
    }

    let flows = this.flowRef.current.getElementsByClassName('fui-plan-flow');
    let lastFlow = flows[this.state.editStep !== -1 ? this.state.editStep : this.state.step];
    let prevFlow =
      flows[
        this.state.editStep !== -1 ? (this.state.editStep === 0 ? 0 : this.state.editStep - 1) : this.state.step - 1
      ];
    let rect = lastFlow.getBoundingClientRect();
    let prevRect = prevFlow.getBoundingClientRect();
    let scrollY = window.scrollY || document.documentElement.scrollTop;

    if (window.isIE) {
      elem.scroll(0, rect.top - prevRect.height + scrollY - 50);
    } else {
      elem.scrollTo({
        left: 0,
        top: scrollY - prevRect.height + rect.top - 50,
        behavior: 'smooth',
        cartUpdate: true,
      });
    }

    this.props.history.push('#' + this.state.applyFlow[this.state.step].id);
  };

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  changeFormSection = (index) => {
    if (this.state.applyFlow[index].type === 'identity-validation') {
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'change-alert',
          flowIndex: index,
          flowType: this.state.applyFlow[index].type,
          originFlow: this.state.applyFlow,
          title: '組合方案選項將被清除',
          content: '提醒你，剛才你選擇的組合方案將被清除，請再次確認是否要重新選擇？',
        },
      });
    } else {
      this.setState({
        editStep: index,
      });

      this.forceUpdate();
      setTimeout(() => {
        this.scrollToPosition();
        this.props.history.push(`#${this.state.applyFlow[index].id}`);
      }, 500);
    }
  };

  valuePreview = (flow) => {
    // 根據區塊名稱顯示個別內容
    if (flow.tabs) {
      return '';
    } else if (flow.name === 'identity_validation') {
      return this.state.form.scid.value;
    } else if (flow.name === 'input_validation') {
      return this.state.form.input_validation.value;
    } else if (flow.name === 'gift') {
      return this.state.cart[flow.name].length ? '贈品 x' + this.state.cart[flow.name].length : '';
    } else {
      return this.state.form[flow.name].value;
    }
  };

  optVarify = () => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'otpForm',
        title: '驗證手機號碼',
        phone: this.state.form.phone_number.value,
      },
    });
  };

  identityCheck = () => {
    // doValidation
    // 驗證成功
    if (this.state.identityPass)
      this.setState({
        cart: {
          ...this.state.cart,
          identity_validation: {
            id_number: this.state.form.id_number.value,
            scid: this.state.form.scid.value,
            isPass: true,
          },
        },
      });
    // 如果驗證失敗
    else
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'identityCheck-alert',
          title: '還未符合續約資格',
          content: `很抱歉，你的身份條件暫時無法續約。請洽遠傳門市或手機直撥 <a href='tel:123' target='_blank'>123客服專線</a> 查詢。`,
          confirmText: '可續約時通知我',
          cancelText: '查看原合約',
        },
      });

    this.setState({
      identityPass: !this.state.identityPass,
    });
  };

  submit = () => {
    this.props.setFlowCartData({
      cart: this.state.cart,
      form: this.state.form,
      applyFlow: this.state.applyFlow,
      step: this.state.step,
    });

    this.props.history.push('/estore/order/orderlist_fast_renewal');
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);

    this.setState({
      modalOpen: false,
    });

    if (type === 'otpForm') {
      this.identityCheck();
    }
    // 貨到通知送出
  };

  modalConfirm = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    if (type === 'change-alert') {
      const { currentModal } = this.state;
      if (currentModal.flowType && currentModal.flowType === 'identity-validation') {
        this.setState({
          step: currentModal.flowIndex,
          editStep: -1,
          form: {
            ...this.state.form,
            input_validation: { value: '', required: true },
            id_number: { value: '', required: true },
            scid: { value: '', required: true },
            phone_number: { value: '', required: true },
            gift: { value: '', required: true },
            extra_product: { value: '', required: true },
            extra_plan: { value: '', required: true },
          },
          cart: {
            ...this.state.cart,
            phone_number: null,
            identity_validation: null,
            input_validation: null,
            gift: [],
            extra_product: [],
            extra_plan: [],
            product: null,
          },
        });
        this.props.history.push(`#rn`);
      } else {
        this.resetFormset();
      }
    }
    if (type === 'phoneCheck-alert') {
      this.phoneCheck();
    }
    if (type === 'identityCheck-alert') {
      this.identityCheck();
    }
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalCancel = (type) => {
    // 預約取貨送出
    console.info('MODAL CANCEL', type);
    if (type === 'change-alert') {
      this.canscelSelect();
    }
    this.setState({
      modalOpen: false,
    });
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { form } = this.state;
    return (
      <main>
        <NavAnchor pageTitle='網路門市' />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '網路門市', link: '/estore' },
            { text: '單辦門號', link: '/' },
          ]}
        />

        <EStoreBanner
          slides={[
            {
              image: {
                md: '/resources/cbu/estore-deals/cbu-estore-special-01-1920x500.jpg',
                sm: '/resources/cbu/estore-deals/cbu-etore-special-kv-02-750x900.jpg',
              },
              title: '單辦門號大標題文字最多十六個字元',
              description: `
                <div class='fui-item'>
                  <img src='https://staging-www.fetnet.net/estore/resources/cbu/estore-deals/check-2.svg' />
                  <span class='text'>開賣前50名，送<span class='is-text-accent'>極速快充大禮包</span> (價值 $3,000)</span>
                </div>
                <div class='fui-item'>
                  <img src='https://staging-www.fetnet.net/estore/resources/cbu/estore-deals/check-2.svg' />
                  <span class='text'>一二三四五六七八九十一二三四五六</span>
                </div>
                <div class='fui-item'>
                  <img src='https://staging-www.fetnet.net/estore/resources/cbu/estore-deals/check-2.svg' />
                  <span class='text'>一二三四五六七八九十一二三四五六</span>
                </div>
              `,
            },
          ]}
        />

        {/* 申辦步驟 */}
        <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
          <div className='fui-container'>
            {form.with_plan.value === '1' ? (
              <div className='fui-plan-flow-container' ref={this.flowRef}>
                {this.state.applyFlow.map((flow, i) =>
                  this.state.step >= i ? (
                    <section
                      id={flow.id}
                      className={`fui-plan-flow ${
                        (this.state.step === i && this.state.editStep === -1) ||
                        (this.state.editStep > -1 && this.state.editStep === i)
                          ? 'is-active'
                          : ''
                      }`}
                      key={`fui-plan-flow-${i}`}>
                      <div className='fui-flow-title'>
                        <div className='content'>
                          <h2>
                            <span>
                              {i + 1}.{flow.title}
                            </span>
                            <span className='selected-value'>{this.valuePreview(flow)}</span>
                          </h2>
                        </div>
                        {this.state.canEdit ? (
                          <div className='extra'>
                            <Button btnStyle='secondary' size='small' onClick={(e) => this.changeFormSection(i)}>
                              {this.state.cart[flow.name] === '' ||
                              !this.state.cart[flow.name] ||
                              (Array.isArray(this.state.cart[flow.name]) && !this.state.cart[flow.name].length)
                                ? '尚未選擇'
                                : '修改'}
                            </Button>
                          </div>
                        ) : null}
                        {flow.name === 'plan' && this.state.form.apply_method.value === '遠傳門號續約' ? (
                          <div className='extra d-block'>
                            <Button
                              btnStyle='secondary'
                              size='small'
                              onClick={(e) => this.props.history.push('/estore/renewal/rateplan')}>
                              看更多方案
                            </Button>
                          </div>
                        ) : null}
                      </div>
                      <div className='fui-flow-body'>
                        <h6 className='fui-flow-description'>
                          {flow.name === 'apply_method' ? ' ' : flow.description}
                        </h6>
                        {/* 根據類別切換表單內容 */}
                        {/* 有 Tab 的產品牌卡，用於加購 */}
                        {flow.type === 'tab-checkbox-card' ? (
                          <div>
                            <Tab name={flow.tabs.name} list={flow.tabs.list} onChange={this.CheckboxTabChange} />
                            {flow.tabs.content.map((tab, i) => (
                              <TabPane key={`tab-checkbox-${i}`} active={i === this.state.cuurentCheckboxTab}>
                                <CheckboxCardCarousel
                                  {...tab}
                                  value={form[tab.name].value}
                                  onChange={this.inputChange}
                                  modalOpen={this.openModal}
                                />
                              </TabPane>
                            ))}
                          </div>
                        ) : null}
                        {/* 門號驗證 */}
                        {flow.type === 'identity-validation' ? (
                          <div className='paper fui-validation-input'>
                            <Grid container>
                              <Grid item xs={12} sm={8} md={6}>
                                <LabelInput
                                  label='遠傳門號'
                                  placeholder='輸入門號'
                                  validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
                                  required={form.scid.required}
                                  name='scid'
                                  validationErrors={{
                                    isDefaultRequiredValue: '請輸入原門號號碼',
                                    matchRegexp: '您輸入了錯誤資訊，請再檢查！',
                                  }}
                                  withValidCheck={
                                    this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                  }
                                  value={form.scid.value}
                                  onChange={this.inputChange}
                                />
                                <LabelInput
                                  label='身分證字號'
                                  placeholder='輸入身分證字號'
                                  required={form.id_number.required}
                                  name='id_number'
                                  validations={{
                                    idNumberValid: Validation.idNumberValid,
                                  }}
                                  validationErrors={{
                                    isDefaultRequiredValue: '請輸入身分證字號',
                                    idNumberValid: '您輸入了錯誤資訊，請再檢查！',
                                  }}
                                  withValidCheck={
                                    this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                  }
                                  value={form.id_number.value}
                                  onChange={this.inputChange}
                                />
                                {!(
                                  this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                ) ? (
                                  <Button
                                    btnStyle='primary'
                                    size='large'
                                    disabled={form.scid.value === '' || form.id_number.value === ''}
                                    onClick={this.optVarify}>
                                    查詢可續約方案
                                  </Button>
                                ) : null}
                              </Grid>
                            </Grid>
                          </div>
                        ) : null}
                        {/* 攜入門號驗證 */}
                        {flow.type === 'input-validation' ? (
                          <div className='fui-validation-input'>
                            <Grid container>
                              <Grid item xs={12}>
                                {flow.tabs ? (
                                  <div>
                                    <Tab
                                      name={flow.tabs.name}
                                      list={flow.tabs.list}
                                      onChange={this.CheckboxTabChange}
                                    />
                                    {flow.tabs.content.map((tab, i) => (
                                      <TabPane key={`tab-inputvalid-${i}`} active={i === this.state.cuurentCheckboxTab}>
                                        <div
                                          className={`fui-cards is-simple-step ${
                                            tab.length === 3 ? 'three' : 'four'
                                          }-card no-scrollbar`}>
                                          {tab.map((card, c) => (
                                            <div className='fui-card' key={`input-validation-step-${c}`}>
                                              <div className='fui-card-action'>
                                                <div className='fui-card-caption'>
                                                  <div className='fui-card-content'>
                                                    <div className='circle-number'>{card.no}</div>
                                                    <h3
                                                      className='fui-card-title'
                                                      dangerouslySetInnerHTML={{ __html: card.title }}></h3>
                                                    <p
                                                      className='fui-card-description'
                                                      dangerouslySetInnerHTML={{ __html: card.description }}></p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </TabPane>
                                    ))}
                                  </div>
                                ) : (
                                  <div className='fui-cards three-card no-scrollbar'>
                                    {flow.cards.map((card, c) => (
                                      <div className='fui-card' key={`input-validation-step-${c}`}>
                                        <div className='fui-card-action'>
                                          <div className='fui-card-caption'>
                                            <div className='fui-card-content'>
                                              <div className='circle-number'>{card.no}</div>
                                              <h3 className='fui-card-title'>{card.title}</h3>
                                              <p className='fui-card-description'>{card.description}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={8} md={6}>
                                <LabelInput
                                  label='輸入攜入門號'
                                  placeholder='輸入非遠傳門號'
                                  validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
                                  required={form.input_validation.required}
                                  name='input_validation'
                                  validationErrors={{
                                    isDefaultRequiredValue: '您輸入了錯誤資訊，請再檢查！',
                                    matchRegexp: '您輸入了錯誤資訊，請再檢查！',
                                  }}
                                  value={form.input_validation.value}
                                  withValidCheck={
                                    this.state.cart.input_validation && this.state.cart.input_validation.isPass
                                  }
                                  onChange={this.inputChange}
                                />
                                <div
                                  className='text-sm is-text-gray100'
                                  dangerouslySetInnerHTML={{ __html: flow.note }}></div>
                                {!(this.state.cart.input_validation && this.state.cart.input_validation.isPass) ? (
                                  <Button
                                    btnStyle='primary'
                                    size='large'
                                    disabled={!this.state.inputValidationValid}
                                    onClick={this.phoneCheck}>
                                    驗證號碼
                                  </Button>
                                ) : null}
                              </Grid>
                            </Grid>
                          </div>
                        ) : null}
                        {/* 產品牌卡，用於好禮 */}
                        {flow.type === 'checkbox-card' ? (
                          <CheckboxCardCarousel
                            name={flow.name}
                            {...flow}
                            value={form[flow.name].value}
                            onChange={this.inputChange}
                            modalOpen={this.openModal}
                          />
                        ) : null}
                        {/* 單選 Radio Button，選擇門號 */}
                        {flow.type === 'radio-carousel' ? (
                          <RadioCarousel
                            name={flow.name}
                            {...flow}
                            search={true}
                            disabled={this.state.isBackward}
                            value={form[flow.name].value}
                            onChange={this.inputChange}
                          />
                        ) : null}
                        {/* 單選牌卡，選擇資費方案 */}
                        {flow.type === 'radio-card' ? (
                          <RadioCard
                            name={flow.name}
                            tags={flow.tags}
                            options={flow.options}
                            value={form[flow.name].value}
                            onChange={this.inputChange}
                            modalOpen={this.openModal}
                          />
                        ) : null}
                        {/* 單純的 Radio Button，用於申請方式 */}
                        {flow.type === 'radio' ? (
                          <RadioGroup
                            label=''
                            name={flow.name}
                            options={flow.options}
                            description={flow.description}
                            default={form[flow.name].value}
                            value={form[flow.name].value}
                            onChange={this.inputChange}
                          />
                        ) : null}
                      </div>
                    </section>
                  ) : null
                )}
              </div>
            ) : null}
          </div>
        </Formsy>
        {/* 購物車 */}

        <SectionCollapseInfo title='貼心小叮嚀' content='貼心小叮嚀' />

        <Cart
          canRollback={true}
          afterUpdate={(e) => this.setState({ cartUpdate: false })}
          update={this.state.cartUpdate}
          steps={this.state.applyFlow}
          currentStep={this.state.step}
          openStep={this.state.editStep === -1 ? this.state.step : this.state.editStep}
          cart={this.state.cart}
          submit={(e) => this.submit(e)}
          stepChange={(e) => this.stepChange(e)}
          editStep={(e) => this.changeFormSection(e)}
        />

        <EstoreModal
          {...this.state.currentModal}
          open={this.state.modalOpen}
          onSubmit={this.modalSubmit}
          onConfirm={this.modalConfirm}
          onCancel={this.modalCancel}
          onClose={this.modalClose}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    analyticsInitialState: state.analyticsInitialState, // 可加可不加
    flowCart: state.flowCartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      trackPageViewStart,
      setFlowCartData,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Deals));
