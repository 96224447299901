export const planStep = [
  {
    id: 'apply',
    slug: '申辦',
    name: 'apply_method',
    title: '申辦方式',
    description: ['選個好門號，保值一輩子', '保留原有手機門號加入遠傳更划算', '與老客戶再續前緣，續約優惠享不完'],
    type: 'radio',
    options: [
      { label: '申辦新門號', value: '申辦新門號' },
      { label: '攜碼到遠傳', value: '攜碼到遠傳' },
      { label: '遠傳門號續約', value: '遠傳門號續約' },
    ],
  },
];

export const newFlow = [
  {
    id: 'plan-select',
    slug: '資費',
    title: '資費方案',
    name: 'plan',
    description: '吃到飽吃不飽這裡都有',
    type: 'radio-card',
    tags: {
      name: '資費分類',
      list: [
        // '全部',
        '5G方案',
        '4.5G方案',
        '吃到飽',
        '月付$599以下',
        '月付$600-$999',
        '月付$1,000以上',
        '月付$1,399',
        '輕上網',
      ],
    },
    options: [
      {
        ribbon: '網路限定方案再送禮券',
        value: '月租$1,399x12個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月租',
        price: 1399,
        prepay: 0,
        month: '12',
        note: '學生教職限定',
        list: [
          { title: '商品專案價', originPrice: 12000, projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
          { title: '活動優惠', text: `$500遠百禮券 + $500購物金` },
        ],
        tag: ['5G方案', '吃到飽', '月付$1,000以上', '月付$1,399'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
            '分期資訊',
            '結帳金額 $30,300.',
            {
              '3期0利率': '$10,000<',
              '5期0利率': '$6,060<',
              '6期0利率': '$5,050<',
              '12期0利率': '$2,525',
            },
            '合約說明',
            '設備補貼款 $10000 元。實際應繳之補貼款以合約未到期之“日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '電信補貼款 $10000元，電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        value: '月租$899x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月租',
        price: 899,
        prepay: 6000,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
          { title: '活動優惠', text: `$500遠百禮券 + $500購物金` },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
      {
        value: '月租$599x24個月',
        unit: '月租',
        price: 599,
        prepay: 6000,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
        ],
        tag: ['月付$599以下', '輕上網'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
      {
        value: '月租$499x24個月',
        unit: '月租',
        price: 499,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
        ],
        tag: ['月付$599以下', '輕上網'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'combine_1',
    slug: '商品',
    title: '挑選商品',
    name: 'products',
    type: 'radio-product-card',
    // description: '都送給你了，一定要帶走喔！至少需選兩項～',
    // minNumber: 2,
    tag: ['手機', '配件', '配件'],
    options: [
      {
        type: 'product',
        ribbon: '網路限定優惠',
        image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
        meta: 'APPLE',
        title: 'iPhone 11 Pro Max 256GB',
        combinePrice: 8990,
        value: 'iPhone 11 Pro Max 256GB 1',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
        tag: '5G手機',
        meta: 'APPLE',
        title: 'iPhone 11 Pro Max 256GB',
        combinePrice: 8990,
        value: 'iPhone 11 Pro Max 256GB 2',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        meta: 'APPLE',
        image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
        title: 'Apple iPhone SE (2020) 128GB',
        combinePrice: 8990,
        value: 'Apple iPhone SE (2020) 128GB 3',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        ribbon: '新機上市',
        meta: 'SAMSUNG',
        image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
        title: 'Samsung Galaxy A21s ',
        combinePrice: 8990,
        value: 'Samsung Galaxy A21s 4',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        ribbon: '新機上市',
        meta: 'SAMSUNG',
        image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
        title: 'Samsung Galaxy A21s ',
        combinePrice: 8990,
        value: 'Samsung Galaxy A21s 5',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
    ],
  },
  {
    id: 'combine_2',
    name: 'combine_1',
    slug: '組合',
    title: '挑選組合商品',
    type: 'radio-product-card',
    options: [
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
        value: 'PHILIPS 舒眠抗敏空氣清淨機 1',
        meta: 'PHILIPS',
        name: 'PHILIPS 舒眠抗敏空氣清淨機',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'PHILIPS 舒眠抗敏空氣清淨機',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-10@2x.jpg',
        value: 'Iris Ohyama 雙氣旋智能除蹣清淨機 2',
        meta: 'Iris Ohyama ',
        name: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-11@2x.jpg',
        value: 'Panasonic 15公升蒸氣烘烤爐 3',
        meta: 'PANASONIC',
        name: 'Panasonic 15公升蒸氣烘烤爐',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Panasonic 15公升蒸氣烘烤爐',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-16@2x.jpg',
        value: 'Xiaomi 米家掃拖機器人 4',
        meta: 'XIAOMI',
        name: 'Xiaomi 米家掃拖機器人',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Xiaomi 米家掃拖機器人',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
        value: 'XGIMI MOGO 攜帶型投影機內建喇叭 5',
        meta: 'XGIMI',
        name: 'XGIMI MOGO 攜帶型投影機內建喇叭',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'XGIMI MOGO 攜帶型投影機內建喇叭',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
    ],
  },
  {
    id: 'combine_3',
    name: 'combine_2',
    slug: '組合',
    title: '挑選組合商品',
    type: 'radio-product-card',
    options: [
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
        value: 'PHILIPS 舒眠抗敏空氣清淨機 1',
        meta: 'PHILIPS',
        name: 'PHILIPS 舒眠抗敏空氣清淨機',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'PHILIPS 舒眠抗敏空氣清淨機',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-10@2x.jpg',
        value: 'Iris Ohyama 雙氣旋智能除蹣清淨機 2',
        meta: 'Iris Ohyama ',
        name: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-11@2x.jpg',
        value: 'Panasonic 15公升蒸氣烘烤爐 3',
        meta: 'PANASONIC',
        name: 'Panasonic 15公升蒸氣烘烤爐',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Panasonic 15公升蒸氣烘烤爐',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-16@2x.jpg',
        value: 'Xiaomi 米家掃拖機器人 4',
        meta: 'XIAOMI',
        name: 'Xiaomi 米家掃拖機器人',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Xiaomi 米家掃拖機器人',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
        value: 'XGIMI MOGO 攜帶型投影機內建喇叭 5',
        meta: 'XGIMI',
        name: 'XGIMI MOGO 攜帶型投影機內建喇叭',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'XGIMI MOGO 攜帶型投影機內建喇叭',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
    ],
  },
  {
    id: 'new-number',
    slug: '門號',
    title: '選擇門號',
    type: 'radio-carousel',
    name: 'phone_number',
    description: '選一個好門號給你好運氣',
    searchLabel: '主題號碼',
    tags: ['免費門號', '黃金門號', '一路發168', '66大順', '幸福99', 'Lucky77', '真愛520', '發發88', '123門號'],
    options: [
      {
        label: '0903-063482',
        value: '0903-063482',
        description: '選號費將於第一期帳單進行扣抵',
        price: 1000,
        tag: ['黃金門號'],
      },
      {
        label: '0903-652344',
        value: '0903-652344',
        price: 500,
        tag: ['黃金門號'],
      },
      {
        label: '0903-849753',
        value: '0903-849753',
        tag: ['免費門號'],
      },
      {
        label: '0903-666333',
        value: '0903-666333',
        tag: ['66大順', '免費門號'],
      },
      {
        label: '0903-849123',
        value: '0903-849123',
        tag: ['免費門號', '123門號'],
      },
      {
        label: '0903-666332',
        value: '0903-666332',
        tag: ['66大順', '123門號', '免費門號'],
      },
      {
        label: '0903-245203',
        value: '0903-245203',
        tag: ['真愛520', '免費門號'],
      },
      {
        label: '0903-247752',
        value: '0903-247752',
        tag: ['Lucky77', '免費門號'],
      },
      {
        label: '0903-849953',
        value: '0903-849953',
        tag: ['幸福99', '免費門號'],
      },
      {
        label: '0903-668833',
        value: '0903-668833',
        tag: ['66大順', '發發88', '免費門號'],
      },
      {
        label: '0903-168753',
        value: '0903-168753',
        tag: ['一路發168', '免費門號'],
      },
      {
        label: '0903-666334',
        value: '0903-666334',
        tag: ['66大順', '免費門號'],
      },
    ],
  },
];

export const renewFlow = [
  {
    id: 'rn',
    slug: '驗證',
    title: '續約身份驗證',
    name: 'identity_validation',
    type: 'identity-validation',
    description: '為確保個人資訊安全，請輸入欲續約的手機門號，遠傳電信將為你驗證身份',
    isPass: false,
  },
  {
    id: 'plan-select',
    slug: '資費',
    title: '資費方案',
    name: 'plan',
    description: '吃到飽吃不飽這裡都有',
    type: 'radio-card',
    tags: {
      name: '資費分類',
      list: [
        // '全部',
        '5G方案',
        '4.5G方案',
        '吃到飽',
        '月付$599以下',
        '月付$600-$999',
        '月付$1,000以上',
        '月付$1,399',
        '輕上網',
      ],
    },
    options: [
      {
        ribbon: '網路限定方案再送禮券',
        value: '月租$1,399x12個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月租',
        price: 1399,
        prepay: 0,
        month: '12',
        note: '學生教職限定',
        list: [
          { title: '商品專案價', originPrice: 12000, projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
          { title: '活動優惠', text: `$500遠百禮券 + $500購物金` },
        ],
        tag: ['5G方案', '吃到飽', '月付$1,000以上', '月付$1,399'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
            '分期資訊',
            '結帳金額 $30,300.',
            {
              '3期0利率': '$10,000',
              '5期0利率': '$6,060',
              '6期0利率': '$5,050',
              '12期0利率': '$2,525',
            },
            '合約說明',
            '設備補貼款 $10000 元。實際應繳之補貼款以合約未到期之“日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '電信補貼款 $10000元，電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        value: '月租$899x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月租',
        price: 899,
        prepay: 6000,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
          { title: '活動優惠', text: `$500遠百禮券 + $500購物金` },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
      {
        value: '月租$599x24個月',
        unit: '月租',
        price: 599,
        prepay: 6000,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
        ],
        tag: ['月付$599以下', '輕上網'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
      {
        value: '月租$499x24個月',
        unit: '月租',
        price: 499,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
        ],
        tag: ['月付$599以下', '輕上網'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'combine_1',
    slug: '商品',
    title: '挑選商品',
    name: 'products',
    type: 'radio-product-card',
    // description: '都送給你了，一定要帶走喔！至少需選兩項～',
    // minNumber: 2,
    tag: ['手機', '配件', '配件'],
    options: [
      {
        type: 'product',
        ribbon: '網路限定優惠',
        image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
        meta: 'APPLE',
        title: 'iPhone 11 Pro Max 256GB',
        combinePrice: 8990,
        value: 'iPhone 11 Pro Max 256GB 1',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
        tag: '5G手機',
        meta: 'APPLE',
        title: 'iPhone 11 Pro Max 256GB',
        combinePrice: 8990,
        value: 'iPhone 11 Pro Max 256GB 2',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        meta: 'APPLE',
        image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
        title: 'Apple iPhone SE (2020) 128GB',
        combinePrice: 8990,
        value: 'Apple iPhone SE (2020) 128GB 3',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        ribbon: '新機上市',
        meta: 'SAMSUNG',
        image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
        title: 'Samsung Galaxy A21s ',
        combinePrice: 8990,
        value: 'Samsung Galaxy A21s 4',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        ribbon: '新機上市',
        meta: 'SAMSUNG',
        image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
        title: 'Samsung Galaxy A21s ',
        combinePrice: 8990,
        value: 'Samsung Galaxy A21s 5',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
    ],
  },
  {
    id: 'combine_2',
    name: 'combine_1',
    slug: '組合',
    title: '挑選組合商品',
    type: 'radio-product-card',
    options: [
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
        value: 'PHILIPS 舒眠抗敏空氣清淨機 1',
        meta: 'PHILIPS',
        name: 'PHILIPS 舒眠抗敏空氣清淨機',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'PHILIPS 舒眠抗敏空氣清淨機',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-10@2x.jpg',
        value: 'Iris Ohyama 雙氣旋智能除蹣清淨機 2',
        meta: 'Iris Ohyama ',
        name: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-11@2x.jpg',
        value: 'Panasonic 15公升蒸氣烘烤爐 3',
        meta: 'PANASONIC',
        name: 'Panasonic 15公升蒸氣烘烤爐',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Panasonic 15公升蒸氣烘烤爐',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-16@2x.jpg',
        value: 'Xiaomi 米家掃拖機器人 4',
        meta: 'XIAOMI',
        name: 'Xiaomi 米家掃拖機器人',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Xiaomi 米家掃拖機器人',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
        value: 'XGIMI MOGO 攜帶型投影機內建喇叭 5',
        meta: 'XGIMI',
        name: 'XGIMI MOGO 攜帶型投影機內建喇叭',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'XGIMI MOGO 攜帶型投影機內建喇叭',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
    ],
  },
  {
    id: 'combine_3',
    name: 'combine_2',
    slug: '組合',
    title: '挑選組合商品',
    type: 'radio-product-card',
    options: [
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
        value: 'PHILIPS 舒眠抗敏空氣清淨機 1',
        meta: 'PHILIPS',
        name: 'PHILIPS 舒眠抗敏空氣清淨機',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'PHILIPS 舒眠抗敏空氣清淨機',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-10@2x.jpg',
        value: 'Iris Ohyama 雙氣旋智能除蹣清淨機 2',
        meta: 'Iris Ohyama ',
        name: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-11@2x.jpg',
        value: 'Panasonic 15公升蒸氣烘烤爐 3',
        meta: 'PANASONIC',
        name: 'Panasonic 15公升蒸氣烘烤爐',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Panasonic 15公升蒸氣烘烤爐',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-16@2x.jpg',
        value: 'Xiaomi 米家掃拖機器人 4',
        meta: 'XIAOMI',
        name: 'Xiaomi 米家掃拖機器人',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Xiaomi 米家掃拖機器人',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
        value: 'XGIMI MOGO 攜帶型投影機內建喇叭 5',
        meta: 'XGIMI',
        name: 'XGIMI MOGO 攜帶型投影機內建喇叭',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'XGIMI MOGO 攜帶型投影機內建喇叭',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
    ],
  },
];

export const carryFlow = [
  {
    id: 'plan-select',
    slug: '資費',
    title: '資費方案',
    name: 'plan',
    description: '吃到飽吃不飽這裡都有',
    type: 'radio-card',
    tags: {
      name: '資費分類',
      list: [
        // '全部',
        '5G方案',
        '4.5G方案',
        '吃到飽',
        '月付$599以下',
        '月付$600-$999',
        '月付$1,000以上',
        '月付$1,399',
        '輕上網',
      ],
    },
    options: [
      {
        ribbon: '網路限定方案再送禮券',
        value: '月租$1,399x12個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月租',
        price: 1399,
        prepay: 0,
        month: '12',
        note: '學生教職限定',
        list: [
          { title: '商品專案價', originPrice: 12000, projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
          { title: '活動優惠', text: `$500遠百禮券 + $500購物金` },
        ],
        tag: ['5G方案', '吃到飽', '月付$1,000以上', '月付$1,399'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
            '分期資訊',
            '結帳金額 $30,300.',
            {
              '3期0利率': '$10,000',
              '5期0利率': '$6,060',
              '6期0利率': '$5,050',
              '12期0利率': '$2,525',
            },
            '合約說明',
            '設備補貼款 $10000 元。實際應繳之補貼款以合約未到期之“日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '電信補貼款 $10000元，電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        value: '月租$899x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月租',
        price: 899,
        prepay: 6000,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
          { title: '活動優惠', text: `$500遠百禮券 + $500購物金` },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
      {
        value: '月租$599x24個月',
        unit: '月租',
        price: 599,
        prepay: 6000,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
        ],
        tag: ['月付$599以下', '輕上網'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
      {
        value: '月租$499x24個月',
        unit: '月租',
        price: 499,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
        ],
        tag: ['月付$599以下', '輕上網'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'combine_1',
    slug: '商品',
    title: '挑選商品',
    name: 'products',
    type: 'radio-product-card',
    // description: '都送給你了，一定要帶走喔！至少需選兩項～',
    // minNumber: 2,
    tag: ['手機', '配件', '配件'],
    options: [
      {
        type: 'product',
        ribbon: '網路限定優惠',
        image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
        meta: 'APPLE',
        title: 'iPhone 11 Pro Max 256GB',
        combinePrice: 8990,
        value: 'iPhone 11 Pro Max 256GB 1',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
        tag: '5G手機',
        meta: 'APPLE',
        title: 'iPhone 11 Pro Max 256GB',
        combinePrice: 8990,
        value: 'iPhone 11 Pro Max 256GB 2',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        meta: 'APPLE',
        image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
        title: 'Apple iPhone SE (2020) 128GB',
        combinePrice: 8990,
        value: 'Apple iPhone SE (2020) 128GB 3',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        ribbon: '新機上市',
        meta: 'SAMSUNG',
        image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
        title: 'Samsung Galaxy A21s ',
        combinePrice: 8990,
        value: 'Samsung Galaxy A21s 4',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        ribbon: '新機上市',
        meta: 'SAMSUNG',
        image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
        title: 'Samsung Galaxy A21s ',
        combinePrice: 8990,
        value: 'Samsung Galaxy A21s 5',
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
    ],
  },
  {
    id: 'combine_2',
    name: 'combine_1',
    slug: '組合',
    title: '挑選組合商品',
    type: 'radio-product-card',
    options: [
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
        value: 'PHILIPS 舒眠抗敏空氣清淨機 1',
        meta: 'PHILIPS',
        name: 'PHILIPS 舒眠抗敏空氣清淨機',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'PHILIPS 舒眠抗敏空氣清淨機',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-10@2x.jpg',
        value: 'Iris Ohyama 雙氣旋智能除蹣清淨機 2',
        meta: 'Iris Ohyama ',
        name: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-11@2x.jpg',
        value: 'Panasonic 15公升蒸氣烘烤爐 3',
        meta: 'PANASONIC',
        name: 'Panasonic 15公升蒸氣烘烤爐',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Panasonic 15公升蒸氣烘烤爐',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-16@2x.jpg',
        value: 'Xiaomi 米家掃拖機器人 4',
        meta: 'XIAOMI',
        name: 'Xiaomi 米家掃拖機器人',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Xiaomi 米家掃拖機器人',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
        value: 'XGIMI MOGO 攜帶型投影機內建喇叭 5',
        meta: 'XGIMI',
        name: 'XGIMI MOGO 攜帶型投影機內建喇叭',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'XGIMI MOGO 攜帶型投影機內建喇叭',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
    ],
  },
  {
    id: 'combine_3',
    name: 'combine_2',
    slug: '組合',
    title: '挑選組合商品',
    type: 'radio-product-card',
    options: [
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
        value: 'PHILIPS 舒眠抗敏空氣清淨機 1',
        meta: 'PHILIPS',
        name: 'PHILIPS 舒眠抗敏空氣清淨機',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'PHILIPS 舒眠抗敏空氣清淨機',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-10@2x.jpg',
        value: 'Iris Ohyama 雙氣旋智能除蹣清淨機 2',
        meta: 'Iris Ohyama ',
        name: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-11@2x.jpg',
        value: 'Panasonic 15公升蒸氣烘烤爐 3',
        meta: 'PANASONIC',
        name: 'Panasonic 15公升蒸氣烘烤爐',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Panasonic 15公升蒸氣烘烤爐',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-16@2x.jpg',
        value: 'Xiaomi 米家掃拖機器人 4',
        meta: 'XIAOMI',
        name: 'Xiaomi 米家掃拖機器人',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'Xiaomi 米家掃拖機器人',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
        value: 'XGIMI MOGO 攜帶型投影機內建喇叭 5',
        meta: 'XGIMI',
        name: 'XGIMI MOGO 攜帶型投影機內建喇叭',
        combinePrice: 5100,
        option: {
          label: '顏色',
          name: 'color',
          list: [
            { text: '白', value: '白' },
            { text: '黑', value: '黑' },
          ],
        },
        modal: {
          title: 'XGIMI MOGO 攜帶型投影機內建喇叭',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
    ],
  },
  {
    id: 'np',
    slug: '門號',
    title: '攜入門號確認',
    type: 'input-validation',
    name: 'input_validation',
    description: '攜碼到遠傳請先注意...',
    cards: [
      {
        no: 1,
        title: '確認門號合約',
        description: '手機直撥給原業者，或前往原業者門市查詢合約狀態',
      },
      {
        no: 2,
        title: '逾期費用要繳清',
        description: '確認門號是使用你的姓名與證件申請，並該門號沒有欠費、停話或中止狀態。',
      },
      {
        no: 3,
        title: '攜碼錢我出',
        description: '原業者會收取$112元作業費用，於攜碼啟用後遠傳將會在下一期帳單中返還！',
      },
    ],
    label: '輸入攜入門號',
    placeholder: '輸入原門號號碼',
    note: `This site is protected by reCAPTCHA and the Google <a href='#'>Privacy Policy</a> and <a href='#'>Terms of Service</a> apply.`,
  },
];
