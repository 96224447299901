import React from 'react';
import PropTypes from 'prop-types';

class PanelFlow extends React.Component {
  render() {
    return (
      <div className='fui-step'>
        <div className='fui-step-header'>
          <h2>{this.props.number}</h2>
          <div className='text'>{this.props.title}</div>
        </div>
        <div className='fui-step-content'>{this.props.children}</div>
      </div>
    );
  }
}

PanelFlow.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default PanelFlow;
