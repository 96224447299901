export const products = [
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '網路限定優惠',
    image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
    tag: '5G手機',
    meta: 'APPLE',
    name: 'iPhone 11 Pro Max 256GB',
    salePrice: 1399,
    productPrice: 10500,
    storage: 3,
    value: '1',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'APPLE',
    // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    name: 'Apple iPhone SE (2020) 128GB',
    salePrice: 1399,
    productPrice: 10500,
    value: '2',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    name: 'Samsung Galaxy A21s ',
    salePrice: 1399,
    productPrice: 10500,
    value: '3',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
    tag: '5G手機',
    meta: 'APPLE',
    name: 'iPhone 11 Pro Max 256GB',
    salePrice: 1399,
    productPrice: 10500,
    value: '4',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    meta: 'APPLE',
    image: '/resources/cbu/find-product/480x480.png',
    name: 'Apple iPhone SE (2020) 128GB',
    salePrice: 1399,
    productPrice: 10500,
    value: '5',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    name: 'Samsung Galaxy A21s ',
    salePrice: 1399,
    productPrice: 10500,
    value: '6',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
    tag: '5G手機',
    meta: 'APPLE',
    name: 'iPhone 11 Pro Max 256GB',
    salePrice: 1399,
    productPrice: 10500,
    value: '7',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    meta: 'APPLE',
    image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    name: 'Apple iPhone SE (2020) 128GB',
    salePrice: 1399,
    productPrice: 10500,
    value: '8',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    name: 'Samsung Galaxy A21s ',
    salePrice: 1399,
    productPrice: 10500,
    value: '9',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    name: 'Samsung Galaxy A21s ',
    salePrice: 1399,
    productPrice: 10500,
    value: '10',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
    tag: '5G手機',
    meta: 'APPLE',
    name: 'iPhone 11 Pro Max 256GB',
    salePrice: 1399,
    productPrice: 10500,
    value: '11',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    meta: 'APPLE',
    image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    name: 'Apple iPhone SE (2020) 128GB',
    salePrice: 1399,
    productPrice: 10500,
    value: '12',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    name: 'Samsung Galaxy A21s ',
    salePrice: 1399,
    productPrice: 10500,
    value: '13',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    name: 'Samsung Galaxy A21s ',
    salePrice: 1399,
    productPrice: 10500,
    value: '14',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
    tag: '5G手機',
    meta: 'APPLE',
    name: 'iPhone 11 Pro Max 256GB',
    salePrice: 1399,
    productPrice: 10500,
    value: '15',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    meta: 'APPLE',
    image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    name: 'Apple iPhone SE (2020) 128GB',
    salePrice: 1399,
    productPrice: 10500,
    value: '16',
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    name: 'Samsung Galaxy A21s ',
    salePrice: 1399,
    productPrice: 10500,
    value: '17',
  },
];
