import React from 'react';
import Item from '../item/Item';
import Button from '../Button';

class PromotionCard extends React.Component {
  render() {
    return (
      <div className='fui-card is-guest is-promotion'>
        <div className='fui-card-action'>
          <div className='fui-card-content'>
            <h4 className=''>{this.props.title}</h4>
            {this.props.list
              ? this.props.list.map((item, i) => (
                  <Item prefix={'check'} className='is-text-white'>
                    {item}
                  </Item>
                ))
              : null}

            {this.props.action ? (
              <div className='fui-card-extra'>
                <Button
                  btnStyle='secondary'
                  className='is-reverse is-text-accent'
                  link={this.props.action.link}
                  target={this.props.action.target}>
                  {this.props.action.text}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default PromotionCard;
