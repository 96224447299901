import React from 'react';
import Link from '../Link';

import PropTypes from 'prop-types';

class ProductCardWithMultiAction extends React.Component {
  render() {
    const props = this.props;

    return (
      <div className={`fui-card is-product-carousel is-${props.type}`}>
        <div className='fui-card-action'>
          {props.ribbon ? <div className='fui-card-ribbon'>{props.ribbon}</div> : null}
          {props.image ? (
            <Link to={props.link} target={props.target || '_self'} className={`fui-card-image`}>
              <img src={props.image} srcSet={props.retinaImage || props.image} alt={props.meta} />
            </Link>
          ) : (
            ''
          )}
          <div className='fui-card-caption'>
            <Link to={props.link} target={props.target} className='fui-card-content'>
              {!props.meta ? '' : <div className='fui-card-meta'>{props.meta}</div>}
              <h4 className='fui-card-title'>
                <div className='text'>{props.title}</div>
              </h4>
              {!props.description ? '' : <div className='fui-card-meta'>{props.description}</div>}
            </Link>
            {props.actions ? (
              <div className='fui-card-extra'>
                {props.actions.map((act, j) => (
                  <Link to={act.link} target={act.target || '_self'}>
                    <div className='icon'>
                      <img src={process.env.PUBLIC_URL + `/resources/cbu/search-result/${act.icon}.svg`} alt='' />
                    </div>
                    <div className='text'>{act.text}</div>
                  </Link>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

ProductCardWithMultiAction.propTypes = {
  type: PropTypes.string, // product | ring | music | movie
  link: PropTypes.string,
  target: PropTypes.string, // _self | _blank
  ribbon: PropTypes.string, // product | ring | music | movie
  image: PropTypes.string, // product | ring | music | movie
  retinaImage: PropTypes.string, // product | ring | music | movie
  title: PropTypes.string, // product | ring | music | movie
  meta: PropTypes.string, // product | ring | music | movie
  description: PropTypes.string, // product | ring | music | movie
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      icon: PropTypes.string, // buy | trailer | download | audition
      text: PropTypes.string,
      target: PropTypes.string, // _self | _blank
    })
  ),
};

export default ProductCardWithMultiAction;
