import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';
import React from 'react';

class RadioButtons extends React.Component {
  constructor(props) {
    super(props);

    this.radioButton = React.createRef();
    this.state = {
      submitted: false,
      isInvalid: false,
      options: this.props.options,
      selected: this.props.selected || '',
    };
    this.handleChange = this.handleChange.bind(this);
    // this.passSelectedOption = this.passSelectedOption.bind(this);
  }

  componentDidMount() {
    this.props.setValue(this.props.selected);
  }

  componentDidUpdate(nextProps) {
    // console.log('value', this.props.getValue(), 'nextProps', nextProps.selected, 'props.selected', this.props.selected, 'state.selected',this.state.selected)
    if (this.props.getValue() !== this.state.selected) {
      this.props.setValue(this.props.selected);
      this.setState({
        selected: this.props.selected,
      });
    }
    if (this.props.isFormSubmitted() !== this.state.submitted) {
      this.setState({
        submitted: this.props.isFormSubmitted(),
        isInvalid: this.props.showError(),
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      selected: event.currentTarget.value,
    });
    this.props.setValue(event.currentTarget.value);
    this.props.getSelected(event.currentTarget.value);
    this.setState({
      isInvalid: this.props.showError(),
    });

    // if (typeof window !== 'undefined') {
    //   this.radioButton.current.addEventListener('click', this.passSelectedOption);
    // }
  };

  // passSelectedOption = () => {
  //   console.log('passSelectedOption', this.state.selected)
  //   this.props.getSelected(this.state.selected);
  // };

  mappingOption = () => {
    return this.props.options.map((option) => (
      <label className='radio-button' key={`radio-button-${this.props.name}-${option.label}`}>
        <p>{option.label}</p>
        <input
          type='radio'
          value={option.value}
          checked={option.value === this.state.selected}
          name={this.props.name}
          onChange={this.handleChange}
        />
        <span className='checkmark'></span>
      </label>
    ));
  };

  render() {
    const errorMessage = this.props.getErrorMessage();

    return (
      <div className={`form-group ${this.props.className}`}>
        {!!this.props.label ? (
          <label htmlFor={`input-${this.props.name}`} className={this.props.required ? 'is-required' : ''}>
            {this.props.label}
          </label>
        ) : (
          ''
        )}
        {/* {this.props.label ? <label>{this.props.label}</label> : ''} */}
        <div className='radio-buttons' ref={this.radioButton}>
          <div className='radio-group'>{this.mappingOption()}</div>
        </div>
        {this.state.isInvalid ? <span className='error-message'>{errorMessage}</span> : ''}
      </div>
    );
  }
}

RadioButtons.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  selected: PropTypes.string,
  getSelected: PropTypes.func,
};

export default withFormsy(RadioButtons);
