import { TYPES } from '../action';
import { memberState } from '../state';

export default function memberReducer(state = memberState, action) {
  switch (action.type) {
    case TYPES.SET_LOGIN_DATA:
      console.log(action, Object.assign({}, state, { ...action.member }));
      return Object.assign({}, state, { ...state, ...action.member });
    default:
      return Object.assign({}, state, { ...action.member });
  }
}
