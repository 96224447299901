import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import Link from '../Link';
import TextInput from '../form/TextInput';
import Select from '../form/Select';

import formatNumber from '../../utils/numberFormatter';
import Button from '../Button';

import PropTypes from 'prop-types';

class RecordPanel extends React.Component {
  constructor(props) {
    super(props);

    this.collapseBody = React.createRef();

    this.state = {
      open: false,
      contentHeight: 0,
      editAddress: false,
      address: this.props.orderer.address,
      canSubmit: false,
      form: {
        city: { value: '' },
        area: { value: '' },
        address: { value: '' },
      },
      orderSteps: {
        product_with_plan: ['訂單成立', '資料確認中', '出貨準備中', '物流配送中', '已簽收', '門號已開通'],
        plan: ['訂單成立', '資料確認中', '代客領取', '已簽收', '門號已開通'],
        sim_card_1: ['訂單成立', '資料確認中', '物流配送中', '已送達'],
        sim_card_2: ['訂單成立', '出貨準備中', '已送達'],
      },
    };
  }

  updateContentHeight = () => {
    setTimeout(() => {
      this.setState({
        contentHeight: this.collapseBody.current.clientHeight,
      });
    }, 500);
    this.forceUpdate();
  };

  toggleAddressForm = () => {
    this.setState({
      editAddress: true,
    });

    this.forceUpdate();
    this.updateContentHeight();
  };
  saveAddress = () => {
    this.setState({
      editAddress: false,
      address:
        this.state.form.city.value + this.state.form.area.value + this.state.form.address.value.replace(/\d/g, '*'),
    });

    this.forceUpdate();
    this.updateContentHeight();
  };

  toggleContent = () => {
    this.setState({
      open: !this.state.open,
      contentHeight: this.collapseBody.current.clientHeight,
    });

    this.forceUpdate();

    let collapseTop = (!this.state.open
      ? this.collapseBody.current.parentNode
      : this.collapseBody.current
    ).getBoundingClientRect();
    let scrollY = window.scrollY || document.documentElement.scrollTop;

    if (window.isIE) {
      let elem = window || document.documentElement;
      elem.scroll(0, scrollY + collapseTop.top - 100);
    } else {
      window.scrollTo({
        left: 0,
        top: scrollY + collapseTop.top - 100,
        behavior: 'smooth',
      });
    }
  };

  openModal = () => {
    this.props.modalOpen({
      type: 'notice',
      title: '優惠券使用說明',
      content: [
        [
          '使用期限至2019/1/31',
          '選購完成後，於題寫資料時請記得勾選「優惠券折抵優惠」。',
          '將序號輸入到空白欄位，輸入完成請按「確認」鍵。',
        ],
      ],
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  disbleButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  inputChange = (n, v) => {
    let newForm = Object.assign(this.state.form);

    newForm[n].value = v;

    this.setState({
      form: newForm,
    });
  };

  render() {
    const { open, form } = this.state;
    return (
      <div className={`fui-record-panel ${open ? 'is-open' : ''}`}>
        <div className='collapse-head'>
          <Grid container spacing={5} className='mb-4'>
            <Grid item xs={12} sm={6} md={6}>
              訂單編號：{this.props.order_no}
            </Grid>
            <Grid item xs={12} sm={6} md={6} className='align-right'>
              下單時間 : {this.props.order_at}
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className='product-info'>
                <div className='image'>
                  <img src={this.props.image} alt='' />
                </div>
                <div className='content'>
                  <h3>{this.props.title}</h3>
                  {this.props.subtitle ? <h5 className='is-text-regular'>{this.props.subtitle}</h5> : null}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className='is-text-medium'>
                訂單狀態：{this.props.status} (查貨單號：
                <Link
                  to={this.props.logistics.link}
                  target={this.props.logistics.target || '_self'}
                  className='is-underline-link'>
                  {this.props.logistics.no}
                </Link>
                )
              </div>
              <div className='order-steps'>
                {this.state.orderSteps[this.props.type].map((step, i) => (
                  <div
                    className={`order-step ${this.props.status === step ? 'is-active' : ''}`}
                    key={`${this.props.order_no}-order-step-${i}`}>
                    <div className='step-dot'></div>
                    <div className='step'>{step}</div>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          className='collapse-body'
          style={{
            height: open ? this.state.contentHeight : 0,
          }}>
          <div className='collapse-content' ref={this.collapseBody}>
            <h4 className='is-text-darkgray50'>訂單明細</h4>
            <table>
              <tr>
                <th width='25%'>訂單狀態</th>
                <td>
                  訂單狀態：{this.props.status} (查貨單號：
                  <Link
                    to={this.props.logistics.link}
                    target={this.props.logistics.target || '_self'}
                    className='is-underline-link is-text-blck50'>
                    {this.props.logistics.no}
                  </Link>
                  )
                </td>
              </tr>
              <tr>
                <th>證件上傳狀態</th>
                <td>
                  {this.props.card_status}
                  <div className='action'>
                    <label className='fui-button is-secondary is-small'>
                      <input type='file' name='card' />
                      <span className='text'>立即上傳證件</span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <th>申辦門號</th>
                <td>{this.props.phone_number}</td>
              </tr>
              <tr>
                <th>門號得標費</th>
                <td>${formatNumber(this.props.number_price)}</td>
              </tr>
              <tr>
                <th>門號選號費</th>
                <td>${formatNumber(this.props.number_choose_price)}(遠傳電信帳上收取)</td>
              </tr>
              <tr>
                <th>專案名稱</th>
                <td>{this.props.plan}</td>
              </tr>
              <tr>
                <th>專案商品</th>
                <td>
                  <div>{this.props.projectProduct}</div>
                  <div className='promotion-list'>
                    <span className='label'>我要投保</span>
                    <span className='text'>想讓新機更有保障嗎？遠傳用戶登入門號前往申請！</span>
                    <div className='action'>
                      <Button btnStyle='secondary' size='small'>
                        立即行動
                      </Button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>商品</th>
                <td>{this.props.product}</td>
              </tr>
              <tr>
                <th>配件</th>
                <td>
                  {this.props.accessory.map((acce, i) => (
                    <div key={`${this.props.order_no}-accessory-${i}`}>{acce}</div>
                  ))}
                </td>
              </tr>
              <tr>
                <th>加購商品</th>
                <td>
                  {this.props.addon_product.map((acce, i) => (
                    <div key={`${this.props.order_no}-accessory-${i}`}>{acce}</div>
                  ))}
                </td>
              </tr>
              <tr>
                <th>贈品</th>
                <td>
                  {Array.isArray(this.props.gift)
                    ? this.props.gift.map((g, i) => <div key={`${this.props.order_no}-gift-${i}`}>{g}</div>)
                    : this.props.gift}
                </td>
              </tr>
              <tr>
                <th>商品</th>
                <td>
                  {this.props.good.name}
                  (提貨序號 : {this.props.good.number})
                  <div
                    role='button'
                    onClick={this.openModal}
                    className='is-underline-link is-text-underline is-black50'>
                    使用說明
                  </div>
                </td>
              </tr>
              <tr>
                <th>預繳金額</th>
                <td>${formatNumber(this.props.prepay)}</td>
              </tr>
              <tr>
                <th>總結帳金額</th>
                <td>${formatNumber(this.props.total)}</td>
              </tr>
            </table>
            <h4 className='is-text-darkgray50'>訂購人資訊</h4>
            <table>
              <tr>
                <th width='25%'>訂購人姓名</th>
                <td>{this.props.orderer.name}</td>
              </tr>
              <tr>
                <th>付款方式</th>
                <td>{this.props.orderer.payment}</td>
              </tr>
              <tr>
                <th>取貨方式</th>
                <td>
                  {this.props.orderer.receive} (查貨號碼：
                  <Link
                    to={this.props.logistics.link}
                    target={this.props.logistics.target || '_self'}
                    className='is-underline-link is-text-blck50'>
                    {this.props.logistics.no}
                  </Link>
                  )
                </td>
              </tr>
              <tr>
                <th>聯絡電話</th>
                <td>{this.props.orderer.mobile}</td>
              </tr>
              <tr>
                <th>收貨地址</th>
                <td>
                  {this.state.address}
                  {!this.state.editAddress ? (
                    <div className='action'>
                      <Button btnStyle='secondary' size='small' onClick={this.toggleAddressForm}>
                        修改取貨地址
                      </Button>
                    </div>
                  ) : null}
                  {this.state.editAddress ? (
                    <Formsy onValid={this.enableButton} onInValid={this.disbleButton}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                          <Select
                            label='選擇縣/市'
                            name='city'
                            value={form.city.value}
                            validationErrors={{
                              isDefaultRequiredValue: '請選擇縣/市',
                            }}
                            required={true}
                            options={[{ text: '台北市', value: '台北市' }]}
                            onChange={this.inputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Select
                            label='選擇區域'
                            validationErrors={{
                              isDefaultRequiredValue: '請選擇區域',
                            }}
                            name='area'
                            required={true}
                            value={form.area.value}
                            options={[{ text: '中山區', value: '中山區' }]}
                            onChange={this.inputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInput
                            name='address'
                            value={form.address.value}
                            required={true}
                            placeholder={'街路巷弄'}
                            validationErrors={{
                              isDefaultRequiredValue: '請輸入街路巷弄',
                            }}
                            onChange={(v) => this.inputChange('address', v)}
                          />
                        </Grid>
                      </Grid>
                      <div className='action'>
                        <Button btnStyle='secondary' size='small' className='mt-1' onClick={this.saveAddress}>
                          確定
                        </Button>
                      </div>
                    </Formsy>
                  ) : null}
                </td>
              </tr>
              <tr>
                <th>發票格式</th>
                <td>
                  {this.props.orderer.receipt}
                  <div className='action'>
                    <Button btnStyle='secondary' size='small'>
                      查詢發票號碼
                    </Button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div role='button' className={`collapse-trigger`} onClick={this.toggleContent}>
          <span>{open ? '收合明細' : '查看明細'}</span>
          <i className={`icon-${open ? 'minus' : 'plus'}`}></i>
        </div>
      </div>
    );
  }
}

RecordPanel.propTypes = {
  openModal: PropTypes.func,
};

export default RecordPanel;
