import React from 'react';

import Tooltip from './Tooltip';

import PropTypes from 'prop-types';

class FcoinMsg extends React.Component {
  render() {
    return (
      <div className='fui-item is-fcoin'>
        <img src={process.env.PUBLIC_URL + '/resources/common/images/fcoin.svg'} width='24' alt='' />
        <span dangerouslySetInnerHTML={{ __html: this.props.content }}></span>
        {this.props.tooltip ? (
          <Tooltip
            inline={true}
            content={<i className='icon-information is-text-black50'></i>}
            trigger='click'
            tooltip={`<ul><li>網路門市對於商品專案價格 (經折抵後的最終金額為主) 再回饋遠傳幣</li><li>遠傳幣點數將在門號開通後，約3個日曆天入帳至此次申辦的門號上</li></ul>`}
          />
        ) : null}
      </div>
    );
  }
}

FcoinMsg.propTypes = {
  content: PropTypes.any,
  tooltip: PropTypes.bool,
};

export default FcoinMsg;
