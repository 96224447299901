import React from 'react';
import NavAnchor from '../components/partials/NavAnchor';
import Breadcrumb from '../components/Breadcrumb';
import EstorePromotion from '../components/ad/EstorePromotion';
import ProductMap from '../components/partials/ProductMap';
import ESectionFeature from '../components/partials/ESectionFeature';
import EStoreBanner from '../components/partials/banner/EStoreBanner';
import SectionProductTab from '../components/partials/card/SectionProductTab';
import GreetingGuide from '../components/partials/GreetingGuide';
import ServiceEntry from '../components/partials/card/ServiceEntry';
import ServiceTag from '../components/partials/ServiceTag';
import FooterAd from '../components/ad/FooterAd';

import * as Mock from '../mock/Estore';

class Estore extends React.Component {
  render() {
    return (
      <main>
        <NavAnchor {...Mock.navTitle} />
        <Breadcrumb {...Mock.breadcrumb} />
        <EStoreBanner {...Mock.banner} />
        <div className='ribbon-bg'>
          <GreetingGuide {...Mock.greeting} />
          <SectionProductTab {...Mock.promoProduct} />
        </div>
        <ServiceTag {...Mock.serviceTags} />
        <EstorePromotion {...Mock.eStorePromotion} />
        <ESectionFeature {...Mock.sectionFeature} />
        <ServiceEntry {...Mock.serviceCard} />
        <ProductMap {...Mock.productMap} />
        <FooterAd {...Mock.footerPromo} />
      </main>
    );
  }
}

export default Estore;
