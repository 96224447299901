import React from 'react';
import Slider from 'react-slick';
import { Grid } from '@material-ui/core';
import { withFormsy } from 'formsy-react';
import Dropdown from '../Dropdown';
import formatNumber from '../../utils/numberFormatter';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

class ExclusiveRadioCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.value || '',
      options: this.props.options,
      sliderSetting: {
        dots: false,
        infinite: false,
        arrows: true,
        draggable: true,
        centerMode: false,
        // adaptiveHeight: true,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              arrows: true,
              slidesToShow: 2,
              variableWidth: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              arrows: true,
              slidesToShow: 1,
              variableWidth: true,
            },
          },
        ],
      },
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.options !== this.props.options) {
      this.setState({
        options: this.props.options,
      });
    }
  };

  callModal = (e, opt) => {
    e.preventDefault();
    this.props.modalOpen(opt.plan.modal);
  };

  doClick = (link) => {
    if (this.props.onClick) {
      this.props.onClick(link);
    } else {
      this.props.history.push(link);
    }
  };

  handleChange = (event, option) => {
    this.setState({
      selected: option.value,
    });
    this.props.setValue(option.value);
    this.props.onChange(this.props.name, option.value, option);
  };

  toggleSelect = (e, opt) => {
    if (opt.value === this.props.value) {
      this.handleChange(e, { value: '' });
    } else {
      this.handleChange(e, opt);
    }
  };

  optionChange = (item, idx, e, rootIndex) => {
    let newOpt = Object.assign(this.state.options);

    newOpt[rootIndex].product.option.selected = e;

    this.setState({
      options: newOpt,
    });

    this.forceUpdate();
    // if (this.props.onColorChange) this.props.onColorChange(this.state.selected);
  };

  render() {
    return (
      <Slider {...this.state.sliderSetting} className={this.props.className}>
        {this.state.options.map((opt, i) =>
          opt.type === 'combine' ? (
            <div
              className={`fui-card is-radio-card is-product is-combine ${
                opt.value === this.props.value ? 'is-selected' : ''
              }`}
              key={`exclusive-radio-card-${i}`}>
              <label>
                <input
                  type='radio'
                  id={opt.value}
                  name={this.props.name}
                  value={opt.value}
                  checked={opt.value === this.props.value}
                  onClick={(e) => this.handleChange(e, opt)}
                  onChange={(e) => this.handleChange(e, opt)}
                />
              </label>
              <div className='fui-card-action'>
                {opt.ribbon ? <div className='ribbon'>{opt.ribbon}</div> : null}
                <Grid container>
                  <Grid item xs={6}>
                    <div className='fui-card-content'>
                      <div className='fui-card-image'>
                        <img
                          className='lazyload'
                          data-src={
                            opt.product.image || process.env.PUBLIC_URL + '/resources/common/images/no-image@2x.png'
                          }
                          src={opt.product.image || process.env.PUBLIC_URL + '/resources/common/images/no-image@2x.png'}
                          alt={opt.product.title}
                        />
                      </div>
                      <div className='fui-card-caption'>
                        <div className='fui-card-meta'>{opt.product.meta}</div>
                        <div className='body'>
                          {opt.product.tag ? <span className='tag'>{opt.product.tag}</span> : null}
                          {opt.product.title}
                        </div>
                      </div>
                    </div>
                    <div className='fui-card-extra'>
                      <div className='fui-card-price'>
                        {opt.product.salePrice ? (
                          <div>
                            <span className='label'>月付</span>
                            {opt.product.originPrice ? <del>${formatNumber(opt.product.originPrice)}</del> : null}
                            <b className='price'>{opt.product.salePrice}</b>
                          </div>
                        ) : null}
                        {opt.product.productPrice ? (
                          <div>
                            <span className='label'>商品價</span>
                            {opt.product.originPrice ? <del>${formatNumber(opt.product.originPrice)}</del> : null}
                            <b className={`price ${opt.product.originPrice ? 'is-text-error' : ''}`}>
                              ${formatNumber(opt.product.productPrice)}
                            </b>
                          </div>
                        ) : null}
                        {opt.product.projectPrice ? (
                          <div>
                            <span className='label'>專案價</span>
                            <div>
                              {opt.product.originPrice ? (
                                <del className='is-text-gray100'>${formatNumber(opt.product.originPrice)}</del>
                              ) : null}
                              <b className='price is-text-error'>${formatNumber(opt.product.projectPrice)} 起</b>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {opt.product.option ? (
                        <div className='option-selector'>
                          <span className='label'>{opt.product.option.label}</span>
                          <Dropdown
                            className='is-button'
                            list={opt.product.option.list}
                            label={opt.product.option.selected}
                            arrow={true}
                            hasCheck={false}
                            onChange={(item, idx, e) => this.optionChange(item, idx, e, i)}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className='fui-card-content'>
                      <div className='fui-card-image rateplan'>
                        <img
                          className='lazyload'
                          data-src={
                            process.env.PUBLIC_URL +
                            `/resources/common/images/${opt.plan.type === '5g' ? '5g' : '4g'}-logo.png`
                          }
                          src={
                            process.env.PUBLIC_URL +
                            `/resources/common/images/${opt.plan.type === '5g' ? '5g' : '4g'}-logo.png`
                          }
                          alt={opt.plan.title}
                        />
                      </div>
                      <div className='fui-card-plan'>
                        <h5>
                          月付 <b>${formatNumber(opt.plan.price)}</b>
                          <br />
                          合約 <b>{opt.plan.month}</b> 個月
                        </h5>
                      </div>
                    </div>
                    <div className='fui-card-extra align-center is-text-medium'>
                      <div role='button' onClick={(e) => this.callModal(e, opt)}>
                        <span className='text'>方案詳情</span>
                        <i className='icon-plus'></i>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <button
                      className='fui-button is-secondary is-block mb-0 mt-3'
                      onClick={(e) => this.toggleSelect(e, opt)}>
                      {opt.value === this.props.value ? (
                        <span className='text'>取消選取</span>
                      ) : (
                        <span className='text'>選擇方案</span>
                      )}
                    </button>
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            <div
              className={`fui-card is-radio-card ${opt.value === this.props.value ? 'is-selected' : ''}`}
              key={`exclusive-radio-card-${i}`}>
              <label>
                <input
                  type='radio'
                  id={opt.value}
                  name={this.props.name}
                  value={opt.value}
                  checked={opt.value === this.props.value}
                  onClick={(e) => this.handleChange(e, opt)}
                  onChange={(e) => this.handleChange(e, opt)}
                />
              </label>
              <div className='fui-card-action'>
                <div className='fui-card-plan'>
                  {opt.ribbon ? (
                    <div className={'fui-card-ribbon ' + (opt.ribbon.color ? 'is-' + opt.ribbon.color : '')}>
                      {opt.ribbon.text || opt.ribbon}
                    </div>
                  ) : null}
                  {opt.sticker ? <img src={opt.sticker} alt='' className='fui-card-sticker' /> : null}
                  <div className='plan-container'>
                    <div className='limited'>{opt.note}</div>
                    <div className='plan-content'>
                      <small className='unit'>
                        {opt.unit}
                        {!!opt.originPrice && opt.originPrice > 0 ? <del>${formatNumber(opt.originPrice)}</del> : null}
                      </small>
                      <h1 className='price'>${formatNumber(opt.price, 0)}</h1>
                      <span className='divider'>/</span>
                      <h5 className='month'>{opt.month}個月</h5>
                    </div>
                  </div>
                </div>
                <div className='fui-plan-list'>
                  {opt.list.map((item, j) => (
                    <div className='fui-plan-item' key={`radio-card-${i}-list-${j}`}>
                      <span className='item-title'>{item.title}</span>
                      {item.originPrice || item.projectPrice ? (
                        <span className='item-content'>
                          {item.originPrice > 0 ? <del>${formatNumber(item.originPrice)}</del> : null}
                          <span>${formatNumber(item.projectPrice)}</span>
                        </span>
                      ) : (
                        <span className='item-content' dangerouslySetInnerHTML={{ __html: item.text }}></span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className='fui-card-extra'>
                <div role='button' onClick={(e) => this.props.modalOpen(opt.modal)}>
                  <span className='text'>方案詳情</span>
                  <i className='icon-plus'></i>
                </div>
                <div className='fui-button is-secondary is-block mb-0 mt-2' onClick={(e) => this.toggleSelect(e, opt)}>
                  {opt.value === this.props.value ? (
                    <span className='text'>取消選取</span>
                  ) : (
                    <span className='text'>選擇方案</span>
                  )}
                </div>
              </div>
            </div>
          )
        )}
        <div className='fui-card is-more-option is-radio-card'>
          <div className='fui-card-ribbon is-blue'>看更多手機</div>
          <div className='fui-card-action'>
            <img
              src={process.env.PUBLIC_URL + '/resources/cbu/estore-product/more-phone.png'}
              srcset={process.env.PUBLIC_URL + '/resources/cbu/estore-product/more-phone@2x.png'}
              alt='more-phone'
            />
            <p>
              找不到喜歡的手機款式嗎?
              <br />
              <br />
              <div>提供更多選擇給您</div>
            </p>
          </div>
          <div className='fui-card-extra'>
            <div className='fui-button is-secondary'>
              <span className='text'>Apple</span>
            </div>
            <div className='fui-button is-secondary'>
              <span className='text'>Android</span>
            </div>
          </div>
        </div>
      </Slider>
    );
  }
}

ExclusiveRadioCard.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.any,
  onChange: PropTypes.func,
  modalOpen: PropTypes.func,
};
export default withFormsy(ExclusiveRadioCard);
