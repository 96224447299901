import { reducers } from './reducer';
import { createStore, combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

const allReducer = combineReducers({
  ...reducers,
  routing: routerReducer,
});

const store =
  typeof window !== 'undefined'
    ? createStore(allReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
    : createStore(allReducer);
export default store;
