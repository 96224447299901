import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../components/loading/Loading.js';
import store from '../stores';
import { trackPageViewStart, trackPageViewEnd, trackActionTrigger } from '../stores/action';

import '../sass/loader.sass';
window.isLoadingComplete = false;
window.hash = '';

window.isScroll = true;
window.prevHistory = {
  pathname: '',
  hash: '',
  key: '',
};

const Loader = (props) => {
  const history = useHistory();
  const [timer, setTimer] = React.useState(null);

  React.useLayoutEffect(() => {
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;

    if (typeof window !== 'undefined') {
      if (window.prevHistory.pathname !== window.location.pathname) {
        window.scrollTo(0, 0);

        if (
          window.location.pathname.indexOf('/estore/product/event_product_list/products') > -1 ||
          window.location.pathname.indexOf('/estore/deals') > -1 ||
          window.location.pathname.indexOf('/estore/rateplan') > -1 ||
          window.location.pathname.indexOf('/estore/renewal') > -1 ||
          window.location.pathname.indexOf('/detail') > -1
        ) {
          if (window.location.pathname.indexOf('/estore/renewal/phone') === -1) gotoHash(window.location.hash);
        } else {
          if (window.isLoadingComplete) {
            setTimer(
              setTimeout(() => {
                setTimer(clearTimeout(timer));
                window.isScroll = false;
                loadingAnimate();

                setTimer(
                  setTimeout(() => {
                    setTimer(clearTimeout(timer));
                    gotoHash(window.location.hash);
                  }, 200)
                );
              }, 200)
            );
            window.prevHistory = {
              origin: window.location.origin,
              ...window.location,
            };
          }
        }
      }
    }
  });

  React.useEffect(() => {
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;

    if (typeof window !== 'undefined') {
      window.onload = (e) => {
        e.preventDefault();
        window.isLoadingComplete = true;
        window.scrollTo(0, 0);
        detectPath();

        //console.info('[Loader] has Hash');
        setTimer(
          setTimeout(() => {
            setTimer(clearTimeout(timer));
            window.isScroll = false;
            console.log('useEffect');
            loadingAnimate();
            setTimer(
              setTimeout(() => {
                setTimer(clearTimeout(timer));
                if (window.location.pathname.indexOf('/estore/renewal/phone') === -1) gotoHash(window.location.hash);
              }, 200)
            );
          }, 200)
        );

        window.prevHistory = {
          pathname: window.location.pathname,
          hash: window.location.hash,
          origin: window.location.origin,
          search: window.location.search,
        };
      };
    }

    return function cleanup() {
      unlisten();
    };
  }, [loadingAnimate, timer, gotoHash, history, unlisten]);

  const unlisten = history.listen((listener, action) => {
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;

    if (typeof window !== 'undefined') {
      if (window.prevHistory.pathname === '') {
        window.prevHistory = listener;
      }
      setHeader();

      //start
      let elem = window || document.documentElement;

      document.body.className = '';
      detectPath();

      setTimer(
        setTimeout(() => {
          setTimer(clearTimeout(timer));
          if (
            window.prevHistory.pathname !== listener.pathname &&
            window.location.pathname.indexOf('/estore/product/event_product_list/products') > -1 &&
            window.location.pathname.indexOf('/estore/deals') > -1 &&
            window.location.pathname.indexOf('/estore/rateplan') > -1 &&
            window.location.pathname.indexOf('/estore/renewal') > -1 &&
            window.location.pathname.indexOf('/detail') > -1
          ) {
            loadingAnimate();
          }

          setTimer(
            setTimeout(() => {
              setTimer(clearTimeout(timer));
              if (window.location.pathname.indexOf('/estore/renewal/phone') === -1) gotoHash(window.location.hash);
            }, 200)
          );
          //end
        }, 200)
      );

      // if (window.prevHistory.pathname === listener.pathname && listener.hash !== window.prevHistory.hash) {
      //   window.isScroll = false;
      //   gotoHash(listener.hash);
      // }

      window.prevHistory = {
        origin: window.location.origin,
        ...listener,
      };
    }
  });

  const detectPath = () => {
    const query = new URLSearchParams(window.location.search);

    if (window.channelId === 'SUPPERAPP' || (query.get('channelId') && query.get('channelId') === 'SUPERAPP')) {
      document.body.classList.add('is-webview');
    }
    document.body.classList.add('is-eservice');
    document.body.classList.add('is-estore');

    if (window.location.pathname.indexOf('help-center') > -1) {
      document.body.classList.add('is-help-center');
    }
  };

  const gotoHash = useCallback((hash) => {
    if (
      window.location.pathname.indexOf('/estore/product/event_product_list/products') > -1 ||
      window.location.pathname.indexOf('/estore/deals') > -1 ||
      window.location.pathname.indexOf('/estore/rateplan') > -1 ||
      window.location.pathname.indexOf('/estore/renewal') > -1 ||
      window.location.pathname.indexOf('/detail') > -1
    )
      return;

    let target = document.getElementById(hash.slice(1));
    let elem = window || document.documentElement;
    let scrollY = window.scrollY || document.documentElement.scrollTop;

    if (hash === '#' || hash === '') {
      if (window.isIE) {
        elem.scroll(0, 0);
      } else {
        elem.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
        });
      }
    } else {
      if (target) {
        let rect = target.getBoundingClientRect();
        if (window.isIE) {
          elem.scroll(0, rect.top + scrollY - 100);
        } else {
          elem.scrollTo({
            left: 0,
            top: scrollY + rect.top - 100,
            behavior: 'smooth',
          });
        }
      } else {
        if (window.isIE) {
          elem.scroll(0, 0);
        } else {
          elem.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
          });
        }
      }
    }
    setTimer(
      setTimeout(() => {
        window.isScroll = true;
        setHeader();
        setTimer(clearTimeout(timer));
      }, 200)
    );
  });

  const loadingAnimate = useCallback(() => {
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;
    window.loading = true;

    // setTimer(
    //   setTimeout(() => {
    $html.classList.add('fade-in');
    $html.classList.remove('is-loading-block');
    $html.classList.remove('is-loading');
    $body.classList.remove('is-loading');

    setHeader();

    if (window.prevHistory.pathname !== '') {
      let scrollY =
        window.localStorage.getItem('scrollPosition') &&
        window.location.pathname.indexOf('detail') === -1 &&
        window.location.pathname.indexOf('/estore/order') === -1
          ? Number(window.localStorage.getItem('scrollPosition'))
          : 0;

      console.log('loadingAnimate', scrollY);
      if (window.isIE) {
        let elem = window || document.documentElement;
        elem.scroll(0, scrollY);
      } else {
        window.scrollTo(0, scrollY);
      }
      if ($body.getElementsByClassName('fui-header').length)
        $body.getElementsByClassName('fui-header')[0].setAttribute('style', '');
    }

    setTimeout(() => {
      $html.classList.remove('fade-in');
    }, 1500);

    window.loading = false;
    setHeader();
    setTimer(clearTimeout(timer));
    //   }, 200)
    // );
  });

  const setHeader = () => {
    if (
      (!document.getElementsByClassName('fui-nav-tab').length &&
        !document.getElementsByClassName('fui-nav-anchor').length) ||
      window.scrollY === 0
    ) {
      let headerDom = Array.from(
        document.getElementsByClassName('mothra-header').length
          ? document.getElementsByClassName('mothra-header')
          : document.getElementsByTagName('header')
      );
      headerDom.map((dom) => {
        dom.style.transform = `translateY(0px)`;
      });
    }
  };

  return (
    <section className='loading'>
      <Loading />
    </section>
  );
};

export default Loader;
