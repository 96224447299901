import React from 'react';
import Dropzone from 'react-dropzone';
import Button from '../Button';
import PropTypes from 'prop-types';

// for profile picture
class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.dropzone = React.createRef();
    this.state = {
      hasError: this.props.name === 'identity_image_2' && !!this.props.files[0],
      files: this.props.files,
      isScanning: false,
    };
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    if (!!this.state.files[0]) this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.files !== this.props.files)
      this.setState({
        hasError: this.props.name === 'identity_image_2' && !!this.props.files[0],
        files: this.props.files,
      });
  };

  onDrop = (accepted, rejected) => {
    this.setState({
      isScanning: true,
    });

    this.forceUpdate();
    if (Object.keys(rejected).length !== 0) {
      const message = 'Please submit valid file type';
      this.setState({ warningMsg: message });
    } else {
      this.props.addFile(accepted);
      this.setState({ warningMsg: '' });
      setTimeout(() => {
        this.setState({
          isScanning: false,
        });
    
        this.forceUpdate();
      }, 5000)
    }
  };

  render() {
    const { files } = this.props;

    return (
      <div
        className={`fui-dropzone ${ this.state.hasError ? 'has-error' : '' }`}
        ref={this.dropzone}>
        <Dropzone onDrop={this.onDrop} multiple={false} accept='image/jpg,image/jpeg,image/png'>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} name={this.props.name} accept='image/x-png,image/png,image/jpeg' />
              {!!files[0] ? (
                <div className='upload-preview'>
                  {!this.state.isScanning ? (
                    this.state.hasError ? (
                      <span className='error'>
                        <i className='icon-error'></i>
                      </span>
                    ) : (
                      <span className='check'>
                        <i className='icon-check'></i>
                      </span>
                    )
                  ) : null}

                  <div
                    className='preview-img'
                    style={{ backgroundImage: !!files[0] ? `url(${files[0].preview})` : 'null' }}></div>
                  <Button btnStyle='secondary'>重新上傳</Button>
                  {!this.state.isScanning && this.state.hasError ? (
                    <div className='error-message'>
                      {'圖片無法辨識，可能是因為反光、陰影、圖片歪斜遮住了證件內容。'}
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className='upload-description'>
                  <img src={'/resources/common/images/upload@2x.png'} width='120' alt='upload' />
                  <p>{this.props.placeholder}</p>
                  <Button btnStyle='secondary'>上傳圖片</Button>
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  files: PropTypes.array,
  addFile: PropTypes.func,
};

export default ImageUpload;
