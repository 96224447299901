import React from 'react';
import Slider from 'react-slick';
import Button from '../../Button';

import PropTypes from 'prop-types';

class EStoreBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: window.innerWidth <= 768,
    };

    this.slideSetting = {
      dots: true,
      infinite: true,
      arrows: false,
      focusOnSelect: true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      draggable: true,
      adaptiveHeight: true,
      customPaging: (i) => <a>{i + 1}</a>,
      beforeChange: (i) => {
        setTimeout(() => {
          let items = document.querySelectorAll('.estore-banner-item');
          let len = Array.from(items).length;
          let item = items[i + 1 > len ? 0 : i + 1];
          item.querySelector('a').focus();
        }, 500);
      },
    };
  }

  componentDidMount = () => {
    window.addEventListener('resize', (e) => {
      this.setState({
        isMobile: window.innerWidth <= 768,
      });
    });
  };

  render() {
    return (
      <div className='fui-banner is-estore'>
        <Slider {...this.slideSetting}>
          {this.props.slides.map((slide, i) => (
            <div className='estore-banner-item' key={`estore-banner-${i}`}>
              <div
                className='bg'
                style={{
                  backgroundImage: `url(${this.state.isMobile ? slide.image.sm : slide.image.md})`,
                }}>
                {/* <img src={slide.image.md} alt={slide.title} className='d-none d-sm-block' />
                <img src={slide.image.sm} alt={slide.title} className='d-block d-sm-none' /> */}
              </div>
              <div className='fui-container'>
                <div className='caption'>
                  {slide.tag ? (
                    <div className='tag'>
                      <div className='tag-bg'></div>
                      {slide.tag}
                    </div>
                  ) : null}
                  <h1 dangerouslySetInnerHTML={{ __html: slide.title }}></h1>
                  <div dangerouslySetInnerHTML={{ __html: slide.description }}></div>
                  {/* {Array.isArray(slide.description) ? (
                    <div className='fui-list'>
                      {slide.description.map((desp, i) => (
                        <div className='fui-item' key={`estore-banner-desp-${i}`}>
                          <img
                            src={process.env.PUBLIC_URL + '/resources/cbu/estore-deals/check-2.svg'}
                            width='30'
                            alt='check'
                          />
                          <span className='text' dangerouslySetInnerHTML={{ __html: desp }}></span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h5 dangerouslySetInnerHTML={{ __html: slide.description }}></h5>
                  )} */}

                  {slide.actions ? (
                    <div className='action'>
                      {slide.actions.map((act, j) => (
                        <Button
                          link={act.link}
                          target={act.target}
                          reverse={act.btnStyle === 'secondary'}
                          btnStyle={act.btnStyle}
                          key={`estore-banner-${i}-btn-${j}`}
                          size='large'>
                          {act.text}
                        </Button>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

EStoreBanner.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      tag: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          link: PropTypes.string,
          target: PropTypes.string,
        })
      ),
    })
  ),
};

export default EStoreBanner;
