export const banner = {
  image: {
    md: '/resources/cbu/theme-number/cbu-number-banner-1920x360.jpg',
    sm: '/resources/cbu/theme-number/estore-banner-m-4@2x.jpg',
  },
  title: '多種主題門號任你選…',
};

export const searchList = [
  { text: '66大順', link: '66大順', number: 66 },
  { text: 'Lucky77', link: 'Lucky77', number: 77 },
  { text: '真愛520', link: '真愛520', number: 520 },
  { text: '一路發168', link: '一路發168', number: 168 },
  { text: '黃金門號', link: '黃金門號', number: 66 },
  { text: '好記門號123', link: '好記門號123', number: 123 },
];

export const phoneList = [
  {
    label: '0903-063482',
    value: '0903-063482',
    description: '選號費將於第一期帳單進行扣抵',
    price: 1000,
    tag: ['黃金門號'],
  },
  {
    label: '0903-652344',
    value: '0903-652344',
    price: 500,
    tag: ['黃金門號'],
  },
  {
    label: '0903-849753',
    value: '0903-849753',
    tag: ['免費門號'],
  },
  {
    label: '0903-666333',
    value: '0903-666333',
    tag: ['66大順', '免費門號'],
  },
  {
    label: '0903-849123',
    value: '0903-849123',
    tag: ['免費門號', '123門號'],
  },
  {
    label: '0903-666332',
    value: '0903-666332',
    tag: ['66大順', '123門號', '免費門號'],
  },
  {
    label: '0903-245203',
    value: '0903-245203',
    tag: ['真愛520', '免費門號'],
  },
  {
    label: '0903-247752',
    value: '0903-247752',
    tag: ['Lucky77', '免費門號'],
  },
  {
    label: '0903-849953',
    value: '0903-849953',
    tag: ['幸福99', '免費門號'],
  },
  {
    label: '0903-668833',
    value: '0903-668833',
    tag: ['66大順', '發發88', '免費門號'],
  },
  {
    label: '0903-168753',
    value: '0903-168753',
    tag: ['一路發168', '免費門號'],
  },
  {
    label: '0903-666334',
    value: '0903-666334',
    tag: ['66大順', '免費門號'],
  },
];
