export const Phone = [
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '網路限定優惠',
    image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
    tag: '5G手機',
    meta: 'APPLE',
    title: 'iPhone 11 Pro Max 256GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'APPLE',
    // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    title: 'Samsung Galaxy A21s ',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'APPLE',
    // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'APPLE',
    // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
    tag: '5G手機',
    meta: 'APPLE',
    title: 'iPhone 11 Pro Max 256GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    meta: 'APPLE',
    image: '/resources/cbu/find-product/480x480.png',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    title: 'Samsung Galaxy A21s ',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'APPLE',
    // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'APPLE',
    // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
    tag: '5G手機',
    meta: 'APPLE',
    title: 'iPhone 11 Pro Max 256GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    meta: 'APPLE',
    image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    title: 'Samsung Galaxy A21s ',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'APPLE',
    // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'APPLE',
    // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'APPLE',
    // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'APPLE',
    // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'APPLE',
    // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/product/detail/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'APPLE',
    // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
];
