export const exclusiveFlow = [
  {
    id: 'exclusive-offer',
    name: 'exclusive_offer',
    slug: '專屬推薦方案',
    title: '專屬推薦方案',
  },
];

export const recommandOptions = [
  {
    type: 'combine',
    ribbon: '搭配商品更划算',
    value: '1',
    product: {
      link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
      ribbon: '搭配商品更划算',
      image: '/resources/cbu/estore-renewal/demo-phone-1.jpg',
      tag: '5G手機',
      meta: 'APPLE',
      title: 'iPhone 11 Pro Max 256GB',
      projectPrice: 10500,
      option: {
        label: '顏色',
        name: 'color',
        selected: '黑',
        list: [
          { text: '黑', value: '黑' },
          { text: '白', value: '白' },
        ],
      },
    },
    plan: {
      type: '5g',
      value: '月付 $1,399 合約 24 個月',
      sticker: '/resources/cbu/estore/5-g.png',
      unit: '月租',
      price: 1399,
      prepay: 0,
      month: '24',
      modal: {
        title: '$1,399/24個月 方案詳情',
        content: [
          {
            商品專案價: '$10,000',
            預繳金: '$1,000',
            上網傳輸量: '合約內不限速吃到飽',
            網內通話: '每通前5分鐘免費',
            網外通話: '60分鐘免費',
            語音優惠: '市話 + 網外送120分鐘',
            活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
          },
          '分期資訊',
          '結帳金額 $30,300.',
          {
            '3期0利率': '$10,000<',
            '5期0利率': '$6,060<',
            '6期0利率': '$5,050<',
            '12期0利率': '$2,525',
          },
          '<b>贈品/商品兌換說明</b>',
          '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
          '<b>門號開通說明</b>',
          [
            '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
            '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
          ],
          '<b>其他說明</b>',
          '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
          '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
        ],
      },
    },
  },
  {
    type: 'combine',
    ribbon: '搭配商品更划算',
    value: '2',
    product: {
      link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
      ribbon: '搭配商品更划算',
      image: '/resources/cbu/estore-renewal/demo-phone-2.png',
      tag: '5G手機',
      meta: 'APPLE',
      title: 'iPhone 11 Pro Max 256GB',
      originPrice: 12000,
      projectPrice: 10500,
      option: {
        label: '顏色',
        name: 'color',
        selected: '黑',
        list: [
          { text: '黑', value: '黑' },
          { text: '白', value: '白' },
        ],
      },
    },
    plan: {
      type: '4.5g',
      value: '月租 $1,399 x 24 個月',
      sticker: '/resources/cbu/estore/5-g.png',
      unit: '月租',
      price: 1399,
      prepay: 0,
      month: '24',
      content: [
        {
          上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
          量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
          網內通話: '免費',
          網外通話: '送120分鐘/月',
          市話通話: '1.8/分鐘',
          商品專案價: '$10,000',
          預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
          加值優惠: '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
          活動優惠: '辦就送遠傳幣購物金 $6,500',
        },
      ],
      modal: {
        title: '$1,399/24個月 方案詳情',
        content: [
          {
            商品專案價: '$10,000',
            預繳金: '$1,000',
            上網傳輸量: '合約內不限速吃到飽',
            網內通話: '每通前5分鐘免費',
            網外通話: '60分鐘免費',
            語音優惠: '市話 + 網外送120分鐘',
            活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
          },
          '分期資訊',
          '結帳金額 $30,300.',
          {
            '3期0利率': '$10,000<',
            '5期0利率': '$6,060<',
            '6期0利率': '$5,050<',
            '12期0利率': '$2,525',
          },
          '<b>贈品/商品兌換說明</b>',
          '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
          '<b>門號開通說明</b>',
          [
            '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
            '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
          ],
          '<b>其他說明</b>',
          '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
          '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
        ],
      },
    },
  },
  {
    ribbon: '先辦享優惠  隨時可換機',
    value: '3',
    sticker: '/resources/cbu/estore/5-g.png',
    unit: '月租',
    price: 1399,
    prepay: 0,
    month: '12',
    note: '學生教職限定',
    list: [
      { title: '上網傳輸量', text: `一二三四五六七八九十一二三四五六七八九十` },
      { title: '網內通話', text: `一二三四五六七八九十` },
      { title: '網外通話', text: `一二三四五六七八九十` },
      { title: '活動優惠', text: `一二三四五六七八九十一二三四五六七八九十` },
    ],
    tag: ['5G方案', '吃到飽', '月付$1,000以上', '月付$1,399'],
    modal: {
      title: '$1,399/12個月 方案詳情',
      content: [
        {
          商品專案價: '$10,000',
          預繳金: '$1,000',
          上網傳輸量: '合約內不限速吃到飽',
          網內通話: '每通前5分鐘免費',
          網外通話: '60分鐘免費',
          語音優惠: '市話 + 網外送120分鐘',
          活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
        },
        '分期資訊',
        '結帳金額 $30,300.',
        {
          '3期0利率': '$10,000',
          '5期0利率': '$6,060',
          '6期0利率': '$5,050',
          '12期0利率': '$2,525',
        },
        '<b>贈品/商品兌換說明</b>',
        '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
        '<b>門號開通說明</b>',
        [
          '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
          '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
        ],
        '<b>其他說明</b>',
        '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
        '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
      ],
    },
  },
];
export const recommandPlan = [
  {
    type: 'combine',
    ribbon: '單辦門號',
    product: {
      link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
      ribbon: '網路限定優惠',
      image: '/resources/cbu/estore-renewal/demo-phone-1.jpg',
      tag: '5G手機',
      meta: 'APPLE',
      title: 'iPhone 11 Pro Max 256GB',
      projectPrice: 10500,
      option: {
        label: '顏色',
        name: 'color',
        selected: '黑',
        list: [
          { text: '黑', value: '黑' },
          { text: '白', value: '白' },
        ],
      },
    },
    plan: {
      type: '5g',
      value: '月付 $1,399 合約 24 個月',
      sticker: '/resources/cbu/estore/5-g.png',
      unit: '月租',
      price: 1399,
      prepay: 0,
      month: '24',
      modal: {
        title: '$1,399/24個月 方案詳情',
        content: [
          {
            商品專案價: '$10,000',
            預繳金: '$1,000',
            上網傳輸量: '合約內不限速吃到飽',
            網內通話: '每通前5分鐘免費',
            網外通話: '60分鐘免費',
            語音優惠: '市話 + 網外送120分鐘',
            活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
          },
          '分期資訊',
          '結帳金額 $30,300.',
          {
            '3期0利率': '$10,000<',
            '5期0利率': '$6,060<',
            '6期0利率': '$5,050<',
            '12期0利率': '$2,525',
          },
          '<b>贈品/商品兌換說明</b>',
          '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
          '<b>門號開通說明</b>',
          [
            '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
            '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
          ],
          '<b>其他說明</b>',
          '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
          '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
        ],
      },
    },
    action: {
      text: '立即申辦',
      link: '',
    },
  },
  {
    type: 'combine',
    product: {
      link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
      ribbon: '網路限定優惠',
      image: '/resources/cbu/estore-renewal/demo-phone-2.png',
      tag: '5G手機',
      meta: 'APPLE',
      title: 'iPhone 11 Pro Max 256GB',
      originPrice: 12000,
      projectPrice: 10500,
      option: {
        label: '顏色',
        name: 'color',
        selected: '黑',
        list: [
          { text: '黑', value: '黑' },
          { text: '白', value: '白' },
        ],
      },
    },
    plan: {
      type: '4.5g',
      value: '月租 $1,399 x 24 個月',
      sticker: '/resources/cbu/estore/5-g.png',
      unit: '月租',
      price: 1399,
      prepay: 0,
      month: '24',
      modal: {
        title: '$1,399/24個月 方案詳情',
        content: [
          {
            商品專案價: '$10,000',
            預繳金: '$1,000',
            上網傳輸量: '合約內不限速吃到飽',
            網內通話: '每通前5分鐘免費',
            網外通話: '60分鐘免費',
            語音優惠: '市話 + 網外送120分鐘',
            活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
          },
          '分期資訊',
          '結帳金額 $30,300.',
          {
            '3期0利率': '$10,000<',
            '5期0利率': '$6,060<',
            '6期0利率': '$5,050<',
            '12期0利率': '$2,525',
          },
          '<b>贈品/商品兌換說明</b>',
          '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
          '<b>門號開通說明</b>',
          [
            '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
            '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
          ],
          '<b>其他說明</b>',
          '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
          '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
        ],
      },
    },
    action: {
      text: '立即申辦',
      link: '',
    },
  },
  {
    ribbon: '網路限定方案再送禮券',
    value: '月租$1,399x12個月',
    sticker: '/resources/cbu/estore/5-g.png',
    unit: '月租',
    price: 1399,
    prepay: 0,
    month: '12',
    note: '學生教職限定',
    list: [
      { title: '上網傳輸量', text: `一二三四五六七八九十一二三四五六七八九十` },
      { title: '網內通話', text: `一二三四五六七八九十` },
      { title: '網外通話', text: `一二三四五六七八九十` },
      { title: '活動優惠', text: `一二三四五六七八九十一二三四五六七八九十` },
    ],
    tag: ['5G方案', '吃到飽', '月付$1,000以上', '月付$1,399'],
    modal: {
      title: '$1,399/12個月 方案詳情',
      content: [
        {
          商品專案價: '$10,000',
          預繳金: '$1,000',
          上網傳輸量: '合約內不限速吃到飽',
          網內通話: '每通前5分鐘免費',
          網外通話: '60分鐘免費',
          語音優惠: '市話 + 網外送120分鐘',
          活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
        },
        '分期資訊',
        '結帳金額 $30,300.',
        {
          '3期0利率': '$10,000',
          '5期0利率': '$6,060',
          '6期0利率': '$5,050',
          '12期0利率': '$2,525',
        },
        '<b>贈品/商品兌換說明</b>',
        '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
        '<b>門號開通說明</b>',
        [
          '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
          '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
        ],
        '<b>其他說明</b>',
        '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
        '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
      ],
    },
    action: {
      text: '立即申辦',
      link: '',
    },
  },
];
export const threeC = [
  {
    type: 'product',
    link: '/estore/renewal/3c/3c-product-name',
    ribbon: '網路限定優惠',
    image: '/resources/cbu/find-product/396x476_1.jpg',
    meta: 'APPLE',
    title: 'Apple Watch',
    originPrice: '$5,600',
    projectPrice: '$5,100',
  },
  {
    type: 'product',
    link: '/estore/renewal/3c/3c-product-name',
    image: '/resources/cbu/find-product/estore-product-thumb-10@2x.jpg',
    tag: '福利A品',
    meta: 'Iris Ohyama',
    title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
    projectPrice: '$1,399',
  },
  {
    type: 'product',
    link: '/estore/renewal/3c/3c-product-name',
    image: '/resources/cbu/find-product/estore-product-thumb-11@2x.jpg',
    meta: 'Panasonic',
    title: 'Panasonic 15公升蒸氣烘烤爐',
    projectPrice: '$15,0000',
  },
  {
    type: 'product',
    link: '/estore/renewal/3c/3c-product-name',
    image: '/resources/cbu/find-product/estore-product-thumb-11@2x.jpg',
    meta: 'Panasonic',
    title: 'Panasonic 15公升蒸氣烘烤爐',
    projectPrice: '$15,0000',
  },
  {
    type: 'product',
    link: '/estore/renewal/3c/3c-product-name',
    image: '/resources/cbu/find-product/estore-product-thumb-11@2x.jpg',
    meta: 'Panasonic',
    title: 'Panasonic 15公升蒸氣烘烤爐',
    projectPrice: '$15,0000',
  },
  {
    type: 'product',
    link: '/estore/renewal/3c/3c-product-name',
    ribbon: '網路限定優惠',
    image: '/resources/cbu/find-product/estore-product-thumb-09@2x.jpg',
    meta: 'PHILIPS',
    title: 'PHILIPS 舒眠抗敏空氣清淨機',
    originPrice: '$5,600',
    projectPrice: '$5,100',
    // projectPrice: '$0'
  },
  {
    type: 'product',
    link: '/estore/renewal/3c/3c-product-name',
    image: '/resources/cbu/find-product/estore-product-thumb-10@2x.jpg',
    tag: '福利A品',
    meta: 'Iris Ohyama',
    title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
    projectPrice: '$1,399',
  },
  {
    type: 'product',
    link: '/estore/renewal/3c/3c-product-name',
    image: '/resources/cbu/find-product/estore-product-thumb-11@2x.jpg',
    meta: 'Panasonic',
    title: 'Panasonic 15公升蒸氣烘烤爐',
    projectPrice: '$15,0000',
  },
];

export const phone = [
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    ribbon: '網路限定優惠',
    image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
    tag: '5G手機',
    meta: 'APPLE',
    title: 'iPhone 11 Pro Max 256GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'APPLE',
    image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    title: 'Samsung Galaxy A21s ',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    title: 'Samsung Galaxy A21s ',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    title: 'Samsung Galaxy A21s ',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
    tag: '5G手機',
    meta: 'APPLE',
    title: 'iPhone 11 Pro Max 256GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    meta: 'APPLE',
    image: '/resources/cbu/find-product/480x480.png',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    title: 'Samsung Galaxy A21s ',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    title: 'Samsung Galaxy A21s ',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    title: 'Samsung Galaxy A21s ',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
    tag: '5G手機',
    meta: 'APPLE',
    title: 'iPhone 11 Pro Max 256GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    meta: 'APPLE',
    image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
    title: 'Apple iPhone SE (2020) 128GB',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    title: 'Samsung Galaxy A21s ',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    title: 'Samsung Galaxy A21s ',
    projectPrice: 10500,
  },
  {
    type: 'product',
    link: '/estore/renewal/phone/iphone-11-pro-max-256gb',
    ribbon: '新機上市',
    meta: 'SAMSUNG',
    image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
    title: 'Samsung Galaxy A21s ',
    projectPrice: 10500,
  },
];

export const newFlow = [
  {
    id: 'plan-select',
    slug: '資費',
    title: '資費方案',
    name: 'plan',
    description: '吃到飽吃不飽這裡都有',
    type: 'radio-card',
    tags: {
      name: '資費分類',
      list: [
        // '全部',
        '5G方案',
        '4.5G方案',
        '吃到飽',
        '月付$599以下',
        '月付$600-$999',
        '月付$1,000以上',
        '月付$1,399',
        '輕上網',
      ],
    },
    options: [
      {
        ribbon: '網路限定方案再送禮券',
        value: '月租$1,399x12個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月租',
        price: 1399,
        prepay: 0,
        month: '12',
        note: '學生教職限定',
        list: [
          { title: '商品專案價', originPrice: 12000, projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
          { title: '活動優惠', text: `$500遠百禮券 + $500購物金` },
        ],
        tag: ['5G方案', '吃到飽', '月付$1,000以上', '月付$1,399'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
            '分期資訊',
            '結帳金額 $30,300.',
            {
              '3期0利率': '$10,000',
              '5期0利率': '$6,060',
              '6期0利率': '$5,050',
              '12期0利率': '$2,525',
            },
            '合約說明',
            '設備補貼款 $10000 元。實際應繳之補貼款以合約未到期之“日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '電信補貼款 $10000元，電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        value: '月租$899x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月租',
        price: 899,
        prepay: 6000,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
          { title: '活動優惠', text: `$500遠百禮券 + $500購物金` },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
      {
        value: '月租$599x24個月',
        unit: '月租',
        price: 599,
        prepay: 6000,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
        ],
        tag: ['月付$599以下', '輕上網'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
      {
        value: '月租$499x24個月',
        unit: '月租',
        price: 499,
        month: '24',
        list: [
          { title: '商品專案價', text: `$10,000` },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
        ],
        tag: ['月付$599以下', '輕上網'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'giveaway',
    slug: '好禮',
    title: '好禮送給你',
    name: 'gift',
    type: 'checkbox-card',
    description: '都送給你了，一定要帶走喔！至少需選兩項～',
    minNumber: 2,
    options: [
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-12@2x.jpg',
        value: 'VANTEC Qi 紅色無線快速充電盤',
        meta: 'VANTEC Qi',
        name: 'VANTEC Qi 紅色無線快速充電盤',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-13@2x.jpg',
        value: '原廠 EarPods 耳機 - Lightning線',
        meta: 'APPLE',
        name: '原廠 EarPods 耳機 - Lightning線',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-14@2x.jpg',
        value: 'MYCELL AirPods Pro 粉沙收納盒',
        meta: 'MYCELL',
        name: 'MYCELL AirPods Pro 粉沙收納盒',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-15@2x.jpg',
        value: 'Samsung S20+原廠灰色透視感應皮套',
        meta: 'SAMSUNG',
        name: 'Samsung S20+原廠灰色透視感應皮套',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
    ],
  },
  {
    id: 'add-ons',
    slug: '加購',
    title: '加購商品及服務',
    type: 'tab-checkbox-card',
    description: '不買沒關係，買了絕對超值！',
    tabs: {
      name: 'checkbox-card-tab',
      list: [{ label: '加購商品' }, { label: '加值服務' }],
      content: [
        {
          name: 'extra_product',
          withNumberController: true,
          options: [
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
              value: 'PHILIPS 舒眠抗敏空氣清淨機',
              meta: 'PHILIPS',
              name: 'PHILIPS 舒眠抗敏空氣清淨機',
              productPrice: 5100,
              modal: {
                title: 'PHILIPS 舒眠抗敏空氣清淨機',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-10@2x.jpg',
              value: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
              meta: 'Iris Ohyama ',
              name: 'Iris Ohyama 雙氣旋智能除蹣清淨機',

              modal: {
                title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-11@2x.jpg',
              value: 'Panasonic 15公升蒸氣烘烤爐',
              meta: 'PANASONIC',
              name: 'Panasonic 15公升蒸氣烘烤爐',
              productPrice: 15000,
              modal: {
                title: 'Panasonic 15公升蒸氣烘烤爐',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-16@2x.jpg',
              value: 'Xiaomi 米家掃拖機器人',
              meta: 'XIAOMI',
              name: 'Xiaomi 米家掃拖機器人',
              productPrice: 6900,
              modal: {
                title: 'Xiaomi 米家掃拖機器人',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
              value: 'XGIMI MOGO 攜帶型投影機內建喇叭',
              meta: 'XGIMI',
              name: 'XGIMI MOGO 攜帶型投影機內建喇叭',
              productPrice: 6900,
              modal: {
                title: 'XGIMI MOGO 攜帶型投影機內建喇叭',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
          ],
        },
        {
          name: 'extra_plan',
          withNumberController: false,
          options: [
            {
              type: 'friday',
              value: 'friDay影音免費爽看30天',
              meta: '第一個月免費',
              name: 'friDay影音免費爽看30天',
              productPrice: 199,
              day: 30,
              unit: '天',
              modal: {
                title: 'friDay影音免費爽看30天',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'friday',
              value: '【超值限定】friDay影音爽看100天',
              meta: '好劇多到看不完',
              name: '【超值限定】friDay影音爽看100天',
              originPrice: 599,
              productPrice: 399,
              day: 100,
              unit: '天',
              modal: {
                title: '【超值限定】friDay影音爽看100天',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'music',
              value: 'friDay拍拍本隨手拍、隨手印',
              meta: '記錄你的珍貴時刻',
              name: 'friDay拍拍本隨手拍、隨手印',
              originPrice: 149,
              productPrice: 99,
              unit: '月',
              modal: {
                title: 'friDay拍拍本隨手拍、隨手印',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'music',
              value: 'friDay拍拍本隨手拍、隨手印 2',
              meta: '記錄你的珍貴時刻',
              name: 'friDay拍拍本隨手拍、隨手印 2',
              originPrice: 349,
              productPrice: 199,
              unit: '月',
              modal: {
                title: 'friDay拍拍本隨手拍、隨手印 2',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
          ],
        },
      ],
    },
  },
];
