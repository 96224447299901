import React from 'react';
import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import Formsy from 'formsy-react';
import { setFlowCartData } from '../../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import FormBanner from '../../components/partials/banner/FormBanner';
import Button from '../../components/Button';
import Link from '../../components/Link';
import Item from '../../components/item/Item';
import ImageUpload from '../../components/form/ImageUpload';
import Checkbox from '../../components/form/Checkbox';
import RadioButtons from '../../components/form/RadioButtons';
import LabelInput from '../../components/form/LabelInput';
import Select from '../../components/form/Select';

import Autocomplete from 'react-autocomplete';
import EstoreModal from '../../components/EstoreModal';
import DatePicker from 'react-datepicker';
import TW from 'date-fns/locale/zh-TW';

import formatNumber from '../../utils/numberFormatter';
import * as Validation from '../../utils/validation';
import * as Mock from '../../mock/Identity';

class IdentityFillin extends React.Component {
  constructor(props) {
    super(props);

    this.emailTnput = React.createRef();
    this.body = React.createRef();
    let dt = new Date();
    this.state = {
      cart: Mock.Cart,
      modalOpen: false,
      maxDate: [dt.getFullYear(), dt.getMonth() + 1, dt.getDate()],
      currentModal: {
        type: 'policy',
        title: '',
        content: '',
      },
      pageInfo: {
        pageTitle: '確認身份及基本資料',
        pageSubTitle: `上傳證件可以自動為您帶入資料免填寫，加速結帳資訊的填寫速度。填寫前請查看 <a href='https://www.fetnet.net/content/cbu/tw/information/privacy.html' target='_blank' class='outer-link'>個資保護法內容 <img src='${
          process.env.PUBLIC_URL + '/resources/common/images/external.svg'
        }' alt='link' /></a>`,
        ctaText: '下一步',
      },
      isMemberData: false,
      collapseOpen: false,
      contentHeight: 0,
      staff: [''],
      canSubmit: false,
      step: 0,
      datepickerUpdate: false,
      modalForm: {
        scid: { value: '', required: true },
        id_number: { value: '', required: true },
      },
      basicInfoForm: {
        combine_bill: { value: false },
        name: { value: '' },
        id_number: { value: '' },
        secondary_card: { value: '' },
        secondary_card_number: { value: '' },
        birthday: { value: '' },
        city: { value: '' },
        area: { value: '' },
        address: { value: '' },
        email: { value: '' },
        mobile: { value: '' },
        recommand: { value: '' },
        recommand_code: { value: '' },
      },
      emailList: [],
    };
  }

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  componentDidMount = () => {
    this.props.setFlowCartData(Mock.Cart);
    if (this.props.location.state && this.props.location.state.co_type) {
      this.setCoTypeWording();
      this.setState({
        nextPageInfo: {
          co_type: this.props.location.state.co_type,
        },
      });
    }

    if (!Mock.Cart) {
      this.props.history.go(-1);
    }
  };

  componentDidUpdate = (nextProps) => {
    if (this.props.onChange && (nextProps.open !== this.state.open || this.props.open !== this.state.open)) {
      this.setState({
        collapseOpen: this.props.collapseOpen,
      });
    }
  };

  setCoTypeWording = () => {
    let pageTitle = '確認身份及基本資料';
    let pageSubtitle = `上傳證件可以自動為您帶入資料免填寫，加速結帳資訊的填寫速度。填寫前請 <a href='https://www.fetnet.net/content/cbu/tw/information/privacy.html' target='_blank' class='outer-link'>查看個資保護法內容 <img src='${
      process.env.PUBLIC_URL + '/resources/common/images/external.svg'
    }' alt='link' /></a>`;
    let ctaText = '下一步';

    /**
      **All case**
      NC_identity
      NC
      NH_identity
      NH
      LC
      LC_identity
      LH
      LH_identity
      DA
    */
    switch (this.props.location.state.co_type) {
      case 'NC':
        pageSubtitle = `請確認個人基本資料。填寫前請 <a href='https://www.fetnet.net/content/cbu/tw/information/privacy.html' target='_blank' class='outer-link'>查看個資保護法內容 <img src='${
          process.env.PUBLIC_URL + '/resources/common/images/external.svg'
        }' alt='link' /></a>`;
        break;
      case 'NH':
        pageSubtitle = `請確認個人基本資料。填寫前請 <a href='https://www.fetnet.net/content/cbu/tw/information/privacy.html' target='_blank' class='outer-link'>查看個資保護法內容 <img src='${
          process.env.PUBLIC_URL + '/resources/common/images/external.svg'
        }' alt='link' /></a>`;
        break;
      case 'LC':
        pageSubtitle = `太好了！你已完成身份驗證，請確認個人基本資料，填寫前請 <a href='https://www.fetnet.net/content/cbu/tw/information/privacy.html' target='_blank' class='outer-link'>查看個資保護法內容 <img src='${
          process.env.PUBLIC_URL + '/resources/common/images/external.svg'
        }' alt='link' /></a>`;
        break;
      case 'LC_identity':
        pageSubtitle = `請確認個人基本資料。填寫前請 <a href='https://www.fetnet.net/content/cbu/tw/information/privacy.html' target='_blank' class='outer-link'>查看個資保護法內容 <img src='${
          process.env.PUBLIC_URL + '/resources/common/images/external.svg'
        }' alt='link' /></a>`;
        break;
      case 'LH':
        pageSubtitle = `太好了！你已完成身份驗證，請確認個人基本資料，填寫前請 <a href='https://www.fetnet.net/content/cbu/tw/information/privacy.html' target='_blank' class='outer-link'>查看個資保護法內容 <img src='${
          process.env.PUBLIC_URL + '/resources/common/images/external.svg'
        }' alt='link' /></a>`;
        break;
      case 'LH_identity':
        pageSubtitle = `請確認個人基本資料。填寫前請 <a href='https://www.fetnet.net/content/cbu/tw/information/privacy.html' target='_blank' class='outer-link'>查看個資保護法內容 <img src='${
          process.env.PUBLIC_URL + '/resources/common/images/external.svg'
        }' alt='link' /></a>`;
        break;
      case 'DA':
        pageSubtitle = `請確認個人基本資料。填寫前請 <a href='https://www.fetnet.net/content/cbu/tw/information/privacy.html' target='_blank' class='outer-link'>查看個資保護法內容 <img src='${
          process.env.PUBLIC_URL + '/resources/common/images/external.svg'
        }' alt='link' /></a>`;
        break;
    }

    this.setState({
      pageInfo: {
        pageTitle: pageTitle,
        pageSubTitle: pageSubtitle,
        ctaText: ctaText,
      },
    });
  };

  modalFormChange = (name, value) => {
    let modalForm = Object.assign(this.state.modalForm);
    modalForm[name].value = value;
    this.setState({
      modalForm,
    });
  };

  basicFormChange = (name, value) => {
    let basicInfoForm = Object.assign(this.state.basicInfoForm);
    basicInfoForm[name].value = value;

    if (name === 'combine_bill') {
      if (value === true)
        this.setState({
          currentModal: {
            type: 'combinebillForm',
            title: '合併帳單',
          },
          modalOpen: true,
        });
      else
        this.setState({
          isMemberData: false,
        });
    }
    this.setState({
      basicInfoForm,
      datepickerUpdate: name === 'birthday',
    });

    this.forceUpdate();
  };

  cartUpdate = (name, index, value) => {
    let cart = Object.assign(this.state.cart);
    cart[name][index].number = value;
    this.setState({
      cart: cart,
    });

    this.props.setFlowCartData(cart);
    this.forceUpdate();
  };

  setContentHeight = () => {
    this.setState({
      contentHeight: this.body.current.clientHeight,
    });
  };

  openModal = (e, type, name) => {
    e.preventDefault();

    this.setState({
      currentModal: {
        type: type,
        ...Mock[name],
      },
      modalOpen: true,
    });
  };

  addStaffCard = () => {
    let staff = Object.assign(this.state.staff);
    staff.push('');
    this.setState({
      staff,
    });
  };

  addFile = (name, file) => {
    let cardForm = Object.assign(this.state.cardForm);
    cardForm[name].value = Object.assign(file[0], {
      preview: URL.createObjectURL(file[0]),
    });

    this.setState({
      cardForm,
    });
  };

  nextStep = () => {
    this.setState({
      step: this.state.step + 1,
    });

    setTimeout(() => {
      this.props.history.push('#fillin');
    }, 300);
  };

  prevStep = () => {
    this.props.history.go(-1);
  };

  openCollapse = (event) => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });

    this.forceUpdate();
    this.setContentHeight();

    // [AA Tracking]
    if (window && typeof window.ctrf === 'function')
      window.ctrf(event || {}, this.state.open ? `Tab_${this.props.title}_收合` : `Tab_${this.props.title}_展開`);
  };

  isImageEmpty = () => {
    let result = false;
    ['identity_image_1', 'identity_image_2', 'secondary_card_1', 'secondary_card_2', 'staff_card_1'].forEach((text) => {
      if (this.state.cardForm[text].value === null) result = true;
    });
    return result;
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);

    if (type === 'combinebillForm') {
      this.setState({
        basicInfoForm: {
          combine_bill: { value: true },
          name: { value: '吳明士', required: true },
          id_number: { value: 'A123456789', required: true },
          secondary_card: { value: '健保卡', required: true },
          secondary_card_number: { value: 'G123456789' },
          birthday: { value: new Date('1990/06/30'), required: true },
          city: { value: '台北市', required: true },
          area: { value: '中山區', required: true },
          address: { value: '樂群二路187號6樓之2', required: true },
          email: { value: 'service@fetnet.com.tw', required: true },
          mobile: { value: '0923456789', required: true },
          recommand: { value: '', required: true },
          recommand_code: { value: '', required: true },
        },
        isMemberData: true,
        modalOpen: false,
      });
    } else {
      this.setState({
        modalOpen: false,
      });
    }
    this.forceUpdate();
  };

  modalCancel = (type) => {
    console.info('MODAL CANCEL', type);

    this.setState({
      modalOpen: false,
    });

    this.forceUpdate();
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);

    if (type === 'combinebillForm') {
      this.setState({
        basicInfoForm: {
          ...this.state.basicInfoForm,
          combine_bill: { value: false },
        },
        modalOpen: false,
      });
    } else {
      this.setState({
        modalOpen: false,
      });
    }

    this.forceUpdate();
  };

  emailKeydown = (e) => {
    let val = e.target.value;

    //   this.setState({
    //     emailList: [`${val}@gmail.com`, `${val}@yahoo.com`, `${val}@yahoo.com.tw`, `${val}@outlook.com`],
    //   });
  };

  render() {
    const { basicInfoForm, modalForm, pageInfo } = this.state;
    const CustomInput1 = React.forwardRef(({ value, onClick }, ref) => (
      <input
        type='text'
        readOnly
        ref={ref}
        value={value}
        placeholder={this.props.placeholder}
        disabled={this.props.disable}
        onClick={() => {
          onClick();
        }}
      />
    ));

    return (
      <main>
        <FormBanner
          title={pageInfo.pageTitle}
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className='fui-order-container section-upload'>
          <div className='fui-container'>
            <h4 className='is-text-darkgray50' dangerouslySetInnerHTML={{ __html: pageInfo.pageSubTitle }}></h4>

            <Formsy onValid={this.enableButton} onInvalid={this.disableButton} id='fillin'>
              <div className='paper is-paper-collapse'>
                <div className='collapse-header'>
                  <h4>
                    填寫基本資料
                    <span className='check'>
                      <i className='icon-check'></i>
                    </span>
                  </h4>
                </div>
                <div className='collapse-body'>
                  <div className='form-group with-agree mb-5'>
                    <Checkbox
                      className='is-agreement'
                      name='combine_bill'
                      checked={basicInfoForm.combine_bill.value}
                      label='我已有遠傳其他門號，我要合併帳單（將自動帶出部分基本資料，加速填寫流程）'
                      onChange={this.basicFormChange}
                    />
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <LabelInput
                        name='name'
                        label={'姓名'}
                        placeholder='輸入姓名'
                        required={basicInfoForm.name.required}
                        validationErrors={{
                          isDefaultRequiredValue: ' 請輸入姓名！',
                        }}
                        disabled={this.state.isMemberData}
                        value={basicInfoForm.name.value}
                        onChange={this.basicFormChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <LabelInput
                        name='id_number'
                        label='身分證字號'
                        disabled={this.state.isMemberData}
                        validations={{
                          idNumberValid: Validation.idNumberValid,
                        }}
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入身分證字號',
                          idNumberValid: '請輸入有效身分證字號！',
                        }}
                        placeholder='請輸入身分證字號'
                        required={basicInfoForm.id_number.required}
                        value={basicInfoForm.id_number.value}
                        onChange={this.basicFormChange}
                      />
                    </Grid>
                  </Grid>

                  <div className='form-group'>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <label>
                          第二證件
                          <div
                            className='is-underline-link d-inline-block ml-1'
                            onClick={(e) => this.openModal(e, 'secondCardModal', 'secondCardModal')}>
                            什麼是第二證件？
                          </div>
                        </label>
                        <Select
                          disabled={this.state.isMemberData}
                          validationErrors={{
                            isDefaultRequiredValue: '請選擇第二證件選項！',
                          }}
                          required={basicInfoForm.secondary_card.required}
                          value={basicInfoForm.secondary_card.value}
                          label='請選擇'
                          options={[
                            { text: '駕照', value: '駕照' },
                            { text: '健保卡', value: '健保卡' },
                            { text: '學生證', value: '學生證' },
                          ]}
                          name='secondary_card'
                          onChange={this.basicFormChange}
                        />
                      </Grid>
                      {basicInfoForm.secondary_card.value !== '' ? (
                        <Grid item xs={12} sm={12} md={6}>
                          <LabelInput
                            label={
                              basicInfoForm.secondary_card.value === '駕照'
                                ? '管轄編號'
                                : basicInfoForm.secondary_card.value === '健保卡'
                                ? '健保卡流水編號'
                                : basicInfoForm.secondary_card.value === '學生證'
                                ? '學號'
                                : ''
                            }
                            placeholder={
                              basicInfoForm.secondary_card.value === '駕照'
                                ? '請輸入管轄編號共12碼'
                                : basicInfoForm.secondary_card.value === '健保卡'
                                ? '請輸入健保卡流水編號共12碼'
                                : basicInfoForm.secondary_card.value === '學生證'
                                ? '請輸入學號'
                                : ''
                            }
                            disabled={this.state.isMemberData}
                            required={basicInfoForm.secondary_card.value !== ''}
                            validationErrors={{
                              isDefaultRequiredValue: `請輸入${basicInfoForm.secondary_card.value}號碼！`,
                            }}
                            name='secondary_card_number'
                            value={basicInfoForm.secondary_card_number.value}
                            onChange={this.basicFormChange}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </div>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <div
                        className={`form-group ${
                          this.state.datepickerUpdate && basicInfoForm.birthday.value === '' ? 'is-invalid' : ''
                        }`}>
                        <label>出生年月日</label>
                        <DatePicker
                          customInput={<CustomInput1 />}
                          maxDate={new Date(this.state.maxDate[0] - 20, this.state.maxDate[1], this.state.maxDate[2])}
                          yearDropdownItemNumber={50}
                          showYearDropdown={true}
                          showMonthDropdown={true}
                          dateFormat='yyyy/MM/dd'
                          locale={TW}
                          disabled={this.state.isMemberData}
                          required={basicInfoForm.birthday.required}
                          placeholder='輸入出生年月日'
                          selected={basicInfoForm.birthday.value}
                          onChange={(date) => this.basicFormChange('birthday', date)}
                          onBlur={(e) => this.setState({ datepickerUpdate: true })}
                        />
                        {this.state.datepickerUpdate && basicInfoForm.birthday.value === '' ? (
                          <span className='error-message'>請輸入出生年月日！</span>
                        ) : null}
                        <div className='is-text-darkgray50 mt-2'>※未滿20歲無法透過網路門市申請</div>
                      </div>
                    </Grid>
                  </Grid>

                  <div className='form-group mt-5'>
                    <label>戶籍地址</label>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Select
                          validationErrors={{
                            isDefaultRequiredValue: '請選擇縣/市！',
                          }}
                          disabled={this.state.isMemberData}
                          required={basicInfoForm.city.required}
                          value={basicInfoForm.city.value}
                          label={basicInfoForm.city.value === '' ? '選擇縣/市' : basicInfoForm.city.value}
                          options={[
                            { text: '台北市', value: '台北市' },
                            { text: '新北市', value: '新北市' },
                            { text: '桃園縣', value: '桃園縣' },
                          ]}
                          name='city'
                          onChange={this.basicFormChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Select
                          validationErrors={{
                            isDefaultRequiredValue: '請選擇鄉/鎮！',
                          }}
                          disabled={this.state.isMemberData}
                          required={basicInfoForm.area.required}
                          value={basicInfoForm.area.value}
                          label={basicInfoForm.area.value === '' ? '選擇鄉鎮市區' : basicInfoForm.area.value}
                          options={[
                            { text: '中山區', value: '中山區' },
                            { text: '新北市', value: '新北市' },
                            { text: '桃園縣', value: '桃園縣' },
                          ]}
                          name='area'
                          onChange={this.basicFormChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelInput
                          disabled={this.state.isMemberData}
                          name='address'
                          placeholder='請輸入完整地址'
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入有效戶籍地址！',
                          }}
                          required={basicInfoForm.address.required}
                          value={basicInfoForm.address.value}
                          onChange={this.basicFormChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className='mt-5'>
                      <Grid item xs={12} sm={12} md={6}>
                        <Autocomplete
                          id='email-autocomplete'
                          wrapperStyle={{ width: '100%', position: 'relative' }}
                          items={this.state.emailList}
                          value={basicInfoForm.email.value}
                          renderMenu={(children) => <div className='fui-dropdown fui-keyword-menu'>{children}</div>}
                          renderItem={(item, isHighlighted) => (
                            <div
                              key={`autocompelte-${item}`}
                              role='button'
                              className={`fui-item ${isHighlighted ? 'is-selected' : ''}`}>
                              <div
                                className='content'
                                dangerouslySetInnerHTML={{
                                  __html: item,
                                }}></div>
                            </div>
                          )}
                          getItemValue={(item) => item}
                          onChange={(event, value) => {
                            this.basicFormChange('email', value);
                            let val = value.indexOf('@') === -1 ? value : value.split('@')[0];
                            let suggest = [
                              `${val}@gmail.com`,
                              `${val}@yahoo.com`,
                              `${val}@yahoo.com.tw`,
                              `${val}@outlook.com`,
                            ];
                            let menu = suggest.filter((su) => su.indexOf(value) > -1);

                            this.setState({
                              emailList: menu,
                            });
                          }}
                          onSelect={(value, item) => {
                            this.basicFormChange('email', item);
                          }}
                          renderInput={(props) => {
                            // console.log(props);
                            return (
                              <div className='form-group mb-1'>
                                <label>電子信箱</label>
                                <div className='fui-input'>
                                  <input
                                    ref={this.emailTnput}
                                    {...props}
                                    placeholder={this.props.placeholder || '請輸入電子郵件'}
                                  />
                                </div>
                              </div>
                              // <div ref={params.InputProps.ref}>
                              //   <input style={{ width: 200 }} type="text" {...params.inputProps} />
                              // </div>
                            );
                          }}
                        />
                        <div className='is-text-darkgray50'>※訂單通知及未來每期電子帳單將寄送此郵件信箱</div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          name='mobile'
                          label='行動電話'
                          disabled={this.state.isMemberData}
                          validations={{
                            idNumberValid: Validation.mobileValid,
                          }}
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入行動電話！',
                            idNumberValid: '請輸入有效的行動電話！',
                          }}
                          placeholder='請輸入行動電話'
                          required={basicInfoForm.mobile.required}
                          value={basicInfoForm.mobile.value}
                          onChange={this.basicFormChange}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} className='mt-5'>
                      <Grid item xs={12} sm={12} md={12}>
                        <div className='form-group mb-0'>
                          <label>選擇推薦管道</label>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                              <Select
                                validationErrors={{
                                  isDefaultRequiredValue: '請選擇推薦管道！',
                                }}
                                required={basicInfoForm.recommand.required}
                                value={basicInfoForm.recommand.value}
                                label={basicInfoForm.recommand.value === '' ? '請選擇' : basicInfoForm.recommand.value}
                                options={[
                                  { text: '網路廣告', value: '網路廣告' },
                                  { text: '企業客戶', value: '企業客戶' },
                                  { text: '遠傳門市人員', value: '遠傳門市人員' },
                                  { text: '手機王', value: '手機王' },
                                  { text: '台灣大車隊', value: '台灣大車隊' },
                                  { text: '以上皆非', value: '以上皆非' },
                                ]}
                                name='recommand'
                                onChange={this.basicFormChange}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              {basicInfoForm.recommand.value === '網路廣告' ? (
                                <LabelInput
                                  label=''
                                  name='recommand_code'
                                  placeholder='輸入推薦序號'
                                  value={basicInfoForm.recommand_code.value}
                                  required={basicInfoForm.recommand.value === '網路廣告'}
                                  validationErrors={{
                                    isDefaultRequiredValue: '請輸入推薦序號',
                                  }}
                                  onChange={this.basicFormChange}
                                />
                              ) : null}
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
              <div className='page-action'>
                <Button btnStyle='primary' size='large' disabled={!this.state.canSubmit} link='/estore/order/payment'>
                  {pageInfo.ctaText}
                </Button>
                <Button btnStyle='secondary' size='large' onClick={(e) => this.prevStep()}>
                  返回
                </Button>
              </div>
            </Formsy>
          </div>
        </section>

        <EstoreModal
          {...this.state.currentModal}
          open={this.state.modalOpen}
          onSubmit={this.modalSubmit}
          onClose={this.modalClose}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.flowCartReducer || state.cartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFlowCartData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(IdentityFillin);
