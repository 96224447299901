import React from 'react';

import { setCartData, trackPageViewStart } from '../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import Cart from '../components/Cart';

import NavAnchor from '../components/partials/NavAnchor';
import Breadcrumb from '../components/Breadcrumb';
import EStoreBanner from '../components/partials/banner/EStoreBanner';
import EstoreModal from '../components/EstoreModal';
import CheckboxCards from '../components/form/CheckboxCards';
import SectionCollapseInfo from '../components/partials/collapse/SectionCollapseInfo';

import * as Validation from '../utils/validation';
import formatNumber from '../utils/numberFormatter';

import { setMobile } from '../components/util';

import * as Mock from '../mock/RedAndGreenB';

class RedAndGreenB extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      phoneList: [],
      cartValid: false,
      tags: ['Apple', 'Samsung', 'OPPO'],
      currentTag: '全部',
      currentModal: {
        type: 'notice',
        title: '',
        content: '',
      },
      allProducts: Mock.products,
      products: [],
      form: {
        phone: { value: '' },
      },
      cart: {
        list: {
          phone: [],
        },
      },
    };
  }

  componentDidMount() {
    this.setState({
      products: this.filteredOption(),
    });
    window.addEventListener('resize', (e) => setMobile);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', (e) => setMobile);
  };

  inputChange = (n, v, obj) => {
    let newForm = Object.assign(this.state.form);
    let list = Object.assign(this.state.cart.list);

    newForm[n].value = v;
    list[n] = obj;

    // console.log(list.phone.length, list.extra_product.length);
    // debugger;
    this.setState({
      form: newForm,
      cartUpdate: true,
      cartValid: list.phone.length > 0,
      cart: {
        list: list,
      },
    });

    this.forceUpdate();

    setTimeout(() => {
      console.log('cartUpdate', this.state.cartUpdate);
      this.props.setCartData(this.state.cart.list);
    }, 300);
  };

  valuePreview = (flow) => {
    // 根據區塊名稱顯示個別內容
    if (flow.tabs) {
      return '';
    } else if (flow.name === 'identity_validation') {
      return this.state.form.scid.value;
    } else if (flow.name === 'input_validation') {
      return this.state.form.input_validation.value;
    } else if (flow.name === 'gift') {
      return this.state.cart[flow.name].length ? '贈品 x' + this.state.cart[flow.name].length : '';
    } else {
      return this.state.form[flow.name].value;
    }
  };

  updateFormFromCart = (cart) => {
    for (var i in cart.list) {
      let item = cart.list[i];
      let value = this.state.form[i].value.split(/,/g);
      if (item.length) {
        let newVal = [];
        item.forEach((prod, j) => {
          if (value.indexOf(prod.value) > -1) {
            newVal.push(prod.value);
          }
        });
        this.setState({
          form: {
            ...this.state.form,
            [i]: { value: newVal.toString() },
          },
        });
      } else {
        this.setState({
          form: {
            ...this.state.form,
            [i]: { value: '' },
          },
        });
      }
    }
    this.setState({ cart: cart });
  };

  updateTag = (tag) => {
    this.setState({
      currentTag: tag,
    });
    this.forceUpdate();

    setTimeout(() => {
      this.setState({
        products: this.filteredOption(),
      });
    }, 300);
  };

  filteredOption = () => {
    let tag = this.state.currentTag.toLowerCase();

    if (tag === '全部') {
      return this.state.allProducts;
    } else {
      return this.state.allProducts.filter(
        (prod) =>
          (prod.name && prod.name.toLowerCase().indexOf(tag) > -1) ||
          (prod.title && prod.title.toLowerCase().indexOf(tag) > -1) ||
          (prod.meta && prod.meta.indexOf(tag) > -1)
      );
    }
  };

  openModal = (modal) => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'notice',
        ...modal,
      },
    });
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);

    this.setState({
      modalOpen: false,
    });

    if (type === 'otpForm') {
      this.identityCheck();
    }
    // 貨到通知送出
  };

  modalConfirm = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    if (type === 'change-alert') {
      this.resetFormset();
    }
    if (type === 'phoneCheck-alert') {
      // this.phoneCheck();
    }
    if (type === 'identityCheck-alert') {
      // this.identityCheck();
    }
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalCancel = (type) => {
    // 預約取貨送出
    console.info('MODAL CANCEL', type);
    if (type === 'change-alert') {
      this.canscelSelect();
    }
    this.setState({
      modalOpen: false,
    });
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { form } = this.state;
    return (
      <main>
        <NavAnchor pageTitle='網路門市' />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '網路門市', link: '/estore' },
            { text: '促銷活動商品列表', link: '/' },
          ]}
        />

        <EStoreBanner
          slides={[
            {
              image: {
                md: '/resources/cbu/estore-event-product/cbu-ebustore-1920x500.jpg',
                sm: '/resources/cbu/estore-event-product/cbu-ebustore-banner-750x900.jpg',
              },
              title: '單辦門號大標題文字最多十六個字元',
              description: [
                '一二三四五六七八九十一二三四五六',
                '一二三四五六七八九十一二三四五六',
                '一二三四五六七八九十一二三四五六',
              ],
              actions: [
                {
                  text: 'Primary',
                  btnStyle: 'primary',
                  link: '#',
                  target: '_self',
                },
                {
                  text: 'Secondary',
                  btnStyle: 'secondary',
                  link: '#',
                  target: '_blank',
                },
              ],
            },
          ]}
        />

        <section className='ribbon-bg pt-3 pt-md-0'>
          <div className='fui-container'>
            <Formsy>
              <div className='fui-plan-flow-container' ref={this.flowRef}>
                <section id='red-green' className={`fui-plan-flow is-active`}>
                  <div className='fui-flow-title'>
                    <h2>寵i自己 iPhone SE 熱銷中</h2>
                  </div>
                  <div className='fui-flow-body'>
                    <h6 className='fui-flow-description'>超值優惠商品任你選</h6>
                    <div className='tag-filter mb-3'>
                      <div className='content mr-0'>
                        <div className='tag-list'>
                          <label
                            className={`fui-button is-label ${this.state.currentTag === '全部' ? 'is-active' : ''}`}
                            role='button'
                            onClick={(e) => this.updateTag('全部')}>
                            <span className='text'>全部({this.state.allProducts.length})</span>
                          </label>
                          {this.state.tags.map((tag, i) => (
                            <label
                              className={`fui-button is-label ${this.state.currentTag === tag ? 'is-active' : ''}`}
                              role='button'
                              key={`menu-tag-${i}`}
                              onClick={(e) => this.updateTag(tag)}>
                              <span className='text'>{tag}</span>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                    <CheckboxCards
                      name='phone'
                      perPage={12}
                      options={this.state.products}
                      value={this.state.form.phone.value}
                      withNumberController={true}
                      onChange={(n, v, obj) => this.inputChange(n, v, obj)}
                    />
                  </div>
                </section>
              </div>
            </Formsy>
          </div>
        </section>

        <SectionCollapseInfo title='貼心小叮嚀' content='貼心小叮嚀' />

        <Cart
          onUpdate={this.updateFormFromCart}
          afterUpdate={(e) => this.setState({ cartUpdate: false })}
          update={this.state.cartUpdate}
          cart={this.state.cart}
          isValid={this.state.cartValid}
          stepChange={(e) => this.stepChange(e)}
          editStep={(e) => this.changeFormSection(e)}
        />

        <EstoreModal
          {...this.state.currentModal}
          open={this.state.modalOpen}
          onSubmit={this.modalSubmit}
          onConfirm={this.modalConfirm}
          onCancel={this.modalCancel}
          onClose={this.modalClose}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    analyticsInitialState: state.analyticsInitialState, // 可加可不加
    cart: state.cartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      trackPageViewStart,
      setCartData,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RedAndGreenB));
