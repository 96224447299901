import React from 'react';
import PropTypes from 'prop-types';

class PanelFlowContainer extends React.Component {
  render() {
    return <div className='fui-flow-container'>{this.props.children}</div>;
  }
}

PanelFlowContainer.propTypes = {
  children: PropTypes.any,
};

export default PanelFlowContainer;
