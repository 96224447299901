export const breadcrumb = {
  breadcrumb: [
    { text: '首頁', link: '/' },
    { text: '網路門市', link: '/' },
  ],
};

export const navTitle = {
  pageTitle: '網路門市',
  button: [
    {
      text: '新申辦',
      link: '/',
    },
    {
      text: '推薦續約',
      link: '/',
    },
  ],
};

export const banner = {
  slides: [
    {
      image: {
        md: '/resources/cbu/estore/estore-banner-1440-x-460@2x.jpg',
        sm: '/resources/cbu/estore/estore-banner-m@2x.jpg',
      },
      tag: '網路限定',
      title: '499吃到飽不限速',
      description: `<h5>iPhone11系列送遠傳friDay90天，再送手機保險3個月</h5>`,
      actions: [
        { text: '線上申辦', link: '/estore/renewal/rateplan', btnStyle: 'primary' },
        { text: '線上申辦', link: '/estore/renewal/rateplan', btnStyle: 'secondary' },
      ],
    },
    {
      image: {
        md: '/resources/cbu/estore/estore-banner-1440-x-460@2x.jpg',
        sm: '/resources/cbu/estore/estore-banner-m@2x.jpg',
      },
      tag: '網路限定',
      title: '499吃到飽不限速',
      description: 'iPhone11系列送遠傳friDay90天，再送手機保險3個月',
      actions: [{ text: '線上申辦', link: '/estore/product', btnStyle: 'primary' }],
    },
    {
      image: {
        md: '/resources/cbu/estore/estore-banner-1440-x-460@2x.jpg',
        sm: '/resources/cbu/estore/estore-banner-m@2x.jpg',
      },
      tag: '網路限定',
      title: '499吃到飽不限速',
      description: 'iPhone11系列送遠傳friDay90天，再送手機保險3個月',
      actions: [{ text: '線上申辦', link: '/estore/product', btnStyle: 'primary' }],
    },
    {
      image: {
        md: '/resources/cbu/estore/estore-banner-1440-x-460@2x.jpg',
        sm: '/resources/cbu/estore/estore-banner-m@2x.jpg',
      },
      tag: '網路限定',
      title: '499吃到飽不限速',
      description: 'iPhone11系列送遠傳friDay90天，再送手機保險3個月',
      actions: [{ text: '線上申辦', link: '/estore/product', btnStyle: 'primary' }],
    },
    {
      image: {
        md: '/resources/cbu/estore/estore-banner-1440-x-460@2x.jpg',
        sm: '/resources/cbu/estore/estore-banner-m@2x.jpg',
      },
      tag: '網路限定',
      title: '499吃到飽不限速',
      description: 'iPhone11系列送遠傳friDay90天，再送手機保險3個月',
      actions: [{ text: '線上申辦', link: '/estore/product', btnStyle: 'primary' }],
    },
    {
      image: {
        md: '/resources/cbu/estore/estore-banner-1440-x-460@2x.jpg',
        sm: '/resources/cbu/estore/estore-banner-m@2x.jpg',
      },
      tag: '網路限定',
      title: '499吃到飽不限速',
      description: 'iPhone11系列送遠傳friDay90天，再送手機保險3個月',
      actions: [{ text: '線上申辦', link: '/estore/product', btnStyle: 'primary' }],
    },
  ],
};

export const greeting = {
  greetingMsg: '雅婷，需要什麼服務？',
  bubbles: [
    {
      type: 'icons',
      icons: [
        {
          icon: {
            default: '/resources/cbu/estore/e-store-exclusive.png',
            retina: '/resources/cbu/estore/e-store-exclusive@2x.png',
          },
          text: '網路限定優惠',
          link: '/estore/product',
          target: '_self',
        },
        {
          icon: {
            default: '/resources/cbu/estore/report.png',
            retina: '/resources/cbu/estore/report@2x.png',
          },
          text: '推薦續約',
          link: '/estore/renewal/rateplan',
          target: '_self',
        },
        {
          icon: {
            default: '/resources/cbu/estore/find-phone.png',
            retina: '/resources/cbu/estore/find-phone@2x.png',
          },
          text: '找手機',
          link: '/estore/product',
          target: '_self',
        },
        {
          icon: {
            default: '/resources/cbu/estore/sim.png',
            retina: '/resources/cbu/estore/sim@2x.png',
          },
          text: '單辦門號',
          link: '',
          target: '_self',
        },
        {
          icon: {
            default: '/resources/cbu/estore/order-history.png',
            retina: '/resources/cbu/estore/order-history@2x.png',
          },
          text: '查訂單',
          link: '/',
          target: '_self',
        },
      ],
    },
    {
      type: 'text',
      text: '您的約期即將於2020年3月到期，遠傳為你準備 <a href="/">續約好禮</a> ❤️',
    },
    {
      type: 'cards',
      cards: [
        {
          image: {
            default: '/resources/cbu/estore/personalize-promo-01.jpg',
            retina: '/resources/cbu/estore/personalize-promo-01@2x.jpg',
          },
          title: '新春絕配',
          description: '續約老客戶有福啦！本月三好康旺旺來，續約3C加購配件1元起。',
          actions: [{ text: '心動續約', link: '/', target: '_self' }],
        },
        {
          image: {
            default: '/resources/cbu/estore/personalize-promo-02.jpg',
            retina: '/resources/cbu/estore/personalize-promo-02@2x.jpg',
          },
          title: '續約好康 1+1',
          description: '美拍手機+智慧生活商品$0元起，網路門市加碼送配件購物金。',
          actions: [{ text: '心動續約', link: '/', target: '_self' }],
        },
        {
          image: {
            default: '/resources/cbu/estore/personalize-promo-03.jpg',
            retina: '/resources/cbu/estore/personalize-promo-03@2x.jpg',
          },
          title: '續約不用搭手機',
          description: '給您一個續約的新選擇，不用打手機，通話費再加碼送給您。',
          actions: [{ text: '心動續約', link: '/', target: '_self' }],
        },
      ],
    },
  ],
};

export const sectionFeature = {
  title: '為什麽選擇網路門市？',
  color: 'accent',
  column: 3,
  list: [
    {
      title: '挑戰通路超低價',
      image: '/resources/cbu/estore/e-store-feature-1@2x.png',
      list: [
        { text: '免比價省時省荷包' },
        { text: '全館免運費' },
        { text: '分期0利率沒負擔' },
        { text: '多樣商品真實惠' },
      ],
    },
    {
      title: '輕鬆買安心退',
      image: '/resources/cbu/estore/e-store-feature-2@2x.png',
      list: [{ text: '流程安全最放心' }, { text: '專程解說超安心' }, { text: '超商/門市配送好貼心' }],
    },
    {
      title: '售後服務最到位',
      image: '/resources/cbu/estore/e-store-feature-3@2x.png',
      list: [{ text: '訂單成立最快2天到貨' }, { text: '門號可攜斷訊1天賠償百元' }, { text: '鑑賞期10天保證輕鬆買' }],
    },
  ],
};

export const eStorePromotion = {
  image: {
    md: '/resources/cbu/estore/202101041019499.jpg',
    sm: '/resources/cbu/estore/estore-promotino-sm.jpg',
  },
  subtitle: '全新上線 遠傳資費得來速',
  title: '30秒找出您的方案 加碼送購物金',
  action: {
    text: '立即體驗',
    link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
    target: '_self',
  },
};

export const promoProduct = {
  title: '本週最熱門',
  more: {
    text: '看更多',
    link: '/estore/product',
  },
  tabs: {
    name: 'promo-product-tab',
    default: 0,
    list: [
      { label: 'iPhone手機' },
      { label: 'Android手機' },
      { label: '穿戴裝置' },
      { label: '拆封機' },
      { label: '配件' },
    ],
  },
  tabContent: [
    [
      {
        image: '/resources/cbu/estore/640x480.jpg',
        ribbon: '網路限定優惠',
        meta: 'Apple',
        title: 'iPhone 11 Pro Max 256GB',
        link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
        target: '_self',
        tag: '5G手機',
        salePrice: '$41,400',
        projectPrice: '$10,500',
        originPrice: '$45,400',
      },
      {
        image: '/resources/cbu/estore/640x480.jpg',
        meta: 'Apple',
        title: 'iPhone 11 Pro Max 128GB',
        link: '/estore/product/phone/detail/iphone-11-pro-max-128gb',
        target: '_self',
        tag: '福利A品',
        salePrice: '$1,399',
        projectPrice: '$10,500',
      },
      {
        image: '/resources/cbu/estore/old.jpg',
        meta: 'Apple',
        title: 'iPhone 11 Pro Max 64GB',
        link: '/estore/product/phone/detail/iphone-11-pro-max-64gb',
        target: '_self',
        tag: '5G手機',
        salePrice: '$41,400',
        projectPrice: '$10,500',
      },
      {
        image: '/resources/cbu/estore/640x480.jpg',
        meta: 'Apple',
        title: 'iPhone 11 Pro Max 32GB',
        link: '/estore/product/phone/detail/iphone-11-pro-max-32gb',
        target: '_self',
        tag: '5G手機',
        salePrice: '$41,400',
        projectPrice: '$10,500',
      },
    ],
  ],
};

export const serviceTags = {
  title: '看看大家都搜什麽',
  tags: [
    { name: 'iPhone 11', link: '#', target: '_self' },
    { name: '拍照手機', link: '#', target: '_self' },
    { name: '福利品', link: '#', target: '_self' },
    { name: '0元手機', link: '#', target: '_blank' },
    { name: 'iPhone', link: '#', target: '_blank' },
    { name: 'Android', link: '#', target: '_blank' },
    { name: '追劇', link: '#', target: '_blank' },
  ],
};

export const serviceCard = {
  title: '線上申辦',
  cards: [
    {
      title: '企業行動優惠',
      link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
    },
    {
      title: '手機保險',
      link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
    },
    {
      title: '大寬頻企業光纖',
      link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
    },
    {
      title: 'Wifi 上網',
      link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
    },
  ],
};

export const footerPromo = {
  image: {
    md: '/resources/cbu/estore/cbu-promotion-banner-05-1920x156.jpg',
    sm: '/resources/cbu/estore/cbu-promotion-banner-05-750x540.jpg',
  },
  title: '網路門市限定 週三驚爆閃購日，AirPods、藍牙耳機週週1折起',
  action: {
    text: '立即領折扣序號',
    link: '/',
  },
};
export const productMap = {
  title: '我想尋找...',
  data: [
    {
      title: '找產品',
      list: [
        { text: '主打新機', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '智慧家電', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '出國遠遊卡', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '福利品', link: '#', target: '_self', icon: 'chevron-right' },
      ],
    },
    {
      title: '找方案',
      list: [
        { text: '網路獨享資費', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '自選門號', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '本月續約方案', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '家用寬頻', link: '#', target: '_self', icon: 'chevron-right' },
      ],
    },
    {
      title: '找網購服務',
      list: [
        { text: '訂單查詢', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '訂單相關問題', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '配送說明', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '超商取貨說明', link: '#', target: '_self', icon: 'chevron-right' },
      ],
    },
    {
      title: '找幫助',
      list: [
        { text: '我要發問', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '常見網購問題', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '預約客服回撥', link: '#', target: '_self', icon: 'chevron-right' },
        { text: '手機平板操作手冊', link: '#', target: '_self', icon: 'chevron-right' },
      ],
    },
  ],
};
