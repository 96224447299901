export const PlanContent = {
  title: '方案內容',
  content: [
    {
      商品專案價: '$10,000',
      預繳金: '$1,000',
      上網傳輸量: '合約內不限速吃到飽',
      網內通話: '每通前5分鐘免費',
      網外通話: '60分鐘免費',
      語音優惠: '市話 + 網外送120分鐘',
      活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
    },
  ],
};

export const mobilePolicy = {
  title: '行動電話號碼可攜式服務條款',
  content: [
    '遠傳電信股份有限公司行動寬頻業務/第三代行動通信服務契約',
    '立契約書人：遠傳電信股份有限公司（以下簡稱甲方）',
    '申請用戶本人        （以下簡稱乙方）',
    '茲因電信服務事宜，雙方同意訂立本契約書，並經雙方合意訂定條款如下，以資共同遵守：',
    '第一章 服務範圍',
    '第一條 甲方營業種類係提供行動寬頻業務（以下簡稱本業務）。',
    '第二條 本業務專供乙方作正常合法通信之用。',
    '第三條 本業務之營業區域由主管機關核定為：全區：包括臺灣全島（含澎湖縣）、金門縣、連江縣。',
    '第四條 甲方對乙方提供通信服務之項目如下：',
    '一、基本項目：提供乙方接取網際網路(簡稱行動上網)、雙向撥叫市內電話、國內長途電話、國際長途電話、行動電話。',
    '二、加值項目：甲方得依系統供裝情形及乙方終端設',
  ],
};

export const internetPolicy = {
  title: '遠傳行動寬頻業務服務契約',
  content: [
    '立契約書人：',
    '遠傳電信股份有限公司（以下簡稱甲方）',
    '申請用戶本人（以下簡稱乙方）',
    '茲因電信服務事宜，雙方同意訂立本契約書，並經雙方合意訂定條款如下，以資共同遵守：',
    '第一章 服務範圍',
    '第一條 甲方營業種類係提供行動寬頻業務（以下簡稱本業務）。',
    '第二條 ',
    '第三條 本業務之營業區域由主管機關核定為：全區：包括臺灣全島（含澎湖縣）、金門縣、連江縣。 ',
    '第四條 甲方對乙方提供通信服務之項目如下： ',
    '一、基本項目：提供乙方接取網際網路(簡稱行動上網)、雙向撥叫市內電話、國內長途電話、國際長途電話、行動電話。 ',
    '二、加值項目：甲方得依系統供裝情形及乙方終端設備，提供',
  ],
};

export const buyingPolicy = {
  title: '網路申購合約條款',
  content: [
    '遠傳電信股份有限公司(以下稱本公司)將依照以下條款之約定(以下稱本條款)，提供網路購物服務。為保障您的權益，請於註冊前詳細閱讀之。當您從網際網路的視窗按下「同意」，或實際開始使用本公司之服務時，均視為您已詳細閱讀並同意遵守本條款。且本公司得於任何時候修改本條款之內容，並透過網際網路在本公司網站上公告，由於用戶人數眾多，本公司並不會再另行通知，故用戶在此認知並表示同意將會隨時注意並自行上網查詢條款內容是否有所更動及其更動之內容；用戶參與或使用本公司相關活動或服務，與本公司間所生之權利義務關係，均應以當時最後修訂之條款內容為依據。您若於條款修改公告後仍繼續使用本公司之網路購物服務，亦表示您同意修改後之條款。如果您經過考慮後不同意本條款的內容，請您不要進行註冊程序，或立即停止使用本公司之網路購物服務。',
    '若您為未滿十八歲，除應符合上述規定外，並請由您的家長（或監護人）閱讀、瞭解並同意本條款之所有內容及其後修改變更後，方得使用或繼續使用網路購物服務。當您使用或繼續使用網路購物服務時，即表示您的家長（或監護人）已閱讀、',
  ],
};

export const lifePolicy = {
  title: '「遠傳生活+」會員服務條款',
  content: [
    '當您申請加入遠傳電信網路會員的同時，為了提供您使用遠傳電信及網站上各項基本、加值服務，及接受本公司提供給您各項優惠訊息，您已知悉並同意本公司將對您在會員資料中所填寫的個人資料（包括姓名、地址、聯絡電話、電子信箱、生日及其他您同意填寫的資料），進行蒐集、利用與處理。您有權隨時向本公司申請補充、更正、查閱或提供您個人資料之複本，您亦有權要求本公司停止蒐集、處理、利用或刪除您的個人資料（提醒您注意，於此同時，本公司將無法繼續提供您所申請之服務）。您欲行使前述各項權利時，本公司的客戶服務連繫窗口與各直營門市皆能受理您的請求。您可以在本公司官方網站(www.fetnet.net)找到本公司之客服電話、電子郵件信箱及各直營門市地址。本公司基於前述原因而需蒐集、處理或利用您的個人資料時，您可以自由選擇是否提供您的個人資料，若您選擇不提供或提供不完全時，基於健全電信業務與加值網路業務之執行，本公司將可能無法提供您服務。',
  ],
};

export const Cart = {
  apply_method: '申辦新門號',
  plan: {
    ribbon: '網路限定方案再送禮券',
    value: '月租$1,399x12個月',
    sticker: '/resources/cbu/estore/5-g.png',
    unit: '月租',
    price: 1399,
    prepay: 0,
    month: '12',
    note: '學生教職限定',
    list: [
      { title: '商品專案價', originPrice: 12000, projectPrice: 10000 },
      { title: '上網傳輸量', text: '合約內不限速吃到飽' },
      { title: '語音優惠', text: '市話 + 網外送120分鐘' },
      { title: '活動優惠', text: '$500遠百禮券 + $500購物金' },
    ],
    tag: ['5G方案', '吃到飽', '月付$1,000以上', '月付$1,399'],
    modal: {
      title: '$1,399/12個月 方案詳情',
      content: [
        {
          商品專案價: '$10,000',
          預繳金: '$1,000',
          上網傳輸量: '合約內不限速吃到飽',
          網內通話: '每通前5分鐘免費',
          網外通話: '60分鐘免費',
          語音優惠: '市話 + 網外送120分鐘',
          活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
        },
      ],
    },
  },
  product: {
    name: 'Apple iPhone11 Pro Max 256GB',
    originPrice: 39900,
    salePrice: 38900,
    projectPrice: 25600,
    color: '夜幕綠',
  },
  identity_validation: null,
  input_validation: null,
  phone_number: {
    label: '0903-063-482',
    value: '0903-063-482',
    description: '選號費將於第一期帳單進行扣抵',
    price: 1000,
    tag: ['黃金門號'],
  },
  discount: {
    name: '全館滿$3,000折$200',
    amount: 200,
  },
  gift: [
    {
      type: 'product',
      image: '/resources/cbu/estore-product/estore-product-thumb-12@2x.jpg',
      value: 'VANTEC Qi 紅色無線快速充電盤',
      meta: 'VANTEC Qi',
      name: 'VANTEC Qi 紅色無線快速充電盤',
      originPrice: 2400,
      productPrice: 0,
      color: '紅色',
      modal: {
        title: 'VANTEC Qi 紅色無線快速充電盤',
        content: [
          '商品規格',
          [
            '3.5mm 超薄無線充電盤',
            '支持Qi 無線充電IOS & Android 手機',
            'FOD自動智慧識別金屬異物',
            '3.5mm 超薄無線充電盤',
            '輸出 (Output)：5W/ 7.5W / 9W',
          ],
        ],
      },
    },
    {
      type: 'product',
      image: '/resources/cbu/estore-product/estore-product-thumb-14@2x.jpg',
      value: 'MYCELL AirPods Pro 粉沙收納盒',
      meta: 'MYCELL',
      name: 'MYCELL AirPods Pro 粉沙收納盒',
      originPrice: 2400,
      productPrice: 0,
      modal: {
        title: 'VANTEC Qi 紅色無線快速充電盤',
        content: [
          '商品規格',
          [
            '3.5mm 超薄無線充電盤',
            '支持Qi 無線充電IOS & Android 手機',
            'FOD自動智慧識別金屬異物',
            '3.5mm 超薄無線充電盤',
            '輸出 (Output)：5W/ 7.5W / 9W',
          ],
        ],
      },
    },
  ],
  extra_product: [
    {
      type: 'product',
      image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
      value: 'PHILIPS 舒眠抗敏空氣清淨機',
      meta: 'PHILIPS',
      name: 'PHILIPS 舒眠抗敏空氣清淨機',
      productPrice: 5100,
      modal: {
        title: 'PHILIPS 舒眠抗敏空氣清淨機',
        content: [
          '商品規格',
          [
            '3.5mm 超薄無線充電盤',
            '支持Qi 無線充電IOS & Android 手機',
            'FOD自動智慧識別金屬異物',
            '3.5mm 超薄無線充電盤',
            '輸出 (Output)：5W/ 7.5W / 9W',
          ],
        ],
      },
      number: 1,
    },
    {
      type: 'product',
      image: '/resources/cbu/estore-product/estore-product-thumb-11@2x.jpg',
      value: 'Panasonic 15公升蒸氣烘烤爐',
      meta: 'PANASONIC',
      name: 'Panasonic 15公升蒸氣烘烤爐',
      productPrice: 15000,
      modal: {
        title: 'Panasonic 15公升蒸氣烘烤爐',
        content: [
          '商品規格',
          [
            '3.5mm 超薄無線充電盤',
            '支持Qi 無線充電IOS & Android 手機',
            'FOD自動智慧識別金屬異物',
            '3.5mm 超薄無線充電盤',
            '輸出 (Output)：5W/ 7.5W / 9W',
          ],
        ],
      },
      number: 1,
    },
  ],
  extra_plan: [
    {
      type: 'friday',
      value: 'friDay影音免費爽看30天',
      meta: '第一個月免費',
      name: 'friDay影音免費爽看30天',
      productPrice: 199,
      day: 30,
      unit: '天',
    },
  ],
};
