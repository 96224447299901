import React from 'react';
import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import Formsy from 'formsy-react';
import { setFlowCartData } from '../../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import FormBanner from '../../components/partials/banner/FormBanner';
import Button from '../../components/Button';
import Link from '../../components/Link';
import Item from '../../components/item/Item';
import ImageUpload from '../../components/form/ImageUpload';
import Checkbox from '../../components/form/Checkbox';
import RadioButtons from '../../components/form/RadioButtons';
import LabelInput from '../../components/form/LabelInput';
import Select from '../../components/form/Select';
import EstoreModal from '../../components/EstoreModal';
import DatePicker from 'react-datepicker';
import TW from 'date-fns/locale/zh-TW';

import formatNumber from '../../utils/numberFormatter';
import * as Validation from '../../utils/validation';
import * as Mock from '../../mock/Identity';
import TextInput from '../../components/form/TextInput';

class Identity extends React.Component {
  constructor(props) {
    super(props);

    let dt = new Date();
    // this.body = React.createRef();
    this.state = {
      cart: Mock.Cart,
      modalOpen: false,
      minDate: [dt.getFullYear() - 20, dt.getMonth(), dt.getDate()],
      currentModal: {
        type: 'notice',
        title: '',
        content: '',
      },
      pageInfo: {
        pageTitle: '確認身份及基本資料',
        pageSubTitle: `上傳證件可以自動為您帶入資料免填寫，加速結帳資訊的填寫速度。填寫前請查看 <a href='https://www.fetnet.net/content/cbu/tw/information/privacy.html' target='_blank' class='outer-link'>個資保護法內容 <img src='${
          process.env.PUBLIC_URL + '/resources/common/images/external.svg'
        }' alt='link' /></a>`,
        ctaText: '下一步',
      },
      isMemberData: false,
      collapseOpen: false,
      contentHeight: 0,
      staff: [''],
      canSubmit: false,
      step: 0,
      datepickerUpdate: false,
      modalForm: {
        scid: { value: '', required: true },
        id_number: { value: '', required: true },
      },
      companyIdentityList: [
        { text: '員工父母', value: '員工父母' },
        { text: '員工小孩', value: '員工小孩' },
      ],
      cardForm: {
        identity_image_1: { value: null, required: true },
        identity_image_2: { value: null, required: true },
        secondary_card_1: { value: null, required: true },
        secondary_card_2: { value: null, required: true },
        staff_type: { value: '1', required: true },
        staff_card_1: { value: null, required: true },
        staff_card_2: { value: null, required: true },
        staff_card_3: { value: null, required: true },
        staff_card_4: { value: null, required: true },
        staff_card_5: { value: null, required: true },
        staff_card_6: { value: null, required: true },
        upload_later: { value: false, required: true },
      },
      basicInfoForm: {
        combine_bill: { value: false },
        name: { value: '', required: true },
        id_number: { value: '', required: true },
        secondary_card: { value: '', required: true },
        secondary_card_number: { value: '', required: true },
        birthday: { value: '', required: true },
        city: { value: '', required: true },
        area: { value: '', required: true },
        address: { value: '', required: true },
        email: { value: '', required: true },
        mobile: { value: '', required: true },
        company_identity: { value: '員眷', required: true },
        company_identity_type: { value: '員工父母', required: true },
        company_number: { value: '', required: true },
        company_name: { value: '', required: true },
        referrer_name: { value: '', required: true },
        referrer_number: { value: '', required: true },
        recommand: { value: '', required: true },
        recommand_code: { value: '', required: true },
      },
      nextPageInfo: null,
    };
  }

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  componentDidMount = () => {
    // 如果有 co_type 根據 co_type 寫入對應文字
    if (this.props.location.state && this.props.location.state.co_type) {
      this.setCoTypeWording();
      this.setState({
        nextPageInfo: {
          co_type: this.props.location.state.co_type,
        },
      });
    }

    if (!this.props.cart) {
      this.props.history.go(-1);
    }
  };

  setCoTypeWording = () => {
    let pageTitle = '確認身份及基本資料';
    let pageSubtitle = `上傳證件可以自動為您帶入資料免填寫，加速結帳資訊的填寫速度。填寫前請查看 <a href='https://www.fetnet.net/content/cbu/tw/information/privacy.html' target='_blank' class='outer-link'>查看個資保護法內容 <img src='${
      process.env.PUBLIC_URL + '/resources/common/images/external.svg'
    }' alt='link' /></a>`;
    let ctaText = '下一步';

    /**
      **All case**
      NC_identity
      NC
      NH_identity
      NH
      LC
      LC_identity
      LH
      LH_identity
      DA
    */
    switch (this.props.location.state.co_type) {
      case 'LC_identity':
        ctaText = '下一步';
        pageSubtitle = `太好了！你已完成身份驗證。請上傳證件可以自動為您帶入資料免填寫，加速結帳資訊的填寫速度。填寫前請 <a href='https://www.fetnet.net/content/cbu/tw/information/privacy.html' target='_blank' class='outer-link'>查看個資保護法內容 <img src='${
          process.env.PUBLIC_URL + '/resources/common/images/external.svg'
        }' alt='link' /></a>`;
        break;

      case 'LH_identity':
        ctaText = '下一步';
        pageSubtitle = `太好了！你已完成身份驗證。請上傳證件可以自動為您帶入資料免填寫，加速結帳資訊的填寫速度。填寫前請 <a href='https://www.fetnet.net/content/cbu/tw/information/privacy.html' target='_blank' class='outer-link'>查看個資保護法內容 <img src='${
          process.env.PUBLIC_URL + '/resources/common/images/external.svg'
        }' alt='link' /></a>`;
        break;
    }

    this.setState({
      pageInfo: {
        pageTitle: pageTitle,
        pageSubTitle: pageSubtitle,
        ctaText: ctaText,
      },
    });
  };

  modalFormChange = (name, value) => {
    let modalForm = Object.assign(this.state.modalForm);
    modalForm[name].value = value;
    this.setState({
      modalForm,
    });
  };

  inputChange = (name, value) => {
    let cardForm = Object.assign(this.state.cardForm);
    cardForm[name].value = value;
    this.setState({
      cardForm,
    });
  };

  basicFormChange = (name, value) => {
    let basicInfoForm = Object.assign(this.state.basicInfoForm);
    basicInfoForm[name].value = value;

    // console.log(name, value);
    if (name === 'combine_bill') {
      if (value === true)
        this.setState({
          currentModal: {
            type: 'combinebillForm',
            title: '合併帳單',
          },
          modalOpen: true,
        });
      else
        this.setState({
          isMemberData: false,
        });
    }
    this.setState({
      basicInfoForm,
      datepickerUpdate: name === 'birthday',
    });

    this.forceUpdate();
  };

  cartUpdate = (name, index, value) => {
    let cart = Object.assign(this.state.cart);
    cart[name][index].number = value;
    this.setState({
      cart: cart,
    });

    this.props.setFlowCartData(cart);
    this.forceUpdate();
  };

  setContentHeight = () => {
    this.setState({
      contentHeight: this.body.current.clientHeight,
    });
  };

  openModal = (e, type, name) => {
    e.preventDefault();

    this.setState({
      currentModal: {
        type: type,
        ...Mock[name],
      },
      modalOpen: true,
    });
  };

  addStaffCard = () => {
    let staff = Object.assign(this.state.staff);
    staff.push('');
    this.setState({
      staff,
    });
  };

  addFile = (name, file) => {
    let cardForm = Object.assign(this.state.cardForm);
    cardForm[name].value = Object.assign(file[0], {
      preview: URL.createObjectURL(file[0]),
    });

    this.setState({
      cardForm,
    });
  };

  nextStep = () => {
    this.props.history.push('/estore/order/fillin');
  };

  prevStep = () => {
    this.props.history.go(-1);
  };

  openCollapse = (event) => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });

    this.forceUpdate();
    this.setContentHeight();

    // [AA Tracking]
    if (window && typeof window.ctrf === 'function')
      window.ctrf(event || {}, this.state.open ? `Tab_${this.props.title}_收合` : `Tab_${this.props.title}_展開`);
  };

  isImageEmpty = () => {
    let result = false;
    ['identity_image_1', 'identity_image_2', 'secondary_card_1', 'secondary_card_2', 'staff_card_1'].forEach((text) => {
      if (this.state.cardForm[text].value === null) result = true;
    });
    return result;
  };

  nextPage = () => {
    this.props.history.push('/estore/order/payment', this.state.nextPageInfo);
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);

    if (type === 'combinebillForm') {
      this.setState({
        basicInfoForm: {
          combine_bill: { value: true },
          name: { value: '吳明士', required: true },
          id_number: { value: 'A123456789', required: true },
          secondary_card: { value: '健保卡', required: true },
          secondary_card_number: { value: 'G123456789' },
          birthday: { value: new Date('1990/06/30'), required: true },
          city: { value: '台北市', required: true },
          area: { value: '中山區', required: true },
          address: { value: '樂群二路187號6樓之2', required: true },
          email: { value: 'service@fetnet.com.tw', required: true },
          mobile: { value: '0923456789', required: true },
          company_identity: { value: '員眷', required: true },
          company_identity_type: { value: '員工父母', required: true },
          company_number: { value: '', required: true },
          company_name: { value: '', required: true },
          referrer_name: { value: '', required: true },
          referrer_number: { value: '', required: true },
          recommand: { value: '', required: true },
          recommand_code: { value: '', required: true },
        },
        isMemberData: true,
        modalOpen: false,
      });
    } else {
      this.setState({
        modalOpen: false,
      });
    }
    this.forceUpdate();
  };

  modalCancel = (type) => {
    console.info('MODAL CANCEL', type);

    this.setState({
      modalOpen: false,
    });

    this.forceUpdate();
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);

    if (type === 'combinebillForm') {
      this.setState({
        basicInfoForm: {
          ...this.state.basicInfoForm,
          combine_bill: { value: false },
        },
        modalOpen: false,
      });
    } else {
      this.setState({
        modalOpen: false,
      });
    }

    this.forceUpdate();
  };

  render() {
    const { step, cardForm, basicInfoForm, modalForm, pageInfo } = this.state;

    return (
      <main>
        <FormBanner
          title={pageInfo.pageTitle}
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className='fui-order-container section-upload'>
          <div className='fui-container'>
            <h4
              className='is-text-darkgray50'
              dangerouslySetInnerHTML={{
                __html: pageInfo.pageSubTitle,
              }}></h4>

            <Formsy>
              <div className={`paper is-paper-collapse`} id='fillin'>
                <div className='collapse-header'>
                  <h4 className='m-0'>
                    上傳證件資料
                    <span className='check'>
                      <i className='icon-check'></i>
                    </span>
                  </h4>
                  <div className='action'>
                    <Button btnStyle='secondary' onClick={this.prevStep}>
                      修改
                    </Button>
                  </div>
                </div>
                <div className={`collapse-body`}>
                  <Item prefix='bulleted'>
                    請拍攝完整彩色清晰影像，聚焦證件文字且避免反光；上傳檔案格式必須為 jpg 或 jpeg。
                  </Item>
                  <h4 className='subtitle mt-4'>1. 身分證</h4>
                  <Grid container spacing={2} className='fui-upload-container'>
                    <Grid item xs={12} sm={12} md={6}>
                      <ImageUpload
                        placeholder='可拖曳證件正面至此區塊'
                        name='identity_image_1'
                        addFile={(files) => this.addFile('identity_image_1', files)}
                        files={[cardForm.identity_image_1.value]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <ImageUpload
                        placeholder='可拖曳證件反面至此區塊'
                        name='identity_image_2'
                        addFile={(files) => this.addFile('identity_image_2', files)}
                        files={[cardForm.identity_image_2.value]}
                      />
                    </Grid>
                  </Grid>
                  <h4 className='subtitle'>
                    2. 第二證件 (健保卡、駕照、學生證皆可)
                    <div
                      className='is-underline-link'
                      onClick={(e) => this.openModal(e, 'secondCardModal', 'secondCardModal')}>
                      什麼是第二證件？
                    </div>
                  </h4>
                  <Grid container spacing={2} className='fui-upload-container'>
                    <Grid item xs={12} sm={12} md={6}>
                      <ImageUpload
                        placeholder='可拖曳證件正面至此區塊'
                        name='secondary_card_1'
                        addFile={(files) => this.addFile('secondary_card_1', files)}
                        files={[cardForm.secondary_card_1.value]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <ImageUpload
                        name='secondary_card_2'
                        placeholder='可拖曳證件反面至此區塊'
                        addFile={(files) => this.addFile('secondary_card_2', files)}
                        files={[cardForm.secondary_card_2.value]}
                      />
                    </Grid>
                  </Grid>
                  <h4 className='subtitle'>3. 證明員工或親屬身份證件</h4>
                  <Item prefix='bulleted' className='is-text-darkgray50'>
                    請上傳員工本人身分證背面、戶口名簿、戶籍謄本等可證明親屬關係的相關文件。
                  </Item>
                  <RadioButtons
                    name='staff_type'
                    options={[
                      { label: '我是員工本人', value: '1' },
                      { label: '我是員工眷屬/親友', value: '2' },
                    ]}
                    selected={cardForm.staff_type.value}
                    getSelected={(v) => this.inputChange('staff_type', v)}
                  />
                  <Grid container spacing={2} className='fui-upload-container'>
                    {this.state.staff.map((staf, i) => (
                      <Grid item xs={12} sm={12} md={6} key={`staff-card-${i}`}>
                        <ImageUpload
                          addFile={(files) => this.addFile(`staff_card_${i + 1}`, files)}
                          name={`staff_card_${i + 1}`}
                          files={[cardForm[`staff_card_${i + 1}`].value]}
                        />
                      </Grid>
                    ))}
                    {this.state.staff.length < 6 ? (
                      <Grid item xs={12} sm={12} md={6} onClick={this.addStaffCard}>
                        <div className='fui-upload-more' role='button'>
                          <span>上傳更多</span>
                          <i className='icon-plus'></i>
                        </div>
                      </Grid>
                    ) : null}
                  </Grid>
                </div>
              </div>

              <div className='form-group with-agree single-check'>
                <Checkbox
                  className='is-agreement'
                  name='upload_later'
                  checked={cardForm.upload_later.value}
                  label='稍後再上傳證件，將無法為你自動帶入資料，減少填寫基本資料欄位，我們將傳送簡訊及Email提醒你，如果在未來48小時內未完成上傳，將會取消此筆訂單。'
                  onChange={this.inputChange}
                />
              </div>
              <div className='page-action'>
                <Button
                  btnStyle='primary'
                  size='large'
                  disabled={
                    !cardForm.upload_later ? this.isImageEmpty() : this.isImageEmpty() && !cardForm.upload_later.value
                  }
                  onClick={this.nextStep}>
                  繼續填寫基本資料
                </Button>
                <Button btnStyle='secondary' size='large' onClick={(e) => this.props.history.go(-1)}>
                  返回
                </Button>
              </div>
            </Formsy>
          </div>
        </section>

        <EstoreModal
          {...this.state.currentModal}
          open={this.state.modalOpen}
          onSubmit={this.modalSubmit}
          onClose={this.modalClose}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.flowCartReducer || state.cartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFlowCartData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Identity);
