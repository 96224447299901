export const HeaderMock = {
  cbuLink: '//www.fetnet.net',
  ebuLink: '/ebu/index',
  logoLink: '/index',
  searchLink: '/',
  loginlink: '#',
  lang: 'zh-TW',
  switch: {
    on: '商務',
    off: '個人',
  },
  logo: '/resources/common/images/fetnet-logo.png',
  aboutLink: '關於遠傳',
  keywordInput: '想要找什麼？',
  footerLink: ['聯絡客服', '網站地圖'],
  searchHelpers: [
    {
      link: '#',
      image: '/resources/common/images/store.png',
      text: '找門市',
    },
    {
      link: '/ebu/help-center?tab=ebu',
      image: '/resources/common/images/help-center.png',
      text: '幫助中心',
    },
    {
      link: '#',
      image: '/resources/common/images/download-catalog.png',
      text: '最新型錄',
    },
  ],
  keywords: [
    {
      text: '繳費',
    },
    {
      text: '電子帳單',
    },
    {
      text: '交易紀錄',
    },
    {
      text: '吃到飽',
    },
    {
      text: '續約',
    },
    {
      text: '國際漫遊',
    },
    {
      text: '月月好禮優惠',
    },
    {
      text: '小愛講',
    },
    {
      text: 'friday拍拍本',
    },
    {
      text: '遠傳小額付費登入網頁',
    },
  ],
  notLoginMenu: {
    content: {
      menu: [
        [
          { link: '/', text: '登入/註冊' }
        ],
        [
          { link: '/', text: '個人專區' }
        ],
        [
          { link: '/', text: '我的訂單' },
          { link: '/', text: 'Happy Go 優惠' },
        ],
        [
          { link: '/', text: '帳戶管理' },
        ]
      ]
    }
  },
  rentLoginMenu: {
    content: {
      menu: [
        [
          { link: '/', text: '個人專區' },
          { link: '/', text: '線上繳費' },
          { link: '/', text: '帳單查詢' },
          { link: '/', text: '合約查詢' },
        ],
        [
          { link: '/', text: '我的訂單' },
          { link: '/', text: 'Happy Go 優惠' },
        ],
        [
          { link: '/', text: '帳戶管理' },
          { link: '/', text: '登出' },
        ]
      ]
    }
  },
  prepaidLoginMenu: {
    content: {
      menu: [
        [
          { link: '/', text: '個人專區' },
          { link: '/', text: '語音儲值' },
          { link: '/', text: '網路儲值' },
          { link: '/', text: '餘額/上網/到期日查詢' },
        ],
        [
          { link: '/', text: '我的訂單' },
          { link: '/', text: 'Happy Go 優惠' },
        ],
        [
          { link: '/', text: '帳戶管理' },
          { link: '/', text: '登出' },
        ]
      ]
    }
  },
  noMsisdnLoginMenu: {
    content: {
      menu: [
        [
          { link: '/', text: '個人專區' },
        ],
        [
          { link: '/', text: '我的訂單' },
          { link: '/', text: 'Happy Go 優惠' },
        ],
        [
          { link: '/', text: '帳戶管理' },
        ]
      ]
    }
  },
  listMaxLength: 4,
  mobileMenu: {
    content: {
      menu: [
        {
          title: '商品',
          list: [
            {
              icon: '/resources/common/menu-icon/phone.svg',
              iconActive: '/resources/common/menu-icon/phone-active.svg',
              text: '商品',
              list: [
                { link: '/', text: '手機', },
                { link: '/', text: '平板、3C' },
                { link: '/', text: '智慧家電', },
                { link: '/', text: '配件', },
                { link: '/', text: '出國上網卡', },
              ]
            },
            {
              icon: '/resources/common/menu-icon/application.svg',
              iconActive: '/resources/common/menu-icon/application-active.svg',
              text: '行動應用',
              list: [
                { link: '#', text: '手機保險', },
                { link: '#', text: 'Bobee 守護寶', },
                { link: '#', text: '愛講定位手錶', },
                { link: '#', text: '遠傳全能行動管家', },
                { link: '#', text: '一號多機', },
                { link: '#', text: 'MMS/簡訊服務', },
              ],
            },
            {
              icon: '/resources/common/menu-icon/entertament.svg',
              iconActive: '/resources/common/menu-icon/entertament-active.svg',
              text: '數位・加值服務',
              list: [
                { link: '/', text: 'friDay 影音' },
                { link: '/', text: 'friDay 音樂' },
                { link: '/', text: 'friDay 購物' },
                { link: '/', text: 'friDay 相片書' },
                { link: '/', text: 'friDay 57' },
                { link: '/', text: 'friDay 來電答鈴' },
              ]
            }
          ],
        },
        {
          title: '資費',
          list: [
            {
              icon: '/resources/common/menu-icon/online-plan.svg',
              iconActive: '/resources/common/menu-icon/online-plan-active.svg',
              text: '網路限定',
              list: [
                { link: '/', text: '$499 飆網吃到飽' },
                { link: '/', text: '$149 輕速雙飽' },
                { link: '/', text: '$199 夠用最實在' },
                { link: '/', text: '$399 網內飽 + 夯機 $0' },
                { link: '/', text: '$399 吃到飽 + 夯機 $0' },
                { link: '/', text: '推薦續約' },
              ]
            },
            {
              icon: '/resources/common/menu-icon/main-plan.svg',
              iconActive: '/resources/common/menu-icon/main-plan-active.svg',
              text: '主要資費',
              list: [
                { link: '/', text: '4.5G 資費' },
                { link: '/', text: '預付卡' },
                { link: '/', text: '國際電話' },
                { link: '/', text: 'Wi-Fi 上網' },
              ]
            },
            {
              icon: '/resources/common/menu-icon/travel-wifi.svg',
              iconActive: '/resources/common/menu-icon/travel-wifi-active.svg',
              text: '出國/來台上網',
              list: [
                { link: '/', text: '月租用戶原號漫遊' },
                { link: '/', text: '想去哪？就搜哪！' },
                { link: '/', text: '預付卡原號漫遊' },
                { link: '/', text: '出國神卡 - 遠遊卡' },
                { link: '/', text: '來台旅客上網卡' },
                { link: '/', text: '漫遊開關設定' },
                { link: '/', text: '機上漫遊' },
              ]
            },
            {
              icon: '/resources/common/menu-icon/home-wifi.svg',
              iconActive: '/resources/common/menu-icon/home-wifi-active.svg',
              text: '家用上網',
              list: [
                { link: '/', text: '遠傳大無線' },
                { link: '/', text: '遠傳大寬頻光纖' },
              ]
            },
          ],
        },
        {
          title: '遠傳生活圈',
          list: [
            {
              icon: '/resources/common/menu-icon/topic.svg',
              iconActive: '/resources/common/menu-icon/topic-active.svg',
              text: '主題策展',
              list: [
                { link: '/', text: '生活圈策展' },
                { link: '/', text: '異鄉遊子' },
                { link: '/', text: '追劇人生' },
                { link: '/', text: '斜槓老爸' },
                { link: '/', text: '樂活自在' },
              ]
            },
            {
              icon: '/resources/common/menu-icon/lifestyle.svg',
              iconActive: '/resources/common/menu-icon/lifestyle-active.svg',
              text: '我的生活圈',
              list: [
                { link: '/', text: '我的遠傳幣' },
                { link: '/', text: '會員等級說明' },
                { link: '/', text: '白金會員生活禮' },
              ]
            },
          ],
        },
        {
          title: '個人專區',
          list: [
            {
              icon: '/resources/common/menu-icon/pay.svg',
              iconActive: '/resources/common/menu-icon/pay-active.svg',
              text: '帳單/繳費',
              list: [
                {
                  text: '帳單服務',
                  list: [
                    { link: '/', text: '本期應繳金額' },
                    { link: '/', text: '未結帳金額/上網用量' },
                    { link: '/', text: '繳費/代收交易/發票查詢' },
                    { link: '/', text: '4G上網用量加購' },
                    { link: '/', text: '帳單代收設定/額度管理' },
                    { link: '/', text: '4G即時用量查詢' },
                  ]
                },
                {
                  text: '繳費服務',
                  list: [
                    { link: '/', text: '線上繳費' },
                    { link: '/', text: '銀行帳號繳費' },
                    { link: '/', text: '信用卡繳費' },
                    { link: '/', text: '手機條碼繳費' },
                  ]
                },
                {
                  text: '帳單通知設定',
                  list: [
                    { link: '/', text: '帳務及繳費通知設定' },
                    { link: '/', text: '「合併帳單」申請' },
                  ]
                },
              ]
            },
            {
              icon: '/resources/common/menu-icon/contract.svg',
              iconActive: '/resources/common/menu-icon/contract-active.svg',
              text: '合約費率',
              list: [
                { link: '/', text: '目前費率查詢' },
                { link: '/', text: '費率異動/變更' },
                { link: '/', text: '推薦費率查詢' },
                { link: '/', text: '合約資訊查詢' }
              ]
            },
            {
              icon: '/resources/common/menu-icon/global.svg',
              iconActive: '/resources/common/menu-icon/global-active.svg',
              text: '漫遊與門號服務',
              list: [
                {
                  text: '出國漫遊',
                  list: [
                    { link: '/', text: '漫遊通話/上網設定' },
                    { link: '/', text: '漫遊上網包申辦' },
                    { link: '/', text: '7日/15日漫遊優惠申請' },
                    { link: '/', text: '漫遊上網用量查詢' },
                    { link: '/', text: '漫遊加購紀錄' },
                    { link: '/', text: '漫遊旅遊包購買紀錄' },
                  ]
                },
                {
                  text: '門號功能設定',
                  list: [
                    { link: '/', text: '來電答鈴' },
                    { link: '/', text: '來電過濾' },
                    { link: '/', text: '停車費代收設定' },
                  ]
                },
              ]
            },
            {
              icon: '/resources/common/menu-icon/history.svg',
              iconActive: '/resources/common/menu-icon/history-active.svg',
              text: '交易/異動紀錄',
              list: [
                {
                  link: '/',
                  text: '停車費代收記錄查詢'
                },
              ]
            },
            {
              icon: '/resources/common/menu-icon/prepaid.svg',
              iconActive: '/resources/common/menu-icon/prepaid-active.svg',
              text: '預付卡專區',
              list: [
                {
                  text: '儲值/加購',
                  list: [
                    { link: '/', text: '通話金儲值' },
                    { link: '/', text: '上網儲值' },
                    { link: '/', text: '儲值卡儲值' },
                    { link: '/', text: '漫遊上網加購' },
                  ]
                },
                {
                  text: '交易/紀錄查詢',
                  list: [
                    { link: '/', text: '餘額/上網/到期日查詢' },
                    { link: '/', text: '儲值交易查詢' },
                    { link: '/', text: '預付卡加購交易查詢' },
                    { link: '/', text: '發票紀錄查詢' },
                    { link: '/', text: '小額代收/網路門市交易查詢' },
                  ]
                },
              ]
            },
            {
              icon: '/resources/common/menu-icon/member-cbu.svg',
              iconActive: '/resources/common/menu-icon/member-cbu-active.svg',
              text: '帳戶服務',
              list: [
                {
                  link: '/',
                  text: '我的訂單'
                },
                {
                  text: '帳戶管理',
                  list: [
                    { link: '/', text: '個人資料維護' },
                    { link: '/', text: '快速登入設定' },
                    { link: '/', text: '密碼變更' },
                    { link: '/', text: '付款工具維護' },
                    { link: '/', text: '電子發票設定' },
                    { link: '/', text: '更改手機號碼' },
                    { link: '/', text: '訂閱電子報' },
                    { link: '/', text: '已領取序號查詢' },
                  ]
                },
                {
                  text: 'Happy Go 優惠',
                  list: [
                    { link: '/', text: 'Happy Go 點數' },
                    { link: '/', text: 'Happy Go 折抵' },
                    { link: '/', text: 'Happy Go 折抵紀錄查詢' },
                  ]
                },
              ]
            },
          ],
        },
        {
          title: '幫助中心',
          list: [
            {
              icon: '/resources/common/menu-icon/help-center.svg',
              iconActive: '/resources/common/menu-icon/help-center-active.svg',
              text: '常見問題',
              list: [
                { link: '/', text: '幫助中心首頁' },
                { link: '/', text: '帳單/繳費/合約' },
                { link: '/', text: '網路購物與商品' },
                { link: '/', text: '遠傳生活圈' },
                { link: '/', text: '月租型' },
                { link: '/', text: '其他常見問題' },
              ]
            },
            {
              icon: '/resources/common/menu-icon/store-service.svg',
              iconActive: '/resources/common/menu-icon/store-service-active.svg',
              text: '門市服務',
              list: [
                { link: '/', text: '找門市' },
                { link: '/', text: '門市預約及查詢' },
                { link: '/', text: '預約門市換號' },
                { link: '/', text: '服務申請證件提醒' }
              ]
            },
            {
              icon: '/resources/common/menu-icon/customer.svg',
              iconActive: '/resources/common/menu-icon/customer-active.svg',
              text: '客服中心',
              list: [
                { link: '/', text: '客服信箱' },
                { link: '/', text: '服務公告' },
                { link: '/', text: '行動客服 APP' }
              ]
            },
            {
              icon: '/resources/common/menu-icon/information-learning.svg',
              iconActive: '/resources/common/menu-icon/information-learning-active.svg',
              text: '資訊學習',
              list: [
                { link: '/', text: '手機/平板操作說明' },
                { link: '/', text: '免費學習課程' },
                { link: '/', text: 'APP 總覽' }
              ]
            },
            {
              icon: '/resources/common/menu-icon/fix.svg',
              iconActive: '/resources/common/menu-icon/fix-active.svg',
              text: '維修保固',
              list: [
                { link: '/', text: '保固查詢' },
                { link: '/', text: '網路預約報修' },
                { link: '/', text: '網路預約報修查詢' },
                { link: '/', text: '維修進度查詢' },
              ]
            },
            {
              icon: '/resources/common/menu-icon/other.svg',
              iconActive: '/resources/common/menu-icon/other-active.svg',
              text: '其他服務',
              list: [
                { link: '/', text: '網內外門號查詢' },
                { link: '/', text: '電信特殊號碼查詢' },
                { link: '/', text: '網路涵蓋率查詢' },
                { link: '/', text: '固網電子帳單' },
                { link: '/', text: '寬頻服務' },
              ]
            },
          ],
        },
        {
          title: '5G',
          link: '#',
        }
      ],
      moreCase: '/ebu/performance',
    },
    promotion: {
      image: {
        md: '/resources/common/images/megameun-promotion-img-web@2x.jpg',
        sm: '/resources/common/images/promotion-bg-product-m-copy-6@2x.jpg',
      },
      title: '愛講智慧音箱輕鬆聲控家電',
      content: 'surco雲端家電遙控最低$0起',
      action: {
        text: '立即領取',
        link: '#',
      },
    },
  },
  desktopMenu: {
    content: {
      menu: [
        {
          text: '商品',
          layout: 'list-1',
          content: {
            menu: [
              {
                title: '商品',
                list: [
                  {
                    link: '/content/ebu/tw/solution/micro-business/micro-business.html',
                    text: '手機',
                  },
                  {
                    link: '/content/ebu/tw/solution/micro-business/micro-business.html',
                    text: '平板、3C',
                  },
                  {
                    link: '/content/ebu/tw/solution/micro-business/micro-business.html',
                    text: '智慧家電',
                  },
                  {
                    link: '/content/ebu/tw/solution/micro-business/micro-business.html',
                    text: '出國上網卡',
                  },
                  {
                    link: '/content/ebu/tw/solution/micro-business/micro-business.html',
                    text: '配件',
                  },
                ],
              },
              {
                title: '行動應用',
                list: [
                  {
                    link: '/content/ebu/tw/solution/enterprise-cloud-solution.html',
                    text: '手機保險',
                  },
                  {
                    link: '/content/ebu/tw/solution/enterprise-aem-solution.html',
                    text: 'Bobee 守護寶',
                  },
                  {
                    link: '/content/ebu/tw/solution/enterprise-solutions-service.html',
                    text: '愛講定位手錶',
                  },
                  {
                    link: '/content/ebu/tw/solution/enterprise-data-service.html',
                    text: '遠傳全能行動管家',
                  },
                  {
                    link: '/content/ebu/tw/solution/enterprise-data-service.html',
                    text: '一號多機',
                  },
                ],
                more: {
                  link: '/content/ebu/tw/sitemap.html',
                  text: '看更多',
                },
              },
              {
                title: '數位・加值服務',
                list: [
                  {
                    link: '/content/ebu/tw/solution/large-enterprise/l3/large-enterprise-iot/IoT-intro.html',
                    text: 'friDay 影音',
                  },
                  {
                    link: '/content/ebu/tw/solution/enterprise-aem-solution.html',
                    text: 'friDay 音樂',
                  },
                  {
                    link: '/content/ebu/tw/product/security/security_product-l31.html',
                    text: 'friDay 購物',
                  },
                  {
                    link: '/content/ebu/tw/solution/enterprise-cloud-solution.html',
                    text: 'friDay 相片書',
                  },
                  {
                    link: '/content/ebu/tw/solution/enterprise-cloud-solution.html',
                    text: 'friDay 57',
                  },
                ],
                more: {
                  link: '/content/ebu/tw/sitemap.html',
                  text: '看更多',
                },
              },
            ],
            cards: [
              {
                link: '/ebu/solution',
                bg: 'is-yellow',
                icon: '/resources/common/images/card-icon-gift.svg',
                title: '科技宅嚴選神器',
                description: '買 iPhone 送 AirPods'
              },
              {
                link: '#',
                bg: 'is-red',
                icon: '/resources/common/images/card-icon-coin.svg',
                title: '隨身攜愛隨時守護',
                description: '申辦愛講定位手錶送購物金'
              },
              {
                link: '#',
                bg: 'is-sky',
                icon: '/resources/common/images/card-icon-video.svg',
                title: 'friday 影音',
                description: '陪你免費追劇 30 天'
              },
            ],
            commercial: {
              image: {
                md: '/resources/common/images/megameun-promotion-img-web@2x.jpg',
                sm: '/resources/common/images/promotion-bg-product-m-copy-6@2x.jpg',
              },
              title: '愛講智慧音箱輕鬆聲控家電',
              content: 'surco雲端家電遙控最低$0起',
              action: {
                text: '立即領取',
                link: '#',
              },
            },
          },
        },
        {
          text: '資費',
          layout: 'list-1',
          content: {
            menu: [
              {
                title: '網路限定',
                list: [
                  {
                    link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/volte/volte-vowifi-intro.html',
                    text: '$499飆網吃到飽',
                  },
                  {
                    link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/mvpn/mvpn-intro.html',
                    text: '$149輕速雙飽',
                  },
                  {
                    link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/wvpn.html',
                    text: '$199夠用最實在',
                  },
                  {
                    link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/fet-uc/fet-uc-intro.html',
                    text: '$399追劇影片好滿足',
                  },
                  {
                    link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/sms/sms-intro.html',
                    text: '推薦續約',
                  },
                ],
                more: {
                  link: '/content/ebu/tw/sitemap.html',
                  text: '看更多',
                },
              },
              {
                title: '主要資費',
                list: [
                  {
                    link: '/content/ebu/tw/product/broadband_product/broadband.html',
                    text: '4.5G資費',
                  },
                  {
                    link: '/content/ebu/tw/product/broadband_product/voice.html',
                    text: '預付卡',
                  },
                  {
                    link: '/content/ebu/tw/product/broadband_product/data-circuit.html',
                    text: '國際電話007',
                  },
                  {
                    link: '/content/ebu/tw/product/broadband_product/virtual-network2.html',
                    text: 'Wi-Fi 上網',
                  },
                ],
              },
              {
                title: '出國/來台上網',
                list: [
                  {
                    link: '/content/ebu/tw/product/broadband_product/broadband.html',
                    text: '月租用戶原號漫遊',
                  },
                  {
                    link: '/content/ebu/tw/product/broadband_product/voice.html',
                    text: '想去哪？就搜哪！',
                  },
                  {
                    link: '/content/ebu/tw/product/broadband_product/data-circuit.html',
                    text: '預付卡原號漫遊',
                  },
                  {
                    link: '/content/ebu/tw/product/broadband_product/virtual-network2.html',
                    text: '出國上網卡 - 遠遊卡',
                  },
                  {
                    link: '/content/ebu/tw/product/broadband_product/virtual-network2.html',
                    text: '來台旅客上網卡',
                  },
                ],
                more: {
                  link: '/content/ebu/tw/sitemap.html',
                  text: '看更多',
                },
              },
              {
                title: '家用上網',
                list: [
                  {
                    link: '/content/ebu/tw/product/broadband_product/broadband.html',
                    text: '遠傳大無線',
                  },
                  {
                    link: '/content/ebu/tw/product/broadband_product/voice.html',
                    text: '遠傳大寬頻光纖',
                  },
                ],
              },
            ],
            cards: [
              {
                link: '/ebu/solution',
                bg: 'is-yellow',
                icon: '/resources/common/images/card-icon-gift.svg',
                title: '科技宅嚴選神器',
                description: '買 iPhone 送 AirPods'
              },
              {
                link: '#',
                bg: 'is-red',
                icon: '/resources/common/images/card-icon-coin.svg',
                title: '隨身攜愛隨時守護',
                description: '申辦愛講定位手錶送購物金'
              },
              {
                link: '#',
                bg: 'is-sky',
                icon: '/resources/common/images/card-icon-video.svg',
                title: 'friday 影音',
                description: '陪你免費追劇 30 天'
              },
            ],
            commercial: {
              image: {
                md: '/resources/common/images/megameun-promotion-img-web@2x.jpg',
                sm: '/resources/common/images/promotion-bg-product-m-copy-6@2x.jpg',
              },
              title: '愛講智慧音箱輕鬆聲控家電',
              content: 'surco雲端家電遙控最低$0起',
              action: {
                text: '立即領取',
                link: '#',
              },
            },
          },
        },
        {
          text: '個人專區',
          layout: 'list-2',
          content: {
            menu: [
              {
                title: '帳單/費用',
                list: [
                  {
                    link: '/ebu/help-center?tab=ebu',
                    text: '帳單查詢',
                  },
                  {
                    link: '#',
                    text: '未結帳金額/上網用量',
                  },
                  {
                    link: '/ebu/help-center?tab=contact',
                    text: '線上繳費',
                  },
                  {
                    link: '#',
                    text: '繳費/代收交易/發票查詢',
                  },
                  {
                    link: '/ebu/help-center?tab=contact',
                    text: '帳單代收設定/額度管理',
                  },
                ],
                more: {
                  text: '看更多',
                  link: '/ebu/sitemap#help',
                },
              },
              {
                title: '合約費率',
                list: [
                  {
                    link: '/ebu/help-center?tab=ebu',
                    text: '合約資訊查詢',
                  },
                  {
                    link: '#',
                    text: '目前費率查詢',
                  },
                  {
                    link: '/ebu/help-center?tab=contact',
                    text: '費率異動/變更',
                  },
                  {
                    link: '#',
                    text: '推薦費率查詢',
                  },
                ]
              },
              {
                title: '漫遊與門號服務',
                list: [
                  {
                    link: '/ebu/help-center?tab=ebu',
                    text: '漫遊通話/上網設定',
                  },
                  {
                    link: '#',
                    text: '漫遊優惠方案申辦',
                  },
                  {
                    link: '/ebu/help-center?tab=contact',
                    text: '漫遊上網用量查詢',
                  },
                  {
                    link: '#',
                    text: '掛失服務',
                  },
                  {
                    link: '/ebu/help-center?tab=contact',
                    text: '停車費代收',
                  },
                ],
                more: {
                  text: '看更多',
                  link: '/ebu/sitemap#help',
                },
              },
              {
                title: '交易/異動紀錄',
                list: [
                  {
                    link: '/ebu/help-center?tab=ebu',
                    text: '門號/服務異動紀錄',
                  },
                  {
                    link: '#',
                    text: '設備維修進度查詢',
                  },
                  {
                    link: '/ebu/help-center?tab=contact',
                    text: '通訊問題反應紀錄',
                  },
                  {
                    link: '#',
                    text: '停車費代收紀錄查詢',
                  },
                  {
                    link: '/ebu/help-center?tab=contact',
                    text: '門市預約記錄',
                  },
                ],
                more: {
                  text: '看更多',
                  link: '/ebu/sitemap#help',
                },
              },
              {
                title: '預付卡專區',
                list: [
                  {
                    link: '/ebu/help-center?tab=ebu',
                    text: '上網儲值',
                  },
                  {
                    link: '#',
                    text: '通話金儲值',
                  },
                  {
                    link: '/ebu/help-center?tab=contact',
                    text: '餘額/上網/到期日查詢',
                  },
                  {
                    link: '#',
                    text: '漫遊上網加購',
                  },
                  {
                    link: '/ebu/help-center?tab=contact',
                    text: '儲值卡儲值',
                  },
                ],
                more: {
                  text: '看更多',
                  link: '/ebu/sitemap#help',
                },
              },
              {
                title: '帳戶服務',
                list: [
                  {
                    link: '/ebu/help-center?tab=ebu',
                    text: '密碼變更',
                  },
                  {
                    link: '#',
                    text: '個人資料維護',
                  },
                  {
                    link: '/ebu/help-center?tab=contact',
                    text: '我的訂單',
                  },
                  {
                    link: '#',
                    text: 'Happy Go 點數',
                  },
                  {
                    link: '/ebu/help-center?tab=contact',
                    text: '訂閱電子報',
                  },
                ],
                more: {
                  text: '看更多',
                  link: '/ebu/sitemap#help',
                },
              },
            ],
            commercial: {
              image: {
                md: '/resources/common/images/megameun-promotion-img-web@2x.jpg',
                sm: '/resources/common/images/promotion-bg-product-m-copy-6@2x.jpg',
              },
              title: '愛講智慧音箱輕鬆聲控家電',
              content: 'surco雲端家電遙控最低$0起',
              action: {
                text: '立即領取',
                link: '#',
              },
            },
          },
        },
        {
          text: '遠傳生活圈',
          layout: 'list-1',
          content: {
            menu: [
              {
                title: '主題策展',
                list: [
                  {
                    link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/volte/volte-vowifi-intro.html',
                    text: '生活圈策展',
                  },
                  {
                    link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/mvpn/mvpn-intro.html',
                    text: '異鄉遊子',
                  },
                  {
                    link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/wvpn.html',
                    text: '追劇人生',
                  },
                  {
                    link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/fet-uc/fet-uc-intro.html',
                    text: '斜槓老爸',
                  },
                  {
                    link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/sms/sms-intro.html',
                    text: '樂活自在',
                  },
                ],
                more: {
                  link: '/content/ebu/tw/sitemap.html',
                  text: '看更多',
                },
              },
              {
                title: '我的生活圈',
                list: [
                  {
                    link: '/content/ebu/tw/product/broadband_product/broadband.html',
                    text: '我的遠傳幣',
                  },
                  {
                    link: '/content/ebu/tw/product/broadband_product/voice.html',
                    text: '會員等級說明',
                  },
                  {
                    link: '/content/ebu/tw/product/broadband_product/data-circuit.html',
                    text: '白金會員生活禮',
                  },
                ],
              },
            ],
            cards: [
              {
                link: '/ebu/solution',
                bg: 'is-yellow',
                icon: '/resources/common/images/card-icon-gift.svg',
                title: '科技宅嚴選神器',
                description: '買 iPhone 送 AirPods'
              },
              {
                link: '#',
                bg: 'is-red',
                icon: '/resources/common/images/card-icon-coin.svg',
                title: '隨身攜愛隨時守護',
                description: '申辦愛講定位手錶送購物金'
              },
              {
                link: '#',
                bg: 'is-sky',
                icon: '/resources/common/images/card-icon-video.svg',
                title: 'friday 影音',
                description: '陪你免費追劇 30 天'
              },
            ],
            commercial: {
              image: {
                md: '/resources/common/images/megameun-promotion-img-web@2x.jpg',
                sm: '/resources/common/images/promotion-bg-product-m-copy-6@2x.jpg',
              },
              title: '愛講智慧音箱輕鬆聲控家電',
              content: 'surco雲端家電遙控最低$0起',
              action: {
                text: '立即領取',
                link: '#',
              },
            },
          },
        },
        {
          text: '5G',
          link: '#',
        },
        {
          text: '幫助中心',
          layout: 'list-2',
          content: {
            menu: [
              {
                title: '常見問題',
                list: [
                  {
                    link: '/ebu/help-center?tab=ebu',
                    text: '帳單/繳費/合約',
                  },
                  {
                    link: '#',
                    text: '網路購物與商品',
                  },
                  {
                    link: '/ebu/help-center?tab=contact',
                    text: '遠傳生活圈',
                  },
                  {
                    link: '/ebu/help-center?tab=ebu',
                    text: '月租型',
                  },
                  {
                    link: '#',
                    text: '其他常見問題',
                  },
                ],
                more: {
                  text: '更多幫助',
                  link: '/ebu/sitemap#help',
                },
              },
              {
                title: '門市服務',
                list: [
                  {
                    link: '/ebu/help-center/document',
                    text: '找門市',
                  },
                  {
                    link: '#',
                    text: '門市預約及查詢',
                  },
                  {
                    link: '#',
                    text: '預約門市換號',
                  },
                  {
                    link: '#',
                    text: '服務申請證件提醒',
                  },
                ],
              },
              {
                title: '客服中心',
                list: [
                  {
                    link: '#',
                    text: '客服信箱',
                  },
                  {
                    link: '#',
                    text: '服務公告',
                  },
                  {
                    link: '#',
                    text: '行動客服APP',
                  },
                ],
              },
              {
                title: '資訊學習',
                list: [
                  {
                    link: '#',
                    text: '手機/平板操作說明',
                  },
                  {
                    link: '#',
                    text: '免費學習課程',
                  },
                  {
                    link: '#',
                    text: 'APP總覽',
                  }
                ],
              },
              {
                title: '維修保固',
                list: [
                  {
                    link: '#',
                    text: '保固查詢',
                  },
                  {
                    link: '#',
                    text: '網路預約報修',
                  },
                  {
                    link: '#',
                    text: '網路預約報修查詢',
                  },
                  {
                    link: '#',
                    text: '維修進度查詢',
                  },
                ],
              },
              {
                title: '其他服務',
                list: [
                  {
                    link: '#',
                    text: '網內外門號查詢',
                  },
                  {
                    link: '#',
                    text: '電信特殊號碼查詢',
                  },
                  {
                    link: '#',
                    text: '網路涵蓋率查詢',
                  },
                  {
                    link: '#',
                    text: '固網電子帳單',
                  },
                  {
                    link: '#',
                    text: '寬頻服務',
                  },
                ],
              },
            ],
            commercial: {
              image: {
                md: '/resources/common/images/megameun-promotion-img-web@2x.jpg',
                sm: '/resources/common/images/promotion-bg-product-m-copy-6@2x.jpg',
              },
              title: '愛講智慧音箱輕鬆聲控家電',
              content: 'surco雲端家電遙控最低$0起',
              action: {
                text: '立即領取',
                link: '#',
              },
            },
          },
        },
      ],
    },
  },
};
