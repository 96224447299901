export const Spec = [
  {
    key: '產品介紹',
    value: [
      {
        key: '智慧型 HDR',
        value: `新一代智慧型 HDR 使用先進演算法，微調照片中的亮部與暗部細節。現在，它更運用機器學習技術辨識你照片中的臉孔，聰明地調整光影。也就是說，iPhone 11 Pro 能自動加強拍攝主體與背景的細部，即便某些數位單眼相機也不見得有此能耐。`,
      },
    ],
  },
  {
    key: '產品規格',
    value: [
      {
        key: '有效期限',
        value: `啟用後15日內`,
      },
      {
        key: '網路流量',
        value: `首 10GB 高速上網流量，後降速 128Kbps 吃到飽`,
      },
      {
        key: '指定網路',
        value: `歐洲國家依當地合作電信商為準`,
      },
      {
        key: '適用地區',
        value: `【西歐】法國、荷蘭、比利時、聖馬丁、法屬圭亞那、愛爾蘭共和國、瓜德羅普島、馬提尼克、直布羅陀、聖巴泰勒米、盧森堡、法屬安的列斯、馬約特島<br/>
                【中歐】德國、瑞士、奧地利、匈牙利、波蘭、克羅地亞、列支敦士登<br/>
                【南歐】義大利、西班牙、葡萄牙、希臘、馬耳他、塞浦路斯<br/>
                【北歐】冰島、芬蘭、瑞典、挪威、丹麥、愛沙尼亞<br/>
                【東歐】立陶宛、捷克共和國、拉脫維亞、羅馬尼亞、保加利亞、斯洛文尼亞、斯洛伐克
              `,
      },
      {
        key: '重要提醒',
        value: `本產品於美國不涵蓋地區為「關島」及「塞班」、加拿大不涵蓋地區為「黃刀鎮」`,
      },
    ],
  },
];

export const Evaluation = `<div id="product-article-title-0" style="height: 45"></div>
<h2>"蘋果推出新一代智慧型手機 iPhone SE，整體外型延續 iPhone 8 的機身設計，採用前後單鏡頭配置，前鏡頭具有 700 萬畫素（F2.2 光圈），更新增了 6 種人像光模式；後置 1,200 萬畫素主鏡頭（F1.8 光圈），導入新一代智慧型 HDR 模式，能夠微調照片背景中的亮部與暗部細節。究竟這款造型經典、價格親民的 iPhone SE 二代在攝錄上是否也能有超乎意料的表現呢？請看《SOGI 手機王》的相機實測。<br><br><img title="iPhone入門款 SE 2代單鏡頭相機、人像模式實測" class="js-lazy" alt="iPhone入門款 SE 2代單鏡頭相機、人像模式實測" src="/estore/upload_img/article/ff8080817177b5100171818ac5d91d67/202004/202004290059290440_ExtraLargeSize-640x640.jpg"><br></h2><h2 class="text-dark"><a id="gwt-uid-41">攝錄體驗</a></h2>
"蘋果推出新一代智慧型手機 iPhone SE，整體外型延續 iPhone 8 的機身設計，採用前後單鏡頭配置，前鏡頭具有 700 萬畫素（F2.2 光圈），更新增了 6 種人像光模式；後置 1,200 萬畫素主鏡頭（F1.8 光圈），導入新一代智慧型 HDR 模式，能夠微調照片背景中的亮部與暗部細節。究竟這款造型經典、價格親民的 iPhone SE 二代在攝錄上是否也能有超乎意料的表現呢？請看《SOGI 手機王》的相機實測。<br><br><img title="iPhone入門款 SE 2代單鏡頭相機、人像模式實測" class="js-lazy" alt="iPhone入門款 SE 2代單鏡頭相機、人像模式實測" src="/estore/upload_img/article/ff8080817177b5100171818ac5d91d67/202004/202004290059290440_ExtraLargeSize-640x640.jpg"><br><h2 class="text-dark"><a id="gwt-uid-41">攝錄體驗</a></h2>`;

export const Product = {
  type: 'phone',
  slug: 'iphone-11-pro-max-256gb',
  name: 'Apple iPhone11 Pro Max 256GB',
  ribbon: '遠傳獨家',
  tag: ['5G手機', '福利A品'],
  images: {
    夜幕綠: ['/resources/cbu/estore-product/product-6.jpg', '/resources/cbu/estore-product/product-4.jpg'],
    土豪金: ['/resources/cbu/estore-product/product-1.jpg', '/resources/cbu/estore-product/product-4.jpg'],
    太空灰: ['/resources/cbu/estore-product/product-5.jpg', '/resources/cbu/estore-product/product-4.jpg'],
    太空灰: ['/resources/cbu/estore-product/product-5.jpg', '/resources/cbu/estore-product/product-4.jpg'],
    太空灰: ['/resources/cbu/estore-product/product-5.jpg', '/resources/cbu/estore-product/product-4.jpg'],
  },
  colors: [
    {
      label: '夜幕綠',
      value: '夜幕綠',
      color: 'rgb(201, 219, 221)',
    },
    {
      label: '土豪金',
      value: '土豪金',
      color: 'rgb(220, 207, 191)',
    },
    {
      label: '太空灰',
      value: '太空灰',
      color: 'rgb(128, 133, 136)',
    },
    {
      label: '土豪金',
      value: '土豪金',
      color: 'rgb(220, 207, 191)',
    },
    {
      label: '太空灰',
      value: '太空灰',
      color: 'rgb(128, 133, 136)',
    },
  ],
  withPlan: [
    { label: '搭門號', value: '1' },
    { label: '買單機', value: '0' },
  ],
  discount: {
    text: '自4/24至5/31，買iPhone指定機款即可$0帶走台塑生醫面膜。',
    link: '#',
    target: '_self',
  },
  originPrice: 39900,
  salePrice: 38900,
  projectPrice: 25600,
  notice: [
    {
      text: '分期0利率，每期最低 $74',
      modal: {
        title: '信用卡分期付款',
        content: [
          '分期資訊',
          '結帳金額 $30,300.',
          {
            '3期0利率': '$10,000',
            '5期0利率': '$6,000',
            '6期0利率': '$5,000',
            '12期0利率': '$2,525',
          },
          '貼心提醒',
          [
            '手機分期付款僅提供宅配取貨，並使用線上刷卡供銷費者使用。',
            '分期價除不盡餘額部分，將於第一期帳單收取。',
            '各產品可供分期期數不盡相同，依結帳時系統提供分期數為主；如一張訂單有多項商品，只會出現共同皆可使用之分期數。',
          ],
        ],
      },
    },
  ],
};

export const productNotice = {
  text: `本產品僅含本島運送，不含壁掛安裝費用。部分偏遠地區將需額外加收費用，費用由廠商電話聯絡時確認。辦理新申辦/攜碼訂單，將先收到SIM卡及申請書，成功啟用2-3週後，商品將由廠商另行配送；申辦續約於續約成功2-3週後`,
};

export const planStep = [
  {
    id: 'apply',
    slug: '申辦',
    name: 'apply_method',
    title: '申辦方式',
    description: ['選個好門號，保值一輩子', '我想保留目前的手機號碼，轉換到遠傳', '我有遠傳門號，想跟遠傳再續前緣'],
    type: 'radio',
    options: [
      { label: '申辦新門號', value: '申辦新門號' },
      { label: '攜碼', value: '攜碼' },
      { label: '遠傳門號續約', value: '遠傳門號續約' },
    ],
  },
];

export const renewFlow = [
  {
    id: 'rn',
    slug: '驗證',
    title: '續約身份驗證',
    name: 'identity_validation',
    type: 'identity-validation',
    description: '為確保個人資訊安全，請輸入欲續約的手機門號，遠傳電信將為你驗證身份',
    isPass: false,
  },
  {
    id: 'plan-select',
    slug: '資費',
    title: '資費方案',
    name: 'plan',
    description: '吃到飽吃不飽這裡都有',
    type: 'radio-card',
    tags: {
      name: '資費分類',
      list: [
        '全部',
        '5G方案',
        '4.5G方案',
        '吃到飽',
        '月付$599以下',
        '月付$600-$999',
        '月付$1,000以上',
        '月付$1,399',
        '輕上網',
      ],
    },

    options: [
      {
        ribbon: '網路限定方案',
        value: '月付$1399x12個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 1399,
        prepay: 6000,
        note: '網路門市限定',
        month: '12',
        list: [
          { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: { text: '需持學生證或教職證', color: 'blue' },
        value: '月付$999x12個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 999,
        originPrice: 1299,
        prepay: 6000,
        month: '12',
        // note: '網路門市限定',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: '5G飆網24GB' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `辦就送7-11 抵用券 $250，加碼送$1,000 易遊網旅遊票券`,
            color: 'red',
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        value: '月付$999x24個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 999,
        prepay: 6000,
        note: '網路門市限定',
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: '上網吃到飽',
              量到限速: '不限速',
              網內通話: '前5分鐘免費/通',
              網外通話: '送30鐘/月',
              市話通話: '1.8/分鐘',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        value: '月付$799x12個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 799,
        prepay: 6000,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: '上網吃到飽',
              量到限速: '不限速',
              網內通話: '前5分鐘免費/通',
              網外通話: '送30鐘/月',
              市話通話: '1.8/分鐘',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
    ],
  },
  {
    id: 'giveaway',
    slug: '好禮',
    title: '好禮送給你',
    name: 'gift',
    type: 'checkbox-card',
    description: '都送給你了，一定要帶走喔！至少需選兩項～',
    minNumber: 2,
    options: [
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-12@2x.jpg',
        value: 'VANTEC Qi 紅色無線快速充電盤',
        meta: 'VANTEC Qi',
        name: 'VANTEC Qi 紅色無線快速充電盤',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '3.5mm 超薄無線充電盤',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-13@2x.jpg',
        value: '原廠 EarPods 耳機 - Lightning線',
        meta: 'APPLE',
        name: '原廠 EarPods 耳機 - Lightning線',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '3.5mm 超薄無線充電盤',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-14@2x.jpg',
        value: 'MYCELL AirPods Pro 粉沙收納盒',
        meta: 'MYCELL',
        name: 'MYCELL AirPods Pro 粉沙收納盒',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '3.5mm 超薄無線充電盤',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-15@2x.jpg',
        value: 'Samsung S20+原廠灰色透視感應皮套',
        meta: 'SAMSUNG',
        name: 'Samsung S20+原廠灰色透視感應皮套',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '3.5mm 超薄無線充電盤',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
    ],
  },
  {
    id: 'add-ons',
    slug: '加購',
    title: '加購商品及服務',
    type: 'tab-checkbox-card',
    description: '不買沒關係，買了絕對超值！',
    tabs: {
      name: 'checkbox-card-tab',
      list: [{ label: '加購商品' }, { label: '加值服務' }],
      content: [
        {
          name: 'extra_product',
          withNumberController: true,
          options: [
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
              value: 'PHILIPS 舒眠抗敏空氣清淨機',
              meta: 'PHILIPS',
              name: 'PHILIPS 舒眠抗敏空氣清淨機',
              productPrice: 5100,
              modal: {
                title: 'PHILIPS 舒眠抗敏空氣清淨機',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-10@2x.jpg',
              value: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
              meta: 'Iris Ohyama ',
              name: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
              productPrice: 1399,
              modal: {
                title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-11@2x.jpg',
              value: 'Panasonic 15公升蒸氣烘烤爐',
              meta: 'PANASONIC',
              name: 'Panasonic 15公升蒸氣烘烤爐',
              productPrice: 15000,
              modal: {
                title: 'Panasonic 15公升蒸氣烘烤爐',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-16@2x.jpg',
              value: 'Xiaomi 米家掃拖機器人',
              meta: 'XIAOMI',
              name: 'Xiaomi 米家掃拖機器人',
              productPrice: 6900,
              modal: {
                title: 'Xiaomi 米家掃拖機器人',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
              value: 'XGIMI MOGO 攜帶型投影機內建喇叭',
              meta: 'XGIMI',
              name: 'XGIMI MOGO 攜帶型投影機內建喇叭',
              productPrice: 6900,
              modal: {
                title: 'XGIMI MOGO 攜帶型投影機內建喇叭',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
          ],
        },
        {
          name: 'extra_plan',
          withNumberController: false,
          options: [
            {
              type: 'friday',
              value: 'friDay影音免費爽看30天',
              meta: '第一個月免費',
              name: 'friDay影音免費爽看30天',
              productPrice: 199,
              day: 30,
              unit: '天',
              modal: {
                title: 'friDay影音免費爽看30天',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'friday',
              value: '【超值限定】friDay影音爽看100天',
              meta: '好劇多到看不完',
              name: '【超值限定】friDay影音爽看100天',
              originPrice: 599,
              productPrice: 399,
              day: 100,
              unit: '天',
              modal: {
                title: '【超值限定】friDay影音爽看100天',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'music',
              value: 'friDay拍拍本隨手拍、隨手印',
              meta: '記錄你的珍貴時刻',
              name: 'friDay拍拍本隨手拍、隨手印',
              originPrice: 149,
              productPrice: 99,
              unit: '月',
              modal: {
                title: 'friDay拍拍本隨手拍、隨手印',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'music',
              value: 'friDay拍拍本隨手拍、隨手印 2',
              meta: '記錄你的珍貴時刻',
              name: 'friDay拍拍本隨手拍、隨手印 2',
              originPrice: 349,
              productPrice: 199,
              unit: '月',
              modal: {
                title: 'friDay拍拍本隨手拍、隨手印 2',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
          ],
        },
      ],
    },
  },
];

export const carryFlow = [
  {
    id: 'plan-select',
    slug: '資費',
    title: '資費方案',
    name: 'plan',
    description: '吃到飽吃不飽這裡都有',
    type: 'radio-card',
    tags: {
      name: '資費分類',
      list: [
        '全部',
        '5G方案',
        '4.5G方案',
        '吃到飽',
        '月付$599以下',
        '月付$600-$999',
        '月付$1,000以上',
        '月付$1,399',
        '輕上網',
      ],
    },
    options: [
      {
        ribbon: '網路限定方案',
        value: '月付$1399x12個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 1399,
        prepay: 6000,
        note: '網路門市限定',
        month: '12',
        list: [
          { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: { text: '需持學生證或教職證', color: 'blue' },
        value: '月付$799x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 799,
        originPrice: 999,
        prepay: 6000,
        month: '24',
        // note: '網路門市限定',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: '5G飆網24GB' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `辦就送7-11 抵用券 $250，加碼送$1,000 易遊網旅遊票券`,
            color: 'red',
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        value: '月付$999x12個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 999,
        prepay: 6000,
        note: '網路門市限定',
        month: '12',
        list: [
          { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: '上網吃到飽',
              量到限速: '不限速',
              網內通話: '前5分鐘免費/通',
              網外通話: '送30鐘/月',
              市話通話: '1.8/分鐘',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        value: '月付$699x24個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 699,
        prepay: 6000,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: '上網吃到飽',
              量到限速: '不限速',
              網內通話: '前5分鐘免費/通',
              網外通話: '送30鐘/月',
              市話通話: '1.8/分鐘',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
    ],
  },
  {
    id: 'np',
    slug: '門號',
    title: '攜入門號確認',
    type: 'input-validation',
    name: 'input_validation',
    description: '攜碼到遠傳請先注意...',
    cards: [
      {
        no: 1,
        title: '確認門號合約',
        description: '手機直撥給原業者，或前往原業者門市查詢合約狀態',
      },
      {
        no: 2,
        title: '逾期費用要繳清',
        description: '確認門號是使用你的姓名與證件申請，並該門號沒有欠費、停話或中止狀態。',
      },
      {
        no: 3,
        title: '攜碼錢我出',
        description: '原業者會收取$112元作業費用，於攜碼啟用後遠傳將會在下一期帳單中返還！',
      },
    ],
    label: '輸入攜入門號',
    placeholder: '輸入非遠傳號碼',
    note: `This site is protected by reCAPTCHA and the Google <a href='#'>Privacy Policy</a> and <a href='#'>Terms of Service</a> apply.`,
  },
  {
    id: 'giveaway',
    slug: '好禮',
    title: '好禮送給你',
    name: 'gift',
    type: 'checkbox-card',
    description: '都送給你了，一定要帶走喔！至少需選兩項～',
    minNumber: 2,
    options: [
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-12@2x.jpg',
        value: 'VANTEC Qi 紅色無線快速充電盤',
        meta: 'VANTEC Qi',
        name: 'VANTEC Qi 紅色無線快速充電盤',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '3.5mm 超薄無線充電盤',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-13@2x.jpg',
        value: '原廠 EarPods 耳機 - Lightning線',
        meta: 'APPLE',
        name: '原廠 EarPods 耳機 - Lightning線',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '3.5mm 超薄無線充電盤',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-14@2x.jpg',
        value: 'MYCELL AirPods Pro 粉沙收納盒',
        meta: 'MYCELL',
        name: 'MYCELL AirPods Pro 粉沙收納盒',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '3.5mm 超薄無線充電盤',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-15@2x.jpg',
        value: 'Samsung S20+原廠灰色透視感應皮套',
        meta: 'SAMSUNG',
        name: 'Samsung S20+原廠灰色透視感應皮套',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '3.5mm 超薄無線充電盤',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
    ],
  },
  {
    id: 'add-ons',
    slug: '加購',
    title: '加購商品及服務',
    type: 'tab-checkbox-card',
    description: '不買沒關係，買了絕對超值！',
    tabs: {
      name: 'checkbox-card-tab',
      list: [{ label: '加購商品' }, { label: '加值服務' }],
      content: [
        {
          name: 'extra_product',
          withNumberController: true,
          options: [
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
              value: 'PHILIPS 舒眠抗敏空氣清淨機',
              meta: 'PHILIPS',
              name: 'PHILIPS 舒眠抗敏空氣清淨機',
              productPrice: 5100,
              modal: {
                title: 'PHILIPS 舒眠抗敏空氣清淨機',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-10@2x.jpg',
              value: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
              meta: 'Iris Ohyama ',
              name: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
              productPrice: 1399,
              modal: {
                title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-11@2x.jpg',
              value: 'Panasonic 15公升蒸氣烘烤爐',
              meta: 'PANASONIC',
              name: 'Panasonic 15公升蒸氣烘烤爐',
              productPrice: 15000,
              modal: {
                title: 'Panasonic 15公升蒸氣烘烤爐',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-16@2x.jpg',
              value: 'Xiaomi 米家掃拖機器人',
              meta: 'XIAOMI',
              name: 'Xiaomi 米家掃拖機器人',
              productPrice: 6900,
              modal: {
                title: 'Xiaomi 米家掃拖機器人',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
              value: 'XGIMI MOGO 攜帶型投影機內建喇叭',
              meta: 'XGIMI',
              name: 'XGIMI MOGO 攜帶型投影機內建喇叭',
              productPrice: 6900,
              modal: {
                title: 'XGIMI MOGO 攜帶型投影機內建喇叭',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
          ],
        },
        {
          name: 'extra_plan',
          withNumberController: false,
          options: [
            {
              type: 'friday',
              value: 'friDay影音免費爽看30天',
              meta: '第一個月免費',
              name: 'friDay影音免費爽看30天',
              productPrice: 199,
              day: 30,
              unit: '天',
              modal: {
                title: 'friDay影音免費爽看30天',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'friday',
              value: '【超值限定】friDay影音爽看100天',
              meta: '好劇多到看不完',
              name: '【超值限定】friDay影音爽看100天',
              originPrice: 599,
              productPrice: 399,
              day: 100,
              unit: '天',
              modal: {
                title: '【超值限定】friDay影音爽看100天',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'music',
              value: 'friDay拍拍本隨手拍、隨手印',
              meta: '記錄你的珍貴時刻',
              name: 'friDay拍拍本隨手拍、隨手印',
              originPrice: 149,
              productPrice: 99,
              unit: '月',
              modal: {
                title: 'friDay拍拍本隨手拍、隨手印',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'music',
              value: 'friDay拍拍本隨手拍、隨手印 2',
              meta: '記錄你的珍貴時刻',
              name: 'friDay拍拍本隨手拍、隨手印 2',
              originPrice: 349,
              productPrice: 199,
              unit: '月',
              modal: {
                title: 'friDay拍拍本隨手拍、隨手印 2',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '3.5mm 超薄無線充電盤',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
          ],
        },
      ],
    },
  },
];

export const newFlow = [
  {
    id: 'plan-select',
    slug: '資費',
    title: '資費方案',
    name: 'plan',
    description: '吃到飽吃不飽這裡都有',
    type: 'radio-card',
    tags: {
      name: '資費分類',
      list: [
        '全部',
        '5G方案',
        '4.5G方案',
        '吃到飽',
        '月付$599以下',
        '月付$600-$999',
        '月付$1,000以上',
        '月付$1,399',
        '輕上網',
      ],
    },
    options: [
      {
        ribbon: '網路限定方案',
        value: '月付$1399x12個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 1399,
        prepay: 6000,
        note: '網路門市限定',
        month: '12',
        list: [
          { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: { text: '需持學生證或教職證', color: 'blue' },
        value: '月付$999x12個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 999,
        originPrice: 1299,
        prepay: 6000,
        month: '12',
        // note: '網路門市限定',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: '5G飆網24GB' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `辦就送7-11 抵用券 $250，加碼送$1,000 易遊網旅遊票券`,
            color: 'red',
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        value: '月付$999x24個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 999,
        prepay: 6000,
        note: '網路門市限定',
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: '上網吃到飽',
              量到限速: '不限速',
              網內通話: '前5分鐘免費/通',
              網外通話: '送30鐘/月',
              市話通話: '1.8/分鐘',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        value: '月付$799x24個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 799,
        prepay: 6000,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: '上網吃到飽',
              量到限速: '不限速',
              網內通話: '前5分鐘免費/通',
              網外通話: '送30鐘/月',
              市話通話: '1.8/分鐘',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
    ],
  },
  {
    id: 'new-number',
    slug: '門號',
    title: '選擇門號',
    type: 'radio-carousel',
    name: 'phone_number',
    description: '選一個好門號給你好運氣',
    searchLabel: '主題號碼',
    tags: ['免費門號', '黃金門號', '一路發168', '66大順', '幸福99', 'Lucky77', '真愛520', '發發88', '123門號'],
    options: [
      {
        label: '0903-063-482',
        value: '0903-063-482',
        description: '選號費將於第一期帳單進行扣抵',
        price: 1000,
        tag: ['黃金門號'],
      },
      {
        label: '0903-652-344',
        value: '0903-652-344',
        price: 500,
        tag: ['黃金門號'],
      },
      {
        label: '0903-849-753',
        value: '0903-849-753',
        tag: ['免費門號'],
      },
      {
        label: '0903-666-333',
        value: '0903-666-333',
        tag: ['66大順', '免費門號'],
      },
      {
        label: '0903-849-123',
        value: '0903-849-123',
        tag: ['免費門號', '123門號'],
      },
      {
        label: '0903-666-332',
        value: '0903-666-332',
        tag: ['66大順', '123門號', '免費門號'],
      },
      {
        label: '0903-245-203',
        value: '0903-245-203',
        tag: ['真愛520', '免費門號'],
      },
      {
        label: '0903-247-752',
        value: '0903-247-752',
        tag: ['Lucky77', '免費門號'],
      },
      {
        label: '0903-849-953',
        value: '0903-849-953',
        tag: ['幸福99', '免費門號'],
      },
      {
        label: '0903-668-833',
        value: '0903-668-833',
        tag: ['66大順', '發發88', '免費門號'],
      },
      {
        label: '0903-168-753',
        value: '0903-168-753',
        tag: ['一路發168', '免費門號'],
      },
      {
        label: '0903-666-334',
        value: '0903-666-334',
        tag: ['66大順', '免費門號'],
      },
    ],
  },
  {
    id: 'giveaway',
    slug: '好禮',
    title: '好禮送給你',
    name: 'gift',
    type: 'checkbox-card',
    description: '都送給你了，一定要帶走喔！至少需選兩項～',
    minLength: 2,
    maxLength: 2,
    options: [
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-12@2x.jpg',
        value: 'VANTEC Qi 紅色無線快速充電盤',
        meta: 'VANTEC Qi',
        name: 'VANTEC Qi 紅色無線快速充電盤',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-13@2x.jpg',
        value: '原廠 EarPods 耳機 - Lightning線',
        meta: 'APPLE',
        name: '原廠 EarPods 耳機 - Lightning線',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-14@2x.jpg',
        value: 'MYCELL AirPods Pro 粉沙收納盒',
        meta: 'MYCELL',
        name: 'MYCELL AirPods Pro 粉沙收納盒',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-15@2x.jpg',
        value: 'Samsung S20+原廠灰色透視感應皮套',
        meta: 'SAMSUNG',
        name: 'Samsung S20+原廠灰色透視感應皮套',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
    ],
  },
  {
    id: 'add-ons',
    slug: '加購',
    title: '加購商品及服務',
    type: 'tab-checkbox-card',
    description: '不買沒關係，買了絕對超值！',
    tabs: {
      name: 'checkbox-card-tab',
      list: [{ label: '加購商品' }, { label: '加值服務' }],
      content: [
        {
          name: 'extra_product',
          withNumberController: true,
          options: [
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
              value: 'PHILIPS 舒眠抗敏空氣清淨機',
              meta: 'PHILIPS',
              name: 'PHILIPS 舒眠抗敏空氣清淨機',
              productPrice: 5100,
              modal: {
                title: 'PHILIPS 舒眠抗敏空氣清淨機',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    'WPC國際 Qi 認證/ NCC 國家認證',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-10@2x.jpg',
              value: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
              meta: 'Iris Ohyama ',
              name: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
              productPrice: 1399,
              modal: {
                title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    'WPC國際 Qi 認證/ NCC 國家認證',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-11@2x.jpg',
              value: 'Panasonic 15公升蒸氣烘烤爐',
              meta: 'PANASONIC',
              name: 'Panasonic 15公升蒸氣烘烤爐',
              productPrice: 15000,
              modal: {
                title: 'Panasonic 15公升蒸氣烘烤爐',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    'WPC國際 Qi 認證/ NCC 國家認證',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-16@2x.jpg',
              value: 'Xiaomi 米家掃拖機器人',
              meta: 'XIAOMI',
              name: 'Xiaomi 米家掃拖機器人',
              productPrice: 6900,
              modal: {
                title: 'Xiaomi 米家掃拖機器人',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    'WPC國際 Qi 認證/ NCC 國家認證',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
              value: 'XGIMI MOGO 攜帶型投影機內建喇叭',
              meta: 'XGIMI',
              name: 'XGIMI MOGO 攜帶型投影機內建喇叭',
              productPrice: 6900,
              modal: {
                title: 'XGIMI MOGO 攜帶型投影機內建喇叭',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    'WPC國際 Qi 認證/ NCC 國家認證',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
          ],
        },
        {
          name: 'extra_plan',
          withNumberController: false,
          options: [
            {
              type: 'friday',
              value: 'friDay影音免費爽看30天',
              meta: '第一個月免費',
              name: 'friDay影音免費爽看30天',
              productPrice: 199,
              day: 30,
              unit: '天',
              modal: {
                title: 'friDay影音免費爽看30天',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    'WPC國際 Qi 認證/ NCC 國家認證',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'friday',
              value: '【超值限定】friDay影音爽看100天',
              meta: '好劇多到看不完',
              name: '【超值限定】friDay影音爽看100天',
              originPrice: 599,
              productPrice: 399,
              day: 100,
              unit: '天',
              modal: {
                title: '【超值限定】friDay影音爽看100天',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    'WPC國際 Qi 認證/ NCC 國家認證',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'music',
              value: 'friDay拍拍本隨手拍、隨手印',
              meta: '記錄你的珍貴時刻',
              name: 'friDay拍拍本隨手拍、隨手印',
              originPrice: 149,
              productPrice: 99,
              unit: '月',
              modal: {
                title: 'friDay拍拍本隨手拍、隨手印',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    'WPC國際 Qi 認證/ NCC 國家認證',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
            {
              type: 'music',
              value: 'friDay拍拍本隨手拍、隨手印 2',
              meta: '記錄你的珍貴時刻',
              name: 'friDay拍拍本隨手拍、隨手印 2',
              originPrice: 349,
              productPrice: 199,
              unit: '月',
              modal: {
                title: 'friDay拍拍本隨手拍、隨手印 2',
                content: [
                  '商品規格',
                  [
                    '3.5mm 超薄無線充電盤',
                    'WPC國際 Qi 認證/ NCC 國家認證',
                    '支持Qi 無線充電IOS & Android 手機',
                    'FOD自動智慧識別金屬異物',
                    '輸出 (Output)：5W/ 7.5W / 9W',
                  ],
                ],
              },
            },
          ],
        },
      ],
    },
  },
];
