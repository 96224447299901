import React from 'react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';

class ColorSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isInvalid: false,
      options: this.props.options,
      selected: { label: '', value: '' },
    };
  }

  componentDidMount() {
    this.props.setValue(this.props.value);
    this.setState({
      selected: this.setSelected(this.props.value),
    });
  }

  componentDidUpdate(nextProps) {
    if (this.props.getValue() !== this.state.selected.value) {
      this.props.setValue(this.props.value);
      this.setState({
        selected: this.setSelected(this.props.value),
      });
    }
    if (this.props.isFormSubmitted() !== this.state.submitted) {
      this.setState({
        submitted: this.props.isFormSubmitted(),
        isInvalid: this.props.showError(),
      });
    }
  }

  setSelected = (value) => {
    return this.props.options.filter((opt, i) => opt.value === value)[0];
  };

  handleChange = (event) => {
    this.setState({
      selected: this.setSelected(event.currentTarget.value),
    });
    this.props.setValue(event.currentTarget.value);
    this.props.onChange(this.props.name, event.currentTarget.value);
    this.setState({
      isInvalid: this.props.showError(),
    });
  };

  renderOptions = () =>
    this.props.options.map((option, i) => (
      <label className='fui-color-selector-item' key={`fui-color-selector-item-${i}`}>
        <input
          type='radio'
          value={option.value}
          checked={option.value === this.state.selected.value}
          name={this.props.name}
          onChange={this.handleChange}
        />
        <div
          className='color-item'
          style={{
            background: option.color,
          }}></div>
      </label>
    ));

  render() {
    return (
      <div className={`fui-color-selector-container ${this.props.className || ''}`}>
        <div className='body1'>選擇顏色：{this.state.selected.label}</div>
        <div className='fui-color-selector-items'>{this.renderOptions()}</div>
      </div>
    );
  }
}

ColorSelector.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
};

export default withFormsy(ColorSelector);
