export function throttle(fn, delay = 200, threshold = 600) {
  let timer = null;
  let last = null;
  return function (...args) {
    const context = this;
    const now = +Date.now();
    if (!last) {
      last = now;
    }
    if (now - last < threshold) {
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
        last = now;
        fn.apply(context, args);
      }, delay);
    } else {
      last = now;
      timer && clearTimeout(timer);
      fn.apply(context, args);
    }
  };
}

export function getTargetRect(target) {
  return target !== window
    ? target.getBoundingClientRect()
    : document.documentElement.getBoundingClientRect() || document.body.getBoundingClientRect();
}

// export function scrollTo(target, scrollTop) {
//   if (target === window) {
//     document.body.scrollTop = scrollTop;
//     document.documentElement.scrollTop = scrollTop;
//   } else {
//     target.scrollTop = scrollTop;
//   }
// }
export const getScrollPage = () => {
  let docScrollTop = 0;
  if (document.documentElement && document.documentElement !== null) {
    docScrollTop = document.documentElement.scrollTop;
  }
  return window.pageYOffset || docScrollTop;
};

/**
 * get the scroll of a parent element
 * @return {number}
 */
export const getScrollElement = (elementId) => {
  let elemScrollTop = 0;
  const element = document.getElementById(elementId);
  if (element !== null) {
    elemScrollTop = element.scrollTop;
  }
  return elemScrollTop;
};

/**
 * scroll to y number of a page
 * @param {number} scrollnumber
 * @return {void}
 */
export const scrollTo = (scrollnumber) => {
  window.requestAnimationFrame(() => {
    window.scrollTo(0, scrollnumber);
  });
};
/**
 * scroll to y number inside an element
 * @param {number} scrollnumber
 * @return {void}
 */
export const scrollToElement = (scrollnumber, elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollTop = scrollnumber;
  }
};

/**
 * verif if window exist
 * @return boolean
 */
export const isBrowser = () => typeof window !== 'undefined';

export const setMobile = (obj) => {
  obj.setState({
    isMobile: window.innerWidth < 768,
  });
};
