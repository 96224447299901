import React from 'react';
import Collapse from '../../collapse/Collapse';

import PropTypes from 'prop-types';

class SectionCollapseInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  render() {
    return (
      <section className='fui-section-collapse'>
        <div className='fui-container'>
          <Collapse title={this.props.title} content={this.props.content} />
        </div>
      </section>
    );
  }
}

SectionCollapseInfo.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export default SectionCollapseInfo;
