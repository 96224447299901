import React from 'react';
import { setCartData } from '../../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import Formsy from 'formsy-react';
import Slider from 'react-slick';

import NavTab from '../../components/partials/NavTab';
import Breadcrumb from '../../components/Breadcrumb';
import Link from '../../components/Link';
import Button from '../../components/Button';
import Cart from '../../components/Cart';

import ColorSelector from '../../components/form/ColorSelector';
import RadioButtons from '../../components/form/RadioButtons';
import NumberSelector from '../../components/form/NumberSelector';
import LabelInput from '../../components/form/LabelInput';

import EstoreModal from '../../components/EstoreModal';
import * as Validation from '../../utils/validation';
import formatNumber from '../../utils/numberFormatter';

import axios from 'axios';
import * as Mock from '../../mock/Accessory';

class Accessory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      cartUpdate: false,
      currentModal: {
        type: '',
        title: '',
        content: '',
      },
      hasCart: false,
      productInfo: { ...Mock.Product },
      currentNav: 0,
      currentImage: [],
      sldierSetting: {
        dots: true,
        infinite: false,
        arrows: true,
        slidesToShow: 1,
        draggable: true,
        adaptiveHeight: true,
      },
      installmentModal: {
        type: 'notice',
        title: '信用卡分期付款',
        content: [
          '分期資訊',
          '結帳金額 $30,300',
          {
            '3期0利率': '$10,000',
            '5期0利率': '$6,000',
            '6期0利率': '$5,000',
            '12期0利率': '$2,520',
          },
          [
            '手機分期付款僅提供宅配取貨，並使用線上刷卡供銷費者使用。',
            '分期價除不盡餘額部分，將於第一期帳單收取。',
            '各產品可供分期期數不盡相同，依結帳時系統提供分期數為主；如一張訂單有多項商品，只會出現共同皆可使用之分期數。',
          ],
        ],
      },
      welfareModal: {
        type: 'notice',
        title: '備註',
        content: `
            本產品為拆封九成五新或原廠整新非全新品，機身可能有輕微細紋，不影響功能。外盒可能有些許磨損或使用痕跡，規格及盒內配件以實際出貨為主，請不介意之消費者再進行購買。<br/><br/>
            請您耐心等候，為保障您退換貨權益，請於安裝SIM卡前先行檢查商品外觀及隨盒配件是否完整。同一筆訂單中的商品會一起出貨，故可能因為訂單中加購的商品缺貨而導致訂單延遲出貨，下單前請務必再次確認商品型號/顏色/資費方案及加購商品是否正確。
          `,
      },
      // 使用既有的表單組件，再根據表單的值修改帶入購物車的內容
      form: {
        color: { value: Mock.Product.colors[0].value, required: true },
        scid: { value: '', required: true },
        number: { value: 1, required: true },
        phone_number: { value: '', required: true },
      },
      cart: {
        list: [],
      },
      step: 0,
      editStep: -1,
    };
  }

  componentDidMount = () => {
    let filter = this.props.cart.list.filter((ca) => ca.name === this.state.productInfo.name);

    this.setState({
      hasCart: filter.length > 0,
      currentImage: !this.state.productInfo.images
        ? []
        : Array.isArray(this.state.productInfo.images)
        ? this.state.productInfo.images
        : this.state.productInfo.images[this.state.form.color.value],
    });
  };

  CheckboxTabChange = (idx) => {
    this.setState({
      cuurentCheckboxTab: idx,
    });
  };

  changeNavTab = (index) => {
    this.setState({
      currentNav: index,
    });
  };

  inputChange = (n, v, content) => {
    let form = Object.assign(this.state.form);

    // 如果已進行申辦流程，切換申辦方式時跳出 modal
    if (n === 'apply_method' && this.state.editStep !== -1) {
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'alert',
          originSelect: form[n].value,
          originFlow: this.state.applyFlow,
          title: '組合方案選項將被清除',
          content: '提醒你，剛才你選擇的組合方案將被清除，請再次確認是否要重新選擇？',
        },
      });
    }

    form[n].value = v;

    // 切換顏色，圖片跟著切換內容
    if (n === 'color') {
      this.setState({
        currentImage: this.state.productInfo.images[v],
      });
    }

    let list = Object.assign(this.state.cart.list);

    if (n === 'number') {
      if (list.length) {
        let productIndex = -1;

        list.forEach((item, i) => {
          if (item.name === this.state.productInfo.name) productIndex = i;
        });

        list[productIndex].number = v;
      } else {
        list.push({
          ...this.state.productInfo,
          number: v,
        });
      }
    }
    // 將完整資料帶入購物車

    this.setState({
      form: form,
      cart: {
        list: list,
      },
      cartUpdate: true,
    });

    this.forceUpdate();
  };

  openModal = (modal) => {
    this.setState({
      modalOpen: true,
      currentModal: modal,
    });
  };

  // 取消切換申辦方式，還原為既有狀態
  canscelSelect = () => {
    let form = Object.assign(this.state.form);
    let cart = { ...this.state.cart };

    form.apply_method.value = this.state.currentModal.originSelect;
    cart.apply_method = this.state.currentModal.originSelect;

    this.setState({
      modalOpen: false,
      applyFlow: this.state.currentModal.originFlow,
      cart: { ...cart, apply_method: this.state.currentModal.originSelect },
      form: {
        ...form,
        apply_method: { value: this.state.currentModal.originSelect },
      },
    });
  };

  resetFormset = () => {
    // 重置 cart 與 form
    this.setState({
      cart: {
        list: [],
      },
      form: {
        ...this.state.form,
        number: { value: 1, required: true },
        plan: { value: '', required: true },
        phone_number: { value: '', required: true },
        gift: { value: '', required: true },
        extra_product: { value: '', required: true },
        extra_plan: { value: '', required: true },
      },
      editStep: -1,
      step: 0,
      modalOpen: false,
    });
  };

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  setProductModal = (type) => {
    if (type === 'delivery') {
      this.setState({
        currentModal: {
          type: 'deliveryForm',
          title: '到貨請通知我',
        },
        modalOpen: true,
      });
    }

    if (type === 'reservation') {
      this.setState({
        currentModal: {
          type: 'reservationForm',
          title: '預約單取貨',
        },
        modalOpen: true,
      });
    }
  };

  modalFormChange = (form, name, value) => {
    let newForm = Object.assign(this.state[form]);
    newForm[name].value = value;

    this.setState({
      [form]: newForm,
    });
  };

  addToCart = () => {
    // debugger;
    let list = Object.assign(this.state.cart.list);
    let productIndex = -1;

    if (list.length)
      list.forEach((item, i) => {
        if (item.name === this.state.productInfo.name) productIndex = i;
      });

    if (productIndex > -1 && list.length && list[productIndex].number < this.state.productInfo.storage) {
      list[productIndex].number = list[productIndex].number + 1;

      this.setState({
        form: {
          ...this.state.form,
          number: { value: list[productIndex].number },
        },
        cart: {
          list: list,
        },
        cartUpdate: true,
      });

      this.props.setCartData(list);
      this.forceUpdate();
    }

    if (productIndex === -1) {
      let newList = [
        ...list,
        {
          ...this.state.productInfo,
          color: this.state.form.color.value,
          number: this.state.form.number.value,
        },
      ];
      this.setState({
        cart: { list: newList },
      });
      this.props.setCartData(newList);
    }
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalCancel = (type) => {
    // 預約取貨送出
    console.info('MODAL CANCEL', type);
    this.setState({
      modalOpen: false,
    });
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { form, productInfo, currentNav } = this.state;

    return (
      <main>
        <NavTab pageTitle={productInfo.name} />
        <Breadcrumb
          color={currentNav === 0 ? 'block' : 'white'}
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '網路門市', link: '/estore' },
            { text: '手機', link: '/estore/product' },
            { text: productInfo.name, link: `/estore/product/detail/${productInfo.slug}` },
          ]}
        />
        <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
          <section className='fui-product-section'>
            <div className='fui-container'>
              {/* 產品資訊 */}
              <div className='fui-panel'>
                <div className='fui-replenishment-ribbon'>
                  <span className='text'>補貨中</span>
                </div>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    {/* 產品圖，若產品數量不足，增加樣式 is-replenishment */}
                    <Slider {...this.state.sldierSetting} className='fui-product-slider is-replenishment'>
                      {this.state.currentImage.length ? (
                        this.state.currentImage.map((img, i) => (
                          <img src={img} alt='' key={`fui-product-slider-${i}`} />
                        ))
                      ) : (
                        <img src={process.env.PUBLIC_URL + '/resources/common/images/no-image@2x.png'} alt='' />
                      )}
                    </Slider>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    {/* 產品內容 */}
                    <h2>
                      {productInfo.tag
                        ? productInfo.tag.map((tag, i) => (
                            <span className='fui-tag' key={`product-tag-${i}`}>
                              {tag}
                            </span>
                          ))
                        : null}
                      {productInfo.name}
                    </h2>
                    <ColorSelector
                      required={true}
                      name='color'
                      options={productInfo.colors}
                      value={form.color.value}
                      onChange={this.inputChange}
                    />

                    <div className='price-section'>
                      <div>商品價</div>
                      <h4>
                        <del>${formatNumber(productInfo.originPrice, 0)}</del>
                        <span>${formatNumber(productInfo.salePrice, 0)}</span>
                      </h4>
                      <div className='mb-2'>
                        <NumberSelector
                          name='number'
                          value={form.number.value}
                          min={1}
                          max={3}
                          onChange={(n, v, c) => this.inputChange(n, v, c)}
                        />
                      </div>
                      <div className='fui-product-action'>
                        <Button btnStyle='primary' size='large'>
                          立即結帳
                        </Button>
                        <Button
                          btnStyle='secondary'
                          size='large'
                          disabled={this.state.hasCart}
                          onClick={(e) => this.addToCart(e)}>
                          加入購物車
                        </Button>
                      </div>
                    </div>

                    {productInfo.discount ? (
                      <div className='fui-discount-bar'>
                        <span className='discount-ribbon'>促銷優惠</span>
                        <Link
                          to={productInfo.discount.link}
                          target={productInfo.discount.target}
                          className='fui-discount-link'>
                          <span className='content'>{productInfo.discount.text}</span>
                          <span>
                            <i className='icon-chevron-right'></i>
                          </span>
                        </Link>
                      </div>
                    ) : null}

                    <div className='fui-notice'>
                      <div className='fui-item'>
                        分期0利率，每期最低 ${this.state.installmentCount}
                        <div
                          role='button'
                          onClick={(e) => this.openModal(this.state.installmentModal)}
                          className='more'>
                          詳情
                        </div>
                      </div>
                      <div className='fui-item'>
                        <span dangerouslySetInnerHTML={{ __html: Mock.productNotice.text }}></span>
                        <div role='button' onClick={(e) => this.openModal(this.state.welfareModal)} className='more'>
                          詳情
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className='fui-panel  is-spec-panel'>
                {Mock.Spec.map((sp, i) => (
                  <div key={`spect-panel-${i}`}>
                    {typeof sp.value[0] === 'object' ? (
                      <div className='spec-group'>
                        <h2 dangerouslySetInnerHTML={{ __html: sp.key }}></h2>
                        {sp.value.map((sp_item, k) => (
                          <div className='spec-item' key={`spec-panel-${i}-item-${k}`}>
                            <div className='spec-title'>{sp_item.key}</div>
                            <div className='spec-content' dangerouslySetInnerHTML={{ __html: sp_item.value }}></div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className='spec-group'>
                        <h2 dangerouslySetInnerHTML={{ __html: sp.key }}></h2>
                        <div className='spec-content mb-5'>
                          {sp.value.map((sp_item, k) => (
                            <div className='fui-item' key={`spec-panel-${i}-item-${k}`}>
                              <img
                                src={process.env.PUBLIC_URL + '/resources/cbu/estore-product/red-check.svg'}
                                width='30'
                                alt='check'
                              />
                              <span className='text' dangerouslySetInnerHTML={{ __html: sp_item }}></span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </section>
        </Formsy>

        <Cart update={this.state.cartUpdate} afterUpdate={(e) => this.setState({ cartUpdate: false })} />

        <EstoreModal
          {...this.state.currentModal}
          open={this.state.modalOpen}
          onSubmit={this.modalSubmit}
          onClose={this.modalClose}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCartData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Accessory);
