export const planStep = [
  {
    id: 'apply',
    slug: '申辦',
    name: 'apply_method',
    title: '申辦方式',
    description: ['選個好門號，保值一輩子', '我想保留目前的手機號碼，轉換到遠傳', '我有遠傳門號，想跟遠傳再續前緣'],
    type: 'radio',
    options: [
      { label: '申辦新門號', value: '申辦新門號' },
      { label: '攜碼', value: '攜碼' },
    ],
  },
];

export const themeNumberFlow = [
  {
    id: 'new-number',
    slug: '門號',
    title: '選擇門號',
    type: 'radio-carousel',
    name: 'phone_number',
    description: '選一個好門號給你好運氣',
    searchLabel: '主題號碼',
    tags: ['免費門號', '黃金門號', '一路發168', '66大順', '幸福99', 'Lucky77', '真愛520', '發發88', '123門號'],
    options: [
      {
        label: '0903-063482',
        value: '0903-063482',
        description: '選號費將於第一期帳單進行扣抵',
        price: 1000,
        tag: ['黃金門號'],
      },
      {
        label: '0903-652344',
        value: '0903-652344',
        price: 500,
        tag: ['黃金門號'],
      },
      {
        label: '0903-849753',
        value: '0903-849753',
        tag: ['免費門號'],
      },
      {
        label: '0903-666333',
        value: '0903-666333',
        tag: ['66大順', '免費門號'],
      },
      {
        label: '0903-849123',
        value: '0903-849123',
        tag: ['免費門號', '123門號'],
      },
      {
        label: '0903-666332',
        value: '0903-666332',
        tag: ['66大順', '123門號', '免費門號'],
      },
      {
        label: '0903-245203',
        value: '0903-245203',
        tag: ['真愛520', '免費門號'],
      },
      {
        label: '0903-247752',
        value: '0903-247752',
        tag: ['Lucky77', '免費門號'],
      },
      {
        label: '0903-849953',
        value: '0903-849953',
        tag: ['幸福99', '免費門號'],
      },
      {
        label: '0903-668833',
        value: '0903-668833',
        tag: ['66大順', '發發88', '免費門號'],
      },
      {
        label: '0903-168753',
        value: '0903-168753',
        tag: ['一路發168', '免費門號'],
      },
      {
        label: '0903-666334',
        value: '0903-666334',
        tag: ['66大順', '免費門號'],
      },
    ],
  },
  {
    id: 'plan-select',
    slug: '資費',
    title: '資費方案',
    name: 'plan',
    description: '吃到飽吃不飽這裡都有',
    type: 'radio-card',
    tags: {
      name: '資費分類',
      list: [
        '全部',
        '5G方案',
        '4.5G方案',
        '吃到飽',
        '月付$599以下',
        '月付$600-$999',
        '月付$1,000以上',
        '月付$1,399',
        '輕上網',
      ],
    },
    options: [
      {
        ribbon: '網路限定方案再送禮券',
        value: '月付$1,399x12個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 1399,
        prepay: 0,
        month: '12',
        note: '網路門市限定',
        list: [
          { title: '商品專案價', originPrice: 12000, projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
          { title: '活動優惠', text: `$500遠百禮券 + $500購物金` },
        ],
        tag: ['5G方案', '吃到飽', '月付$1,000以上', '月付$1,399'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
      {
        value: '月付$899x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 899,
        prepay: 6000,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
          { title: '活動優惠', text: `$500遠百禮券 + $500購物金` },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
      {
        value: '月付$599x24個月',
        unit: '月付',
        price: 599,
        prepay: 6000,
        month: '24',
        list: [
          { title: '商品專案價', projectPrice: 10000 },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
        ],
        tag: ['月付$599以下', '輕上網'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
      {
        value: '月付$499x24個月',
        unit: '月付',
        price: 499,
        month: '24',
        list: [
          { title: '商品專案價', text: `$10,000` },
          { title: '上網傳輸量', text: `合約內不限速吃到飽` },
          { title: '語音優惠', text: `市話 + 網外送120分鐘` },
        ],
        tag: ['月付$599以下', '輕上網'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              商品專案價: '$10,000',
              預繳金: '$1,000',
              上網傳輸量: '合約內不限速吃到飽',
              網內通話: '每通前5分鐘免費',
              網外通話: '60分鐘免費',
              語音優惠: '市話 + 網外送120分鐘',
              活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
            },
          ],
        },
      },
    ],
  },
];
export const renewFlow = [
  {
    id: 'rn',
    slug: '驗證',
    title: '續約身份驗證',
    name: 'identity_validation',
    type: 'identity-validation',
    description: '為確保個人資訊安全，請輸入欲續約的手機門號，遠傳電信將為你驗證身份',
    isPass: false,
  },
  {
    id: 'plan-select',
    slug: '資費',
    title: '資費方案',
    name: 'plan',
    description: '吃到飽吃不飽這裡都有',
    type: 'radio-card',
    tags: {
      name: '資費分類',
      list: [
        '全部',
        '5G方案',
        '4.5G方案',
        '吃到飽',
        '月付$599以下',
        '月付$600-$999',
        '月付$1,000以上',
        '月付$1,399',
        '輕上網',
      ],
    },
    options: [
      {
        ribbon: '網路限定方案再送禮券',
        value: '月付$1,399x12個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 1399,
        originPrice: 1499,
        prepay: 0,
        month: '12',
        note: '網路門市限定',
        list: [
          { title: '上網傳輸量', text: '5G飆網24GB', tooltip: '網速級距 500Mbps' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
            tooltip: '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)',
          },
          { title: '網內通話', text: '前5分鐘免費/通' },
          { title: '網外通話', text: '送60分鐘/月' },
          { title: '市話通話', text: `$1.8/分鐘` },
          { title: '活動優惠', text: `辦就送7-11 抵用券 $250，加碼送$1,000 易遊網旅遊票券`, color: 'red' },
        ],
        tag: ['5G方案', '吃到飽', '月付$1,000以上', '月付$1,399'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        value: '月付$999x24個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 999,
        prepay: 6000,
        month: '24',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: '上網吃到飽',
              量到限速: '不限速',
              網內通話: '前5分鐘免費/通',
              網外通話: '送30鐘/月',
              市話通話: '1.8/分鐘',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: { text: '須持學生證或教職證', color: 'blue' },
        value: '月付$799x24個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 799,
        prepay: 6000,
        month: '24',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: '上網吃到飽',
              量到限速: '不限速',
              網內通話: '前5分鐘免費/通',
              網外通話: '送30鐘/月',
              市話通話: '1.8/分鐘',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              活動優惠: '電信帳單回饋遠傳幣點數，1點可折1元',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: '網路限定方案再送禮券',
        value: '月付$699x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 699,
        originPrice: 1499,
        prepay: 6000,
        month: '24',
        note: '網路門市限定',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: '5G飆網24GB', tooltip: '網速級距 500Mbps' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
            tooltip: '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `辦就送7-11 抵用券 $250，加碼送$1,000 易遊網旅遊票券`,
            color: 'red',
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: '網路限定方案再送禮券',
        value: '月付$999x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 999,
        // originPrice: 1499,
        prepay: 6000,
        month: '24',
        // note: '網路門市限定',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: '5G飆網24GB' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        // ribbon: '網路限定方案再送禮券',
        value: '月付$499x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 499,
        // originPrice: 1499,
        prepay: 6000,
        month: '24',
        // note: '網路門市限定',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: '5G飆網24GB' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: { text: '須持學生證或教職證', color: 'blue' },
        value: '月付$399x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 399,
        // originPrice: 1499,
        prepay: 6000,
        month: '24',
        // note: '網路門市限定',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: '5G飆網24GB' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
    ],
  },
  {
    id: 'giveaway',
    slug: '好禮',
    title: '好禮送給你',
    name: 'gift',
    type: 'checkbox-card',
    description: '都送給你了，一定要帶走喔！至少需選兩項～',
    minNumber: 2,
    options: [
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-12@2x.jpg',
        value: 'VANTEC Qi 紅色無線快速充電盤',
        meta: 'VANTEC Qi',
        name: 'VANTEC Qi 紅色無線快速充電盤',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: `
            <p>商品規格</p>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>3.5mm 超薄無線充電盤</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>WPC國際 Qi 認證/ NCC 國家認證</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>支持Qi 無線充電IOS & Android 手機</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>FOD自動智慧識別金屬異物</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>輸出 (Output)：5W/ 7.5W / 9W</span>
            </div>
          `,
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-13@2x.jpg',
        value: '原廠 EarPods 耳機 - Lightning線',
        meta: 'APPLE',
        name: '原廠 EarPods 耳機 - Lightning線',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: `
            <p>商品規格</p>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>3.5mm 超薄無線充電盤</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>WPC國際 Qi 認證/ NCC 國家認證</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>支持Qi 無線充電IOS & Android 手機</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>FOD自動智慧識別金屬異物</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>輸出 (Output)：5W/ 7.5W / 9W</span>
            </div>
          `,
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-14@2x.jpg',
        value: 'MYCELL AirPods Pro 粉沙收納盒',
        meta: 'MYCELL',
        name: 'MYCELL AirPods Pro 粉沙收納盒',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: `
            <p>商品規格</p>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>3.5mm 超薄無線充電盤</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>WPC國際 Qi 認證/ NCC 國家認證</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>支持Qi 無線充電IOS & Android 手機</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>FOD自動智慧識別金屬異物</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>輸出 (Output)：5W/ 7.5W / 9W</span>
            </div>
          `,
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-15@2x.jpg',
        value: 'Samsung S20+原廠灰色透視感應皮套',
        meta: 'SAMSUNG',
        name: 'Samsung S20+原廠灰色透視感應皮套',
        originPrice: 2400,
        productPrice: 0,
        modal: {
          title: 'VANTEC Qi 紅色無線快速充電盤',
          content: `
            <p>商品規格</p>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>3.5mm 超薄無線充電盤</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>WPC國際 Qi 認證/ NCC 國家認證</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>支持Qi 無線充電IOS & Android 手機</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>FOD自動智慧識別金屬異物</span>
            </div>
            <div class='fui-item'>
              <span class='prefix'><i class='bulleted-dot'></i></span>
              <span class='content'>輸出 (Output)：5W/ 7.5W / 9W</span>
            </div>
          `,
        },
      },
    ],
  },
  {
    id: 'add-ons',
    slug: '加購',
    title: '加購商品及服務',
    type: 'tab-checkbox-card',
    description: '不買沒關係，買了絕對超值！',
    tabs: {
      name: 'checkbox-card-tab',
      list: [{ label: '加購商品' }, { label: '加值服務' }],
      content: [
        {
          name: 'extra_product',
          withNumberController: true,
          options: [
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
              value: 'PHILIPS 舒眠抗敏空氣清淨機',
              meta: 'PHILIPS',
              name: 'PHILIPS 舒眠抗敏空氣清淨機',
              productPrice: 5100,
              modal: {
                title: 'PHILIPS 舒眠抗敏空氣清淨機',
                content: `
                  <p>商品規格</p>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>3.5mm 超薄無線充電盤</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>WPC國際 Qi 認證/ NCC 國家認證</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>支持Qi 無線充電IOS & Android 手機</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>FOD自動智慧識別金屬異物</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>輸出 (Output)：5W/ 7.5W / 9W</span>
                  </div>
                `,
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-10@2x.jpg',
              value: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
              meta: 'Iris Ohyama ',
              name: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
              productPrice: 1399,
              modal: {
                title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
                content: `
                  <p>商品規格</p>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>3.5mm 超薄無線充電盤</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>WPC國際 Qi 認證/ NCC 國家認證</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>支持Qi 無線充電IOS & Android 手機</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>FOD自動智慧識別金屬異物</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>輸出 (Output)：5W/ 7.5W / 9W</span>
                  </div>
                `,
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-11@2x.jpg',
              value: 'Panasonic 15公升蒸氣烘烤爐',
              meta: 'PANASONIC',
              name: 'Panasonic 15公升蒸氣烘烤爐',
              productPrice: 15000,
              modal: {
                title: 'Panasonic 15公升蒸氣烘烤爐',
                content: `
                  <p>商品規格</p>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>3.5mm 超薄無線充電盤</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>WPC國際 Qi 認證/ NCC 國家認證</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>支持Qi 無線充電IOS & Android 手機</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>FOD自動智慧識別金屬異物</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>輸出 (Output)：5W/ 7.5W / 9W</span>
                  </div>
                `,
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-16@2x.jpg',
              value: 'Xiaomi 米家掃拖機器人',
              meta: 'XIAOMI',
              name: 'Xiaomi 米家掃拖機器人',
              productPrice: 6900,
              modal: {
                title: 'Xiaomi 米家掃拖機器人',
                content: `
                  <p>商品規格</p>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>3.5mm 超薄無線充電盤</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>WPC國際 Qi 認證/ NCC 國家認證</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>支持Qi 無線充電IOS & Android 手機</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>FOD自動智慧識別金屬異物</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>輸出 (Output)：5W/ 7.5W / 9W</span>
                  </div>
                `,
              },
            },
            {
              type: 'product',
              image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
              value: 'XGIMI MOGO 攜帶型投影機內建喇叭',
              meta: 'XGIMI',
              name: 'XGIMI MOGO 攜帶型投影機內建喇叭',
              productPrice: 6900,
              modal: {
                title: 'XGIMI MOGO 攜帶型投影機內建喇叭',
                content: `
                  <p>商品規格</p>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>3.5mm 超薄無線充電盤</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>WPC國際 Qi 認證/ NCC 國家認證</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>支持Qi 無線充電IOS & Android 手機</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>FOD自動智慧識別金屬異物</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>輸出 (Output)：5W/ 7.5W / 9W</span>
                  </div>
                `,
              },
            },
          ],
        },
        {
          name: 'extra_plan',
          withNumberController: false,
          options: [
            {
              type: 'friday',
              value: 'friDay影音免費爽看30天',
              meta: '第一個月免費',
              name: 'friDay影音免費爽看30天',
              productPrice: 199,
              day: 30,
              unit: '天',
              modal: {
                title: 'friDay影音免費爽看30天',
                content: `
                  <p>商品規格</p>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>3.5mm 超薄無線充電盤</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>WPC國際 Qi 認證/ NCC 國家認證</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>支持Qi 無線充電IOS & Android 手機</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>FOD自動智慧識別金屬異物</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>輸出 (Output)：5W/ 7.5W / 9W</span>
                  </div>
                `,
              },
            },
            {
              type: 'friday',
              value: '【超值限定】friDay影音爽看100天',
              meta: '好劇多到看不完',
              name: '【超值限定】friDay影音爽看100天',
              originPrice: 599,
              productPrice: 399,
              day: 100,
              unit: '天',
              modal: {
                title: '【超值限定】friDay影音爽看100天',
                content: `
                  <p>商品規格</p>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>3.5mm 超薄無線充電盤</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>WPC國際 Qi 認證/ NCC 國家認證</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>支持Qi 無線充電IOS & Android 手機</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>FOD自動智慧識別金屬異物</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>輸出 (Output)：5W/ 7.5W / 9W</span>
                  </div>
                `,
              },
            },
            {
              type: 'music',
              value: 'friDay拍拍本隨手拍、隨手印',
              meta: '記錄你的珍貴時刻',
              name: 'friDay拍拍本隨手拍、隨手印',
              originPrice: 149,
              productPrice: 99,
              unit: '月',
              modal: {
                title: 'friDay拍拍本隨手拍、隨手印',
                content: `
                  <p>商品規格</p>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>3.5mm 超薄無線充電盤</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>WPC國際 Qi 認證/ NCC 國家認證</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>支持Qi 無線充電IOS & Android 手機</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>FOD自動智慧識別金屬異物</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>輸出 (Output)：5W/ 7.5W / 9W</span>
                  </div>
                `,
              },
            },
            {
              type: 'music',
              value: 'friDay拍拍本隨手拍、隨手印 2',
              meta: '記錄你的珍貴時刻',
              name: 'friDay拍拍本隨手拍、隨手印 2',
              originPrice: 349,
              productPrice: 199,
              unit: '月',
              modal: {
                title: 'friDay拍拍本隨手拍、隨手印 2',
                content: `
                  <p>商品規格</p>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>3.5mm 超薄無線充電盤</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>WPC國際 Qi 認證/ NCC 國家認證</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>支持Qi 無線充電IOS & Android 手機</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>FOD自動智慧識別金屬異物</span>
                  </div>
                  <div class='fui-item'>
                    <span class='prefix'><i class='bulleted-dot'></i></span>
                    <span class='content'>輸出 (Output)：5W/ 7.5W / 9W</span>
                  </div>
                `,
              },
            },
          ],
        },
      ],
    },
  },
];

export const carryFlow = [
  {
    id: 'plan-select',
    slug: '資費',
    title: '資費方案',
    name: 'plan',
    description: '吃到飽吃不飽這裡都有',
    type: 'radio-card',
    tags: {
      name: '資費分類',
      list: [
        '全部',
        '5G方案',
        '4.5G方案',
        '吃到飽',
        '月付$599以下',
        '月付$600-$999',
        '月付$1,000以上',
        '月付$1,399',
        '輕上網',
      ],
    },
    options: [
      {
        ribbon: '網路限定方案再送禮券',
        value: '月付$1,399x12個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 1399,
        originPrice: 1499,
        prepay: 0,
        month: '12',
        note: '網路門市限定',
        list: [
          { title: '上網傳輸量', text: '5G飆網24GB', tooltip: '網速級距 500Mbps' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
            tooltip: '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)',
          },
          { title: '網內通話', text: '前5分鐘免費/通' },
          { title: '網外通話', text: '送60分鐘/月' },
          { title: '市話通話', text: `$1.8/分鐘` },
          { title: '活動優惠', text: `辦就送7-11 抵用券 $250，加碼送$1,000 易遊網旅遊票券`, color: 'red' },
        ],
        tag: ['5G方案', '吃到飽', '月付$1,000以上', '月付$1,399'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        value: '月付$999x24個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 999,
        prepay: 6000,
        month: '24',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: '上網吃到飽',
              量到限速: '不限速',
              網內通話: '前5分鐘免費/通',
              網外通話: '送30鐘/月',
              市話通話: '1.8/分鐘',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: { text: '須持學生證或教職證', color: 'blue' },
        value: '月付$799x24個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 799,
        prepay: 6000,
        month: '24',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: '上網吃到飽',
              量到限速: '不限速',
              網內通話: '前5分鐘免費/通',
              網外通話: '送30鐘/月',
              市話通話: '1.8/分鐘',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              活動優惠: '電信帳單回饋遠傳幣點數，1點可折1元',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: '網路限定方案再送禮券',
        value: '月付$699x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 699,
        originPrice: 1499,
        prepay: 6000,
        month: '24',
        note: '網路門市限定',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: '5G飆網24GB', tooltip: '網速級距 500Mbps' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
            tooltip: '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `辦就送7-11 抵用券 $250，加碼送$1,000 易遊網旅遊票券`,
            color: 'red',
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: '網路限定方案再送禮券',
        value: '月付$999x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 999,
        // originPrice: 1499,
        prepay: 6000,
        month: '24',
        // note: '網路門市限定',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: '5G飆網24GB' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        // ribbon: '網路限定方案再送禮券',
        value: '月付$499x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 499,
        // originPrice: 1499,
        prepay: 6000,
        month: '24',
        // note: '網路門市限定',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: '5G飆網24GB' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: { text: '須持學生證或教職證', color: 'blue' },
        value: '月付$399x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 399,
        // originPrice: 1499,
        prepay: 6000,
        month: '24',
        // note: '網路門市限定',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: '5G飆網24GB' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
    ],
  },
  {
    id: 'np',
    slug: '門號',
    title: '攜入門號確認',
    type: 'input-validation',
    name: 'input_validation',
    description: '攜碼到遠傳請先注意...',
    tabs: {
      name: 'contract-card-tab',
      list: [{ label: '合約未到期' }, { label: '合約已到期' }],
      content: [
        [
          {
            no: 1,
            title: '確認合約到期日',
            description:
              '方法 1 : 使用原業者的網站或APP查詢<br/>方法 2 : 手機直撥給原業者<br/>方法 3 : 前往原業者門市查詢',
          },
          {
            no: 2,
            title: '備好雙證件',
            description: '準備身分證及第二證件 (健保卡、駕照) 進行身分驗證。',
          },
          {
            no: 3,
            title: '指定門號開通日，<br class="d-none d-md-block" />訊號不中斷',
            description: '可預約門號在合約到期日的隔天開通，不再擔心違約金，或會有網路訊號空窗期！',
          },
          {
            no: 4,
            title: '更換SIM卡，<br class="d-none d-md-block" />開啟遠傳心生活',
            description: '新的SIM卡會在開通前送達，更換SIM卡後，開始享有遠傳飆速網路。',
          },
        ],
        [
          {
            no: 1,
            title: '確認合約已到期',
            description: '合約已到期，且該門號沒有欠費、停話或中止狀態，立即申辦享有最新方案優惠！',
          },
          {
            no: 2,
            title: '備好雙證件',
            description: '準備身分證及第二證件 (健保卡、駕照) 進行身分驗證。',
          },
          {
            no: 3,
            title: '更換SIM卡，<br class="d-none d-md-block" />開啟遠傳心生活!',
            description: '新的SIM卡會在開通前送達，更換SIM卡後，開始享有遠傳飆速網路。',
          },
        ],
      ],
    },
    label: '輸入攜入門號',
    placeholder: '輸入非遠傳號碼',
    note: `This site is protected by reCAPTCHA and the Google <a href='#'>Privacy Policy</a> and <a href='#'>Terms of Service</a> apply.`,
  },
];

export const newFlow = [
  {
    id: 'plan-select',
    slug: '資費',
    title: '資費方案',
    name: 'plan',
    description: '吃到飽吃不飽這裡都有',
    type: 'radio-card',
    tags: {
      name: '資費分類',
      list: [
        '全部',
        '5G方案',
        '4.5G方案',
        '吃到飽',
        '月付$599以下',
        '月付$600-$999',
        '月付$1,000以上',
        '月付$1,399',
        '輕上網',
      ],
    },
    options: [
      {
        ribbon: '網路限定方案再送禮券',
        value: '月付$1,399x12個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 1399,
        originPrice: 1499,
        prepay: 0,
        month: '12',
        note: '網路門市限定',
        list: [
          { title: '上網傳輸量', text: '5G飆網24GB', tooltip: '網速級距 500Mbps' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
            tooltip: '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)',
          },
          { title: '網內通話', text: '前5分鐘免費/通' },
          { title: '網外通話', text: '送60分鐘/月' },
          { title: '市話通話', text: `$1.8/分鐘` },
          { title: '活動優惠', text: `辦就送7-11 抵用券 $250，加碼送$1,000 易遊網旅遊票券`, color: 'red' },
        ],
        tag: ['5G方案', '吃到飽', '月付$1,000以上', '月付$1,399'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        value: '月付$999x24個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 999,
        prepay: 6000,
        month: '24',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: '上網吃到飽',
              量到限速: '不限速',
              網內通話: '前5分鐘免費/通',
              網外通話: '送30鐘/月',
              市話通話: '1.8/分鐘',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: { text: '須持學生證或教職證', color: 'blue' },
        value: '月付$799x24個月',
        sticker: '/resources/cbu/estore/5-g.png',
        unit: '月付',
        price: 799,
        prepay: 6000,
        month: '24',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: `上網吃到飽` },
          { title: '量到限速', text: `不限速` },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: '上網吃到飽',
              量到限速: '不限速',
              網內通話: '前5分鐘免費/通',
              網外通話: '送30鐘/月',
              市話通話: '1.8/分鐘',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              活動優惠: '電信帳單回饋遠傳幣點數，1點可折1元',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: '網路限定方案再送禮券',
        value: '月付$699x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 699,
        originPrice: 1499,
        prepay: 6000,
        month: '24',
        note: '網路門市限定',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: '5G飆網24GB', tooltip: '網速級距 500Mbps' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
            tooltip: '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `辦就送7-11 抵用券 $250，加碼送$1,000 易遊網旅遊票券`,
            color: 'red',
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: '網路限定方案再送禮券',
        value: '月付$999x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 999,
        // originPrice: 1499,
        prepay: 6000,
        month: '24',
        // note: '網路門市限定',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: '5G飆網24GB' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        // ribbon: '網路限定方案再送禮券',
        value: '月付$499x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 499,
        // originPrice: 1499,
        prepay: 6000,
        month: '24',
        // note: '網路門市限定',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: '5G飆網24GB' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
      {
        ribbon: { text: '須持學生證或教職證', color: 'blue' },
        value: '月付$399x24個月',
        sticker: '/resources/cbu/estore/4-5-g.png',
        unit: '月付',
        price: 399,
        // originPrice: 1499,
        prepay: 6000,
        month: '24',
        // note: '網路門市限定',
        list: [
          // { title: '商品專案價', projectPrice: 10000, originPrice: 20000 },
          { title: '上網傳輸量', text: '5G飆網24GB' },
          {
            title: '量到限速',
            text: '量到4G網路吃到飽',
          },
          { title: '網內通話', text: `前5分鐘免費/通` },
          { title: '網外通話', text: `送30鐘/月` },
          { title: '市話通話', text: `1.8/分鐘` },
          {
            title: '活動優惠',
            text: `電信帳單回饋遠傳幣點數，1點可折1元`,
          },
        ],
        tag: ['4.5G方案', '吃到飽', '月付$600-$999'],
        modal: {
          title: '$1,399/12個月 方案詳情',
          content: [
            {
              上網傳輸量: ['5G飆網60GB', '網速級距 500Mbps'],
              量到限速: ['量到4G網路吃到飽', '5G上網傳輸量使用超過24GB時，會降速至4G網路吃到飽 (網速級距 5Mbps)'],
              網內通話: '免費',
              網外通話: '送120分鐘/月',
              市話通話: '1.8/分鐘',
              商品專案價: '$10,000',
              預繳金: ['$6,000', '預繳金額可折抵您每月的帳單費用, 且會依您的資格調整，請依最終結帳訂單金額為主。'],
              加值優惠:
                '遠傳friDayVR 30天0元綁約 + 遠傳friDay影音明星多視角30天0元綁約 + 遠傳friDay影音4K 365天6片綁約',
              活動優惠: '辦就送遠傳幣購物金 $6,500',
            },
            '<b>贈品/商品兌換說明</b>',
            '遠傳幣將於申辦成功後 14 個日曆天下午 14:00 自動匯入至客戶門號對應之遠傳心生活 App 帳號，不另外用簡訊通知。',
            '<b>門號開通說明</b>',
            [
              '新申辦/攜碼完成申辦後需更換遠傳SIM卡，SIM卡將在申辦資格審核完成隔日起，最快1-3個工作天內配達，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。',
              '收到SIM卡後，請在指定開通日插入新的SIM卡，然後重新開機後即完成門號啟用。',
            ],
            '<b>其他說明</b>',
            '＊實際應繳之補貼款以合約未到期之”日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
            '＊電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
          ],
        },
      },
    ],
  },
  {
    id: 'new-number',
    slug: '門號',
    title: '選擇門號',
    type: 'radio-carousel',
    name: 'phone_number',
    description: '選一個好門號給你好運氣',
    searchLabel: '主題號碼',
    tags: ['免費門號', '黃金門號', '一路發168', '66大順', '幸福99', 'Lucky77', '真愛520', '發發88', '123門號'],
    options: [
      {
        label: '0903-063-482',
        value: '0903-063-482',
        description: '選號費將於第一期帳單進行扣抵',
        price: 1000,
        tag: ['黃金門號'],
      },
      {
        label: '0903-652-344',
        value: '0903-652-344',
        price: 500,
        tag: ['黃金門號'],
      },
      {
        label: '0903-849-753',
        value: '0903-849-753',
        tag: ['免費門號'],
      },
      {
        label: '0903-666-333',
        value: '0903-666-333',
        tag: ['66大順', '免費門號'],
      },
      {
        label: '0903-849-123',
        value: '0903-849-123',
        tag: ['免費門號', '123門號'],
      },
      {
        label: '0903-666-332',
        value: '0903-666-332',
        tag: ['66大順', '123門號', '免費門號'],
      },
      {
        label: '0903-245-203',
        value: '0903-245-203',
        tag: ['真愛520', '免費門號'],
      },
      {
        label: '0903-247-752',
        value: '0903-247-752',
        tag: ['Lucky77', '免費門號'],
      },
      {
        label: '0903-849-953',
        value: '0903-849-953',
        tag: ['幸福99', '免費門號'],
      },
      {
        label: '0903-668-833',
        value: '0903-668-833',
        tag: ['66大順', '發發88', '免費門號'],
      },
      {
        label: '0903-168-753',
        value: '0903-168-753',
        tag: ['一路發168', '免費門號'],
      },
      {
        label: '0903-666-334',
        value: '0903-666-334',
        tag: ['66大順', '免費門號'],
      },
    ],
  },
];
