import React from 'react';
import Tab from '../tab/Tab';
import PropTypes from 'prop-types';

class NavContentTab2 extends React.Component {
  constructor(props) {
    super(props);

    this.elem = React.createRef();
  }

  componentDidMount = () => {
    this.initScroll();
  };

  initScroll = () => {
    let item = this.elem.current.getElementsByClassName('is-active')[0];
    let tab = this.elem.current.getElementsByClassName('fui-tab')[0];
    let rect = item.getBoundingClientRect();
    if (rect.left + item.clientWidth > window.innerWidth) {
      tab.scrollTo(rect.left, 0);
    }
  };

  handleChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render() {
    return (
      <div className={`fui-nav-tab is-box-tab ${this.props.className}`} ref={this.elem}>
        <Tab {...this.props.tabs} onChange={(e) => this.handleChange(e)} />
      </div>
    );
  }
}

NavContentTab2.propTypes = {
  tabs: PropTypes.shape({
    default: PropTypes.number,
    name: PropTypes.string.isRequired,
    icon: PropTypes.bool,
    title: PropTypes.bool,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        focusIcon: PropTypes.string,
        meta: PropTypes.string,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default NavContentTab2;
