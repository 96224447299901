import React from 'react';
import Button from '../Button';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';

class InputGroup extends React.Component {
  constructor(props) {
    super(props);

    this.input = React.createRef();
  }

  componentDidMount() {
    this.input.current.value = this.props.getValue();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.input.current.value = this.props.value;
      this.props.setValue(this.props.value);
    }
  }

  sendIuput = () => {
    this.props.onChange(this.props.name, this.input.current.value);
  };

  render() {
    return (
      <div className={`form-group ${this.props.className || ''} ${this.props.error ? 'is-invalid' : ''}`}>
        <label htmlFor={this.props.name}>{this.props.label}</label>
        <div className='input-group'>
          <div className='fui-input'>
            <input
              type='tel'
              placeholder={this.props.placeholder}
              name={this.props.name}
              id={this.props.name}
              ref={this.input}
            />
          </div>
          <div className='action'>
            <Button btnStyle='secondary' onClick={this.sendIuput}>
              {this.props.buttonText}
            </Button>
          </div>
        </div>
        {this.props.error ? <span className='error-message'>{this.props.error}</span> : null}
        {this.props.info ? <div className='mt-1 is-text-darkgray50'>{this.props.info}</div> : null}
      </div>
    );
  }
}

InputGroup.propTypes = {
  label: PropTypes.string,
  buttonText: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  info: PropTypes.string,
  error: PropTypes.string,
};

export default withFormsy(InputGroup);
