import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isBrowser, getScrollPage, getScrollElement, scrollTo, scrollToElement } from './util';

class ScrollMemory extends Component {
  constructor(props) {
    super(props);
    // add event for click on previous or next browser button
    this.detectPop = this.detectPop.bind(this);
    // stock location key with scroll associate
    this.url = new Map();
  }

  componentDidMount = () => {
    window.addEventListener('popstate', this.detectPop);
  };

  componentWillUnmount = () => {
    window.removeEventListener('popstate', this.detectPop);
  };

  shouldComponentUpdate = (nextProps) => {
    if (!isBrowser()) return false;
    const { location } = this.props;
    // location before change url
    const actual = location;
    // location after change url
    const next = nextProps.location;
    // the first page has not key, set "enter" for key
    const key = actual.key || 'enter';

    // if hash => let the normal operation of the browser
    const locationChanged = (next.pathname !== actual.pathname || next.search !== actual.search) && next.hash === '';

    // get scroll of the page or the element before change location
    const scroll = this.props.elementID ? getScrollElement(this.props.elementID) : getScrollPage();

    if (locationChanged) {
      window.localStorage.setItem('scrollPosition', this.props.elementID || 0);
      // pass page or element scroll to top
      // this.props.elementID ? scrollToElement(0, this.props.elementID) : scrollTo(0);
      // save scroll with key location
      this.url.set(key, scroll);
    }
    // never render
    return false;
  };

  /**
   * callback for event popstate
   *
   * @memberof ScrollMemory
   */
  detectPop = (location) => {
    if (!isBrowser()) return;
    const { state } = location;
    // key or enter page
    const key = state && state.key ? state.key : 'enter';
    // get the next for scroll position
    const nextFind = this.url.get(key);

    // if find in url map => scroll to position
    if (nextFind) {
      window.localStorage.setItem('scrollPosition', this.props.elementID || nextFind);
      // this.props.elementID ? scrollToElement(nextFind, this.props.elementID) : scrollTo(nextFind);
    }
  };

  render() {
    return null;
  }
}

export default withRouter(ScrollMemory);
