import React from 'react';
import Slider from 'react-slick';
import { withFormsy } from 'formsy-react';
import Button from '../Button';
import DropdownTag from '../DropdownTag';
import Tooltip from '../Tooltip';
import formatNumber from '../../utils/numberFormatter';

import PropTypes from 'prop-types';

class RadioCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tagList: this.props.tags,
      tagOpen: false,
      compare: this.props.isCompare,
      compareList: [],
      selected: '',
      currentTag: {
        main: null,
        sub: null,
      },
      filterArray: {
        main: [],
        sub: [],
      },
      sliderSetting: {
        dots: false,
        infinite: false,
        arrows: true,
        draggable: true,
        adaptiveHeight: true,
        centerMode: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              arrows: true,
              slidesToShow: 2,
              variableWidth: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              arrows: true,
              slidesToShow: 1,
              variableWidth: true,
            },
          },
        ],
      },
    };
  }

  componentDidMount = () => {
    let tags = [...this.props.tags.list];
    let main = tags.splice(0, tags[0] === '全部' ? 3 : 2);

    this.setState({
      filterArray: {
        main: main,
        sub: tags,
      },
      currentTag: {
        main: main[0],
        sub: null,
      },
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isCompare && (prevProps.value !== this.props.value || this.props.value !== this.state.compareList)) {
      this.setState({
        compareList: this.props.value,
        compare: this.props.isCompare,
      });

      this.forceUpdate();
    }
  }

  handleChange = (event, option) => {
    if (this.props.isCompare) {
      let selected = Object.assign(this.state.compareList);
      let val = option.value;

      if (option.checked || selected.indexOf(val) === -1) {
        selected.push(val);
      } else {
        selected.splice(selected.indexOf(val), 1);
      }
      this.setState({
        compareList: selected,
      });
      this.props.setValue(selected.length ? selected : []);
      this.props.onChange('compare', selected);
    } else {
      this.setState({
        selected: option.value,
      });
      this.props.setValue(option.value);
      this.props.onChange(this.props.name, option.value, option);
    }
  };

  filterOption = () => {
    const { currentTag } = this.state;
    if (!currentTag.main && !currentTag.sub) return this.props.options;

    let newOpts = this.props.options.reduce((accr, opt, i) => {
      if (!currentTag.sub) {
        if (opt.tag.indexOf(currentTag.main) > -1 || currentTag.main === '全部') {
          accr.push(opt);
        }
      } else {
        if (!currentTag.main && opt.tag.indexOf(currentTag.sub) > -1) {
          accr.push(opt);
        } else {
          if (
            (opt.tag.indexOf(currentTag.main) > -1 || currentTag.main === '全部') &&
            opt.tag.indexOf(currentTag.sub) > -1
          )
            accr.push(opt);
        }
      }
      return accr;
    }, []);

    return newOpts;
  };

  setCompare = () => {
    this.props.onCompareChange(true);
  };

  toggleSelect = (e, opt) => {
    if (this.props.isCompare) {
      if (this.state.compareList.indexOf(opt.value) > -1) {
        this.handleChange(e, { checked: false, ...opt });
      } else {
        this.handleChange(e, { checked: true, ...opt });
      }
    } else {
      if (opt.value === this.props.value) {
        this.handleChange(e, { value: '' });
      } else {
        this.handleChange(e, opt);
      }
    }
  };

  render() {
    return (
      <div className='fui-radio-card-section'>
        {this.props.hasCompare && !this.props.isCompare ? (
          <div className='fui-compare'>
            <Button btnStyle='secondary' size='small' onClick={this.setCompare}>
              資費比比看
            </Button>
          </div>
        ) : null}
        <div className='tag-filter'>
          <div className='content'>
            <div className='tag-list'>
              <div className='tag-group'>
                {this.state.filterArray.main.map((tag, i) => (
                  <label
                    className={`fui-button is-label ${this.state.currentTag.main === tag ? 'is-active' : ''}`}
                    role='button'
                    key={`menu-tag-${i}`}
                    onClick={(e) =>
                      this.setState({
                        currentTag: {
                          ...this.state.currentTag,
                          main: tag,
                        },
                      })
                    }>
                    <span className='text'>{tag}</span>
                  </label>
                ))}
              </div>
              {this.state.filterArray.sub.map((tag, i) => (
                <label
                  className={`fui-button is-label ${this.state.currentTag.sub === tag ? 'is-active' : ''}`}
                  role='button'
                  key={`menu-tag-${i}`}
                  onClick={(e) =>
                    this.setState({
                      currentTag: {
                        ...this.state.currentTag,
                        sub: tag === this.state.currentTag.sub ? null : tag,
                      },
                    })
                  }>
                  <span className='text'>{tag}</span>
                </label>
              ))}
            </div>
          </div>
          {/* <div className='extra'>
            <DropdownTag
              className='is-dropdown-tag'
              selected={this.state.currentTag}
              label='更多'
              arrow={true}
              name={this.props.tags.name}
              list={this.state.filterArray}
              onChange={(tag) =>
                this.setState({
                  currentTag: tag,
                })
              }
            />
          </div> */}
        </div>
        <Slider {...this.state.sliderSetting} className={!this.filterOption().length ? 'no-result' : ''}>
          {this.filterOption().length ? (
            this.filterOption().map((opt, i) => (
              <div
                className={`fui-card is-radio-card ${
                  (this.props.isCompare && this.state.compareList.indexOf(opt.value) > -1) ||
                  (!this.props.isCompare && opt.value === this.props.value)
                    ? 'is-selected'
                    : ''
                }`}
                key={`radio-card-${i}`}>
                <label>
                  <input
                    type={this.props.isCompare ? 'checkbox' : 'radio'}
                    id={opt.value}
                    name={this.props.isCompare ? 'compare[]' : this.props.name}
                    value={opt.value}
                    checked={
                      (this.props.isCompare && this.state.compareList.indexOf(opt.value) > -1) ||
                      (!this.props.isCompare && opt.value === this.props.value)
                    }
                    onChange={(e) => this.handleChange(e, opt)}
                    disabled={
                      this.props.isCompare &&
                      this.state.compareList.length >= 3 &&
                      this.state.compareList.indexOf(opt.value) === -1
                    }
                  />
                </label>
                <div className='fui-card-action'>
                  <div className='fui-card-plan'>
                    {opt.ribbon ? (
                      <div className={'fui-card-ribbon ' + (opt.ribbon.color ? 'is-' + opt.ribbon.color : '')}>
                        {opt.ribbon.text || opt.ribbon}
                      </div>
                    ) : null}
                    {opt.sticker ? <img src={opt.sticker} alt='' className='fui-card-sticker' /> : null}
                    <div className='plan-container'>
                      <div className='limited'>{opt.note}</div>
                      <div className='plan-content'>
                        <small className='unit'>
                          {opt.unit}
                          {!!opt.originPrice && opt.originPrice > 0 ? (
                            <del>${formatNumber(opt.originPrice)}</del>
                          ) : null}
                        </small>
                        <h1 className='price'>${formatNumber(opt.price, 0)}</h1>
                        <span className='divider'>/</span>
                        <h5 className='month'>{opt.month}個月</h5>
                      </div>
                    </div>
                  </div>
                  <div className='fui-plan-list'>
                    {opt.list.map((item, j) => (
                      <div className='fui-plan-item' key={`radio-card-${i}-list-${j}`}>
                        <span className='item-title'>{item.title}</span>
                        {item.originPrice || item.projectPrice ? (
                          <span className='item-content'>
                            <big>${formatNumber(item.projectPrice)}</big>
                            {item.originPrice > 0 ? <del>${formatNumber(item.originPrice)}</del> : null}
                          </span>
                        ) : (
                          <span
                            className={
                              'item-content ' +
                              (item.title === '活動優惠' ? `is-text-${item.color === 'red' ? 'error' : ''} ` : '')
                            }
                            dangerouslySetInnerHTML={{ __html: item.text }}></span>
                        )}

                        {!!item.tooltip ? (
                          <span className='item-tooltip'>
                            <Tooltip content={<i className='icon-info'></i>} trigger='click' tooltip={item.tooltip} />
                          </span>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
                <div className='fui-card-extra'>
                  <div role='button' onClick={(e) => this.props.modalOpen(opt.modal)}>
                    <span className='text'>方案詳情</span>
                    <i className='icon-plus'></i>
                  </div>
                  <div
                    onClick={(e) => this.toggleSelect(e, opt)}
                    disabled={
                      this.props.isCompare &&
                      this.state.compareList.length >= 3 &&
                      this.state.compareList.indexOf(opt.value) === -1
                    }
                    className={
                      'fui-button is-secondary is-block mb-0 mt-2 ' +
                      (this.props.isCompare &&
                      this.state.compareList.length >= 3 &&
                      this.state.compareList.indexOf(opt.value) === -1
                        ? 'is-disabled'
                        : '')
                    }>
                    {(!this.props.isCompare && opt.value === this.props.value) ||
                    (!!this.props.isCompare && this.state.compareList.indexOf(opt.value) > -1) ? (
                      <span className='text'>取消選取</span>
                    ) : (
                      <span className='text'>選擇方案</span>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className='no-result'>
              <img src={'/resources/cbu/estore-product/searchnoresult@2x.png'} width='220' alt='搜尋無結果！' />
              <div>搜尋無結果！</div>
            </div>
          )}
        </Slider>
      </div>
    );
  }
}

RadioCard.propTypes = {
  name: PropTypes.string,
  isCompare: PropTypes.bool,
  hasCompare: PropTypes.bool,
  className: PropTypes.string,
  tags: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.any,
  onChange: PropTypes.func,
  modalOpen: PropTypes.func,
};

export default withFormsy(RadioCard);
