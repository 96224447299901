export const pairStep = [
  {
    name: 'phone',
    title: '買手機',
    description: '超值優惠商品任你選',
    tag: ['Apple', 'Samsung', 'OPPO'],
    type: 'checkbox-card',
    withNumberController: true,
    options: [
      {
        type: 'product',
        link: '/estore/product/detail/iphone-11-pro-max-256gb',
        ribbon: '網路限定優惠',
        image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
        tag: '5G手機',
        meta: 'APPLE',
        name: 'iPhone 11 Pro Max 256GB',
        salePrice: 1399,
        productPrice: 10500,
        value: '1',
      },
      {
        type: 'product',
        link: '/estore/product/detail/iphone-11-pro-max-256gb',
        ribbon: '新機上市',
        meta: 'APPLE',
        // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
        name: 'Apple iPhone SE (2020) 128GB',
        salePrice: 1399,
        productPrice: 10500,
        value: '2',
      },
      {
        type: 'product',
        link: '/estore/product/detail/iphone-11-pro-max-256gb',
        ribbon: '新機上市',
        meta: 'SAMSUNG',
        image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
        name: 'Samsung Galaxy A21s ',
        salePrice: 1399,
        productPrice: 10500,
        value: '3',
      },
      {
        type: 'product',
        link: '/estore/product/detail/iphone-11-pro-max-256gb',
        ribbon: '新機上市',
        meta: 'APPLE',
        // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
        name: 'Apple iPhone SE (2020) 128GB',
        salePrice: 1399,
        productPrice: 10500,
        value: '4',
      },
      {
        type: 'product',
        link: '/estore/product/detail/iphone-11-pro-max-256gb',
        ribbon: '新機上市',
        meta: 'APPLE',
        // image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
        name: 'Apple iPhone SE (2020) 128GB',
        salePrice: 1399,
        productPrice: 10500,
        value: '5',
      },
      {
        type: 'product',
        link: '/estore/product/detail/iphone-11-pro-max-256gb',
        image: '/resources/cbu/find-product/estore-product-thumb-01@2x.jpg',
        tag: '5G手機',
        meta: 'APPLE',
        name: 'iPhone 11 Pro Max 256GB',
        salePrice: 1399,
        productPrice: 10500,
        value: '6',
      },
      {
        type: 'product',
        link: '/estore/product/detail/iphone-11-pro-max-256gb',
        meta: 'APPLE',
        image: '/resources/cbu/find-product/480x480.png',
        name: 'Apple iPhone SE (2020) 128GB',
        salePrice: 1399,
        productPrice: 10500,
        value: '7',
      },
      {
        type: 'product',
        link: '/estore/product/detail/iphone-11-pro-max-256gb',
        meta: 'SAMSUNG',
        image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
        name: 'Samsung Galaxy A21s ',
        salePrice: 1399,
        productPrice: 10500,
        value: '8',
      },
    ],
  },
  {
    name: 'extra_product',
    type: 'checkbox-card',
    title: '加購商品',
    description: '自己搭最划算！',
    tag: ['3C', '智能產品', '配件'],
    withNumberController: true,
    options: [
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
        value: 'PHILIPS 舒眠抗敏空氣清淨機',
        meta: 'PHILIPS',
        name: 'PHILIPS 舒眠抗敏空氣清淨機',
        productPrice: 5100,
        modal: {
          name: 'PHILIPS 舒眠抗敏空氣清淨機',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-10@2x.jpg',
        value: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
        meta: 'Iris Ohyama ',
        name: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
        productPrice: 1399,
        modal: {
          name: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-11@2x.jpg',
        value: 'Panasonic 15公升蒸氣烘烤爐',
        meta: 'PANASONIC',
        name: 'Panasonic 15公升蒸氣烘烤爐',
        productPrice: 15000,
        modal: {
          name: 'Panasonic 15公升蒸氣烘烤爐',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-16@2x.jpg',
        value: 'Xiaomi 米家掃拖機器人',
        meta: 'XIAOMI',
        name: 'Xiaomi 米家掃拖機器人',
        productPrice: 6900,
        modal: {
          name: 'Xiaomi 米家掃拖機器人',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
      {
        type: 'product',
        image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
        value: 'XGIMI MOGO 攜帶型投影機內建喇叭',
        meta: 'XGIMI',
        name: 'XGIMI MOGO 攜帶型投影機內建喇叭',
        productPrice: 6900,
        modal: {
          name: 'XGIMI MOGO 攜帶型投影機內建喇叭',
          content: [
            '商品規格',
            [
              '3.5mm 超薄無線充電盤',
              'WPC國際 Qi 認證/ NCC 國家認證',
              '支持Qi 無線充電IOS & Android 手機',
              'FOD自動智慧識別金屬異物',
              '輸出 (Output)：5W/ 7.5W / 9W',
            ],
          ],
        },
      },
    ],
  },
];
