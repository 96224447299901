//  Redux

//   - state <
//   - reducer <- action
//   - action <-- data

// 1. 登入，將登入狀態寫入 redux，每登入，state = null
// 2. 進入 renewal/(renewal/Login.js)  取得 redux memberState
// 3. 判斷是否要登入：是，留在原頁。否：this.props.history.push('/renewal/rateplan')

export const memberState = {
  // 可續約
  canRenewal: false,
};

export const helpCenterInitialState = {
  headerType: 'common',
  footType: 'common',
  storeType: '',
  helpCenterSearchKeyword: '',
  helpCenterSearchHot: '',
  helpCenterSearchCategory: '',
  helpCenterFaqCategoryMenu: null,
  searchPath: '',
};

export const dataLayerInitialState = {
  dataLayerEventName: '',
  dataLayerPayload: {},
};

export const formInitialState = {
  formPayload: {
    URI: '',
    successUrl: '',
    failUrl: '',
  },
  formName: '',
};

export const analyticsInitialState = {
  customEventName: '',
  digitalData: {},
};

export const emmaInitialState = {
  useEmmaAvatar: true,
  show: true,
  link: '/content/ebu/tw/help-center/contact-us.html',
};

export const cartInitialState = { list: [] };

export const flowCartInitialState = {};
