import React from 'react';

import { setFlowCartData, trackPageViewStart } from '../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import Cart from '../components/FlowCart';
import Button from '../components/Button';

import RadioGroup from '../components/form/RadioGroup';
import RadioCard from '../components/form/RadioCard';
import RadioProductCard from '../components/form/RadioProductCard';
import RadioCarousel from '../components/form/RadioCarousel';
import CheckboxCardCarousel from '../components/form/CheckboxCardCarousel';
import LabelInput from '../components/form/LabelInput';
import NavAnchor from '../components/partials/NavAnchor';
import Breadcrumb from '../components/Breadcrumb';
import Tab from '../components/tab/Tab';
import TabPane from '../components/tab/TabPane';
import EStoreBanner from '../components/partials/banner/EStoreBanner';
import EstoreModal from '../components/EstoreModal';
import SectionCollapseInfo from '../components/partials/collapse/SectionCollapseInfo';

import * as Validation from '../utils/validation';
import formatNumber from '../utils/numberFormatter';
import { setMobile } from '../components/util';

import * as Mock from '../mock/MultiProduct';

class MultiProduct extends React.Component {
  constructor(props) {
    super(props);
    this.flowRef = React.createRef();

    this.state = {
      modalOpen: false,
      inputValidationValid: false,
      canEdit: true,
      isBackward: false,
      cartUpdate: false,
      currentModal: {
        type: 'notice',
        title: '',
        content: '',
      },
      originFlow: Mock.planStep,
      applyFlow: [...Mock.planStep, ...Mock.newFlow],
      form: {
        id_number: { value: '', required: true },
        input_validation: { value: '', required: true },
        scid: { value: '', required: true },
        apply_method: { value: '' },
        number: { value: 1, required: true },
        phone_number: { value: 1, required: true },
        plan: { value: '', required: true },
        products: { value: '', required: true },
        combine_1: { value: '', required: true },
        combine_2: { value: '', required: true },
      },
      cart: {
        apply_method: '',
        plan: null,
        identity_validation: null,
        input_validation: null,
        phone_number: null,
        products: null,
        combine_1: null,
        combine_2: null,
      },
      step: 0,
      editStep: -1,
    };
  }

  componentDidMount() {
    if (!!this.props.flowCart.cart) {
      this.setState({
        isBackward: true,
        cart: this.props.flowCart.cart,
        applyFlow: this.props.flowCart.applyFlow,
        step: this.props.flowCart.step,
        form: this.props.flowCart.form,
      });

      setTimeout(() => {
        this.scrollToPosition();

        if (this.state.step === 0) {
          this.initModal();
        }
      }, 1500);
    }

    window.addEventListener('resize', (e) => setMobile(this));

    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', this.detectStep);

      if (window.innerWidth > 768) window.addEventListener('mousewheel', this.detectSection);
      else window.addEventListener('scroll', this.detectSection);
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', (e) => setMobile(this));
    window.removeEventListener('popstate', this.detectStep);
    if (window.innerWidth > 768) window.removeEventListener('mousewheel', this.detectSection);
    else window.removeEventListener('scroll', this.detectSection);
  };

  detectStep = (e) => {
    let step = this.state.editStep !== -1 ? this.state.editStep : this.state.step;

    if (this.props.location.pathname.indexOf('/estore/product/event_product_list/products') > -1) {
      if (step - 1 >= 0) {
        this.changeFormSection(step - 1);
      } else {
        window.history.go(-1);
      }
    }
    return false;
  };

  detectSection = (e) => {
    if (this.state.step > 0) return;

    if (this.flowRef.current) {
      let fistSec = this.flowRef.current.getElementsByTagName('section')[0];
      let fistRect = fistSec.getBoundingClientRect();
      let scrollY = window.scrollY || document.documentElement.scrollTop;

      if (scrollY > fistRect.top) {
        // debugger;
        this.props.history.push('#' + fistSec.id);
      }
    }
  };

  resetFormset = () => {
    // 重置 cart 與 form
    this.setState({
      cart: {
        ...this.state.cart,
        plan: null,
        identity_validation: null,
        input_validation: null,
        phone_number: null,
        products: null,
        combine_1: null,
        combine_2: null,
      },
      form: {
        ...this.state.form,
        id_number: { value: '', required: true },
        input_validation: { value: '', required: true },
        scid: { value: '', required: true },
        number: { value: 1, required: true },
        phone_number: { value: 1, required: true },
        plan: { value: '', required: true },
        products: { value: '', required: true },
        combine_1: { value: '', required: true },
        combine_2: { value: '', required: true },
      },
      editStep: -1,
      step: 0,
      modalOpen: false,
    });
  };

  inputChange = (n, v, content) => {
    let form = Object.assign(this.state.form);

    if (n === 'apply_method' && this.state.editStep !== -1) {
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'change-alert',
          originSelect: form[n].value,
          originFlow: this.state.applyFlow,
          title: '組合方案選項將被清除',
          content: '提醒你，剛才你選擇的組合方案將被清除，請再次確認是否要重新選擇？',
        },
      });
    }

    form[n].value = v;

    // 切換顏色，圖片跟著切換內容
    if (n === 'color') {
      this.setState({
        currentImage: this.state.productInfo.images[v],
        cart: {
          ...this.state.cart,
          apply_method: '',
          plan: null,
          product: null,
          identity_validation: null,
          input_validation: null,
          phone_number: null,
          gift: [],
          extra_product: [],
          extra_plan: [],
        },
      });

      form = {
        ...form,
        input_validation: { value: '', required: true },
        id_number: { value: '', required: true },
        scid: { value: '', required: true },
        apply_method: { value: '' },
        plan: { value: '', required: true },
        phone_number: { value: '', required: true },
        gift: { value: '', required: true },
        extra_product: { value: '', required: true },
        extra_plan: { value: '', required: true },
      };
    }
    // 根據申辦方式切換申辦流程
    if (n === 'apply_method') {
      // 申辦新門號
      let flow = [];
      if (v === '申辦新門號') {
        flow = [...this.state.originFlow, ...Mock.newFlow];
      }
      // 攜碼到遠傳
      if (v === '攜碼到遠傳') {
        flow = [...this.state.originFlow, ...Mock.carryFlow];
      }
      // 遠傳門號續約
      if (v === '遠傳門號續約') {
        flow = [...this.state.originFlow, ...Mock.renewFlow];
      }
      // 表單內容更新，購物車內容同步更新
      this.setState({
        cart: {
          ...this.state.cart,
          apply_method: v,
        },
        applyFlow: flow,
      });
      this.forceUpdate();
    }

    if (
      this.state.applyFlow[this.state.editStep > -1 ? this.state.editStep : this.state.step].type === 'input-validation'
    ) {
      this.setState({
        inputValidationValid: /^09\d{2}-?\d{3}-?\d{3}$/.test(v),
        cart: {
          ...this.state.cart,
          input_validation: {
            phone_number: v,
            isPass: false,
          },
        },
      });
    }
    // 將完整資料帶入購物車
    if (!!content) {
      let cart = { ...this.state.cart };

      if (content.option)
        cart[n] = {
          ...content,
          [content.option.name]: content.option.selected,
        };
      else cart[n] = content;

      this.setState({
        cart: cart,
      });

      this.forceUpdate();
    }

    this.setState({
      form: form,
      cartUpdate: true,
    });

    this.props.setFlowCartData(this.state.cart);

    this.forceUpdate();
  };

  changeFormSection = (index) => {
    if (this.state.applyFlow[index].type === 'identity-validation') {
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'change-alert',
          flowIndex: index,
          flowType: this.state.applyFlow[index].type,
          originFlow: this.state.applyFlow,
          title: '組合方案選項將被清除',
          content: '提醒你，剛才你選擇的組合方案將被清除，請再次確認是否要重新選擇？',
        },
      });
    } else {
      this.setState({
        editStep: index,
      });

      this.forceUpdate();
      setTimeout(() => {
        this.scrollToPosition();
        this.props.history.push(`#${this.state.applyFlow[index].id}`);
      }, 500);
    }
  };

  valuePreview = (flow) => {
    // 根據區塊名稱顯示個別內容
    if (flow.tabs) {
      return '';
    } else if (flow.name === 'identity_validation') {
      return this.state.form.scid.value;
    } else if (flow.name === 'input_validation') {
      return this.state.form.input_validation.value;
    } else if (flow.name === 'gift') {
      return this.state.cart[flow.name].length ? '贈品 x' + this.state.cart[flow.name].length : '';
    } else {
      return this.state.form[flow.name].value;
    }
  };

  openModal = (modal) => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'notice',
        ...modal,
      },
    });
  };

  stepChange = (step) => {
    this.setState({
      editStep: -1,
      step: step,
    });

    this.forceUpdate();
    setTimeout(() => {
      this.scrollToPosition();
    }, 500);
  };

  scrollToPosition = () => {
    // 捲動到對應位置
    let elem = window || document.documentElement;
    if (!this.flowRef.current) {
      if (window.isIE) {
        elem.scroll(0, 0);
      } else {
        elem.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
          cartUpdate: true,
        });
      }
      return;
    }

    let flows = this.flowRef.current.getElementsByClassName('fui-plan-flow');
    let lastFlow = flows[this.state.editStep !== -1 ? this.state.editStep : this.state.step];
    let prevFlow =
      flows[
        this.state.editStep !== -1 ? (this.state.editStep === 0 ? 0 : this.state.editStep - 1) : this.state.step - 1
      ];
    let rect = lastFlow.getBoundingClientRect();
    let prevRect = prevFlow.getBoundingClientRect();
    let scrollY = window.scrollY || document.documentElement.scrollTop;

    if (window.isIE) {
      elem.scroll(0, rect.top - prevRect.height + scrollY - 50);
    } else {
      elem.scrollTo({
        left: 0,
        top: scrollY - prevRect.height + rect.top - 50,
        behavior: 'smooth',
        cartUpdate: true,
      });
    }

    this.props.history.push('#' + this.state.applyFlow[this.state.step].id);
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);

    this.setState({
      modalOpen: false,
    });

    if (type === 'otpForm') {
      this.identityCheck();
    }
    // 貨到通知送出
  };

  phoneCheck = () => {
    // 為顯示不同狀態的畫面，不同次點擊切換不同狀態
    if (this.state.identityPass) {
      this.setState({
        cart: {
          ...this.state.cart,
          input_validation: {
            phone_number: this.state.form.input_validation.value,
            isPass: /^09\d{2}-?\d{3}-?\d{3}$/.test(this.state.form.input_validation.value),
          },
        },
      });
    } else
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'phoneCheck-alert',
          title: '還未符合續約資格',
          content: `很抱歉，你的身份條件暫時無法續約。請洽遠傳門市或手機直撥 <a href='tel:123' target='_blank'>123客服專線</a> 查詢。`,
          confirmText: '可續約時通知我',
          cancelText: '可續約時通知我',
        },
      });

    this.setState({
      identityPass: !this.state.identityPass,
    });
  };

  doModalEvent = () => {
    this.setState({
      modalOpen: false,
    });
  };

  optVarify = () => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'otpForm',
        title: '驗證手機號碼',
        phone: this.state.form.phone_number.value,
      },
    });
  };

  identityCheck = () => {
    // doValidation
    // 驗證成功
    if (this.state.identityPass)
      this.setState({
        cart: {
          ...this.state.cart,
          identity_validation: {
            id_number: this.state.form.id_number.value,
            scid: this.state.form.scid.value,
            isPass: true,
          },
        },
      });
    // 如果驗證失敗
    else
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'identityCheck-alert',
          title: '還未符合續約資格',
          content: `很抱歉，你的身份條件暫時無法續約。請洽遠傳門市或手機直撥 <a href='tel:123' target='_blank'>123客服專線</a> 查詢。`,
          confirmText: '可續約時通知我',
          cancelText: '查看原合約',
        },
      });

    this.setState({
      identityPass: !this.state.identityPass,
    });
  };

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  modalConfirm = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    if (type === 'change-alert') {
      const { currentModal } = this.state;
      if (currentModal.flowType && currentModal.flowType === 'identity-validation') {
        this.setState({
          step: currentModal.flowIndex,
          editStep: -1,
          form: {
            ...this.state.form,
            input_validation: { value: '', required: true },
            id_number: { value: '', required: true },
            scid: { value: '', required: true },
            phone_number: { value: '', required: true },
            gift: { value: '', required: true },
            extra_product: { value: '', required: true },
            extra_plan: { value: '', required: true },
          },
          cart: {
            ...this.state.cart,
            phone_number: null,
            identity_validation: null,
            input_validation: null,
            gift: [],
            extra_product: [],
            extra_plan: [],
            product: null,
          },
        });
        this.props.history.push(`#rn`);
      } else {
        this.resetFormset();
      }
    }
    if (type === 'phoneCheck-alert') {
      // this.phoneCheck();
    }
    if (type === 'identityCheck-alert') {
      // this.identityCheck();
    }
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalCancel = (type) => {
    // 預約取貨送出
    console.info('MODAL CANCEL', type);
    if (type === 'change-alert') {
      const { currentModal } = this.state;
      if (currentModal.flowType && currentModal.flowType === 'identity-validation') {
        this.setState({
          step: currentModal.flowIndex,
          editStep: -1,
          form: {
            ...this.state.form,
            input_validation: { value: '', required: true },
            id_number: { value: '', required: true },
            scid: { value: '', required: true },
            phone_number: { value: '', required: true },
            gift: { value: '', required: true },
            extra_product: { value: '', required: true },
            extra_plan: { value: '', required: true },
          },
          cart: {
            ...this.state.cart,
            phone_number: null,
            identity_validation: null,
            input_validation: null,
            gift: [],
            extra_product: [],
            extra_plan: [],
            product: null,
          },
        });
      } else {
        this.resetFormset();
      }
    }
    this.setState({
      modalOpen: false,
    });
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { form } = this.state;
    return (
      <main>
        <NavAnchor pageTitle='網路門市' />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '網路門市', link: '/estore' },
            { text: '促銷活動商品列表', link: '/' },
          ]}
        />

        <EStoreBanner
          slides={[
            {
              image: {
                md: '/resources/cbu/estore-event-product/cbu-ebustore-1920x500.jpg',
                sm: '/resources/cbu/estore-event-product/cbu-ebustore-banner-750x900.jpg',
              },
              title: '單辦門號大標題文字最多十六個字元',
              description: [
                '一二三四五六七八九十一二三四五六',
                '一二三四五六七八九十一二三四五六',
                '一二三四五六七八九十一二三四五六',
              ],
              actions: [
                {
                  text: 'Primary',
                  btnStyle: 'primary',
                  link: '#',
                  target: '_self',
                },
                {
                  text: 'Secondary',
                  btnStyle: 'secondary',
                  link: '#',
                  target: '_blank',
                },
              ],
            },
          ]}
        />

        <section className='pt-0'>
          <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
            {/* 申辦步驟 */}
            <div className='fui-container'>
              <div className='fui-plan-flow-container' ref={this.flowRef}>
                {this.state.applyFlow.map((flow, i) =>
                  this.state.step >= i ? (
                    <section
                      id={flow.id}
                      className={`fui-plan-flow ${
                        (this.state.step === i && this.state.editStep === -1) ||
                        (this.state.editStep > -1 && this.state.editStep === i)
                          ? 'is-active'
                          : ''
                      }`}
                      key={`fui-plan-flow-${i}`}>
                      <div className='fui-flow-title'>
                        <div className='content'>
                          <h2>
                            <span>
                              {i + 1}.{flow.title}
                            </span>
                            <span className='selected-value'>{this.valuePreview(flow)}</span>
                          </h2>
                        </div>
                        {this.state.canEdit ? (
                          <div className='extra'>
                            <Button btnStyle='secondary' size='small' onClick={(e) => this.changeFormSection(i)}>
                              {this.state.cart[flow.name] === '' ||
                              !this.state.cart[flow.name] ||
                              (Array.isArray(this.state.cart[flow.name]) && !this.state.cart[flow.name].length)
                                ? '尚未選擇'
                                : '修改'}
                            </Button>
                          </div>
                        ) : null}
                      </div>
                      <div className='fui-flow-body'>
                        <h6 className='fui-flow-description'>
                          {flow.name === 'apply_method'
                            ? form.apply_method.value === '申辦新門號'
                              ? flow.description[0]
                              : form.apply_method.value === '攜碼到遠傳'
                              ? flow.description[1]
                              : form.apply_method.value === '遠傳門號續約'
                              ? flow.description[2]
                              : ' '
                            : flow.description}
                        </h6>
                        {/* 根據類別切換表單內容 */}
                        {/* 有 Tab 的產品牌卡，用於加購 */}
                        {flow.type === 'tab-checkbox-card' ? (
                          <div>
                            <Tab name={flow.tabs.name} list={flow.tabs.list} onChange={this.CheckboxTabChange} />
                            {flow.tabs.content.map((tab, i) => (
                              <TabPane key={`tab-checkbox-${i}`} active={i === this.state.cuurentCheckboxTab}>
                                <CheckboxCardCarousel
                                  {...tab}
                                  value={form[tab.name].value}
                                  onChange={this.inputChange}
                                  modalOpen={this.openModal}
                                />
                              </TabPane>
                            ))}
                          </div>
                        ) : null}
                        {/* 門號驗證 */}
                        {flow.type === 'identity-validation' ? (
                          <div className='paper fui-validation-input'>
                            <Grid container>
                              <Grid item xs={12} sm={8} md={6}>
                                <LabelInput
                                  label='遠傳門號'
                                  placeholder='輸入門號'
                                  validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
                                  required={form.scid.required}
                                  name='scid'
                                  validationErrors={{
                                    isDefaultRequiredValue: '請輸入原門號號碼',
                                    matchRegexp: '您輸入了錯誤資訊，請再檢查！',
                                  }}
                                  withValidCheck={
                                    this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                  }
                                  value={form.scid.value}
                                  onChange={this.inputChange}
                                />
                                <LabelInput
                                  label='身分證字號'
                                  placeholder='輸入身分證字號'
                                  required={form.id_number.required}
                                  name='id_number'
                                  validations={{
                                    idNumberValid: Validation.idNumberValid,
                                  }}
                                  validationErrors={{
                                    isDefaultRequiredValue: '請輸入身分證字號',
                                    idNumberValid: '您輸入了錯誤資訊，請再檢查！',
                                  }}
                                  withValidCheck={
                                    this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                  }
                                  value={form.id_number.value}
                                  onChange={this.inputChange}
                                />
                                {!(
                                  this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                ) ? (
                                  <Button
                                    btnStyle='primary'
                                    size='large'
                                    disabled={form.scid.value === '' || form.id_number.value === ''}
                                    onClick={this.optVarify}>
                                    查詢可續約方案
                                  </Button>
                                ) : null}
                              </Grid>
                            </Grid>
                          </div>
                        ) : null}
                        {/* 攜入門號驗證 */}
                        {flow.type === 'input-validation' ? (
                          <div className='fui-validation-input'>
                            <Grid container>
                              <Grid item xs={12}>
                                <div className='fui-cards three-card no-scrollbar'>
                                  {flow.cards.map((card, c) => (
                                    <div className='fui-card' key={`input-validation-step-${c}`}>
                                      <div className='fui-card-action'>
                                        <div className='fui-card-caption'>
                                          <div className='fui-card-content'>
                                            <div className='circle-number'>{card.no}</div>
                                            <h3 className='fui-card-title'>{card.title}</h3>
                                            <p className='fui-card-description'>{card.description}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={8} md={6}>
                                <LabelInput
                                  label='輸入攜入門號'
                                  placeholder='輸入原門號號碼'
                                  validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
                                  required={form.input_validation.required}
                                  name='input_validation'
                                  validationErrors={{
                                    isDefaultRequiredValue: '您輸入了錯誤資訊，請再檢查！',
                                    matchRegexp: '您輸入了錯誤資訊，請再檢查！',
                                  }}
                                  value={form.input_validation.value}
                                  withValidCheck={
                                    this.state.cart.input_validation && this.state.cart.input_validation.isPass
                                  }
                                  onChange={this.inputChange}
                                />
                                <div
                                  className='text-sm is-text-gray100'
                                  dangerouslySetInnerHTML={{ __html: flow.note }}></div>
                                {!(this.state.cart.input_validation && this.state.cart.input_validation.isPass) ? (
                                  <Button
                                    btnStyle='primary'
                                    size='large'
                                    disabled={!this.state.inputValidationValid}
                                    onClick={this.phoneCheck}>
                                    驗證號碼
                                  </Button>
                                ) : null}
                              </Grid>
                            </Grid>
                          </div>
                        ) : null}
                        {/* 產品牌卡，用於好禮 */}
                        {flow.type === 'checkbox-card' ? (
                          <CheckboxCardCarousel
                            name={flow.name}
                            {...flow}
                            value={form[flow.name].value}
                            onChange={this.inputChange}
                            modalOpen={this.openModal}
                          />
                        ) : null}
                        {/* 單選 Radio Button，選擇門號 */}
                        {flow.type === 'radio-carousel' ? (
                          <RadioCarousel
                            name={flow.name}
                            {...flow}
                            search={true}
                            disabled={this.state.isBackward}
                            value={form[flow.name].value}
                            onChange={this.inputChange}
                          />
                        ) : null}
                        {/* 單選牌卡，選擇資費方案 */}
                        {flow.type === 'radio-card' ? (
                          <RadioCard
                            name={flow.name}
                            tags={flow.tags}
                            options={flow.options}
                            value={form[flow.name].value}
                            onChange={this.inputChange}
                            modalOpen={this.openModal}
                          />
                        ) : null}
                        {flow.type === 'radio-product-card' ? (
                          <RadioProductCard
                            name={flow.name}
                            tags={flow.tags}
                            options={flow.options}
                            value={form[flow.name].value}
                            onChange={this.inputChange}
                            modalOpen={this.openModal}
                          />
                        ) : null}
                        {/* 單純的 Radio Button，用於申請方式 */}
                        {flow.type === 'radio' ? (
                          <RadioGroup
                            label=''
                            name={flow.name}
                            options={flow.options}
                            default={form[flow.name].value}
                            value={form[flow.name].value}
                            onChange={this.inputChange}
                          />
                        ) : null}
                      </div>
                    </section>
                  ) : null
                )}
              </div>
            </div>
          </Formsy>
        </section>

        <SectionCollapseInfo title='貼心小叮嚀' content='貼心小叮嚀' />

        {/* 購物車 */}
        <Cart
          canRollBack={true}
          afterUpdate={(e) => this.setState({ cartUpdate: false })}
          update={this.state.cartUpdate}
          steps={this.state.applyFlow}
          currentStep={this.state.step}
          openStep={this.state.editStep === -1 ? this.state.step : this.state.editStep}
          cart={this.state.cart}
          stepChange={(e) => this.stepChange(e)}
          editStep={(e) => this.changeFormSection(e)}
        />

        <EstoreModal
          {...this.state.currentModal}
          open={this.state.modalOpen}
          onSubmit={this.modalSubmit}
          onConfirm={this.modalConfirm}
          onCancel={this.modalCancel}
          onClose={this.modalClose}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    analyticsInitialState: state.analyticsInitialState, // 可加可不加
    flowCart: state.flowCartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      trackPageViewStart,
      setFlowCartData,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiProduct));
