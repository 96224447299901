import React from 'react';

import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';
import NavTab from '../components/partials/NavTab';
import Link from '../components/Link';
import Breadcrumb from '../components/Breadcrumb';
import LabelInput from '../components/form/LabelInput';
import RadioGroup from '../components/form/RadioGroup';
import Select from '../components/form/Select';
import EstoreModal from '../components/EstoreModal';
import CheckboxAgree from '../components/form/CheckboxAgree';
import SectionCollapseInfo from '../components/partials/collapse/SectionCollapseInfo';

import { setMobile } from '../components/util';
import * as Validation from '../utils/validation';
import Button from '../components/Button';

class PreReg extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canSubmit: false,
      isMobile: window.innerWidth <= 768 ? true : false,
      campigan: {
        md: '/resources/cbu/estore-pre-reg/samsung-s-20-campigan@2x.jpg',
        sm: '/resources/cbu/estore-pre-reg/samsung-s-20-campigan-m@2x.jpg',
      },
      step: 0,
      modalOpen: false,
      currentModal: {
        type: 'form',
        title: '',
      },
      form: {
        name: { value: '', required: true, label: '姓名', placeholder: '請輸入姓名' },
        id_number: { value: '', required: true, label: '身分證字號', placeholder: '請輸入身分證字號' },
        mobile: { value: '', required: true, label: '行動電話', placeholder: '請輸入你的行動電話號碼' },
        email: { value: '', required: true, label: 'Email', placeholder: '請輸入你的電子郵件' },
        reg_type: { value: '新申辦', required: true, label: '申辦方式' },
        brand: { value: '', required: true, label: '請選擇', placeholder: '請選擇' },
        model: { value: '', required: true, label: '請選擇', placeholder: '請選擇' },
        color: { value: '', required: true, label: '請選擇', placeholder: '請選擇' },
        number: { value: '', required: true, label: '請選擇', placeholder: '請選擇' },
        agree: { value: false, required: true, label: '型號', placeholder: '請選擇' },
      },
      result: {
        number: 'PG855632797425',
        reg_at: '2020/01/06 19:27:22',
        reg_type: '新申辦',
        brand: 'Samsung',
        model: 'GALAXY S20 Ultra',
        color: '黑',
      },
    };
  }

  componentDidMount() {
    window.addEventListener('resize ', (e) => setMobile);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize ', (e) => setMobile);
  };

  nextStep = () => {
    this.props.history.push('/estore/event/pre-reg/event/success');
  };

  inputChange = (n, v) => {
    let newForm = Object.assign(this.state.form);

    newForm[n].value = v;

    this.setState({
      form: newForm,
    });

    this.forceUpdate();
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalConfirm = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    if (type === 'change-alert') {
      this.resetFormset();
    }
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalCancel = (type) => {
    // 預約取貨送出
    console.info('MODAL CANCEL', type);
    this.setState({
      modalOpen: false,
    });

    this.forceUpdate();
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { form, step, result } = this.state;
    return (
      <main className='is-event-pre-reg'>
        <NavTab pageTitle='網路門市' />
        <Breadcrumb
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '網路門市', link: '/estore' },
            { text: '促銷活動', link: '/estore/event' },
            { text: 'Samsung Galaxy S20 最新消息', link: '/estore/event/samsung-galaxy-s20-news' },
          ]}
        />
        <div className='fui-container'>
          <div className='pre-reg-search-button'>
            <Button
              btnStyle='secondary'
              size='small'
              onClick={(e) =>
                this.setState({
                  modalOpen: true,
                  currentModal: {
                    type: 'searchPreRegForm',
                    title: '查詢預約結果',
                  },
                })
              }>
              查詢預約結果
            </Button>
          </div>
          <div className='fui-event-banner pt-0'>
            <img src={this.state.isMobile ? this.state.campigan.sm : this.state.campigan.md} alt='' />
          </div>
        </div>

        <section>
          <div className='fui-container'>
            <h4 className='is-text-darkgray50'>請填寫預約資料</h4>
            <Formsy onValid={this.enableButton} onInValid={this.disableButton}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={5}>
                  <LabelInput
                    name='name'
                    {...form.name}
                    onChange={this.inputChange}
                    validationErrors={{
                      isDefaultRequiredValue: '請輸入姓名',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <LabelInput
                    name='id_number'
                    {...form.id_number}
                    onChange={this.inputChange}
                    validations={{
                      idNumberValid: Validation.idNumberValid,
                    }}
                    validationErrors={{
                      isDefaultRequiredValue: '請輸入身分證字號',
                      idNumberValid: '您輸入了錯誤資訊，請再檢查！',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <LabelInput
                    name='mobile'
                    {...form.mobile}
                    onChange={this.inputChange}
                    validations={{
                      mobileValid: Validation.mobileValid,
                    }}
                    validationErrors={{
                      isDefaultRequiredValue: '請輸入行動電話！',
                      mobileValid: '請輸入有效的行動電話！',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <LabelInput
                    name='email'
                    {...form.email}
                    onChange={this.inputChange}
                    validations={{
                      idNumberValid: Validation.emailValid,
                    }}
                    validationErrors={{
                      isDefaultRequiredValue: '請輸入電子郵件！',
                      emailValid: '請輸入有效的電子郵件！',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={10}>
                  <RadioGroup
                    name='reg_type'
                    {...form.reg_type}
                    options={[
                      { label: '新申辦', value: '新申辦' },
                      { label: '攜碼', value: '攜碼' },
                      { label: '續約', value: '續約' },
                      { label: '單買商品', value: '單買商品' },
                    ]}
                    default={form.reg_type.value}
                    onChange={this.inputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <div className='form-group'>
                    <label>品牌</label>
                    <Select
                      validationErrors={{
                        isDefaultRequiredValue: '請選擇品牌',
                      }}
                      required={form.brand.required}
                      value={form.brand.value}
                      label='請選擇'
                      options={[
                        { text: 'APPLE', value: 'APPLE' },
                        { text: 'LG', value: 'LG' },
                        { text: 'SAMSUNG', value: 'SAMSUNG' },
                      ]}
                      name='brand'
                      onChange={this.inputChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <div className='form-group'>
                    <label>型號</label>
                    <Select
                      validationErrors={{
                        isDefaultRequiredValue: '請選擇型號',
                      }}
                      {...form.model}
                      options={[
                        { text: '型號1', value: '型號1' },
                        { text: '型號2', value: '型號2' },
                        { text: '型號3', value: '型號3' },
                      ]}
                      name='model'
                      onChange={this.inputChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <div className='form-group'>
                    <label>顏色</label>
                    <Select
                      validationErrors={{
                        isDefaultRequiredValue: '請選擇顏色',
                      }}
                      {...form.color}
                      options={[
                        { text: '型號1', value: '型號1' },
                        { text: '型號2', value: '型號2' },
                        { text: '型號3', value: '型號3' },
                      ]}
                      name='model'
                      onChange={this.inputChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <div className='form-group'>
                    <label>數量</label>
                    <Select
                      validationErrors={{
                        isDefaultRequiredValue: '請選擇數量',
                      }}
                      {...form.number}
                      options={[
                        { text: '1', value: '1' },
                        { text: '2', value: '2' },
                        { text: '3', value: '3' },
                      ]}
                      name='model'
                      onChange={this.inputChange}
                    />
                  </div>
                </Grid>
              </Grid>

              <CheckboxAgree name='agree' onChange={this.inputChange}>
                填寫前請查看{' '}
                <Link to='#' target='_blank' className='is-underline-link is-text-sm is-text-accent ml-0'>
                  <span className='text'>個資保護法內容</span>
                  <img src={process.env.PUBLIC_URL + '/resources/common/images/external.svg'} alt='link' width='24' />
                </Link>
              </CheckboxAgree>

              <div className='page-action mt-0'>
                <Button btnStyle='primary' size='large' disabled={!this.state.canSubmit} onClick={this.nextStep}>
                  確認送出
                </Button>
              </div>
            </Formsy>
          </div>
        </section>

        <SectionCollapseInfo
          title='注意事項'
          content={`<ul>
            <li>本頁圖僅提供預約Samsung Galaxy S20，不代表訂購成立，實際出貨會依完成訂購付款流程及原廠供貨狀況為準，本預約活動限時限量，額滿為止。</li>
            <li>我要預約所選擇之申辦類型，送出後即無法變更，申請人需符合取貨當日遠傳新辦/攜碼/續約資格，實際申辦資格以系統為準，遠傳保留受理與否之權利。</li>
            <li>請務必確認預約時留存之資料均正確、完整，方能以原留資料進行取貨，若因資料錯誤或不可歸責於遠傳電信因素，致不符預約資格或無法與您取得聯繫，造成錯失取貨期間及相關取貨權利，遠傳電信恕不負責。</li>
            <li>商品取貨流程：本登記活動提供宅配到府或至遠傳直營門市取貨。活動期間於預約頁面申請完成，即可取得預約序號。取貨前會以簡訊發送通知至您預約登記時網頁填寫之相關資料。</li>
          </ul>
          `}
        />

        <EstoreModal
          open={this.state.modalOpen}
          {...this.state.currentModal}
          onSubmit={(e, f) => this.modalSubmit(e, f)}
          onConfirm={(e, f) => this.modalConfirm(e, f)}
          onCancel={(e, f) => this.modalCancel(e, f)}
          onClose={(e, f) => this.modalClose(e, f)}
        />
      </main>
    );
  }
}

export default PreReg;
