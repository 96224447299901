import React from 'react';

import { setCartData, trackPageViewStart } from '../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import Cart from '../components/Cart';
import Button from '../components/Button';

import RadioGroup from '../components/form/RadioGroup';
import RadioCard from '../components/form/RadioCard';
import RadioCarousel from '../components/form/RadioCarousel';
import CheckboxCardCarousel from '../components/form/CheckboxCardCarousel';
import LabelInput from '../components/form/LabelInput';
import NavAnchor from '../components/partials/NavAnchor';
import Breadcrumb from '../components/Breadcrumb';
import Tab from '../components/tab/Tab';
import TabPane from '../components/tab/TabPane';
import EStoreBanner from '../components/partials/banner/EStoreBanner';
import EstoreModal from '../components/EstoreModal';
import SectionCollapseInfo from '../components/partials/collapse/SectionCollapseInfo';

import { setMobile } from '../components/util';
import * as Validation from '../utils/validation';
import formatNumber from '../utils/numberFormatter';

import * as Mock from '../mock/RedAndGreen';

class RedAndGreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      phoneList: [],
      cartValid: false,
      currentModal: {
        type: 'notice',
        title: '',
        content: '',
      },
      applyFlow: Mock.pairStep,
      form: {
        phone: { value: '' },
        extra_product: { value: '', minLength: -1, maxLength: -1 },
      },
      cart: {
        list: {
          phone: [],
          extra_product: [],
        },
      },
    };
  }

  componentDidMount() {
    window.addEventListener('resize', (e) => setMobile(this));
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', (e) => setMobile(this));
  };

  inputChange = (n, v, obj) => {
    let newForm = Object.assign(this.state.form);
    let list = Object.assign(this.state.cart.list);
    newForm[n].value = v;
    list[n] = obj;

    if (n === 'phone') {
      let len = v.split(/,/g);
      newForm.extra_product.minLength = len.length;
      newForm.extra_product.maxLength = len.length;
    }

    // console.log(list.phone.length, list.extra_product.length);
    this.setState({
      form: newForm,
      cartUpdate: true,
      cartValid: list.phone.length > 0 && list.phone.length === list.extra_product.length,
      cart: {
        list: list,
      },
    });

    this.forceUpdate();

    setTimeout(() => {
      console.log('cartUpdate', this.state.cartUpdate);

      this.props.setCartData(this.state.cart.list);
    }, 300);
  };

  valuePreview = (flow) => {
    // 根據區塊名稱顯示個別內容
    if (flow.tabs) {
      return '';
    } else if (flow.name === 'identity_validation') {
      return this.state.form.scid.value;
    } else if (flow.name === 'input_validation') {
      return this.state.form.input_validation.value;
    } else if (flow.name === 'gift') {
      return this.state.cart[flow.name].length ? '贈品 x' + this.state.cart[flow.name].length : '';
    } else {
      return this.state.form[flow.name].value;
    }
  };

  updateFormFromCart = (cart) => {
    for (var i in cart.list) {
      let item = cart.list[i];
      let value = this.state.form[i].value.split(/,/g);
      if (item.length) {
        let newVal = [];
        item.forEach((prod, j) => {
          if (value.indexOf(prod.value) > -1) {
            newVal.push(prod.value);
          }
        });
        this.setState({
          form: {
            ...this.state.form,
            [i]: { value: newVal.toString() },
          },
        });
      } else {
        this.setState({
          form: {
            ...this.state.form,
            [i]: { value: '' },
          },
        });
      }
    }
    this.setState({ cart: cart });
  };

  openModal = (modal) => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'notice',
        ...modal,
      },
    });
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);

    this.setState({
      modalOpen: false,
    });

    if (type === 'otpForm') {
      this.identityCheck();
    }
    // 貨到通知送出
  };

  modalConfirm = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    if (type === 'change-alert') {
      this.resetFormset();
    }
    if (type === 'phoneCheck-alert') {
      // this.phoneCheck();
    }
    if (type === 'identityCheck-alert') {
      // this.identityCheck();
    }
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalCancel = (type) => {
    // 預約取貨送出
    console.info('MODAL CANCEL', type);
    if (type === 'change-alert') {
      this.canscelSelect();
    }
    this.setState({
      modalOpen: false,
    });
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { form } = this.state;
    return (
      <main>
        <NavAnchor pageTitle='網路門市' />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '網路門市', link: '/estore' },
            { text: '促銷活動商品列表', link: '/' },
          ]}
        />

        <EStoreBanner
          slides={[
            {
              image: {
                md: '/resources/cbu/estore-event-product/cbu-ebustore-1920x500.jpg',
                sm: '/resources/cbu/estore-event-product/cbu-ebustore-banner-750x900.jpg',
              },
              title: '單辦門號大標題文字最多十六個字元',
              description: [
                '一二三四五六七八九十一二三四五六',
                '一二三四五六七八九十一二三四五六',
                '一二三四五六七八九十一二三四五六',
              ],
              actions: [
                {
                  text: 'Primary',
                  btnStyle: 'primary',
                  link: '#',
                  target: '_self',
                },
                {
                  text: 'Secondary',
                  btnStyle: 'secondary',
                  link: '#',
                  target: '_blank',
                },
              ],
            },
          ]}
        />

        <section className='ribbon-bg pt-0'>
          <div className='fui-container'>
            <Formsy>
              <div className='fui-plan-flow-container' ref={this.flowRef}>
                {this.state.applyFlow.map((flow, i) => (
                  <section id={flow.id} className={`fui-plan-flow is-active`} key={`fui-plan-flow-${i}`}>
                    <div className='fui-flow-title'>
                      <div className='content'>
                        <h2>
                          <span>
                            {i + 1}.{flow.title}
                          </span>
                          <span className='selected-value'>{this.valuePreview(flow)}</span>
                        </h2>
                      </div>
                      {this.state.canEdit ? (
                        <div className='extra'>
                          <Button btnStyle='secondary' size='small' onClick={(e) => this.changeFormSection(i)}>
                            {this.state.cart[flow.name] === '' ||
                            !this.state.cart[flow.name] ||
                            (Array.isArray(this.state.cart[flow.name]) && !this.state.cart[flow.name].length)
                              ? '尚未選擇'
                              : '修改'}
                          </Button>
                        </div>
                      ) : null}
                    </div>
                    <div className='fui-flow-body'>
                      <h6 className='fui-flow-description'>
                        {flow.name === 'apply_method'
                          ? form.apply_method.value === '申辦新門號'
                            ? flow.description[0]
                            : form.apply_method.value === '攜碼到遠傳'
                            ? flow.description[1]
                            : form.apply_method.value === '遠傳門號續約'
                            ? flow.description[2]
                            : ' '
                          : flow.description}
                      </h6>
                      {/* 根據類別切換表單內容 */}
                      {/* 有 Tab 的產品牌卡，用於加購 */}
                      {flow.type === 'tab-checkbox-card' ? (
                        <div>
                          <Tab name={flow.tabs.name} list={flow.tabs.list} onChange={this.CheckboxTabChange} />
                          {flow.tabs.content.map((tab, i) => (
                            <TabPane key={`tab-checkbox-${i}`} active={i === this.state.cuurentCheckboxTab}>
                              <CheckboxCardCarousel
                                {...tab}
                                value={form[tab.name].value}
                                onChange={this.inputChange}
                                modalOpen={this.openModal}
                              />
                            </TabPane>
                          ))}
                        </div>
                      ) : null}
                      {/* 門號驗證 */}
                      {flow.type === 'identity-validation' ? (
                        <div className='paper fui-validation-input'>
                          <Grid container>
                            <Grid item xs={12} sm={8} md={6}>
                              <LabelInput
                                label='遠傳門號'
                                placeholder='輸入門號'
                                validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
                                required={form.scid.required}
                                name='scid'
                                validationErrors={{
                                  isDefaultRequiredValue: '請輸入原門號號碼',
                                  matchRegexp: '您輸入了錯誤資訊，請再檢查！',
                                }}
                                withValidCheck={
                                  this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                }
                                value={form.scid.value}
                                onChange={this.inputChange}
                              />
                              <LabelInput
                                label='身分證字號'
                                placeholder='輸入身分證字號'
                                required={form.id_number.required}
                                name='id_number'
                                validations={{
                                  idNumberValid: Validation.idNumberValid,
                                }}
                                validationErrors={{
                                  isDefaultRequiredValue: '請輸入身分證字號',
                                  idNumberValid: '您輸入了錯誤資訊，請再檢查！',
                                }}
                                withValidCheck={
                                  this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                }
                                value={form.id_number.value}
                                onChange={this.inputChange}
                              />
                              {!(this.state.cart.identity_validation && this.state.cart.identity_validation.isPass) ? (
                                <Button
                                  btnStyle='primary'
                                  size='large'
                                  disabled={form.scid.value === '' || form.id_number.value === ''}
                                  onClick={this.optVarify}>
                                  查詢可續約方案
                                </Button>
                              ) : null}
                            </Grid>
                          </Grid>
                        </div>
                      ) : null}
                      {/* 攜入門號驗證 */}
                      {flow.type === 'input-validation' ? (
                        <div className='fui-validation-input'>
                          <Grid container>
                            <Grid item xs={12}>
                              <div className='fui-cards three-card no-scrollbar'>
                                {flow.cards.map((card, c) => (
                                  <div className='fui-card' key={`input-validation-step-${c}`}>
                                    <div className='fui-card-action'>
                                      <div className='fui-card-caption'>
                                        <div className='fui-card-content'>
                                          <div className='circle-number'>{card.no}</div>
                                          <h3 className='fui-card-title'>{card.title}</h3>
                                          <p className='fui-card-description'>{card.description}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6}>
                              <LabelInput
                                label='輸入攜入門號'
                                placeholder='輸入原門號號碼'
                                validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
                                required={form.input_validation.required}
                                name='input_validation'
                                validationErrors={{
                                  isDefaultRequiredValue: '您輸入了錯誤資訊，請再檢查！',
                                  matchRegexp: '您輸入了錯誤資訊，請再檢查！',
                                }}
                                value={form.input_validation.value}
                                withValidCheck={
                                  this.state.cart.input_validation && this.state.cart.input_validation.isPass
                                }
                                onChange={this.inputChange}
                              />
                              <div
                                className='text-sm is-text-gray100'
                                dangerouslySetInnerHTML={{ __html: flow.note }}></div>
                              {!(this.state.cart.input_validation && this.state.cart.input_validation.isPass) ? (
                                <Button
                                  btnStyle='primary'
                                  size='large'
                                  disabled={!this.state.inputValidationValid}
                                  onClick={this.phoneCheck}>
                                  驗證號碼
                                </Button>
                              ) : null}
                            </Grid>
                          </Grid>
                        </div>
                      ) : null}
                      {/* 產品牌卡，用於好禮 */}
                      {flow.type === 'checkbox-card' ? (
                        <CheckboxCardCarousel
                          name={flow.name}
                          {...flow}
                          {...form[flow.name]}
                          onChange={this.inputChange}
                          modalOpen={this.openModal}
                        />
                      ) : null}
                      {/* 單選 Radio Button，選擇門號 */}
                      {flow.type === 'radio-carousel' ? (
                        <RadioCarousel
                          name={flow.name}
                          {...flow}
                          search={true}
                          value={form[flow.name].value}
                          onChange={this.inputChange}
                        />
                      ) : null}
                      {/* 單選牌卡，選擇資費方案 */}
                      {flow.type === 'radio-card' ? (
                        <RadioCard
                          name={flow.name}
                          tags={flow.tags}
                          options={flow.options}
                          value={form[flow.name].value}
                          onChange={this.inputChange}
                          modalOpen={this.openModal}
                        />
                      ) : null}
                      {/* 單純的 Radio Button，用於申請方式 */}
                      {flow.type === 'radio' ? (
                        <RadioGroup
                          label=''
                          name={flow.name}
                          options={flow.options}
                          default={form[flow.name].value}
                          value={form[flow.name].value}
                          onChange={this.inputChange}
                        />
                      ) : null}
                    </div>
                  </section>
                ))}
              </div>
            </Formsy>
          </div>
        </section>

        <SectionCollapseInfo title='貼心小叮嚀' content='貼心小叮嚀' />

        <Cart
          onUpdate={this.updateFormFromCart}
          afterUpdate={(e) => this.setState({ cartUpdate: false })}
          update={this.state.cartUpdate}
          steps={this.state.applyFlow}
          currentStep={this.state.step}
          openStep={this.state.editStep === -1 ? this.state.step : this.state.editStep}
          cart={this.state.cart}
          isValid={this.state.cartValid}
          stepChange={(e) => this.stepChange(e)}
          editStep={(e) => this.changeFormSection(e)}
        />

        <EstoreModal
          {...this.state.currentModal}
          open={this.state.modalOpen}
          onSubmit={this.modalSubmit}
          onConfirm={this.modalConfirm}
          onCancel={this.modalCancel}
          onClose={this.modalClose}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    analyticsInitialState: state.analyticsInitialState, // 可加可不加
    cart: state.cartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      trackPageViewStart,
      setCartData,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RedAndGreen));
