import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import ScrollMemory from './components/ScrollMemory';
import withTrackingRoute from './utils/trackingRouter';

import * as Action from './stores/action/index';
import reducers from './stores/reducers';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

import Loader from './components/Loader';
import EmmaService from './components/Emma';
import Cookie from './components/Cookie';
import GoTop from './components/GoTop';
import Header from './components/partials/header/Default';
import Footer from './components/partials/footer/Default';
import EstoreModal from './components/EstoreModal';

// loading animation
import Error from './pages/Error';

import Estore from './pages/Index';
import Exclusive from './pages/Exclusive';
import FindProduct from './pages/FindProduct';
import Product from './pages/Product';
import OrderList from './pages/order/OrderList';
import OrderListFast from './pages/order/OrderListFast';
import OrderListFastRenewal from './pages/order/OrderListFastRenewal';
import Identity from './pages/order/Identity';
import Identity2020 from './pages/order/Identity2020';
import IdentityFillin from './pages/order/IdentityFillin';
import Payment from './pages/order/Payment';
import Complete from './pages/order/Complete';
import OrderRecord from './pages/order/OrderRecord';

import RenewalLogin from './pages/renewal/Login';
import RenewalIndex from './pages/renewal/Index';

import RatePlan from './pages/RatePlan';
import ThemeNumber from './pages/ThemeNumber';

import Delivery from './pages/Delivery';
import PreReg from './pages/PreReg';
import PreRegSuccess from './pages/PreRegSuccess';
import PreRegFail from './pages/PreRegFail';

import Deals from './pages/Deals';
import AccessoryList from './pages/AccessoryList';
import PhoneList from './pages/PhoneList';
import RedAndGreen from './pages/RedAndGreen';
import RedAndGreenB from './pages/RedAndGreenB';
import MultiProduct from './pages/MultiProduct';
import SecretSpecialoffersShopCom from './pages/SecretSpecialoffersShopCom';
import SecretSpecialoffersShopComEDM from './pages/SecretSpecialoffersShopComEDM';

import EbuShop from './pages/EbuShop';
import EbuShopGroup from './pages/EbuShopGroup';

import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

// window.history.pushState('', null, './');
let path = '';
// 如果按瀏覽器上一頁，則 reload 畫面
if (typeof window !== 'undefined') {
  window.addEventListener('popstate', function () {
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;
    if (
      window.location.pathname.indexOf('/estore/product/event_product_list/products') === -1 &&
      window.location.pathname.indexOf('/estore/deals') === -1 &&
      window.location.pathname.indexOf('/estore/rateplan') === -1 &&
      window.location.pathname.indexOf('/estore/renewal') === -1 &&
      window.location.pathname.indexOf('/estore/order') === -1 &&
      window.location.pathname.indexOf('/detail') === -1
    ) {
      window.scrollTo(0, 0);
      $html.className = 'is-loading is-loading-block';
      $html.classList.remove('fade-in');
      $body.classList.add('is-loading');
      window.location.reload(true);
    }
  });

  path = window.location.pathname;
}

function TrackingRoute(props) {
  const eventViewStart = new CustomEvent('event-view-start');
  const eventViewEnd = new CustomEvent('event-view-end');
  const app = document.querySelector('#app');
  const PageComponent = props.component;

  let location = props.location;
  //fire event-view-start
  // launchDigialData(location);
  app.dispatchEvent(eventViewStart);
  useEffect(() => {
    // launchDigialData(location);
    //fire event-view-end
    document.title = props.title;
    app.dispatchEvent(eventViewEnd);
  }, [app, eventViewEnd, location, location.pathname, props.location, props.title]);

  return <PageComponent />;
}

const App = (props) => {
  const [isIEModalOpen, setIsIEModalOpen] = React.useState(false);
  
  const getEmma = () => {
    return {
      text: path.indexOf('/cbu/5g') > -1 ? '我有興趣' : null,
      link: getEmmaLink(),
      show: true,
      useEmmaAvatar: true,
    };
  };

  const getEmmaLink = () => {
    return path.indexOf('/cbu/5g') > -1 ? 'https://enterprise.fetnet.net/content/ebu/tw/5g/form/5G-cbu-form.html' : '';
  };

  const lazyLoad = (e) => {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
      e.target.style.backgroundImage = 'url(' + bg + ')';
    }
  };

  const setMember = () => {
    // 如果有登入可直接呼叫 redux action
    props.setLoginData({ canRenewal: true });
  };

  useEffect(() => {
    if(window !== 'undefined' && !Cookies.get('fetnet-ie-alert') ) {
      console.log('App useEffect, isIE: ' + window.isIE)
      setIsIEModalOpen(true);
    }
    setMember();

    document.addEventListener('lazybeforeunveil', lazyLoad);
    return () => {
      document.removeEventListener('lazybeforeunveil', lazyLoad);
    };
  }, []);

  const closeIEModal = () => {
    Cookies.set(
      'fetnet-ie-alert',
      {
        open: isIEModalOpen || 0,
      },
      { expires: 30 }
    );

    setIsIEModalOpen(false);
  }

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollMemory />
      <Loader />
      <Header />
      <ParallaxProvider>
        <Switch>
          <Route path='/' exact component={Estore} />
          {/* <Route path='/estore' exact component={withTrackingRoute(Estore, '網路商店')} /> */}
          <Route path='/estore' exact component={Estore} />
          <Route path='/estore/ebuShop' exact component={EbuShop} />
          <Route path='/estore/ebuShopGroup' exact component={EbuShopGroup} />
          <Route path='/estore/exclusive' exact component={Exclusive} />

          <Route path='/estore/exclusive/:plan' exact component={Exclusive} />
          <Route path='/estore/product' exact component={FindProduct} />

          <Route path='/estore/product/event_product_list/accessory' exact component={AccessoryList} />
          <Route path='/estore/product/event_product_list/phone' exact component={PhoneList} />
          <Route path='/estore/product/event_product_list/red-green-b' exact component={RedAndGreenB} />
          <Route path='/estore/product/event_product_list/red-green' exact component={RedAndGreen} />
          <Route path='/estore/product/event_product_list/products' exact component={MultiProduct} />
          <Route
            path='/business/specialoffer/secret_specialoffers_shopCom'
            exact
            component={SecretSpecialoffersShopCom}
          />
          <Route
            path='/business/specialoffer/secret_specialoffers_shopCom/edm'
            exact
            component={SecretSpecialoffersShopComEDM}
          />

          <Route path='/estore/product/:category/detail/:pname' exact component={Product} />
          <Route path='/estore/product/:tab' exact component={FindProduct} />
          <Route path='/estore/product/:tab/:category' exact component={FindProduct} />

          {/* 申辦流程 */}
          <Route path='/estore/order/orderlist_fast' exact component={OrderListFast} />
          <Route path='/estore/order/orderlist' exact component={OrderList} />
          <Route path='/estore/order/orderlist_fast_renewal' exact component={OrderListFastRenewal} />
          <Route path='/estore/order/identity' exact component={Identity} />
          <Route path='/estore/order/identity_2020' exact component={Identity2020} />
          <Route path='/estore/order/fillin' exact component={IdentityFillin} />
          <Route path='/estore/order/payment' exact component={Payment} />
          <Route path='/estore/order/complete' exact component={Complete} />

          {/* 訂單紀錄 */}
          <Route path='/estore/order/record' exact component={OrderRecord} />

          {/* 找方案 */}
          <Route path='/estore/rateplan' exact component={RatePlan} />

          {/* 推薦續約 */}
          <Route path='/estore/rateplan/theme-number' exact component={ThemeNumber} />
          <Route path='/estore/rateplan/theme-number/plan-select' exact component={RatePlan} />

          {/* 推薦續約 */}
          <Route path='/estore/renewal/' exact component={RenewalLogin} />
          <Route path='/estore/renewal/exclusive-offer' exact component={RenewalIndex} />
          <Route path='/estore/renewal/rateplan' exact component={RenewalIndex} />
          <Route path='/estore/renewal/phone' exact component={RenewalIndex} />
          <Route path='/estore/renewal/3c' exact component={RenewalIndex} />
          <Route path='/estore/renewal/phone/:pname' exact component={Product} />
          <Route path='/estore/renewal/3c/:pname' exact component={Product} />

          {/* 配送說明 */}
          <Route path='/estore/info/delivery' exact component={Delivery} />
          <Route path='/estore/event/pre-reg/:event' exact component={PreReg} />
          <Route path='/estore/event/pre-reg/:event/complete' exact component={PreRegSuccess} />
          <Route path='/estore/event/pre-reg/:event/due' exact component={PreRegFail} />

          {/* 單辦門號 */}
          <Route path='/estore/deals' exact component={Deals} />

          <Route component={Error} />
        </Switch>
      </ParallaxProvider>
      <Footer adShow={true} />
      <GoTop />
      {
        window.isIE && !Cookies.get('fetnet-ie-alert')
        ? <EstoreModal
            className="is-ie-alert"
            open={isIEModalOpen}
            type="notice"
            title="你正使用IE瀏覽器"
            content="偵測到你正使用IE瀏覽器進行瀏覽，建議使用最新版本的Chrome, Firefox, Safari或者Edge瀏覽器，以享受最全面的遠傳網路門市瀏覽體驗。"
            onClose={closeIEModal}
          /> 
        : null
      }
      
      <EmmaService {...getEmma()} />
      <Cookie />
    </Router>
  );
};

const ReduxExample = connect((state) => state, { ...Action })(App);

export default ReduxExample;
