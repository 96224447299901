import React from 'react';
import Modal from 'react-modal';
import Formsy from 'formsy-react';

import Button from './Button';
import LabelInput from './form/LabelInput';
import TextInput from './form/TextInput';
import RadioButtons from './form/RadioButtons';
import PlanTable from './partials/PlanTable';

import PropTypes from 'prop-types';
import * as Validation from '../utils/validation';
import * as Compare from '../mock/CompareData';

let countdownTimeout = null;

class EstoreModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // reservationForm
      resend: false,
      currentHover: -1,
      countdown: 600,
      countdownTimeout: 600,
      modalOpen: this.props.open || false,
      combinebillSubmit: false,
      deliveryFormWithPlan: this.props.deliveryFormWithPlan || false,
      combinebillForm: {
        scid: { value: '', required: true },
        id_number: { value: '', required: true },
      },
      activityFormSubmit: false,
      activityForm: {
        valid_number: { value: '', required: true },
        serial_number: { value: '', required: true },
      },
      preregSubmit: false,
      searchPreRegForm: {
        scid: { value: '', required: true },
        id_number: { value: '', required: true },
        recapcha: { value: '', required: true },
      },
      reservationSubmit: false,
      reservationForm: {
        reserve_no: { value: '', required: true },
        id_number: { value: '', required: true },
      },
      // deliveryForm
      deliveryFormSubmit: false,
      deliveryForm: {
        type: { value: '' },
        name: { value: '', required: true },
        contact: { value: '', required: true },
      },
      otpFormSubmit: false,
      otpForm: {
        otp_number: { value: '' },
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        deliveryFormWithPlan: this.props.deliveryFormWithPlan || false,
        modalOpen: this.props.open,
      });

      if (this.props.open && this.props.type === 'otpForm') {
        this.doCountDown();
      }
    }
  }

  resetCountdown = () => {
    // API 重新發送簡訊
    this.setState({
      resend: true,
      countdown: 600,
    });
    this.doCountDown();
  };

  doCountDown = () => {
    countdownTimeout = setInterval(() => {
      if (this.state.countdown - 1 >= 0) {
        this.setState({
          countdown: this.state.countdown - 1,
        });
      } else {
        clearInterval(countdownTimeout);
      }
    }, 1000);
  };

  inputChange = (name, value) => {
    let type = this.props.type;
    const newForm = Object.assign(this.state[type]);

    newForm[name].value = value;

    this.setState({
      [type]: newForm,
    });
  };

  enableComninebillButton = () => {
    this.setState({
      combinebillSubmit: true,
    });
  };

  disableComninebillButton = () => {
    this.setState({
      combinebillSubmit: false,
    });
  };

  enablePreRegButton = () => {
    this.setState({
      preregSubmit: true,
    });
  };

  disablePreRegButton = () => {
    this.setState({
      preregSubmit: false,
    });
  };

  searchPreRegForm = () => {
    const { searchPreRegForm } = this.state;

    return (
      <div className='fui-modal-content'>
        {this.renderModalTitle()}
        <div className='fui-modal-body'>
          <Formsy onValid={this.enablePreRegButton} onInvalid={this.disablePreRegButton}>
            <LabelInput
              label='身分證字號'
              placeholder='輸入身分證字號'
              required={searchPreRegForm.id_number.required}
              name='id_number'
              validations={{
                idNumberValid: Validation.idNumberValid,
              }}
              validationErrors={{
                isDefaultRequiredValue: '請輸入身分證字號',
                idNumberValid: '您輸入了錯誤資訊，請再檢查！',
              }}
              withValidCheck={
                searchPreRegForm.id_number.value !== '' &&
                Validation.idNumberValid(null, searchPreRegForm.id_number.value) === true
              }
              value={searchPreRegForm.id_number.value}
              onChange={this.inputChange}
            />
            <LabelInput
              label='手機號碼'
              placeholder='輸入門號'
              validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
              required={searchPreRegForm.scid.required}
              name='scid'
              validationErrors={{
                isDefaultRequiredValue: '請輸入手機號碼',
                matchRegexp: '您輸入了錯誤資訊，請再檢查！',
              }}
              value={searchPreRegForm.scid.value}
              onChange={this.inputChange}
              withValidCheck={
                searchPreRegForm.scid.value !== '' && /^09\d{2}-?\d{3}-?\d{3}$/.test(searchPreRegForm.scid.value)
              }
            />
            <div className='captcha'>
              <div className='form-group mb-3'>
                <TextInput
                  name='recapcha'
                  value={searchPreRegForm.recapcha.value}
                  validationErrors={{
                    isDefaultRequiredValue: '請輸入驗證碼',
                  }}
                  placeholder='請輸入驗證碼'
                  required={true}
                  onChange={(v) => this.inputChange}
                />
                <div className='captcha-box'>
                  <div className='captcha-number'>
                    <img src='https://cldup.com/gZ7MdGePW6-3000x3000.png' alt='驗證碼圖片' />
                  </div>
                  <button type='button' className='captcha-reload'>
                    <img
                      src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoBAMAAAB+0KVeAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUxpcYiIiIeHh39/f4iIiIeHh4eHh4iIiIiIiIiIiIeHh4eHh4WFhYiIiImJiYiIiOpIBKYAAAAPdFJOUwDfIBCgfT+/75DPYFCvUPAcHYoAAAErSURBVHjatVKxSgNBFHzEeCKxOUHbTSpLt1Hbs7JUEOsNIraxsLALYiHYnHDgD1hoFwt7W2vF2m84uSMxiCNvdzWDKaycZt/Ozt7OvHvyb5i5v8XFS1sYyQkUwzviGq8IKEn7qPsbA6D+4WaB0ZUVOTBAJhEOKHzRMqgitwAsx3IFuAxVh77v8BGKbSzKN5oorfcDtCchDHbC4TtZ7uFMl1W8ETmHT10ekHFgVOGdLncmL3Xpwwph4Ld6RAgXMRSGmyI30jTHabokfL1hoMjE8EMd5Sq2FKXq22lalo41pqZl6bVPWwtLx9Y3fmRZ+iwh55FMoUcN7dIfLmI5X1MPyqBIBhN7zThFSR9kzwE43tvKeZh0MiIKMrAW2SdhtDYBnO/KL6wf7v8x7V/iaqjxLs+LqgAAAABJRU5ErkJggg=='
                      alt='button'
                    />
                  </button>
                </div>
              </div>
            </div>
          </Formsy>
        </div>
        <div className='fui-modal-foot two-buttons'>
          <Button
            btnStyle='primary'
            disabled={!this.state.preregSubmit}
            onClick={(e) => this.props.onSubmit(this.props.type, searchPreRegForm)}>
            確認送出
          </Button>
          <Button btnStyle='secondary' onClick={(e) => this.props.onClose(this.props.type)}>
            關閉
          </Button>
        </div>
      </div>
    );
  };

  combinebillForm = () => {
    const { combinebillForm } = this.state;

    return (
      <div className='fui-modal-content'>
        {this.renderModalTitle()}
        <div className='fui-modal-body'>
          <Formsy onValid={this.enableComninebillButton} onInvalid={this.disableComninebillButton}>
            <p>我已有遠傳其他門號，我要合併帳單，輸入以下資訊將自動帶出部分基本資料，加速填寫流程。</p>
            <LabelInput
              label='遠傳門號'
              placeholder='輸入門號'
              validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
              required={combinebillForm.scid.required}
              name='scid'
              validationErrors={{
                isDefaultRequiredValue: '請輸入原門號號碼',
                matchRegexp: '您輸入了錯誤資訊，請再檢查！',
              }}
              value={combinebillForm.scid.value}
              onChange={this.inputChange}
              withValidCheck={true}
            />
            <LabelInput
              label='身分證字號'
              placeholder='輸入身分證字號'
              required={combinebillForm.id_number.required}
              name='id_number'
              validations={{
                idNumberValid: Validation.idNumberValid,
              }}
              validationErrors={{
                isDefaultRequiredValue: '請輸入身分證字號',
                idNumberValid: '您輸入了錯誤資訊，請再檢查！',
              }}
              withValidCheck={true}
              value={combinebillForm.id_number.value}
              onChange={this.inputChange}
            />
          </Formsy>
        </div>
        <div className='fui-modal-foot two-buttons'>
          <Button
            btnStyle='primary'
            disabled={!this.state.combinebillSubmit}
            onClick={(e) => this.props.onSubmit(this.props.type, combinebillForm)}>
            確認查詢
          </Button>
          <Button btnStyle='secondary' onClick={(e) => this.props.onClose(this.props.type)}>
            關閉
          </Button>
        </div>
      </div>
    );
  };

  setHover = (index) => {
    this.setState({
      currentHover: index,
    });
  };

  renderModalTitle = () => {
    return this.props.title ? (
      <div className='fui-modal-head'>
        <h5>{this.props.title}</h5>
      </div>
    ) : null;
  };
  // ReservationForm start
  enableReservationButton = () => {
    this.setState({
      reservationSubmit: true,
    });
  };

  disableReservationButton = () => {
    this.setState({
      reservationSubmit: false,
    });
  };

  reservationForm = () => {
    const { reservationForm } = this.state;

    return (
      <div className='fui-modal-content'>
        {this.renderModalTitle()}
        <div className='fui-modal-body'>
          <p>請填寫以下資料以完成取貨流程，謝謝！</p>
          <Formsy onValid={this.enableReservationButton} onInvalid={this.disableReservationButton}>
            <LabelInput
              name='reserve_no'
              label='預約單號'
              placeholder='請輸入預約單號英數共16碼'
              required={reservationForm.reserve_no.required}
              validationErrors={{
                isDefaultRequiredValue: '請再次確認有效的單號！',
              }}
              value={reservationForm.reserve_no.value}
              onChange={this.inputChange}
            />
            <LabelInput
              name='id_number'
              label='身分證字號'
              validations={{
                idNumberValid: Validation.idNumberValid,
              }}
              validationErrors={{
                isDefaultRequiredValue: '請輸入身分證字號',
                idNumberValid: '您輸入了錯誤資訊，請再檢查！',
              }}
              placeholder='請輸入身分證字號'
              required={reservationForm.id_number.required}
              value={reservationForm.id_number.value}
              onChange={this.inputChange}
            />
          </Formsy>
        </div>
        <div className='fui-modal-foot fui-buttons two-buttons'>
          <Button
            btnStyle='primary'
            disabled={!this.state.reservationSubmit}
            onClick={(e) => this.props.onSubmit(this.props.type, reservationForm)}>
            確定送出
          </Button>
          <Button btnStyle='secondary' onClick={(e) => this.props.onClose(this.props.type)}>
            關閉
          </Button>
        </div>
      </div>
    );
  };
  // ReservationForm end
  /** ---- */
  // Delivery Form start
  enableDeliveryButton = () => {
    this.setState({
      deliverySubmit: true,
    });
  };

  disableDeliveryButton = () => {
    this.setState({
      deliverySubmit: false,
    });
  };

  deliveryForm = () => {
    const { deliveryForm } = this.state;

    return (
      <div className='fui-modal-content'>
        {this.renderModalTitle()}
        <div className='fui-modal-body'>
          <p>請留下以下資料，我們將在商品到貨後第一時間立馬通知你！</p>
          <Formsy onValid={this.enableDeliveryButton} onInvalid={this.disableDeliveryButton}>
            {this.props.deliveryFormWithPlan === '1' ? (
              <RadioButtons
                name='type'
                label='訂單類別'
                options={[
                  { label: '新申辦', value: '新申辦' },
                  { label: '攜碼', value: '攜碼' },
                  { label: '續約', value: '續約' },
                ]}
                selected={deliveryForm.type.value}
                getSelected={this.inputChange}
              />
            ) : null}
            <LabelInput
              name='name'
              label='姓名'
              required={deliveryForm.name.required}
              value={deliveryForm.name.value}
              validationErrors={{
                isDefaultRequiredValue: '請輸入申辦人的姓名',
              }}
              placeholder='請輸入申辦人的姓名'
              onChange={this.inputChange}
            />
            <LabelInput
              name='contact'
              label='聯絡方式'
              required={deliveryForm.contact.required}
              value={deliveryForm.contact.value}
              validationErrors={{
                isDefaultRequiredValue: '請輸入聯絡方式',
              }}
              placeholder='請輸入你的手機號碼或電子郵件信箱'
              onChange={this.inputChange}
            />
          </Formsy>
        </div>
        <div className='fui-modal-foot fui-buttons two-buttons'>
          <Button
            btnStyle='primary'
            disabled={!this.state.deliverySubmit}
            onClick={(e) => this.props.onSubmit(this.props.type, deliveryForm)}>
            確定送出
          </Button>
          <Button btnStyle='secondary' onClick={(e) => this.props.onClose(this.props.type)}>
            關閉
          </Button>
        </div>
      </div>
    );
  };
  // Delivery Form end
  enableActivityForm = () => {
    this.setState({
      activityFormSubmit: true,
    });
  };

  disableActivityForm = () => {
    this.setState({
      activityFormSubmit: false,
    });
  };

  activityForm = () => {
    const { activityForm } = this.state;
    return (
      <div className='fui-modal-content'>
        {this.renderModalTitle()}
        <div className='fui-modal-body'>
          <p>請留下以下資料，我們將在商品到貨後第一時間立馬通知你！</p>
          <Formsy onValid={this.enableActivityForm} onInvalid={this.disableActivityForm}>
            <LabelInput
              name='valid_number'
              label='驗證碼'
              required={activityForm.valid_number.required}
              value={activityForm.valid_number.value}
              validationErrors={{
                isDefaultRequiredValue: '請輸入驗證碼',
              }}
              placeholder='請輸入驗證碼'
              onChange={this.inputChange}
            />
            <LabelInput
              name='serial_number'
              label='序號'
              required={activityForm.serial_number.required}
              value={activityForm.serial_number.value}
              validationErrors={{
                isDefaultRequiredValue: '請輸入序號',
              }}
              placeholder='請輸入序號'
              onChange={this.inputChange}
            />
          </Formsy>
        </div>
        <div className='fui-modal-foot fui-buttons two-buttons'>
          <Button
            btnStyle='primary'
            disabled={!this.state.activityFormSubmit}
            onClick={(e) => this.props.onSubmit(this.props.type, activityForm)}>
            確認
          </Button>
          <Button btnStyle='secondary' onClick={(e) => this.props.onClose(this.props.type)}>
            關閉
          </Button>
        </div>
      </div>
    );
  };

  enableOtpButton = () => {
    this.setState({
      otpFormSubmit: true,
    });
  };

  disableOtpButton = () => {
    this.setState({
      otpFormSubmit: false,
    });
  };

  otpValidationForm = () => {
    return (
      <div className='fui-modal-content'>
        {this.renderModalTitle()}
        <div className='fui-modal-body'>
          <Formsy onValid={this.enableOtpButton} onInvalid={this.disableOtpButton}>
            <p className='is-text-medium'>驗證碼已發送至 {this.props.phone}，請輸入簡訊驗證碼。 </p>
            <LabelInput
              name='otp_number'
              required={true}
              label='簡訊驗證碼'
              placeholder='請輸入簡訊驗證碼'
              validationErrors={{
                isDefaultRequiredValue: '請輸入簡訊驗證碼',
              }}
              value={this.state.otpForm.otp_number.value}
              onChange={this.inputChange}
            />
            <div>
              <span
                className='mr-1'
                dangerouslySetInnerHTML={{
                  __html:
                    this.state.countdown === 0
                      ? '認證時間已到期！'
                      : (this.state.resend ? '你的簡訊已重新發送！' : '') +
                        `剩餘 <span class='is-text-accent'>${this.state.countdown}</span> 秒`,
                }}></span>
              <span
                role='button'
                onClick={this.resetCountdown}
                className='d-inline-block is-text-accent is-text-medium'>
                重新取得？
              </span>
            </div>
          </Formsy>
        </div>
        <div className='fui-modal-foot fui-buttons two-buttons'>
          <Button
            btnStyle='primary'
            disabled={!this.state.otpFormSubmit || this.state.countdown === 0}
            onClick={(e) => this.props.onSubmit(this.props.type, this.state.otpForm)}>
            送出
          </Button>
          <Button btnStyle='secondary' onClick={(e) => this.props.onClose(this.props.type)}>
            關閉
          </Button>
        </div>
      </div>
    );
  };

  alertContent = () => {
    return (
      <div className='fui-modal-content'>
        {this.renderModalTitle()}
        {this.renderContent()}
        <div className='fui-modal-foot fui-buttons two-buttons'>
          <Button btnStyle='primary' onClick={(e) => this.props.onConfirm(this.props.type)}>
            {this.props.confirmText || '確定'}
          </Button>
          <Button btnStyle='secondary' onClick={(e) => this.props.onCancel(this.props.type)}>
            {this.props.cancelText || '取消'}
          </Button>
        </div>
      </div>
    );
  };

  renderCompare = () => {
    return (
      <div className='fui-modal-content is-compare-table'>
        {this.renderModalTitle()}
        <div className='fui-modal-body'>
          <div className='fui-plan-table'>
            <div className='fui-plan-table-header'>
              <div className='fui-plan-table-item is-title'>
                <div className='fui-plan-table-label'>資費方案</div>
              </div>
              <div className='fui-plan-table-item more-height'>
                <div className='fui-plan-table-label'>約期</div>
              </div>
              <div className='fui-plan-table-item more-height'>
                <div className='fui-plan-table-label'>數據傳輸量</div>
              </div>
              <div className='fui-plan-table-item more-height'>
                <div className='fui-plan-table-label'>網內通話</div>
              </div>
              <div className='fui-plan-table-item more-height'>
                <div className='fui-plan-table-label'>網外通話</div>
              </div>
              <div className='fui-plan-table-item more-height'>
                <div className='fui-plan-table-label'>活動優惠</div>
              </div>
            </div>
            <div className='fui-plan-table-body'>
              {Compare.CompareData.map((item, k) =>
                k + 1 <= this.props.compareList.length ? (
                  <div className={`fui-plan-table-segment`} key={`fui-plan-table-segment-${k}`}>
                    <div className='fui-plan-table-title'>
                      <h1>
                        <span className='price is-text-error'>
                          <small>$</small>
                          {item.title}
                        </span>
                        <small>{item.unit}</small>
                      </h1>
                      <Button target={item.action.target || '_self'} link={item.action.link} btnStyle='secondary'>
                        {item.action.text}
                      </Button>
                    </div>
                    <div
                      onMouseOut={(e) => this.setHover(-1)}
                      onMouseOver={(e) => this.setHover(0)}
                      className={`fui-plan-table-item more-height is-text-black50 ${
                        this.state.currentHover === 0 ? 'is-hover' : ''
                      }`}>
                      {item.detail.duration.map((du, idx) => (
                        <div key={`fui-plan-table-segment-${k}-duration-${idx}`}>{du}</div>
                      ))}
                    </div>

                    {typeof item.detail.throughput === 'string' ? (
                      <div
                        onMouseOut={(e) => this.setHover(-1)}
                        onMouseOver={(e) => this.setHover(1)}
                        className={`fui-plan-table-item ${this.state.currentHover === 1 ? 'is-hover' : ''} more-height`}
                        dangerouslySetInnerHTML={{ __html: item.detail.throughput }}></div>
                    ) : (
                      <div
                        onMouseOut={(e) => this.setHover(-1)}
                        onMouseOver={(e) => this.setHover(1)}
                        className={`fui-plan-table-item ${
                          this.state.currentHover === 1 ? 'is-hover' : ''
                        } more-height`}>
                        {item.detail.throughput.map((through, idx) => (
                          <div
                            key={`fui-plan-table-segment-${k}-throughput-${idx}`}
                            dangerouslySetInnerHTML={{ __html: through }}></div>
                        ))}
                      </div>
                    )}

                    {typeof item.detail.intranet === 'string' ? (
                      <div
                        onMouseOut={(e) => this.setHover(-1)}
                        onMouseOver={(e) => this.setHover(2)}
                        className={`fui-plan-table-item ${this.state.currentHover === 1 ? 'is-hover' : ''} more-height`}
                        dangerouslySetInnerHTML={{ __html: item.detail.intranet }}></div>
                    ) : (
                      <div
                        onMouseOut={(e) => this.setHover(-1)}
                        onMouseOver={(e) => this.setHover(2)}
                        className={`fui-plan-table-item ${
                          this.state.currentHover === 1 ? 'is-hover' : ''
                        } more-height`}>
                        {item.detail.intranet.map((intra, idx) => (
                          <div
                            key={`fui-plan-table-segment-${k}-intranet-${idx}`}
                            dangerouslySetInnerHTML={{ __html: intra }}></div>
                        ))}
                      </div>
                    )}

                    {typeof item.detail.offnet === 'string' ? (
                      <div
                        onMouseOut={(e) => this.setHover(-1)}
                        onMouseOver={(e) => this.setHover(3)}
                        className={`fui-plan-table-item ${this.state.currentHover === 1 ? 'is-hover' : ''} more-height`}
                        dangerouslySetInnerHTML={{ __html: item.detail.offnet }}></div>
                    ) : (
                      <div
                        onMouseOut={(e) => this.setHover(-1)}
                        onMouseOver={(e) => this.setHover(3)}
                        className={`fui-plan-table-item ${
                          this.state.currentHover === 1 ? 'is-hover' : ''
                        } more-height`}>
                        {item.detail.offnet.map((off, idx) => (
                          <div
                            key={`fui-plan-table-segment-${k}-offnet-${idx}`}
                            dangerouslySetInnerHTML={{ __html: off }}></div>
                        ))}
                      </div>
                    )}

                    {typeof item.detail.offter === 'string' ? (
                      <div
                        onMouseOut={(e) => this.setHover(-1)}
                        onMouseOver={(e) => this.setHover(5)}
                        className={`fui-plan-table-item ${this.state.currentHover === 1 ? 'is-hover' : ''} more-height`}
                        dangerouslySetInnerHTML={{
                          __html: !item.detail.offter || item.detail.offter === '' ? '-' : item.detail.offter,
                        }}></div>
                    ) : (
                      <div
                        onMouseOut={(e) => this.setHover(-1)}
                        onMouseOver={(e) => this.setHover(5)}
                        className={`fui-plan-table-item ${
                          this.state.currentHover === 1 ? 'is-hover' : ''
                        } more-height`}>
                        {item.detail.offter.map((gift, idx) => (
                          <div
                            key={`fui-plan-table-segment-${k}-offter-${idx}`}
                            dangerouslySetInnerHTML={{ __html: !gift || gift === '' ? '-' : gift }}></div>
                        ))}
                      </div>
                    )}
                  </div>
                ) : null
              )}
            </div>
          </div>
        </div>
        <div className='fui-modal-foot'>
          <Button btnStyle='primary' onClick={(e) => this.props.onClose(this.props.type)}>
            修改
          </Button>
          <Button btnStyle='secondary' onClick={(e) => this.props.onCancel(this.props.type)}>
            取消比較
          </Button>
        </div>
      </div>
    );
  };

  renderTable = (obj) => {
    let table = '';
    for (var i in obj) {
      table += `
        <tr>
          <th>${i}</th>
          <td>${Array.isArray(obj[i]) ? this.renderTabelCell(obj[i]) : `<b>${obj[i]}</b>`}</td>
        </tr>
      `;
    }
    return (
      <div className='fui-table-response mb-3'>
        <table dangerouslySetInnerHTML={{ __html: table }}></table>
      </div>
    );
  };

  renderTabelCell = (cell) => {
    let text = '';
    cell.forEach((content, i) => {
      if (i === 0) text += `<div><b>${content}</b></div>`;
      else text += `<small>${content}</small>`;
    });

    return text;
  };

  renderList = (list) => {
    return list.map((item, i) => (
      <div className='fui-item' key={`fui-modal-body-item-${Math.random() * 1000}`}>
        <span className='prefix'>
          <i className='bulleted-dot'></i>
        </span>
        <span className='content' dangerouslySetInnerHTML={{ __html: item }}></span>
      </div>
    ));
  };

  renderContent = () => {
    const { content } = this.props;

    if (typeof content === 'string') {
      return <div className='fui-modal-body' dangerouslySetInnerHTML={{ __html: content }}></div>;
    }

    if (Array.isArray(content)) {
      return (
        <div className='fui-modal-body'>
          {content.map((segment, i) =>
            typeof segment === 'string' ? (
              <p dangerouslySetInnerHTML={{ __html: segment }}></p>
            ) : Array.isArray(segment) ? (
              this.renderList(segment)
            ) : typeof segment === 'object' ? (
              this.renderTable(segment)
            ) : null
          )}
        </div>
      );
    }
  };

  discountContent = () => {
    return (
      <div className='fui-modal-content'>
        {this.renderModalTitle()}
        <div className='discount-divider'>
          <img src={process.env.PUBLIC_URL + '/resources/cbu/estore-product/discount-divider.png'} alt='divider' />
        </div>
        {this.renderContent()}
        <div className='fui-modal-foot'>
          <Button btnStyle='primary' onClick={(e) => this.props.onClose(this.props.type)}>
            {this.props.closeText || '我暸解了'}
          </Button>
        </div>
      </div>
    );
  };

  noticeContent = () => {
    return (
      <div className='fui-modal-content'>
        {this.renderModalTitle()}
        {this.renderContent()}
        <div className='fui-modal-foot'>
          <Button btnStyle='primary' onClick={(e) => this.props.onClose(this.props.type)}>
            {this.props.closeText || '我暸解了'}
          </Button>
        </div>
      </div>
    );
  };

  secondCard = () => {
    return (
      <div className='fui-modal-content'>
        {this.renderModalTitle()}
        <div className='fui-modal-body'>
          <div class='image-content'>
            <div class='image'>
              <img src={process.env.PUBLIC_URL + '/resources/cbu/order/health-id-card@2x.jpg'} width='160' alt='' />
            </div>
            <div class='content'>
              <h6>健保IC卡</h6>
              <div>須包含照片及左下角之流水編號 (僅接受有個人照片之IC卡)</div>
            </div>
          </div>
          <div class='image-content'>
            <div class='image'>
              <img src={process.env.PUBLIC_URL + '/resources/cbu/order/driver-license@2x.jpg'} width='160' alt='' />
            </div>
            <div class='content'>
              <h6>駕照</h6>
              <div>須有管轄編號 (共12碼)</div>
            </div>
          </div>
          {/* <div class='image-content'>
            <div class='image'>
              <img src={process.env.PUBLIC_URL + '/resources/cbu/order/student-card@2x.jpg'} width='160' alt='' />
            </div>
            <div class='content'>
              <h6>學生證</h6>
              <div>須包含照片、身份證字號及學校名稱</div>
            </div>
          </div> */}
        </div>
        <div className='fui-modal-foot'>
          <Button btnStyle='primary' onClick={(e) => this.props.onClose(this.props.type)}>
            {this.props.closeText || '我暸解了'}
          </Button>
        </div>
      </div>
    );
  };

  iframeContent = () => {
    return (
      <div className='fui-modal-content'>
        {this.renderModalTitle()}
        <div className='fui-modal-body'>
          <iframe src={this.props.content} />
        </div>
        <div className='fui-modal-foot'>
          <Button btnStyle='primary' onClick={(e) => this.props.onClose(this.props.type)}>
            {this.props.closeText || '關閉'}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { type } = this.props;

    return (
      <Modal
        onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
        onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
        isOpen={this.props.open}
        contentLabel='Example Modal'
        className={
          this.props.type.indexOf('alert') > -1 || this.props.type.indexOf('Alert') > -1
            ? 'fui-alert-modal'
            : this.props.type.indexOf('form') > -1 || this.props.type.indexOf('Form') > -1
            ? 'fui-form-modal'
            : this.props.type === 'iframe'
            ? 'fui-iframe-modal'
            : this.props.type === 'discount'
            ? 'fui-discount-modal'
            : 'fui-notice-modal'
        }>
        {type === 'compare' ? this.renderCompare() : null}
        {type === 'secondCard' ? this.secondCard() : null}
        {type === 'activityForm' ? this.activityForm() : null}
        {type === 'searchPreRegForm' ? this.searchPreRegForm() : null}
        {type === 'combinebillForm' ? this.combinebillForm() : null}
        {type === 'reservationForm' ? this.reservationForm() : null}
        {type === 'otpForm' ? this.otpValidationForm() : null}
        {type === 'deliveryForm' ? this.deliveryForm() : null}
        {this.props.type.indexOf('alert') > -1 || this.props.type.indexOf('Alert') > -1 ? this.alertContent() : null}
        {type === 'notice' ? this.noticeContent() : null}
        {type === 'iframe' ? this.iframeContent() : null}
        {type === 'secondCardModal' ? this.secondCard() : null}
        {type === 'discount' ? this.discountContent() : null}
      </Modal>
    );
  }
}

EstoreModal.propTypes = {
  type: PropTypes.string.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
  deliveryFormWithPlan: PropTypes.bool, // when type = deliveryForm, set true or false
  content: PropTypes.any,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  closeText: PropTypes.string,
  onClose: PropTypes.func,
};

export default EstoreModal;
