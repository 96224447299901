import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';
import TW from 'date-fns/locale/zh-TW';

class ActivatedDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      excludeDate: props.disableDate.map((d) => new Date(d)),
      highlightDates: props.highlightDates.map((d) => new Date(d)),
      selected: '',
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.selected !== prevProps.selected) {
      this.setState({
        selected: this.props.selected === '' ? '' : new Date(this.props.selected),
      });
    }
  };

  handleDateChange = (date) => {
    // this.setState({
    //   selected: date,
    // });
    let y = date.getFullYear();
    let m = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    let d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    this.props.onChange(`${y}/${m}/${d}`);
  };

  isAvaliable = (date) => {
    let y = date.getFullYear();
    let m = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    let d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    // console.log(`${y}/${m}/${d}`, this.props.disableDate.indexOf(`${y}/${m}/${d}`));
    return this.props.disableDate.indexOf(`${y}/${m}/${d}`) === -1;
  };

  hasPickupValue = (event) => {
    if (this.props.disable) {
      event.preventDefault();
      this.props.showNotice({
        type: 'notice',
        title: '貼心提醒',
        content: '親愛的客戶，在選擇【門號開通日期】前，請先選擇你的【取貨方式】喔！',
      });
      return;
    }
  };

  render() {
    const CustomInput1 = React.forwardRef(({ value, onClick }, ref) => (
      <input
        type='text'
        readOnly
        ref={ref}
        value={value}
        placeholder={this.props.placeholder}
        disabled={this.props.disable}
        onClick={() => {
          onClick();
        }}
      />
    ));

    return (
      <div role='button' onClick={(e) => this.hasPickupValue(e)}>
        <DatePicker
          closeOnScroll={false}
          customInput={<CustomInput1 />}
          yearDropdownItemNumber={10}
          showYearDropdown={true}
          showMonthDropdown={true}
          placeholderText={this.props.placeholder}
          dateFormat='yyyy/MM/dd'
          minDate={new Date(this.props.minDate)}
          maxDate={new Date(this.props.maxDate)}
          type={this.props.type}
          highlightDates={this.state.highlightDates}
          excludeDates={this.state.excludeDate}
          selected={this.state.selected}
          onChange={this.handleDateChange}
          disabled={this.props.disable}
          locale={TW}>
          {this.props.type === 'preorder' ? (
            <div className='preorder-caption'>
              <div>
                <span className='is-bg-main'></span>最快開通日
              </div>
              <div>
                <span className='is-bg-lightgray100'></span>物流作業時間
              </div>
            </div>
          ) : null}
        </DatePicker>
      </div>
    );
  }
}

ActivatedDatePicker.propTypes = {
  placeholder: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  selected: PropTypes.string,
  disable: PropTypes.string,
  disableDate: PropTypes.array,
};

export default ActivatedDatePicker;
