import React from 'react';
import { Grid } from '@material-ui/core';
import PanelList from './PanelList';
import PropTypes from 'prop-types';
import Button from '../Button';

class ListLayout extends React.Component {
  render() {
    return (
      <Grid
        className='panel-layout'
        container
        spacing={5}
        direction={this.props.listPosition && this.props.listPosition === 'right' ? 'row-reverse' : 'row'}>
        <Grid item md={6}>
          <h3 className='fui-panel-title'>{this.props.title}</h3>
          {this.props.children ? this.props.children : null}
          {this.props.list ? <PanelList list={this.props.list} prefix={this.props.listPrefix} /> : null}
          {this.props.actions ? (
            <div className='fui-buttons pt-3'>
              {this.props.actions.map((act, i) => (
                <Button {...act} key={`panel-layout-${i}`}>
                  {act.text}
                </Button>
              ))}
            </div>
          ) : null}
        </Grid>
        <Grid item md={6}>
          <img
            src={process.env.PUBLIC_URL + this.props.image}
            srcSet={process.env.PUBLIC_URL + (this.props.retinaImage || this.props.image)}
            alt={this.props.title}
          />
        </Grid>
      </Grid>
    );
  }
}

ListLayout.propTypes = {
  listPosition: PropTypes.string.isRequired, // left | right
  title: PropTypes.string.isRequired,
  retinaImage: PropTypes.string,
  image: PropTypes.string.isRequired,
  listPrefix: PropTypes.string.isRequired, // check | bulleted | number
  list: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  children: PropTypes.any, // left | right
};

export default ListLayout;
