import React from 'react';

import { setCartData, trackPageViewStart } from '../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import { Grid } from '@material-ui/core';
import NavAnchor from '../components/partials/NavAnchor';
import NavContentTab1 from '../components/partials/NavContentTab1';
import TabPane from '../components/tab/TabPane';
import PlanCard from '../components/partials/card/PlanCard';
import Breadcrumb from '../components/Breadcrumb';
import Button from '../components/Button';
import Item from '../components/item/Item';
import Panel from '../components/panel/Panel';
import PanelList from '../components/panelContent/PanelList';
import PanelContent from '../components/panelContent/PanelContent';
import ListLayout from '../components/panelContent/ListLayout';
import EStoreBanner from '../components/partials/banner/EStoreBanner';
import CardMultiSteps from '../components/card/CardMultiSteps';
import Paginate from '../components/paginate';
import EstoreModal from '../components/EstoreModal';
import ServiceEntry from '../components/partials/card/ServiceEntry';

class EbuShopGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      modalOpen: false,
      currentModal: null,
    };
  }

  updateTab = (index) => {
    this.setState({
      currentTab: index,
    });
  };

  triggerModal = (content) => {
    this.setState({
      modalOpen: true,
      currentModal: content,
    });
  };

  pageChange = (result) => {
    this.props.history.push(
      `${this.props.location.pathname}?keyword=${this.state.keyword}&page=${result.selected + 1}`
    );
  };

  render() {
    return (
      <main>
        <NavAnchor pageTitle='網路門市' />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '網路門市', link: '/estore' },
            { text: '企業用戶專屬行動優惠', link: '/' },
            { text: '美安專屬優惠', link: '/' },
          ]}
        />
        <EStoreBanner
          slides={[
            {
              image: {
                md: '/resources/cbu/estore-event-product/cbu-ebustore-1920x500.jpg',
                sm: '/resources/cbu/estore-event-product/cbu-ebustore-banner-750x900.jpg',
              },
              title: '單辦門號大標題文字最多十六個字元',
              description: [
                '一二三四五六七八九十一二三四五六',
                '一二三四五六七八九十一二三四五六',
                '一二三四五六七八九十一二三四五六',
              ],
              actions: [
                {
                  text: 'CTA',
                  btnStyle: 'primary',
                  link: '#',
                  target: '_self',
                },
                {
                  text: 'Secondary',
                  btnStyle: 'secondary',
                  link: '#',
                  target: '_blank',
                },
              ],
            },
          ]}
        />
        <NavContentTab1
          tabs={{
            default: this.state.currentTab,
            list: [{ label: '優惠方案' }, { label: '特殊方案' }, { label: 'iPhone' }, { label: '選項四' }],
          }}
          onChange={this.updateTab}
        />
        <TabPane active={this.state.currentTab === 0}>
          <div className='ribbon-bg'>
            <PlanCard
              title='單門號享受飆網快感'
              openModal={(e) => this.triggerModal(e)}
              cards={[
                {
                  type: 'default',
                  ribbon: '最熱門',
                  image: '/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png',
                  title: '單門號飆網不限速',
                  list: [{ text: '吃到飽追劇好easy' }, { text: '每月贈499通話金' }, { text: '免費網外市話額度' }],
                  action: [
                    {
                      btnStyle: 'primary',
                      text: '立即申辦',
                      link: '#',
                      target: '_self',
                    },
                    {
                      btnStyle: 'secondary',
                      text: '貼心小叮嚀',
                      modal: {
                        title: '貼心小叮嚀',
                        content: `
                          <p>單門號飆網不限速</p>
                          本方案限指定企業客戶之員工本人及一等親方可辦理。<br/>
                          訂單確認後將由宅配物流人員現場收回影本雙證及其他相關證明文件。<br/>
                          本專案生效後 24 個月內不得退租 ( 含一退一租、轉至 3G、2G 或預付卡、因違約或違反法令致遭停機者 ) 、取消或調降費率。倘有上述情形者視同放棄本專案贈送之優惠，含月租費優惠、網內外語音分鐘數、數據傳輸量、網內免費 SMS 則數等，並需依啟用方案繳交專案補貼款，實際應繳之專案違約金以本專案合約未到期之日為單位，按合約總日數比例計算。計算公式：實際應繳專案補貼款 = 專案補貼款總額 x ( 合約未到期日數 / 合約約定日數 )，四捨五入計算至整數。<br/>
                          專案贈送之免費分鐘數抵扣不含市話、影像電話、國際電話、國際漫遊、語音信箱、加值語音服務、特碼服務及其他免費電話通話分鐘數。<br/>
                        `,
                      },
                    },
                  ],
                },
                {
                  type: 'default',
                  ribbon: '最熱門',
                  image: '/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png',
                  title: '單門號飆網不限速',
                  list: [{ text: '吃到飽追劇好easy' }, { text: '每月贈499通話金' }, { text: '免費網外市話額度' }],
                  action: [
                    {
                      btnStyle: 'primary',
                      text: '立即申辦',
                      link: '#',
                      target: '_self',
                    },
                    {
                      btnStyle: 'secondary',
                      text: '貼心小叮嚀',
                      modal: {
                        title: '貼心小叮嚀',
                        content: `
                          <p>單門號飆網不限速</p>
                          本方案限指定企業客戶之員工本人及一等親方可辦理。<br/>
                          訂單確認後將由宅配物流人員現場收回影本雙證及其他相關證明文件。<br/>
                          本專案生效後 24 個月內不得退租 ( 含一退一租、轉至 3G、2G 或預付卡、因違約或違反法令致遭停機者 ) 、取消或調降費率。倘有上述情形者視同放棄本專案贈送之優惠，含月租費優惠、網內外語音分鐘數、數據傳輸量、網內免費 SMS 則數等，並需依啟用方案繳交專案補貼款，實際應繳之專案違約金以本專案合約未到期之日為單位，按合約總日數比例計算。計算公式：實際應繳專案補貼款 = 專案補貼款總額 x ( 合約未到期日數 / 合約約定日數 )，四捨五入計算至整數。<br/>
                          專案贈送之免費分鐘數抵扣不含市話、影像電話、國際電話、國際漫遊、語音信箱、加值語音服務、特碼服務及其他免費電話通話分鐘數。<br/>
                        `,
                      },
                    },
                  ],
                },
                {
                  type: 'default',
                  ribbon: '最熱門',
                  image: '/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png',
                  title: '單門號飆網不限速',
                  list: [{ text: '吃到飽追劇好easy' }, { text: '每月贈499通話金' }, { text: '免費網外市話額度' }],
                  action: [
                    {
                      btnStyle: 'primary',
                      text: '立即申辦',
                      link: '#',
                      target: '_self',
                    },
                    {
                      btnStyle: 'secondary',
                      text: '貼心小叮嚀',
                      modal: {
                        title: '貼心小叮嚀',
                        content: `
                          <p>單門號飆網不限速</p>
                          本方案限指定企業客戶之員工本人及一等親方可辦理。<br/>
                          訂單確認後將由宅配物流人員現場收回影本雙證及其他相關證明文件。<br/>
                          本專案生效後 24 個月內不得退租 ( 含一退一租、轉至 3G、2G 或預付卡、因違約或違反法令致遭停機者 ) 、取消或調降費率。倘有上述情形者視同放棄本專案贈送之優惠，含月租費優惠、網內外語音分鐘數、數據傳輸量、網內免費 SMS 則數等，並需依啟用方案繳交專案補貼款，實際應繳之專案違約金以本專案合約未到期之日為單位，按合約總日數比例計算。計算公式：實際應繳專案補貼款 = 專案補貼款總額 x ( 合約未到期日數 / 合約約定日數 )，四捨五入計算至整數。<br/>
                          專案贈送之免費分鐘數抵扣不含市話、影像電話、國際電話、國際漫遊、語音信箱、加值語音服務、特碼服務及其他免費電話通話分鐘數。<br/>
                        `,
                      },
                    },
                  ],
                },
                {
                  type: 'default',
                  ribbon: '最熱門',
                  image: '/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png',
                  title: '單門號飆網不限速',
                  list: [{ text: '吃到飽追劇好easy' }, { text: '每月贈499通話金' }, { text: '免費網外市話額度' }],
                  action: [
                    {
                      btnStyle: 'primary',
                      text: '立即申辦',
                      link: '#',
                      target: '_self',
                    },
                    {
                      btnStyle: 'secondary',
                      text: '貼心小叮嚀',
                      modal: {
                        title: '貼心小叮嚀',
                        content: `
                          <p>單門號飆網不限速</p>
                          本方案限指定企業客戶之員工本人及一等親方可辦理。<br/>
                          訂單確認後將由宅配物流人員現場收回影本雙證及其他相關證明文件。<br/>
                          本專案生效後 24 個月內不得退租 ( 含一退一租、轉至 3G、2G 或預付卡、因違約或違反法令致遭停機者 ) 、取消或調降費率。倘有上述情形者視同放棄本專案贈送之優惠，含月租費優惠、網內外語音分鐘數、數據傳輸量、網內免費 SMS 則數等，並需依啟用方案繳交專案補貼款，實際應繳之專案違約金以本專案合約未到期之日為單位，按合約總日數比例計算。計算公式：實際應繳專案補貼款 = 專案補貼款總額 x ( 合約未到期日數 / 合約約定日數 )，四捨五入計算至整數。<br/>
                          專案贈送之免費分鐘數抵扣不含市話、影像電話、國際電話、國際漫遊、語音信箱、加值語音服務、特碼服務及其他免費電話通話分鐘數。<br/>
                        `,
                      },
                    },
                  ],
                },
              ]}
            />
            <PlanCard
              title='挑戰市場最低手機專案價'
              openModal={(e) => this.triggerModal(e)}
              cards={[
                {
                  type: 'default',
                  ribbon: '最熱門',
                  image: '/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png',
                  title: '單門號飆網不限速',
                  list: [{ text: '吃到飽追劇好easy' }, { text: '每月贈499通話金' }, { text: '免費網外市話額度' }],
                  action: [
                    {
                      btnStyle: 'primary',
                      text: '立即申辦',
                      link: '#',
                      target: '_self',
                    },
                    {
                      btnStyle: 'secondary',
                      text: '貼心小叮嚀',
                      modal: {
                        title: '貼心小叮嚀',
                        content: `
                          <p>單門號飆網不限速</p>
                          本方案限指定企業客戶之員工本人及一等親方可辦理。<br/>
                          訂單確認後將由宅配物流人員現場收回影本雙證及其他相關證明文件。<br/>
                          本專案生效後 24 個月內不得退租 ( 含一退一租、轉至 3G、2G 或預付卡、因違約或違反法令致遭停機者 ) 、取消或調降費率。倘有上述情形者視同放棄本專案贈送之優惠，含月租費優惠、網內外語音分鐘數、數據傳輸量、網內免費 SMS 則數等，並需依啟用方案繳交專案補貼款，實際應繳之專案違約金以本專案合約未到期之日為單位，按合約總日數比例計算。計算公式：實際應繳專案補貼款 = 專案補貼款總額 x ( 合約未到期日數 / 合約約定日數 )，四捨五入計算至整數。<br/>
                          專案贈送之免費分鐘數抵扣不含市話、影像電話、國際電話、國際漫遊、語音信箱、加值語音服務、特碼服務及其他免費電話通話分鐘數。<br/>
                        `,
                      },
                    },
                  ],
                },
                {
                  type: 'default',
                  ribbon: '最熱門',
                  image: '/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png',
                  title: '單門號飆網不限速',
                  list: [{ text: '吃到飽追劇好easy' }, { text: '每月贈499通話金' }, { text: '免費網外市話額度' }],
                  action: [
                    {
                      btnStyle: 'primary',
                      text: '立即申辦',
                      link: '#',
                      target: '_self',
                    },
                    {
                      btnStyle: 'secondary',
                      text: '貼心小叮嚀',
                      modal: {
                        title: '貼心小叮嚀',
                        content: `
                          <p>單門號飆網不限速</p>
                          本方案限指定企業客戶之員工本人及一等親方可辦理。<br/>
                          訂單確認後將由宅配物流人員現場收回影本雙證及其他相關證明文件。<br/>
                          本專案生效後 24 個月內不得退租 ( 含一退一租、轉至 3G、2G 或預付卡、因違約或違反法令致遭停機者 ) 、取消或調降費率。倘有上述情形者視同放棄本專案贈送之優惠，含月租費優惠、網內外語音分鐘數、數據傳輸量、網內免費 SMS 則數等，並需依啟用方案繳交專案補貼款，實際應繳之專案違約金以本專案合約未到期之日為單位，按合約總日數比例計算。計算公式：實際應繳專案補貼款 = 專案補貼款總額 x ( 合約未到期日數 / 合約約定日數 )，四捨五入計算至整數。<br/>
                          專案贈送之免費分鐘數抵扣不含市話、影像電話、國際電話、國際漫遊、語音信箱、加值語音服務、特碼服務及其他免費電話通話分鐘數。<br/>
                        `,
                      },
                    },
                  ],
                },
                {
                  type: 'default',
                  ribbon: '最熱門',
                  image: '/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png',
                  title: '單門號飆網不限速',
                  list: [{ text: '吃到飽追劇好easy' }, { text: '每月贈499通話金' }, { text: '免費網外市話額度' }],
                  action: [
                    {
                      btnStyle: 'primary',
                      text: '立即申辦',
                      link: '#',
                      target: '_self',
                    },
                    {
                      btnStyle: 'secondary',
                      text: '貼心小叮嚀',
                      modal: {
                        title: '貼心小叮嚀',
                        content: `
                          <p>單門號飆網不限速</p>
                          本方案限指定企業客戶之員工本人及一等親方可辦理。<br/>
                          訂單確認後將由宅配物流人員現場收回影本雙證及其他相關證明文件。<br/>
                          本專案生效後 24 個月內不得退租 ( 含一退一租、轉至 3G、2G 或預付卡、因違約或違反法令致遭停機者 ) 、取消或調降費率。倘有上述情形者視同放棄本專案贈送之優惠，含月租費優惠、網內外語音分鐘數、數據傳輸量、網內免費 SMS 則數等，並需依啟用方案繳交專案補貼款，實際應繳之專案違約金以本專案合約未到期之日為單位，按合約總日數比例計算。計算公式：實際應繳專案補貼款 = 專案補貼款總額 x ( 合約未到期日數 / 合約約定日數 )，四捨五入計算至整數。<br/>
                          專案贈送之免費分鐘數抵扣不含市話、影像電話、國際電話、國際漫遊、語音信箱、加值語音服務、特碼服務及其他免費電話通話分鐘數。<br/>
                        `,
                      },
                    },
                  ],
                },
              ]}
            />
            <PlanCard
              title='配件用企業隱藏價到手'
              openModal={(e) => this.triggerModal(e)}
              cards={[
                {
                  type: 'default',
                  ribbon: '最熱門',
                  image: '/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png',
                  title: '單門號飆網不限速',
                  list: [{ text: '吃到飽追劇好easy' }, { text: '每月贈499通話金' }, { text: '免費網外市話額度' }],
                  action: [
                    {
                      btnStyle: 'primary',
                      text: '立即申辦',
                      link: '#',
                      target: '_self',
                    },
                    {
                      btnStyle: 'secondary',
                      text: '貼心小叮嚀',
                      modal: {
                        title: '貼心小叮嚀',
                        content: `
                          <p>單門號飆網不限速</p>
                          本方案限指定企業客戶之員工本人及一等親方可辦理。<br/>
                          訂單確認後將由宅配物流人員現場收回影本雙證及其他相關證明文件。<br/>
                          本專案生效後 24 個月內不得退租 ( 含一退一租、轉至 3G、2G 或預付卡、因違約或違反法令致遭停機者 ) 、取消或調降費率。倘有上述情形者視同放棄本專案贈送之優惠，含月租費優惠、網內外語音分鐘數、數據傳輸量、網內免費 SMS 則數等，並需依啟用方案繳交專案補貼款，實際應繳之專案違約金以本專案合約未到期之日為單位，按合約總日數比例計算。計算公式：實際應繳專案補貼款 = 專案補貼款總額 x ( 合約未到期日數 / 合約約定日數 )，四捨五入計算至整數。<br/>
                          專案贈送之免費分鐘數抵扣不含市話、影像電話、國際電話、國際漫遊、語音信箱、加值語音服務、特碼服務及其他免費電話通話分鐘數。<br/>
                        `,
                      },
                    },
                  ],
                },
                {
                  type: 'default',
                  ribbon: '最熱門',
                  image: '/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png',
                  title: '單門號飆網不限速',
                  list: [{ text: '吃到飽追劇好easy' }, { text: '每月贈499通話金' }, { text: '免費網外市話額度' }],
                  action: [
                    {
                      btnStyle: 'primary',
                      text: '立即申辦',
                      link: '#',
                      target: '_self',
                    },
                    {
                      btnStyle: 'secondary',
                      text: '貼心小叮嚀',
                      modal: {
                        title: '貼心小叮嚀',
                        content: `
                          <p>單門號飆網不限速</p>
                          本方案限指定企業客戶之員工本人及一等親方可辦理。<br/>
                          訂單確認後將由宅配物流人員現場收回影本雙證及其他相關證明文件。<br/>
                          本專案生效後 24 個月內不得退租 ( 含一退一租、轉至 3G、2G 或預付卡、因違約或違反法令致遭停機者 ) 、取消或調降費率。倘有上述情形者視同放棄本專案贈送之優惠，含月租費優惠、網內外語音分鐘數、數據傳輸量、網內免費 SMS 則數等，並需依啟用方案繳交專案補貼款，實際應繳之專案違約金以本專案合約未到期之日為單位，按合約總日數比例計算。計算公式：實際應繳專案補貼款 = 專案補貼款總額 x ( 合約未到期日數 / 合約約定日數 )，四捨五入計算至整數。<br/>
                          專案贈送之免費分鐘數抵扣不含市話、影像電話、國際電話、國際漫遊、語音信箱、加值語音服務、特碼服務及其他免費電話通話分鐘數。<br/>
                        `,
                      },
                    },
                  ],
                },
                {
                  type: 'default',
                  ribbon: '最熱門',
                  image: '/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png',
                  title: '單門號飆網不限速',
                  list: [{ text: '吃到飽追劇好easy' }, { text: '每月贈499通話金' }, { text: '免費網外市話額度' }],
                  action: [
                    {
                      btnStyle: 'primary',
                      text: '立即申辦',
                      link: '#',
                      target: '_self',
                    },
                    {
                      btnStyle: 'secondary',
                      text: '貼心小叮嚀',
                      modal: {
                        title: '貼心小叮嚀',
                        content: `
                          <p>單門號飆網不限速</p>
                          本方案限指定企業客戶之員工本人及一等親方可辦理。<br/>
                          訂單確認後將由宅配物流人員現場收回影本雙證及其他相關證明文件。<br/>
                          本專案生效後 24 個月內不得退租 ( 含一退一租、轉至 3G、2G 或預付卡、因違約或違反法令致遭停機者 ) 、取消或調降費率。倘有上述情形者視同放棄本專案贈送之優惠，含月租費優惠、網內外語音分鐘數、數據傳輸量、網內免費 SMS 則數等，並需依啟用方案繳交專案補貼款，實際應繳之專案違約金以本專案合約未到期之日為單位，按合約總日數比例計算。計算公式：實際應繳專案補貼款 = 專案補貼款總額 x ( 合約未到期日數 / 合約約定日數 )，四捨五入計算至整數。<br/>
                          專案贈送之免費分鐘數抵扣不含市話、影像電話、國際電話、國際漫遊、語音信箱、加值語音服務、特碼服務及其他免費電話通話分鐘數。<br/>
                        `,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </TabPane>
        <TabPane active={this.state.currentTab === 1}></TabPane>
        <TabPane active={this.state.currentTab === 2}></TabPane>
        <TabPane active={this.state.currentTab === 3}></TabPane>
        {this.state.modalOpen ? (
          <Modal
            onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
            onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
            isOpen={this.state.modalOpen}
            contentLabel='Example Modal'
            className={'fui-notice-modal'}>
            <div className='fui-modal-content'>
              <div className='fui-modal-head'>
                <h5>{this.state.currentModal.title}</h5>
              </div>
              <div
                className='fui-modal-body'
                dangerouslySetInnerHTML={{ __html: this.state.currentModal.content }}></div>
              <div className='fui-modal-foot two-buttons'>
                <Button btnStyle='primary' onClick={(e) => this.setState({ modalOpen: false })}>
                  確定
                </Button>
                <Button btnStyle='secondary' onClick={(e) => this.setState({ modalOpen: false })}>
                  取消
                </Button>
              </div>
            </div>
          </Modal>
        ) : null}
      </main>
    );
  }
}

export default withRouter(EbuShopGroup);
