export const OrderSteps = {
  product_with_plan: ['訂單成立', '資料確認中', '出貨準備中', '物流配送中', '已簽收', '門號已開通'],
  plan: ['訂單成立', '資料確認中', '代客領取', '已簽收', '門號已開通'],
  sim_card_1: ['訂單成立', '資料確認中', '物流配送中', '已送達'],
  sim_card_2: ['訂單成立', '出貨準備中', '已送達'],
};

export const OrderRecords = [
  {
    type: 'product_with_plan',
    order_no: 'TL18000000000',
    title: 'Apple iPad Pro 11 LTE 256GB (2018)',
    subtitle: '4.5G超極速方案 新絕配599',
    image: '/resources/cbu/estore-order-record/estore-product-thumb-01@2x.jpg',
    status: '物流配送中',
    logistics: {
      no: 'F12952811460',
      link: '#',
      target: '_blank',
    },
    order_at: '2019/12/31 09:26',
    card_status: '證件有誤請補上傳',
    phone_number: '0912-345-678',
    number_price: 10000,
    number_choose_price: 10000,
    plan: '4.5G超極速方案_新絕配599 x 24個月',
    projectProduct: 'Samsung Galaxy S9 128GB 金色 x 1',
    product: 'Samsung Galaxy S9 128GB 金色 x 1',
    accessory: ['Apple iPhoneXs 透明防撞格紋殼 其他 x 1', 'Probox 北歐風藍色15W無線充電盤 x 1'],
    addon_product: ['Beats X磨砂金頸掛式入耳藍牙耳機 x 1', 'JayBird X3藍牙時尚黑運動耳機 x 1'],
    gift: '行李箱',
    good: {
      name: '配件提貨券 $2000元',
      number: '88GHD274A',
    },
    prepay: 10000,
    total: 39999,
    orderer: {
      name: '王**',
      payment: '信用卡',
      receive: '宅配到府',
      mobile: '0934-***-890',
      address: '台北市內湖區瑞***************',
      receipt: '電子發票電子載具',
    },
  },
  {
    type: 'plan',
    order_no: 'TL18000000000',
    title: '4.5G超極速方案 新絕配599',
    image: '/resources/cbu/estore-order-record/cart-plan-template@2x.png',
    status: '已簽收',
    logistics: {
      no: 'F12952811460',
      link: '#',
      target: '_blank',
    },
    order_at: '2019/12/31 09:26',
    card_status: '證件有誤請補上傳',
    phone_number: '0912-345-678',
    number_price: 10000,
    number_choose_price: 10000,
    plan: '4.5G超極速方案_新絕配599 x 24個月',
    projectProduct: 'Samsung Galaxy S9 128GB 金色 x 1',
    product: 'Samsung Galaxy S9 128GB 金色 x 1',
    accessory: ['Apple iPhoneXs 透明防撞格紋殼 其他 x 1', 'Probox 北歐風藍色15W無線充電盤 x 1'],
    addon_product: ['Beats X磨砂金頸掛式入耳藍牙耳機 x 1', 'JayBird X3藍牙時尚黑運動耳機 x 1'],
    gift: '行李箱',
    good: {
      name: '配件提貨券 $2000元',
      number: '88GHD274A',
    },
    prepay: 10000,
    total: 39999,
    orderer: {
      name: '王**',
      payment: '信用卡',
      receive: '宅配到府',
      mobile: '0934-***-890',
      address: '台北市內湖區瑞***************',
      receipt: '電子發票電子載具',
    },
  },
  {
    type: 'sim_card_1',
    order_no: 'TL18000000000',
    title: '遠傳卡2.0 日本/韓國跨國上網卡',
    image: '/resources/cbu/estore-order-record/estore-wireless-card-thumb-01@2x.jpg',
    status: '物流配送中',
    logistics: {
      no: 'F12952811460',
      link: '#',
      target: '_blank',
    },
    order_at: '2019/12/31 09:26',
    card_status: '證件有誤請補上傳',
    phone_number: '0912-345-678',
    number_price: 10000,
    number_choose_price: 10000,
    plan: '4.5G超極速方案_新絕配599 x 24個月',
    projectProduct: 'Samsung Galaxy S9 128GB 金色 x 1',
    product: 'Samsung Galaxy S9 128GB 金色 x 1',
    accessory: ['Apple iPhoneXs 透明防撞格紋殼 其他 x 1', 'Probox 北歐風藍色15W無線充電盤 x 1'],
    addon_product: ['Beats X磨砂金頸掛式入耳藍牙耳機 x 1', 'JayBird X3藍牙時尚黑運動耳機 x 1'],
    gift: '行李箱',
    good: {
      name: '配件提貨券 $2000元',
      number: '88GHD274A',
    },
    prepay: 10000,
    total: 39999,
    orderer: {
      name: '王**',
      payment: '信用卡',
      receive: '宅配到府',
      mobile: '0934-***-890',
      address: '台北市內湖區瑞***************',
      receipt: '電子發票電子載具',
    },
  },
  {
    type: 'sim_card_2',
    order_no: 'TL18000000000',
    title: '遠傳卡2.0 日本/韓國跨國上網卡',
    image: '/resources/cbu/estore-order-record/estore-wireless-card-thumb-01@2x.jpg',
    status: '出貨準備中',
    logistics: {
      no: 'F12952811460',
      link: '#',
      target: '_blank',
    },
    order_at: '2019/12/31 09:26',
    card_status: '證件有誤請補上傳',
    phone_number: '0912-345-678',
    number_price: 10000,
    number_choose_price: 10000,
    plan: '4.5G超極速方案_新絕配599 x 24個月',
    projectProduct: 'Samsung Galaxy S9 128GB 金色 x 1',
    product: 'Samsung Galaxy S9 128GB 金色 x 1',
    accessory: ['Apple iPhoneXs 透明防撞格紋殼 其他 x 1', 'Probox 北歐風藍色15W無線充電盤 x 1'],
    addon_product: ['Beats X磨砂金頸掛式入耳藍牙耳機 x 1', 'JayBird X3藍牙時尚黑運動耳機 x 1'],
    gift: ['行李箱'],
    good: {
      name: '配件提貨券 $2000元',
      number: '88GHD274A',
    },
    prepay: 10000,
    total: 39999,
    orderer: {
      name: '王**',
      payment: '信用卡',
      receive: '宅配到府',
      mobile: '0934-***-890',
      address: '台北市內湖區瑞***************',
      receipt: '電子發票電子載具',
    },
  },
];
