export const Accessories = [
  {
    type: 'product',
    link: '/estore/product/detail/vantec-qi-red-wireless-chargers',
    ribbon: '網路限定優惠',
    image: '/resources/cbu/find-product/estore-product-thumb-12@2x.jpg',
    meta: 'VANTEC Qi',
    name: 'VANTEC Qi 紅色無線快速充電盤',
    productPrice: 1290,
    value: '1',
  },
  {
    type: 'product',
    link: '/',
    image: '/resources/cbu/find-product/estore-product-thumb-13@2x.jpg',
    meta: 'APPLE',
    name: '原廠 EarPods 耳機 - Lightning線',
    productPrice: 990,
    value: '2',
  },
  {
    type: 'product',
    link: '/',
    image: '/resources/cbu/find-product/estore-product-thumb-14@2x.jpg',
    meta: 'MYCELL',
    name: 'MYCELL AirPods Pro 粉沙收納盒',
    productPrice: 390,
    value: '3',
  },
  {
    type: 'product',
    link: '/',
    ribbon: '網路限定優惠',
    image: '/resources/cbu/find-product/estore-product-thumb-12@2x.jpg',
    meta: 'VANTEC Qi',
    name: 'VANTEC Qi 紅色無線快速充電盤',
    productPrice: 1290,
    value: '4',
  },
  {
    type: 'product',
    link: '/',
    image: '/resources/cbu/find-product/estore-product-thumb-13@2x.jpg',
    meta: 'APPLE',
    name: '原廠 EarPods 耳機 - Lightning線',
    productPrice: 990,
    value: '5',
  },
  {
    type: 'product',
    link: '/',
    image: '/resources/cbu/find-product/estore-product-thumb-14@2x.jpg',
    meta: 'MYCELL',
    name: 'MYCELL AirPods Pro 粉沙收納盒',
    productPrice: 390,
    value: '6',
  },
  {
    type: 'wireless',
    link: '/estore/product/detail/japan-5days-internet-card',
    image: '/resources/cbu/find-product/estore-wireless-card-thumb-01@2x.jpg',
    meta: '飆網不降速 插卡即用',
    name: '日本5日不降速上網卡',
    originPrice: 1299,
    productPrice: 999,
    value: '7',
  },
  {
    type: 'wireless',
    link: '/estore/product/detail/japan-5days-internet-card',
    image: '/resources/cbu/find-product/estore-wireless-card-thumb-01@2x.jpg',
    meta: '飆網不降速 插卡即用',
    name: '日本5日不降速上網卡',
    originPrice: 1299,
    productPrice: 999,
    value: '8',
  },
  {
    type: 'wireless',
    link: '/',
    image: '/resources/cbu/find-product/estore-wireless-card-thumb-01@2x.jpg',
    meta: '飆網不降速 插卡即用',
    name: '日本5日不降速上網卡',
    originPrice: 1299,
    productPrice: 999,
    value: '9',
  },
  {
    type: 'wireless',
    link: '/',
    image: '/resources/cbu/find-product/estore-wireless-card-thumb-02@2x.jpg',
    meta: '30日內2GB飆網',
    name: '遠遊卡 韓國30天吃到飽',
    productPrice: 1299,
    value: '10',
  },
  {
    type: 'wireless',
    link: '/',
    image: '/resources/cbu/find-product/estore-wireless-card-thumb-03@2x.jpg',
    meta: '30日內2GB飆網',
    name: '遠遊卡 香港30天吃到飽',
    productPrice: 1299,
    value: '11',
  },
  {
    type: 'wireless',
    link: '/',
    image: '/resources/cbu/find-product/estore-wireless-card-thumb-04@2x.jpg',
    meta: '30日內2GB飆網',
    name: '遠遊卡 美國30天吃到飽',
    productPrice: 1299,
    value: '12',
  },
  {
    type: 'product',
    link: '/estore/product/detail/vantec-qi-red-wireless-chargers',
    ribbon: '網路限定優惠',
    image: '/resources/cbu/find-product/estore-product-thumb-12@2x.jpg',
    meta: 'VANTEC Qi',
    name: 'VANTEC Qi 紅色無線快速充電盤',
    productPrice: 1290,
    value: '13',
  },
  {
    type: 'product',
    link: '/',
    image: '/resources/cbu/find-product/estore-product-thumb-13@2x.jpg',
    meta: 'APPLE',
    name: '原廠 EarPods 耳機 - Lightning線',
    productPrice: 990,
    value: '14',
  },
  {
    type: 'product',
    link: '/',
    image: '/resources/cbu/find-product/estore-product-thumb-14@2x.jpg',
    meta: 'MYCELL',
    name: 'MYCELL AirPods Pro 粉沙收納盒',
    productPrice: 390,
    value: '15',
  },
  {
    type: 'product',
    link: '/estore/product/detail/vantec-qi-red-wireless-chargers',
    ribbon: '網路限定優惠',
    image: '/resources/cbu/find-product/estore-product-thumb-12@2x.jpg',
    meta: 'VANTEC Qi',
    name: 'VANTEC Qi 紅色無線快速充電盤',
    productPrice: 1290,
    value: '16',
  },
  {
    type: 'product',
    link: '/',
    image: '/resources/cbu/find-product/estore-product-thumb-13@2x.jpg',
    meta: 'APPLE',
    name: '原廠 EarPods 耳機 - Lightning線',
    productPrice: 990,
    value: '17',
  },
  {
    type: 'product',
    link: '/',
    image: '/resources/cbu/find-product/estore-product-thumb-14@2x.jpg',
    meta: 'MYCELL',
    name: 'MYCELL AirPods Pro 粉沙收納盒',
    productPrice: 390,
    value: '18',
  },
  {
    type: 'product',
    link: '/',
    ribbon: '網路限定優惠',
    image: '/resources/cbu/find-product/estore-product-thumb-12@2x.jpg',
    meta: 'VANTEC Qi',
    name: 'VANTEC Qi 紅色無線快速充電盤',
    productPrice: 1290,
    value: '19',
  },
  {
    type: 'product',
    link: '/',
    image: '/resources/cbu/find-product/estore-product-thumb-13@2x.jpg',
    meta: 'APPLE',
    name: '原廠 EarPods 耳機 - Lightning線',
    productPrice: 990,
    value: '20',
  },
  {
    type: 'product',
    link: '/',
    image: '/resources/cbu/find-product/estore-product-thumb-14@2x.jpg',
    meta: 'MYCELL',
    name: 'MYCELL AirPods Pro 粉沙收納盒',
    productPrice: 390,
    value: '21',
  },
  {
    type: 'wireless',
    link: '/estore/product/detail/japan-5days-internet-card',
    image: '/resources/cbu/find-product/estore-wireless-card-thumb-01@2x.jpg',
    meta: '飆網不降速 插卡即用',
    name: '日本5日不降速上網卡',
    originPrice: 1299,
    productPrice: 999,
    value: '22',
  },
  {
    type: 'wireless',
    link: '/estore/product/detail/japan-5days-internet-card',
    image: '/resources/cbu/find-product/estore-wireless-card-thumb-01@2x.jpg',
    meta: '飆網不降速 插卡即用',
    name: '日本5日不降速上網卡',
    originPrice: 1299,
    productPrice: 999,
    value: '23',
  },
  {
    type: 'wireless',
    link: '/',
    image: '/resources/cbu/find-product/estore-wireless-card-thumb-01@2x.jpg',
    meta: '飆網不降速 插卡即用',
    name: '日本5日不降速上網卡',
    originPrice: 1299,
    productPrice: 999,
    value: '24',
  },
  {
    type: 'wireless',
    link: '/',
    image: '/resources/cbu/find-product/estore-wireless-card-thumb-02@2x.jpg',
    meta: '30日內2GB飆網',
    name: '遠遊卡 韓國30天吃到飽',
    productPrice: 1299,
    value: '25',
  },
];
