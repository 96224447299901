import React from 'react';
import Slider from 'react-slick';
import { NavLink } from 'react-router-dom';

import Card from '../../card/Card';
import ProductCard from '../../card/ProductCard';

import PropTypes from 'prop-types';

const SectionCarouse4 = (props) => {
  const [setting] = React.useState({
    dots: false,
    infinite: false,
    arrows: true,
    slidesToShow: !!props.slidesToShow ? props.slidesToShow : 4,
    draggable: true,
    responsive: props.responsive
      ? props.responsive
      : [
          {
            breakpoint: 960,
            settings: {
              arrows: true,
              slidesToShow: 4,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 768,
            settings: {
              arrows: true,
              slidesToShow: 2,
              variableWidth: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: true,
              slidesToShow: 2,
              variableWidth: true,
            },
          },
        ],
  });

  return (
    <section className='fui-horzonal-cards is-narrow' id={props.id}>
      {/* <ArrowLeftWhite/>
            <ArrowRightWhite/> */}
      {props.bgImage ? (
        <div className='bg'>
          <img
            src={process.env.PUBLIC_URL + props.bgImage.md}
            className='bg-image d-none d-md-block'
            alt={props.bgImage.md}
          />
          <img
            src={process.env.PUBLIC_URL + props.bgImage.sm}
            className='bg-image d-block d-md-none'
            alt={props.bgImage.sm}
          />
        </div>
      ) : (
        ''
      )}
      <div className='fui-container'>
        <h2 className='section-title m-0 p-0' dangerouslySetInnerHTML={{ __html: props.title }}></h2>
        <Slider {...setting} className='horizontal-cards'>
          {props.cards.map((card, idx) =>
            card.type === 'product' ? (
              <ProductCard key={idx} {...card} />
            ) : (
              <Card key={idx} {...card} style={{ width: 270 }} />
            )
          )}
        </Slider>
        {props.more ? (
          <div className='align-center'>
            <NavLink to={props.more.link} className='fui-more'>
              {props.more.text}
              <br />
              <i className='icon-chevron-down'></i>
            </NavLink>
          </div>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

SectionCarouse4.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  maxCards: PropTypes.number,
  slidesToShow: PropTypes.number,
  cards: PropTypes.arrayOf(PropTypes.objectOf(Card)),
  bgImage: PropTypes.shape({
    md: PropTypes.string,
    sm: PropTypes.string,
  }),
  more: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  }),
  responsive: PropTypes.array,
};

export default SectionCarouse4;
