import React from 'react';
import Slider from 'react-slick';
import Link from '../../Link';

import Card from '../../card/Card';

import PropTypes from 'prop-types';

const SectionCarousel3 = (props) => {
  const setting = {
    dots: false,
    infinite: false,
    arrows: true,
    slidesToShow: 3,
    draggable: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 2,
          variableWidth: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <section className={`fui-horzonal-cards is-narrow ${props.more ? 'no-more' : ''}`} id={props.id}>
      <div className='fui-container'>
        <h2 className='section-title m-0 p-0 d-inline-block' dangerouslySetInnerHTML={{ __html: props.title }}></h2>
        {props.more && props.more.text && props.more.link ? (
          <div className='align-right d-inline-block float-right'>
            <Link to={props.more.link} target={props.more.target} className='fui-more d-flex'>
              {props.more.text}
              <i className='icon-chevron-right ml-2'></i>
            </Link>
          </div>
        ) : null}
        <Slider {...setting} className='horizontal-cards'>
          {props.cards.map((card, idx) => (
            <Card key={idx} {...card} />
          ))}
        </Slider>
      </div>
    </section>
  );
};

SectionCarousel3.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  maxCards: PropTypes.number,
  slidesToShow: PropTypes.number,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  more: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  }),
  responsive: PropTypes.array,
};

export default SectionCarousel3;
