import React from 'react';

import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import TabPane from '../../components/tab/TabPane';
import EstoreModal from '../../components/EstoreModal';
import RecordPanel from '../../components/panel/RecordPanel';

import * as Mock from '../../mock/OrderRecord';

class OrderRecord extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      modalOpen: false,
      currentModal: {
        type: 'notice',
        title: '',
        conent: '',
      },
    };
  }

  modalTrigger = (modal) => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'notice',
        ...modal,
      },
    });
  };

  modalSubmit = (type, form) => {};

  modalConfirm = (type, form) => {};

  modalCancel = (type, form) => {
    this.setState({
      modalOpen: false,
    });
  };

  modalClose = (type, f) => {
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { currentTab } = this.state;

    return (
      <main>
        <FormBanner
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
          title='我的訂單'
        />
        <NavContentTab1
          tabs={{
            name: 'order-record-tab',
            list: [{ label: '網路門市' }, { label: 'friDay購物' }],
          }}
          onChange={(index) => this.setState({ currentTab: index })}
        />
        <TabPane active={currentTab === 0}>
          <section>
            <div className='fui-container'>
              {Mock.OrderRecords.map((records, i) => (
                <RecordPanel {...records} key={`record-${i}`} modalOpen={this.modalTrigger} />
              ))}
            </div>
          </section>
        </TabPane>
        <TabPane active={currentTab === 1}></TabPane>

        <EstoreModal
          open={this.state.modalOpen}
          {...this.state.currentModal}
          onSubmit={this.modalSubmit}
          onConfirm={this.modalConfirm}
          onCancel={this.modalCancel}
          onClose={this.modalClose}
        />
      </main>
    );
  }
}

export default OrderRecord;
