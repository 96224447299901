export const navBar = {
  pageTitle: '網路限定',
  button: [
    {
      text: '新申辦',
      link: '/',
    },
    {
      text: '推薦續約',
      link: '/',
    },
  ],
};

export const breadcrumb = [
  { link: '/', text: '首頁' },
  { link: '/estore', text: '網路門市' },
  { link: '/estore/exclusive', text: '網路限定' },
];

export const faq = {
  title: '常見問題',
  tabs: [{ label: '申辦門號' }, { label: '購物' }, { label: '售後' }],
  collapseList: [
    [
      {
        title: '網路門市申辦攜碼移入生效時間與實體門市之差異?',
        content: '網路門市申辦攜碼移入生效時間與實體門市之差異?',
      },
      {
        title: '我是否可以幫他人在網路門市申辦門號？',
        content: '我是否可以幫他人在網路門市申辦門號？',
      },
      {
        title: '如何查詢申辦進度？',
        content: '如何查詢申辦進度？',
      },
    ],
  ],
};

export const footerPromotion = {
  image: {
    md: '/resources/cbu/estore/cbu-promotion-banner-05-1920x156.jpg',
    sm: '/resources/cbu/estore/cbu-promotion-banner-05-750x540.jpg',
  },
  title: '網路門市限定 週三驚爆閃購日，AirPods、藍牙耳機週週1折起',
  action: {
    text: '立即領折扣序號',
    link: '/',
  },
};
