import React from 'react';
import { Grid } from '@material-ui/core';
import ListItem from '../item/ListItem';
import PropTypes from 'prop-types';

class ESectionFeature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      column: this.props.list.length >= 3 ? 4 : 12 / this.props.list.length,
      style: this.props.bgStyle || 'default',
    };
  }

  render() {
    return (
      <section className={`fui-content is-section-feature`} id={this.props.id}>
        <div className='fui-container'>
          <h2>{this.props.title}</h2>
          <Grid container spacing={1}>
            {this.props.list.map((item, i) => (
              <Grid item key={`list-item-${i}`} xs={12} sm={6} md={this.state.column}>
                <ListItem prefix='check' {...item} className={`${this.props.color ? `is-${this.props.color}` : ''}`} />
              </Grid>
            ))}
          </Grid>
        </div>
      </section>
    );
  }
}

ESectionFeature.propTypes = {
  bgStyle: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  column: PropTypes.number,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.string,
      list: PropTypes.array,
    })
  ),
};

export default ESectionFeature;
