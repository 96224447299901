import React from 'react';
import DropdownTab from '../tab/DropdownTab';
import Button from '../Button';
import PropTypes from 'prop-types';

class NavTab extends React.Component {
  constructor(props) {
    super(props);
    this.elem = React.createRef();
    this.state = {
      defaultTop: 52,
      scrollPos: 0,
      default: this.props.default || 0,
    };
  }

  componentDidMount() {
    let header = document.getElementsByTagName('header');
    this.setState({
      defaultTop: header.length ? header[0].clientHeight : 0,
    });

    if (this.props.tabs) {
      this.props.tabs.list.map((item, i) => {
        if (item.link === window.location.pathname) {
          this.setState({
            default: i,
          });
        }
      });
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', (e) => this.setDefault());
      window.addEventListener('mousewheel', (e) => this.setSrollPosition());
      window.addEventListener('scroll', (e) => this.setSrollPosition());
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', (e) => this.setDefault());
    window.removeEventListener('mousewheel', (e) => this.setSrollPosition());
    window.removeEventListener('scroll', (e) => this.setSrollPosition());
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.default !== this.props.default) {
      this.setState({
        default: this.props.default || 0,
      });
    }
  };

  setDefault = () => {
    let header = document.getElementsByTagName('header');
    if (!this.elem.current) return;
    this.setState({
      defaultTop: header.clientHeight,
    });
  };

  setSrollPosition = (e) => {
    if (!this.elem.current) return;
    // debugger;
    let headerDom = Array.from(
      document.getElementsByClassName('mothra-header').length
        ? document.getElementsByClassName('mothra-header')
        : document.getElementsByTagName('header')
    );
    let defaultTop = headerDom.length ? headerDom[0].clientHeight : 0;
    let scrollY = window.scrollY || document.documentElement.scrollTop;
    let header = headerDom[0].clientHeight;

    if (scrollY > this.state.scrollPos && scrollY > 0) {
      headerDom.map((dom) => {
        console.log(-scrollY < -header ? -header : -scrollY);
        dom.style.transform = `translateY(${-scrollY < -header ? -header : -scrollY}px)`;
      });

      if (scrollY >= header) {
        this.elem.current.classList.add('is-fixed');
      }
    }

    if (scrollY < this.state.scrollPos && scrollY < defaultTop + header) {
      this.elem.current.classList.remove('is-fixed');
      headerDom.map((dom) => {
        dom.style.transform = `translateY(${-scrollY >= 0 ? 0 : -scrollY}px)`;
      });
    }

    this.setState({ scrollPos: scrollY });
    // this.forceUpdate();
  };

  handleChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render() {
    return (
      <div className='fui-nav-tab' ref={this.elem}>
        <div className='fui-container'>
          <div className='content'>
            <div className='subtitle' dangerouslySetInnerHTML={{ __html: this.props.pageTitle || '' }}></div>
            {this.props.tabs ? (
              <DropdownTab tabs={this.props.tabs} default={this.state.default} onChange={(e) => this.handleChange(e)} />
            ) : null}
          </div>
          <div className='extra'>
            {this.props.buttons && this.props.buttons.length
              ? this.props.buttons.map((button, i) => (
                  <Button
                    key={`nav-tab-extra-${i}`}
                    {...button}
                    size='small'
                    btnStyle={button.btnStyle}
                    target={button.target || '_self'}>
                    {button.text}
                  </Button>
                ))
              : ''}
          </div>
        </div>
      </div>
    );
  }
}

NavTab.propTypes = {
  showAll: PropTypes.bool,
  pageTitle: PropTypes.string,
  default: PropTypes.number,
  tabs: PropTypes.shape({
    name: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        focusIcon: PropTypes.string,
        label: PropTypes.string.isRequired,
        link: PropTypes.string,
      })
    ),
  }),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};

export default NavTab;
