import React, { useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Link from '../../Link';
import LinkItem from '../../item/LinkItem';
import Dropdown from '../../Dropdown';
import Switch from '../../Switch';
import SectionAd5 from '../../ad/SectionAd5';
import MenuContent1 from './MenuContent1';
import MenuContent2 from './MenuContent2';
import MenuDrawer from './MenuDrawer';
import KeywordInput from '../../KeywordInput';

import NotificationBar from '../../Notification';

import { HeaderMock } from '../../../mock/RealHeaderMock';
import { HelpCenterMock } from '../../../mock/HelpCenterMock';
import Button from '../../Button';

window.prevHistory = {
  pathname: '',
  hash: '',
  key: '',
};

function isIE() {
  if (typeof window !== 'undefined') {
    var ua = window.navigator.userAgent;
    var msie = /MSIE|Edge|Trident\//g.test(ua);
    // console.info('iE:', (msie > 0 || !!window.MSInputMethodContext || !!document.documentMode))
    return msie;
  } else {
    return false;
  }
}

const Header = (props) => {
  const $html = document.getElementsByTagName('html')[0];
  const $body = document.body;
  const history = useHistory();
  const location = useLocation();
  const [mock, setMock] = React.useState(HeaderMock);
  const isEBU = location.pathname.indexOf('ebu') > -1;
  const [headerType, setHeaderType] = React.useState('default');
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [catogory, setCatogory] = React.useState(0);
  const [sideMenuOpen, setSideMenuOpen] = React.useState(false);
  const [hasNotification] = React.useState(
    ((location.pathname === '/' || location.pathname.indexOf('/index') > -1) && window.innerWidth > 768) ||
      ((location.pathname === '/' || location.pathname.indexOf('/index') > -1) && window.innerWidth < 768)
  );
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [currentMenu, setCurrentMenu] = React.useState(0);
  // record ref
  const $header = React.createRef();
  const $root = document.getElementById('root');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.isIE = isIE();
      if (document.getElementsByTagName('html')[0].getAttribute('lang') === 'en') {
        window.$isEn = true;
        document.body.classList.add('is-en');
      }

      if (window.prevHistory.pathname === '') {
        window.prevHistory = {
          pathname: window.location.pathname,
          hash: window.location.hash,
          origin: window.location.origin,
          search: window.location.search,
        };
      }

      // detectHeader(window.location.pathname);

      const $main = document.getElementsByTagName('main')[1] || document.getElementsByTagName('main')[0];

      // console.log($main, $main.getElementsByTagName('header'))
      if ($main && $main.getElementsByTagName('header').length) {
        if (window.isIE) {
          if ($root.children[0].classList.contains('fui-header')) {
            $root.children[0].removeElement();
          }
          $root.prependHtml($header.current);
        } else {
          if ($root.children[0].classList.contains('fui-header')) {
            $root.children[0].remove();
          }
          $root.prepend($header.current);
        }
      }

      is_high_resolution_screen();
    }

    return function cleanup() {
      unlisten();
    };
  }, [is_high_resolution_screen, $root, $header, unlisten, detectHeader]);

  // 偵測retina解析度
  const notibarLoad = () => {
    let notibar = document.getElementsByClassName('NotificationBar')[0];
    let navAnchor = Array.from(document.getElementsByClassName('fui-nav-anchor')).length;
    let navTab = Array.from(document.getElementsByClassName('fui-nav-tab')).length;
    // let $main = document.getElementsByTagName('main')[0];
    // debugger
    if (
      notibar &&
      (location.pathname === '/' || location.pathname.indexOf('/index')) &&
      !navAnchor.length &&
      !navTab.length
    ) {
      // notibarPadding()
      window.addEventListener('mousewheel', (e) => {
        if (!$header.current) return;
        let scrollY = window.scrollY || document.documentElement.scrollTop;
        $header.current.style.transform = `translateY(-${
          scrollY > notibar.clientHeight ? notibar.clientHeight : scrollY
        }px)`;
      });

      window.addEventListener('scroll', (e) => {
        if (!$header.current) return;
        let scrollY = window.scrollY || document.documentElement.scrollTop;
        $header.current.style.transform = `translateY(-${
          scrollY > notibar.clientHeight ? notibar.clientHeight : scrollY
        }px)`;
      });
    }

    // window.addEventListener('resize', e => {
    //   notibarPadding()
    // })
  };
  const notibarPadding = () => {
    const $main = Array.from(document.getElementsByTagName('main'))[1];
    let notibar = document.getElementsByClassName('NotificationBar')[0];
    // console.log(notibar)
    if (hasNotification) {
      setTimeout(() => {
        $main.style.paddingTop = `${notibar.clientHeight}px`;
      }, 500);
    }
  };

  const is_high_resolution_screen = () => {
    // console.log(`window.devicePixelRatio > 1`, window.devicePixelRatio > 1);
    return window.devicePixelRatio > 1 ? $html.classList.add('is-retina') : null;
  };

  const unlisten = history.listen((location) => {
    if (menuOpen) {
      setMenuOpen(false);
      setSideMenuOpen(false);
    }
    if (searchOpen) setSearchOpen(false);
    detectHeader(location.pathname);
  });

  const detectHeader = (path) => {
    setMock(HeaderMock);
    setHeaderType('blank');
  };

  const openSearch = () => {
    setSearchOpen(true);
    $html.classList.add('sidebar-open');
  };

  const closeSearch = () => {
    setSearchOpen(false);
    $html.classList.remove('sidebar-open');
  };

  const toggleMenu = (index) => {
    if (index !== currentMenu) {
      setSideMenuOpen(true);
      setCurrentMenu(index);
    } else {
      setSideMenuOpen(!sideMenuOpen);
    }
  };

  if (isEBU) document.body.classList.add('is-ebu');

  const isExternalLink = (link) => {
    return !!link && (/\/\//.test(link) || link.indexOf('.pdf') > -1 || /((tel:|mailto:))/.test(link));
  };

  const changeCategory = (menu, index) => {
    if (!!menu.content) {
      setCatogory(index);
    } else {
      if (isExternalLink(menu.link)) window.location.href = menu.link;
      else history.push(menu.link);
    }
  };

  const openMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) $html.classList.add('sidebar-open');
    else $html.classList.remove('sidebar-open');
  };

  const changeIndex = (checked) => {
    $html.classList.add('fade-out');
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.location.href = checked ? mock.ebuLink : mock.cbuLink;
      }, 300);
    }
  };

  const renderCommercial = (menu) => {
    return menu.map((menuItem, i) =>
      catogory === i && !!menuItem.content ? (
        menuItem.layout === 'list-1' ? (
          <div
            className='menu-extra'
            key={`menu-extra-${i}`}
            id={`navigationBar-${i + 1}-${menuItem.content.menu.length + 3}`}>
            <SectionAd5 {...menuItem.content.commercial} />
          </div>
        ) : (
          <div
            className='menu-extra'
            key={`menu-extra-${i}`}
            id={`navigationBar-${i + 1}-${menuItem.content.menu.length + 2}`}>
            <SectionAd5 {...menuItem.content.commercial} />
          </div>
        )
      ) : (
        ''
      )
    );
  };

  const renderMenuContent = (menu) => {
    // console.log(catogory)
    return menu.map((menuItem, i) =>
      catogory === i && menuItem.content ? (
        menuItem.layout === 'list-1' ? (
          <MenuContent1 id={`navigationBar-${i + 1}`} key={`menu-body-${i}`} {...menuItem.content} />
        ) : (
          <MenuContent2 id={`navigationBar-${i + 1}`} key={`menu-body-${i}`} {...menuItem.content} />
        )
      ) : (
        ''
      )
    );
  };

  switch (headerType) {
    case 'blank':
      return (
        <header
          className={`fui-header ${menuOpen ? 'is-open' : ''} ${searchOpen ? 'is-search-open' : ''} is-fixed`}
          ref={$header}>
          <div className='fui-contaier'>
            <nav className='fui-navbar has-shadow is-spaced' role='navigation' aria-label='main navigation'>
              <div className='fui-navbar-menu'>
                <div className='navbar-center position-relative'>
                  <Link to={mock.logoLink} id='brand' className='navbar-item brand'>
                    <img src={process.env.PUBLIC_URL + mock.logo} alt='' />
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </header>
      );
    case 'help-center':
      return (
        <header
          className={`fui-header is-help-center ${menuOpen ? 'is-open' : ''} ${
            searchOpen ? 'is-search-open' : ''
          } is-fixed`}
          ref={$header}>
          <nav className='fui-navbar has-shadow is-spaced' role='navigation' aria-label='main navigation'>
            <div className='fui-container'>
              <div className='fui-navbar-menu'>
                <div className='navbar-left'>
                  <button id='menu-trigger' className='menu-trigger' onClick={openMenu} tabIndex='0'>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
                <div className='navbar-center'>
                  <Link to={mock.logoLink} id='brand' className='navbar-item brand'>
                    <img src={process.env.PUBLIC_URL + mock.logo} alt='' />
                  </Link>
                </div>
              </div>
            </div>
          </nav>
          <div className='fui-sidebar' role='menu' tabIndex='-1' aria-labelledby='menu-trigger'>
            <div className='sidebar-header'>
              <div className='close' onClick={openMenu}></div>
              <Switch checked={isEBU} name='is_business' onChange={changeIndex} off='個人' on='商務' />
            </div>
            <div className='user-section'>
              <div className='avatar'>
                <img src={process.env.PUBLIC_URL + '/resources/common/images/ebu-avatar.png'} alt='login-avatar' />
              </div>
              <Link to={mock.loginlink}>
                <span className='subtitle'>{isEBU ? '企業登入' : '登入 / 註冊'}</span>
              </Link>
            </div>
            {mock.mobileMenu.shortCut ? (
              <div className='entrance'>
                {mock.mobileMenu.shortCut.map((item, idx) => (
                  <div key={`entrance-item-${idx}`} className='entrance-item'>
                    <Link to={item.link} role='button'>
                      <div className='icon'>
                        <img src={process.env.PUBLIC_URL + item.icon} alt={item.text} />
                      </div>
                      <div className='text'>{item.text}</div>
                    </Link>
                  </div>
                ))}
              </div>
            ) : null}

            <div className={`menu`}>
              {mock.mobileMenu.content.menu.map((menuItem, i) =>
                menuItem.link ? (
                  <Link to={menuItem.link} key={`mobile-menu-item-${i}`} className='item'>
                    <div className='menu-header'>
                      <div className='content'>{menuItem.title}</div>
                    </div>
                  </Link>
                ) : (
                  <div
                    key={`mobile-menu-item-${i}`}
                    className={`item is-collapse ${currentMenu === i && sideMenuOpen ? 'menu-open' : ''}`}>
                    <div className='menu-header' onClick={(e) => toggleMenu(i)}>
                      <div className='content'>{menuItem.title}</div>
                      <div className='action'>
                        <i className='icon-chevron-down' />
                      </div>
                    </div>
                    <div className='menu-content'>
                      {menuItem.list.map((item, lindex) => (
                        <Link key={`mobile-menu-list-item-${lindex}`} className='fui-item' to={item.link}>
                          {item.text}
                        </Link>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
            <div className='menu-footer'>
              <LinkItem
                link={mock.mobileMenu.moreCase}
                img={process.env.PUBLIC_URL + '/resources/common/images/success-case.png'}>
                看更多成功案例
              </LinkItem>
              <LinkItem
                link={mock.mobileMenu.aboutLink}
                img={process.env.PUBLIC_URL + '/resources/common/images/about-info.png'}>
                關於遠傳
              </LinkItem>
              <LinkItem link={'#'} img={process.env.PUBLIC_URL + '/resources/common/images/global.png'}>
                English
              </LinkItem>
            </div>
            {!!mock.mobileMenu.promotion ? <SectionAd5 {...mock.mobileMenu.promotion} /> : null}
          </div>
          <div className='fui-menu-container' role='menu' tabIndex='-1' aria-labelledby='menu-trigger'>
            <div className='fui-menu-header'>
              <div className='fui-container'>
                <Link to={mock.logoLink} className='navbar-item brand'>
                  <img src={process.env.PUBLIC_URL + mock.logo} alt='' />
                </Link>
                <button className='close' onClick={openMenu}>
                  <i className='icon-close'></i>
                </button>
              </div>
            </div>
            <div className='fui-menu-body'>
              <div className='fui-container'>
                <Grid container>
                  <Grid item md={2} className='menu-header'>
                    <div className='menu-content'>
                      <div className='list'>
                        {mock.desktopMenu.map((menu, m) => (
                          <LinkItem
                            key={`desktop-menu-item-${m}`}
                            onClick={(e) => changeCategory(menu, m)}
                            className={`paded ${catogory === m ? 'is-active' : ''}`}>
                            {menu.text}
                          </LinkItem>
                        ))}
                      </div>
                    </div>
                    <div className='menu-action'>
                      {catogory === 0 ? (
                        <LinkItem link={mock.mobileMenu.moreCase} img={'/resources/common/images/success-case.png'}>
                          看更多成功案例
                        </LinkItem>
                      ) : null}
                    </div>
                  </Grid>
                  {renderMenuContent(mock.desktopMenu)}
                </Grid>
                {renderCommercial(mock.desktopMenu)}
              </div>
            </div>
          </div>
        </header>
      );
    case 'help-center-menu':
      return (
        <header
          className={`fui-header is-help-center ${menuOpen ? 'is-open' : ''} ${
            searchOpen ? 'is-search-open' : ''
          } is-fixed`}
          ref={$header}>
          <nav className='fui-navbar has-shadow is-spaced' role='navigation' aria-label='main navigation'>
            <div className='fui-container'>
              <div className='fui-navbar-menu'>
                <div className='navbar-left'>
                  <button id='menu-trigger' className='menu-trigger' onClick={openMenu} tabIndex='0'>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
                <div className='navbar-center'>
                  <Link to={mock.logoLink} id='brand' className='navbar-item brand'>
                    <img src={process.env.PUBLIC_URL + mock.logo} alt='' />
                  </Link>
                </div>
              </div>
            </div>
          </nav>
          <div className='fui-sidebar' role='menu' tabIndex='-1' aria-labelledby='menu-trigger'>
            <div className='sidebar-header'>
              <div className='close' onClick={openMenu}></div>
              {/* <Switch checked={isEBU} name='is_business' onChange={changeIndex} off='個人' on='商務' /> */}
            </div>
            {/* <div className='user-section'>
              <div className='avatar'>
                <img src={process.env.PUBLIC_URL + '/resources/common/images/ebu-avatar.png'} alt='login-avatar' />
              </div>
              <Link to={mock.loginlink}>
                <span className='subtitle'>{isEBU ? '企業登入' : '登入 / 註冊'}</span>
              </Link>
            </div> */}
            {mock.mobileMenu.shortCut ? (
              <div className='entrance'>
                {mock.mobileMenu.shortCut.map((item, idx) => (
                  <div key={`entrance-item-${idx}`} className='entrance-item'>
                    <Link to={item.link} role='button'>
                      <div className='icon'>
                        <img src={process.env.PUBLIC_URL + item.icon} alt={item.text} />
                      </div>
                      <div className='text'>{item.text}</div>
                    </Link>
                  </div>
                ))}
              </div>
            ) : null}

            <div className={`menu`}>
              {mock.mobileMenu.content.menu.map((menuItem, i) =>
                menuItem.link ? (
                  <Link to={menuItem.link} key={`mobile-menu-item-${i}`} className='item'>
                    <div className='menu-header'>
                      <div className='content'>{menuItem.title}</div>
                    </div>
                  </Link>
                ) : (
                  <div
                    key={`mobile-menu-item-${i}`}
                    className={`item is-collapse ${currentMenu === i && sideMenuOpen ? 'menu-open' : ''}`}>
                    <div className='menu-header' onClick={(e) => toggleMenu(i)}>
                      <div className='content'>{menuItem.title}</div>
                      <div className='action'>
                        <i className='icon-chevron-down' />
                      </div>
                    </div>
                    <div className='menu-content'>
                      {menuItem.list.map((item, lindex) => (
                        <Link key={`mobile-menu-list-item-${lindex}`} className='fui-item' to={item.link}>
                          {item.text}
                        </Link>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
            <div className='menu-footer'>
              <LinkItem link={mock.mobileMenu.moreCase} img={'/resources/common/images/ebu-home@2x.png'}>
                遠傳電信網站
              </LinkItem>
              <LinkItem link={mock.mobileMenu.moreCase} img={'/resources/common/images/cbu-home@2x.png'}>
                遠傳商務網站
              </LinkItem>
              <LinkItem link={mock.mobileMenu.moreCase} img={'/resources/common/images/about-info.png'}>
                關於遠傳
              </LinkItem>
            </div>
          </div>
          <div className='fui-menu-container' role='menu' tabIndex='-1' aria-labelledby='menu-trigger'>
            <div className='fui-menu-header'>
              <div className='fui-container'>
                <Link to={mock.logoLink} className='navbar-item brand'>
                  <img src={process.env.PUBLIC_URL + mock.logo} alt='' />
                </Link>
                <button className='close' onClick={openMenu}>
                  <i className='icon-close'></i>
                </button>
              </div>
            </div>
            <div className='fui-menu-body'>
              <div className='fui-container'>
                <Grid container>
                  <Grid item md={2} className='menu-header'>
                    <div className='menu-content'>
                      <div className='list'>
                        {mock.desktopMenu.map((menu, m) => (
                          <LinkItem
                            key={`desktop-menu-item-${m}`}
                            onClick={(e) => changeCategory(menu, m)}
                            className={`paded ${catogory === m ? 'is-active' : ''}`}>
                            {menu.text}
                          </LinkItem>
                        ))}
                      </div>
                    </div>
                    <div className='menu-action'>
                      <LinkItem link={mock.mobileMenu.moreCase} img={'/resources/common/images/ebu-home@2x.png'}>
                        遠傳電信網站
                      </LinkItem>
                      <LinkItem link={mock.mobileMenu.moreCase} img={'/resources/common/images/cbu-home@2x.png'}>
                        遠傳商務網站
                      </LinkItem>
                      <LinkItem link={mock.mobileMenu.moreCase} img={'/resources/common/images/about-info.png'}>
                        關於遠傳
                      </LinkItem>
                    </div>
                  </Grid>
                  {renderMenuContent(mock.desktopMenu)}
                </Grid>
                {renderCommercial(mock.desktopMenu)}
              </div>
            </div>
          </div>
        </header>
      );
    default:
      return (
        <header
          className={`fui-header ${menuOpen ? 'is-open' : ''} ${searchOpen ? 'is-search-open' : ''} is-fixed`}
          ref={$header}>
          {/* {location.pathname === '/index2' && hasNotification ? (
            <NotificationBar
              onLoad={(e) => {
                notibarLoad();
              }}
              className='image-bulletin'
              image={{ appIcon: '', md: '', sm: '', alt: '' }}
              appText=''
              count={5}
              activityName='小米促銷活動'
              link=''
              type='normal'
              title='小米體重計2只要 $399'
              click={2}
              second={5}
              target='_blank'
              activityDsc=''
              appDesc=''
              btn='查看詳情'
              sound={true}
            />
          ) : null}
          {location.pathname === '/index' && window.innerWidth > 768 && hasNotification ? (
            <NotificationBar
              onLoad={(e) => {
                notibarLoad();
              }}
              image={{ appIcon: '', md: '', sm: '', alt: '' }}
              appText=''
              count={5}
              activityName='小米促銷活動'
              link=''
              type='normal'
              title='小米體重計2只要 $399'
              click={2}
              second={5}
              target='_blank'
              activityDsc=''
              appDesc=''
              btn='查看詳情'
              sound={true}
            />
          ) : null}
          {(location.pathname.indexOf('/') > -1 || location.pathname.indexOf('/index') > -1) &&
          window.innerWidth < 768 ? (
            <NotificationBar
              onLoad={(e) => {
                notibarLoad();
              }}
              onClose={(e) => {
                notibarPadding();
              }}
              className='app-download'
              sound={false}
              image={{ appIcon: '', md: '', sm: '', alt: '' }}
              appText=''
              count={5}
              activityName='小米促銷活動'
              link=''
              type='normal'
              title='小米體重計2只要 $399'
              click={2}
              second={5}
              target='_blank'
              activityDsc=''
              appDesc=''
              btn='查看詳情'
            />
          ) : null} */}
          <div className='fui-control-bar' id='Header'>
            <div className='fui-container'>
              <div className='left'>
                {mock.switch ? (
                  <Switch
                    checked={isEBU}
                    name='is_business'
                    onChange={changeIndex}
                    off={mock.switch.off}
                    on={mock.switch.on}
                  />
                ) : null}
              </div>
              <div className='right'>
                <Link to='#'>{mock.aboutLink}</Link>
              </div>
            </div>
          </div>
          <nav className='fui-navbar has-shadow is-spaced' role='navigation' aria-label='main navigation'>
            <div className='fui-container'>
              <div className='fui-navbar-menu'>
                <div className='navbar-left' id='navigationBar-0'>
                  <button id='menu-trigger' className='menu-trigger' onClick={openMenu} tabIndex='0'>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
                <div className='navbar-center' id='Header-1'>
                  <Link to={mock.logoLink} id='brand' className='navbar-item brand'>
                    <img src={process.env.PUBLIC_URL + mock.logo} alt='' />
                  </Link>
                </div>
                <div className='navbar-right' id='Header-1'>
                  <Link to='/' className='navbar-item d-none d-md-inline-block'>
                    <img src={'/resources/common/images/estore.svg'} alt='estore' width='30' height='30' />
                  </Link>
                  {/* <Link to={mock.searchLink} className='navbar-item' id='Search'>
                    <i className='icon-search is-text-black50'></i>
                  </Link> */}
                  <button id='search-trigger' className='navbar-item' onClick={openSearch} id='Search-0'>
                    <i className='icon-search'></i>
                  </button>
                  <div to='/index' id='pIcon-0' className='navbar-item d-none d-md-inline-block'>
                    <Dropdown
                      id='member-menu'
                      label={`<img src='${process.env.PUBLIC_URL}/resources/common/images/member-cbu.svg' alt='member-cbu' width='30' height='30' />`}
                      className='is-right is-top'
                      list={mock.notLoginMenu.content.menu}
                      onChange={(item) => console.log(item)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <div className='fui-search-panel' role='menu' tabIndex='-1' aria-labelledby='search-trigger' id='Search'>
            <div className='fui-search-header'>
              <div className='fui-container'>
                <button className='close' onClick={closeSearch}>
                  <i className='icon-close'></i>
                </button>
              </div>
            </div>
            <div className='fui-search-body'>
              <div className='fui-container'>
                <Grid container justify='center'>
                  <Grid item xs={12} sm={12} md={10}>
                    <KeywordInput
                      id='Search-1'
                      title={mock.keywordInput}
                      defaultKeyword={[
                        'Gogoro',
                        'Gogoro 699',
                        'google',
                        'GPS',
                        'GPS Google',
                        'Gogo',
                        'GoPro',
                        'Geo',
                        'SEO',
                        'OPPO',
                      ]}
                      placeholder='立即搜尋 iphone11 方案'
                    />

                    <div className='search-helper'>
                      {mock.searchHelpers.map((item, i) => (
                        <Link id={`Search-${i + 3}`} to={item.link} key={`search-items-${i}`} className='search-item'>
                          <img
                            src={process.env.PUBLIC_URL + item.image}
                            srcSet={process.env.PUBLIC_URL + (item.retinaImage || item.image)}
                            alt={item.text}
                          />
                          <div className='text'>{item.text}</div>
                        </Link>
                      ))}
                    </div>

                    <h5 className='mb-2'>熱門關鍵字</h5>
                    <div id='Search-5'>
                      {mock.keywords.map((item, i) => (
                        <Link
                          key={`search-keyword-${i}`}
                          role='button'
                          to={`${isEBU ? '/ebu' : ''}/search?keyword=${item.text}`}
                          className='fui-button is-label'>
                          {item.text}
                        </Link>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className='fui-search-footer'>
              <Link id='Search-6' to='/'>
                {mock.footerLink[0]}
              </Link>
              <span className='inline-divider'></span>
              <Link id='Search-7' to='/'>
                {mock.footerLink[1]}
              </Link>
            </div>
          </div>
          <div
            className={`fui-sidebar ${sideMenuOpen ? 'has-menu-open' : ''}`}
            role='menu'
            tabIndex='-1'
            aria-labelledby='menu-trigger'>
            <div className='sidebar-header'>
              <div className='close' onClick={openMenu}></div>
              <Switch
                id='navigationMobile-1'
                checked={isEBU}
                name='is_business'
                onChange={changeIndex}
                off='個人'
                on='商務'
              />
            </div>
            <div className='user-section' id='navigationMobile-2'>
              <div className='avatar'>
                <img src={process.env.PUBLIC_URL + '/resources/common/images/emma-avatar.png'} alt='login-avatar' />
              </div>
              <span className='greetings'>嗨！歡迎回來</span>
              <Link to={mock.loginlink}>
                <span className='subtitle is-text-accent'>個人專區首頁</span>
              </Link>
            </div>
            {mock.mobileMenu.shortCut ? (
              <div className='entrance'>
                {mock.mobileMenu.shortCut.map((item, idx) => (
                  <div key={`entrance-item-${idx}`} className='entrance-item'>
                    <Link to={item.link} role='button'>
                      <div className='icon'>
                        <img src={process.env.PUBLIC_URL + item.icon} alt={item.text} />
                      </div>
                      <div className='text'>{item.text}</div>
                    </Link>
                  </div>
                ))}
              </div>
            ) : null}

            <div className={`menu multi-level`}>
              {mock.mobileMenu.content.menu.map((menuItem, i) =>
                menuItem.link ? (
                  <Link to={menuItem.link} key={`mobile-menu-item-${i}`} className='item'>
                    <div id={`navigationMobile-${i + 3}`} className='menu-header'>
                      <div className='content'>{menuItem.title}</div>
                    </div>
                  </Link>
                ) : (
                  <div
                    key={`mobile-menu-item-${i}`}
                    className={`item ${currentMenu === i && sideMenuOpen ? 'menu-open' : ''}`}>
                    <div className='menu-header' id={`navigationMobile-${i + 3}`} onClick={(e) => toggleMenu(i)}>
                      <div className='content'>{menuItem.title}</div>
                      <div className='action'>
                        <i className='icon-chevron-right' />
                      </div>
                    </div>
                    <MenuDrawer
                      id={`navigationMobile-${i + 3}`}
                      {...menuItem}
                      closeDrawer={(e) => toggleMenu(i)}
                      closeMenu={(e) => {
                        toggleMenu(i);
                        openMenu();
                      }}
                    />
                  </div>
                )
              )}
            </div>
            <div className='menu-footer'>
              <LinkItem
                id='navigationMobile-10'
                link={mock.mobileMenu.moreCase}
                img={process.env.PUBLIC_URL + '/resources/common/images/estore-circular.svg'}>
                網路門市首頁
              </LinkItem>
              <LinkItem
                id='navigationMobile-11'
                link={mock.mobileMenu.aboutLink}
                img={process.env.PUBLIC_URL + '/resources/common/images/appdownload@2x.png'}>
                下載行動客服 APP
              </LinkItem>
              <LinkItem
                id='navigationMobile-12'
                link={mock.mobileMenu.aboutLink}
                img={process.env.PUBLIC_URL + '/resources/common/images/about-info.png'}>
                關於遠傳
              </LinkItem>
            </div>
            <div className='align-center'>
              <Button btnStyle='secondary' className='mx-0'>
                登出
              </Button>
            </div>
            {!!mock.mobileMenu.promotion ? <SectionAd5 id='navigationMobile-9' {...mock.mobileMenu.promotion} /> : null}
          </div>
          <div className='fui-menu-container' role='menu' tabIndex='-1' aria-labelledby='menu-trigger'>
            <div className='fui-menu-header'>
              <div className='fui-container' id='Header-1'>
                <Link to={mock.logoLink} className='navbar-item brand'>
                  <img src={process.env.PUBLIC_URL + mock.logo} alt='' />
                </Link>
                <button className='close' onClick={openMenu}>
                  <i className='icon-close'></i>
                </button>
              </div>
            </div>
            <div className='fui-menu-body'>
              <div className='fui-container'>
                <Grid container>
                  <Grid item md={2} className='menu-header'>
                    <div className='menu-content'>
                      <div className='list'>
                        {mock.desktopMenu.content.menu.map((menu, m) => (
                          <LinkItem
                            id={`navigationBar-${m + 1}-1`}
                            key={`desktop-menu-item-${m}`}
                            onClick={(e) => changeCategory(menu, m)}
                            className={`paded ${catogory === m ? 'is-active' : ''}`}>
                            {menu.text}
                          </LinkItem>
                        ))}
                      </div>
                    </div>
                    <div className='menu-action'>
                      <LinkItem
                        link={mock.mobileMenu.moreCase}
                        id='navigationBar-7'
                        img={process.env.PUBLIC_URL + '/resources/common/images/estore-circular.svg'}>
                        網路門市首頁
                      </LinkItem>
                    </div>
                  </Grid>
                  {renderMenuContent(mock.desktopMenu.content.menu)}
                </Grid>
                {renderCommercial(mock.desktopMenu.content.menu)}
              </div>
            </div>
          </div>
        </header>
      );
  }
};

export default Header;
