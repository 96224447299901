import React, { Component } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Link that also works for external URL's
 */
export default class Link extends Component {
  isExternalLink = (link) => {
    return !!link && (/\/\//.test(link) || link.indexOf('.pdf') > -1 || /((tel:|mailto:))/.test(link));
  };

  render() {
    return this.isExternalLink(this.props.to) ? (
      <a href={this.props.to} {...this.props}>
        {this.props.children}
      </a>
    ) : (
      <ReactLink {...this.props}>{this.props.children}</ReactLink>
    );
  }
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
