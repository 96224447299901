import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../Link';
import formatNumber from '../../utils/numberFormatter';
import { withRouter } from 'react-router-dom';

class PlanCard extends React.Component {
  doClick = (link) => {
    if (this.props.onClick) {
      this.props.onClick(link);
    } else {
      this.props.history.push(link);
    }
  };
  render() {
    return (
      <div className={`fui-card is-radio-card`}>
        <div className='fui-card-action'>
          <div className='fui-card-content'>
            <div className='fui-card-plan'>
              {this.props.ribbon ? <div className='fui-card-ribbon'>{this.props.ribbon}</div> : null}
              {this.props.sticker ? <img src={this.props.sticker} alt='' className='fui-card-sticker' /> : null}
              <h1>
                <small className='unit'>{this.props.unit}</small>
                <span className='price'>${formatNumber(this.props.price, 0)}</span>
              </h1>
              <h5 className='month'>{this.props.month}個月</h5>
              <div className='body note'>{this.props.note}</div>
            </div>
            <div className='fui-plan-list'>
              {this.props.list.map((item, j) => (
                <div className='fui-plan-item' key={`radio-card-list-${j}`}>
                  <span className='item-title'>{item.title}</span>
                  {item.originPrice || item.projectPrice ? (
                    <span className='item-content'>
                      {item.originPrice > 0 ? <del>${formatNumber(item.originPrice)}</del> : null}
                      <span>${formatNumber(item.projectPrice)}</span>
                    </span>
                  ) : (
                    <span className='item-content' dangerouslySetInnerHTML={{ __html: item.text }}></span>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className='fui-card-extra'>
            <div role='button' onClick={(e) => this.props.modalOpen(this.props.modal)}>
              <span className='text'>方案詳情</span>
              <i className='icon-plus'></i>
            </div>
          </div>
        </div>
        <div className='fui-card-button'>
          <div role='button' className='fui-button is-primary' onClick={(e) => this.props.doClick(this.props)}>
            <span className='text'>{this.props.action.text}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PlanCard);
