import React, { Component } from 'react';

import Button from '../components/Button';

/**
 * 
 * 
  this.props.history.push('/error', {
    errorCode: '404'
  });
 */
class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorCode: 404,
    };
  }

  componentDidMount() {
    // this.props.setFooter('error');
  }

  render() {
    return (
      <main className='fourOfour-wrapper'>
        <div className='fui-container'>
          <div className='fourOfour'>
            <div className='fourOfour--left'>
              <h2 className='is-text-darkgray50'>頁面載入時發生問題</h2>
              <p className='subtitle is-text-darkgray100'>請重新載入，或稍後再試</p>
              <p className='body1 is-text-darkgray100'>{this.state.errorCode}</p>
              <Button link='../' btnStyle='primary' className='fui-button is-primary'>
                重新載入
              </Button>
            </div>
            <div className='fourOfour--right'>
              <img src={process.env.PUBLIC_URL + '/resources/ebu/images/error@3x.png'} alt='error' />
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Error;
