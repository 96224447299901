import React from 'react';

import { setCartData, trackPageViewStart } from '../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import { Grid } from '@material-ui/core';
import NavAnchor from '../components/partials/NavAnchor';
import NavContentTab1 from '../components/partials/NavContentTab1';
import TabPane from '../components/tab/TabPane';
import PlanCard from '../components/partials/card/PlanCard';
import Breadcrumb from '../components/Breadcrumb';
import Button from '../components/Button';
import Item from '../components/item/Item';
import Panel from '../components/panel/Panel';
import PanelList from '../components/panelContent/PanelList';
import PanelContent from '../components/panelContent/PanelContent';
import ListLayout from '../components/panelContent/ListLayout';
import EStoreBanner from '../components/partials/banner/EStoreBanner';
import CardMultiSteps from '../components/card/CardMultiSteps';
import Paginate from '../components/paginate';
import EstoreModal from '../components/EstoreModal';
import ServiceEntry from '../components/partials/card/ServiceEntry';

class EbuShop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      modalOpen: false,
      currentModal: null,
    };
  }

  updateTab = (index) => {
    this.setState({
      currentTab: index,
    });
  };

  triggerModal = (content) => {
    this.setState({
      modalOpen: true,
      currentModal: content,
    });
  };

  pageChange = (result) => {
    this.props.history.push(
      `${this.props.location.pathname}?keyword=${this.state.keyword}&page=${result.selected + 1}`
    );
  };

  render() {
    return (
      <main>
        <NavAnchor pageTitle='網路門市' />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '網路門市', link: '/estore' },
            { text: '企業用戶專屬行動優惠', link: '/' },
            { text: '美安專屬優惠', link: '/' },
          ]}
        />
        {/* 直接使用 BannerPromotionBasic */}
        <EStoreBanner
          slides={[
            {
              image: {
                md: '/resources/cbu/estore-event-product/cbu-ebustore-1920x500.jpg',
                sm: '/resources/cbu/estore-event-product/cbu-ebustore-banner-750x900.jpg',
              },
              title: '單辦門號大標題文字最多十六個字元',
              description: [
                '一二三四五六七八九十一二三四五六',
                '一二三四五六七八九十一二三四五六',
                '一二三四五六七八九十一二三四五六',
              ],
              actions: [
                {
                  text: 'CTA',
                  btnStyle: 'primary',
                  link: '#',
                  target: '_self',
                },
                {
                  text: 'Secondary',
                  btnStyle: 'secondary',
                  link: '#',
                  target: '_blank',
                },
              ],
            },
          ]}
        />
        <NavContentTab1
          tabs={{
            default: this.state.currentTab,
            list: [{ label: '優惠方案' }, { label: '特殊方案' }, { label: 'iPhone' }, { label: '選項四' }],
          }}
          onChange={this.updateTab}
        />
        <TabPane active={this.state.currentTab === 0}>
          <div className='ribbon-bg'>
            <PlanCard
              title='單門號499超值雙飽'
              openModal={(e) => this.triggerModal(e)}
              cards={[
                {
                  type: 'promotion',
                  title: '月付499網內語音＋高速飆網超值雙飽方案',
                  list: [
                    '新辦攜碼送電影票',
                    '申辦每月抽AirPods',
                    '最高送$4,800通話金',
                    '自動加入美安企業群組',
                    '月租60元免收享回饋',
                    '月租$299，10GB任你用',
                  ],
                  action: {
                    btnStyle: 'secondary',
                    text: '立即申辦',
                    link: '#',
                    target: '_self',
                  },
                },
                {
                  type: 'default',
                  ribbon: '單辦門號',
                  image: '/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png',
                  title: '單門號超值雙飽方案',
                  list: [
                    {
                      text: '合約月租費總額$7,176',
                    },
                    {
                      text: '美安會員5%回饋金$359',
                    },
                  ],
                  action: [
                    {
                      btnStyle: 'primary',
                      text: '立即申辦',
                      link: '#',
                      target: '_self',
                    },
                    {
                      btnStyle: 'secondary',
                      text: '貼心小叮嚀',
                      modal: {
                        title: '貼心小叮嚀',
                        content: `
                          <p>單門號飆網不限速</p>
                          本方案限指定企業客戶之員工本人及一等親方可辦理。<br/>
                          訂單確認後將由宅配物流人員現場收回影本雙證及其他相關證明文件。<br/>
                          本專案生效後 24 個月內不得退租 ( 含一退一租、轉至 3G、2G 或預付卡、因違約或違反法令致遭停機者 ) 、取消或調降費率。倘有上述情形者視同放棄本專案贈送之優惠，含月租費優惠、網內外語音分鐘數、數據傳輸量、網內免費 SMS 則數等，並需依啟用方案繳交專案補貼款，實際應繳之專案違約金以本專案合約未到期之日為單位，按合約總日數比例計算。計算公式：實際應繳專案補貼款 = 專案補貼款總額 x ( 合約未到期日數 / 合約約定日數 )，四捨五入計算至整數。<br/>
                          專案贈送之免費分鐘數抵扣不含市話、影像電話、國際電話、國際漫遊、語音信箱、加值語音服務、特碼服務及其他免費電話通話分鐘數。<br/>
                        `,
                      },
                    },
                  ],
                },
                {
                  type: 'default',
                  ribbon: '單辦門號',
                  image: '/resources/cbu/exclusive/estore-exclusive-plan-icon-02@2x.png',
                  title: '單門號超值雙飽方案',
                  list: [
                    {
                      text: '合約月租費總額$11,976',
                    },
                    {
                      text: '美安會員5%回饋金$599',
                    },
                  ],
                  action: [
                    {
                      btnStyle: 'primary',
                      text: '立即申辦',
                      link: '#',
                      target: '_self',
                    },
                    {
                      btnStyle: 'secondary',
                      text: '貼心小叮嚀',
                      modal: {
                        title: '貼心小叮嚀',
                        content: `
                          <p>單門號飆網不限速</p>
                          本方案限指定企業客戶之員工本人及一等親方可辦理。<br/>
                          訂單確認後將由宅配物流人員現場收回影本雙證及其他相關證明文件。<br/>
                          本專案生效後 24 個月內不得退租 ( 含一退一租、轉至 3G、2G 或預付卡、因違約或違反法令致遭停機者 ) 、取消或調降費率。倘有上述情形者視同放棄本專案贈送之優惠，含月租費優惠、網內外語音分鐘數、數據傳輸量、網內免費 SMS 則數等，並需依啟用方案繳交專案補貼款，實際應繳之專案違約金以本專案合約未到期之日為單位，按合約總日數比例計算。計算公式：實際應繳專案補貼款 = 專案補貼款總額 x ( 合約未到期日數 / 合約約定日數 )，四捨五入計算至整數。<br/>
                          專案贈送之免費分鐘數抵扣不含市話、影像電話、國際電話、國際漫遊、語音信箱、加值語音服務、特碼服務及其他免費電話通話分鐘數。<br/>
                        `,
                      },
                    },
                  ],
                },
              ]}
            />
            <section className='pt-1'>
              <div className='fui-container'>
                <h4 className='is-text-darkgray50'>單辦門號方案細項</h4>
                <div className='table-response'>
                  <table>
                    <thead>
                      <tr>
                        <th width='33%'>月租費</th>
                        <th>$299</th>
                        <th>$499</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>合約期間(A)</td>
                        <td>24個月</td>
                        <td>24個月</td>
                      </tr>
                      <tr>
                        <td>合約期間月租費總和(B)</td>
                        <td>$7,176</td>
                        <td>$11,976</td>
                      </tr>
                      <tr>
                        <td>
                          美安會員回饋金(B)8*5%
                          <br />
                          (僅提供門號月租費總額回饋，不含手機及黃金門號費用)
                        </td>
                        <td>$359</td>
                        <td>$599</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
            <PlanCard
              title='搭手機799抽AirPods'
              openModal={(e) => this.triggerModal(e)}
              cards={[
                {
                  type: 'promotion',
                  title: '月付799網內互打/高速超值雙飽',
                  list: [
                    '新辦攜碼送電影票',
                    '申辦每月抽AirPods',
                    '最高送$4,800通話金',
                    '自動加入美安企業群組',
                    '月租60元免收享回饋',
                    '月租$299，10GB任你用',
                  ],
                  action: {
                    btnStyle: 'secondary',
                    text: '立即申辦',
                    link: '#',
                    target: '_self',
                  },
                },
                {
                  type: 'default',
                  ribbon: '單辦門號',
                  image: '/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png',
                  title: '單門號超值雙飽方案',
                  list: [
                    {
                      text: '合約月租費總額$7,176',
                    },
                    {
                      text: '美安會員5%回饋金$359',
                    },
                  ],
                  action: [
                    {
                      btnStyle: 'primary',
                      text: '立即申辦',
                      link: '#',
                      target: '_self',
                    },
                    {
                      btnStyle: 'secondary',
                      text: '貼心小叮嚀',
                      modal: {
                        title: '貼心小叮嚀',
                        content: `
                          <p>單門號飆網不限速</p>
                          本方案限指定企業客戶之員工本人及一等親方可辦理。<br/>
                          訂單確認後將由宅配物流人員現場收回影本雙證及其他相關證明文件。<br/>
                          本專案生效後 24 個月內不得退租 ( 含一退一租、轉至 3G、2G 或預付卡、因違約或違反法令致遭停機者 ) 、取消或調降費率。倘有上述情形者視同放棄本專案贈送之優惠，含月租費優惠、網內外語音分鐘數、數據傳輸量、網內免費 SMS 則數等，並需依啟用方案繳交專案補貼款，實際應繳之專案違約金以本專案合約未到期之日為單位，按合約總日數比例計算。計算公式：實際應繳專案補貼款 = 專案補貼款總額 x ( 合約未到期日數 / 合約約定日數 )，四捨五入計算至整數。<br/>
                          專案贈送之免費分鐘數抵扣不含市話、影像電話、國際電話、國際漫遊、語音信箱、加值語音服務、特碼服務及其他免費電話通話分鐘數。<br/>
                        `,
                      },
                    },
                  ],
                },
                {
                  type: 'default',
                  ribbon: '單辦門號',
                  image: '/resources/cbu/exclusive/estore-exclusive-plan-icon-02@2x.png',
                  title: '單門號超值雙飽方案',
                  list: [
                    {
                      text: '合約月租費總額$11,976',
                    },
                    {
                      text: '美安會員5%回饋金$599',
                    },
                  ],
                  action: [
                    {
                      btnStyle: 'primary',
                      text: '立即申辦',
                      link: '#',
                      target: '_self',
                    },
                    {
                      btnStyle: 'secondary',
                      text: '貼心小叮嚀',
                      modal: {
                        title: '貼心小叮嚀',
                        content: `
                          <p>單門號飆網不限速</p>
                          本方案限指定企業客戶之員工本人及一等親方可辦理。<br/>
                          訂單確認後將由宅配物流人員現場收回影本雙證及其他相關證明文件。<br/>
                          本專案生效後 24 個月內不得退租 ( 含一退一租、轉至 3G、2G 或預付卡、因違約或違反法令致遭停機者 ) 、取消或調降費率。倘有上述情形者視同放棄本專案贈送之優惠，含月租費優惠、網內外語音分鐘數、數據傳輸量、網內免費 SMS 則數等，並需依啟用方案繳交專案補貼款，實際應繳之專案違約金以本專案合約未到期之日為單位，按合約總日數比例計算。計算公式：實際應繳專案補貼款 = 專案補貼款總額 x ( 合約未到期日數 / 合約約定日數 )，四捨五入計算至整數。<br/>
                          專案贈送之免費分鐘數抵扣不含市話、影像電話、國際電話、國際漫遊、語音信箱、加值語音服務、特碼服務及其他免費電話通話分鐘數。<br/>
                        `,
                      },
                    },
                  ],
                },
              ]}
            />
            <section className='pt-1'>
              <div className='fui-container'>
                <h4 className='is-text-darkgray50'>搭手機方案細項</h4>
                <div className='table-response'>
                  <table>
                    <thead>
                      <tr>
                        <th width='33%'>月租費</th>
                        <th>$299</th>
                        <th>$499</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>合約期間(A)</td>
                        <td>24個月</td>
                        <td>24個月</td>
                      </tr>
                      <tr>
                        <td>合約期間月租費總和(B)</td>
                        <td>$7,176</td>
                        <td>$11,976</td>
                      </tr>
                      <tr>
                        <td>
                          美安會員回饋金(B)8*5%
                          <br />
                          (僅提供門號月租費總額回饋，不含手機及黃金門號費用)
                        </td>
                        <td>$359</td>
                        <td>$599</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
            <section className='py-1'>
              <div className='fui-container'>
                <h4>活動公告</h4>
                <Panel>
                  <PanelContent
                    content={`
                    <p>活動名稱：美安網路商店迎春好友大募集</p>
                    <p>活動期間：2020/03/01~2020/05/31</p>
                    <p>活動說明二：期間內「申辦新門號」或是把「其他家業者攜碼」至遠傳電信即加贈好禮</p>
                    <p>活動名稱：美安網路商店迎春好友大募集</p>
                  `}
                  />
                  <PanelList
                    prefix='bulleted'
                    list={[
                      { text: '申辦出神入話方案（實付資費月付$499）者，即贈華納威秀電影票1張（限當月前200名）。' },
                      {
                        text:
                          '申辦尊爵手機方案（實付資費月付$799）者，即贈Gigastone Type-C 10000mAh行動電源一顆（限當月前100名）。',
                      },
                      { text: '申辦尊爵手機方案（實付資費月付$999）者，即贈遠東集團禮券$1,000元（限當月前100名）。' },
                    ]}
                  />
                </Panel>
              </div>
            </section>
            <CardMultiSteps
              title='簡單申辦4步驟'
              list={[
                {
                  name: '線上申辦',
                  content:
                    '線上申辦指定專案（含付款及上傳證件），訂單完成後，訂單編號將透過E-Mail及簡訊發送予您。後續可透過訂單編號查詢申辦進度。',
                },
                {
                  name: '訂單確認',
                  content: '訂單送出後2-3個工作天內，客服專員與客戶本人聯繫確認申請內容，完成訂單確認。',
                },
                {
                  name: '證件回收',
                  content:
                    '物流中心宅配出貨（ 同步發送簡訊至客戶指定行動門號 ），請客戶本人準備相關證件之正本及影本，供宅配人員確認回收，並完成申請書親簽。',
                },
                {
                  name: '完成啟用',
                  content: '客戶申辦之行動門號，於完成親簽申請書並繳交相關證件後3小時完成啟用。',
                },
              ]}
            />
            <section className='py-1'>
              <div className='fui-container'>
                <h4>中獎公告</h4>
                <Panel>
                  <PanelList
                    list={[
                      { text: '活動名稱：美安大會迎新抽好禮&雙十一加碼送' },
                      { text: '活動期間：2019/11/01~2019/11/30' },
                      { text: '公布日期：2019/12/23' },
                    ]}
                  />
                  <div className='table-response mt-3'>
                    <table>
                      <thead>
                        <tr>
                          <th>編號</th>
                          <th>姓名</th>
                          <th>優惠顧客代碼</th>
                          <th>中獎門號</th>
                          <th>獎項</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>01</td>
                          <td>劉Ｘ瑞</td>
                          <td> - </td>
                          <td>0903XXX998</td>
                          <td>iPhone 11 64G</td>
                        </tr>
                        <tr>
                          <td>01</td>
                          <td>劉Ｘ瑞</td>
                          <td> - </td>
                          <td>0903XXX998</td>
                          <td>iPhone 11 64G</td>
                        </tr>
                        <tr>
                          <td>01</td>
                          <td>劉Ｘ瑞</td>
                          <td> - </td>
                          <td>0903XXX998</td>
                          <td>iPhone 11 64G</td>
                        </tr>
                        <tr>
                          <td>01</td>
                          <td>劉Ｘ瑞</td>
                          <td> - </td>
                          <td>0903XXX998</td>
                          <td>iPhone 11 64G</td>
                        </tr>
                        <tr>
                          <td>01</td>
                          <td>劉Ｘ瑞</td>
                          <td> - </td>
                          <td>0903XXX998</td>
                          <td>iPhone 11 64G</td>
                        </tr>
                        <tr>
                          <td>01</td>
                          <td>劉Ｘ瑞</td>
                          <td> - </td>
                          <td>0903XXX998</td>
                          <td>iPhone 11 64G</td>
                        </tr>
                        <tr>
                          <td>01</td>
                          <td>劉Ｘ瑞</td>
                          <td> - </td>
                          <td>0903XXX998</td>
                          <td>iPhone 11 64G</td>
                        </tr>
                        <tr>
                          <td>01</td>
                          <td>劉Ｘ瑞</td>
                          <td> - </td>
                          <td>0903XXX998</td>
                          <td>iPhone 11 64G</td>
                        </tr>
                        <tr>
                          <td>01</td>
                          <td>劉Ｘ瑞</td>
                          <td> - </td>
                          <td>0903XXX998</td>
                          <td>iPhone 11 64G</td>
                        </tr>
                        <tr>
                          <td>01</td>
                          <td>劉Ｘ瑞</td>
                          <td> - </td>
                          <td>0903XXX998</td>
                          <td>iPhone 11 64G</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <Paginate
                    initialPage={this.state.page - 1}
                    pageCount={100}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    containerClassName='fui-pagination'
                    previousLabel={<i className='icon-chevron-left'></i>}
                    nextLabel={<i className='icon-chevron-right'></i>}
                    firstLabel={<i className='icon-first'></i>}
                    lastLabel={<i className='icon-last'></i>}
                    onPageChange={this.pageChange}
                  />
                </Panel>
              </div>
            </section>
          </div>
        </TabPane>
        <TabPane active={this.state.currentTab === 1}></TabPane>
        <TabPane active={this.state.currentTab === 2}></TabPane>
        <TabPane active={this.state.currentTab === 3}></TabPane>
        {this.state.modalOpen ? (
          <Modal
            onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
            onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
            isOpen={this.state.modalOpen}
            contentLabel='Example Modal'
            className={'fui-notice-modal'}>
            <div className='fui-modal-content'>
              <div className='fui-modal-head'>
                <h5>{this.state.currentModal.title}</h5>
              </div>
              <div
                className='fui-modal-body'
                dangerouslySetInnerHTML={{ __html: this.state.currentModal.content }}></div>
              <div className='fui-modal-foot two-buttons'>
                <Button btnStyle='primary' onClick={(e) => this.setState({ modalOpen: false })}>
                  確定
                </Button>
                <Button btnStyle='secondary' onClick={(e) => this.setState({ modalOpen: false })}>
                  取消
                </Button>
              </div>
            </div>
          </Modal>
        ) : null}
      </main>
    );
  }
}

export default withRouter(EbuShop);
