import React from 'react';
import Helmet from 'react-helmet';
import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import Formsy from 'formsy-react';
import Slider from 'react-slick';
import { setFlowCartData, trackPageViewStart } from '../../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NavTab from '../../components/partials/NavTab';
import Breadcrumb from '../../components/Breadcrumb';
import Link from '../../components/Link';
import LinkItem from '../../components/item/LinkItem';
import Button from '../../components/Button';
import Cart from '../../components/FlowCart';
import Tab from '../../components/tab/Tab';
import TabPane from '../../components/tab/TabPane';
import FormBanner from '../../components/partials/banner/FormBanner';
import FcoinMsg from '../../components/FcoinMsg';

import ColorSelector from '../../components/form/ColorSelector';
import RadioButtons from '../../components/form/RadioButtons';
import RadioGroup from '../../components/form/RadioGroup';
import NumberSelector from '../../components/form/NumberSelector';
import RadioCard from '../../components/form/RadioCard';
import RadioCarousel from '../../components/form/RadioCarousel';
import CheckboxCardCarousel from '../../components/form/CheckboxCardCarousel';
import LabelInput from '../../components/form/LabelInput';
import EstoreModal from '../../components/EstoreModal';

import * as Validation from '../../utils/validation';
import formatNumber from '../../utils/numberFormatter';

import axios from 'axios';
import * as Mock from '../../mock/Product';

// const lastLocation = useLastLocation();

class Phone extends React.Component {
  constructor(props) {
    super(props);
    this.flowRef = React.createRef();

    this.state = {
      prevPage: '',
      modalOpen: false,
      isRenewalPath: false,
      isDiscountInit: true,
      isRenewal: false,
      isBackward: false,
      inputValidationValid: false,
      canEdit: true,
      cartUpdate: false,
      currentModal: {
        type: '',
        title: '',
        content: '',
      },
      article: '',
      artileAnchor: [],
      productInfo: { ...Mock.Product },
      currentNav: window.location.hash === '#comment' ? 2 : 0,
      cuurentCheckboxTab: 0,
      currentImage: [],
      identityPass: false,
      sldierSetting: {
        dots: true,
        infinite: false,
        arrows: true,
        slidesToShow: 1,
        draggable: true,
        adaptiveHeight: true,
      },
      installmentCount: 74,
      installmentModal: {
        type: 'notice',
        title: '信用卡分期付款',
        content: [
          '分期資訊',
          '結帳金額 $30,300',
          {
            '3期0利率': '$10,000',
            '5期0利率': '$6,000',
            '6期0利率': '$5,000',
            '12期0利率': '$2,520',
          },
          [
            '手機分期付款僅提供宅配取貨，並使用線上刷卡供銷費者使用。',
            '分期價除不盡餘額部分，將於第一期帳單收取。',
            '各產品可供分期期數不盡相同，依結帳時系統提供分期數為主；如一張訂單有多項商品，只會出現共同皆可使用之分期數。',
          ],
        ],
      },
      welfareModal: {
        type: 'notice',
        title: '備註',
        content: `
          本產品為<font style="color:#EF1C21;font-weight:bold;">拆封九成五新或原廠整新非全新品</font>
          ，機身可能有輕微細紋，不影響功能。外盒可能有些許磨損或使用痕跡，規格及盒內配件以實際出貨為主，請不介意之消費者再進行購買。<br/><br/>
          請您耐心等候，為保障您退換貨權益，請於安裝SIM卡前先行檢查商品外觀及隨盒配件是否完整。同一筆訂單中的商品會一起出貨，故可能因為訂單中加購的商品缺貨而導致訂單延遲出貨，下單前請務必再次確認商品型號/顏色/資費方案及加購商品是否正確。
        `,
      },
      originFlow: Mock.planStep,
      applyFlow: [...Mock.planStep, ...Mock.newFlow],
      deliverySubmit: false,
      reservationSubmit: false,
      deliveryForm: {
        type: { value: '新申辦' },
        name: { value: '', required: true },
        contact: { value: '', required: true },
      },
      reservationForm: {
        reserve_no: { value: '', required: true },
        id_number: { value: '', required: true },
      },
      // 使用既有的表單組件，再根據表單的值修改帶入購物車的內容
      form: {
        color: { value: Mock.Product.colors[0].value, required: true },
        with_plan: { value: window.location.hash === '#device-alone' ? '0' : '1', required: true },
        input_validation: { value: '', required: true },
        id_number: { value: '', required: true },
        scid: { value: '', required: true },
        apply_method: { value: '' },
        number: { value: 1, required: true },
        plan: { value: '', required: true },
        phone_number: { value: '', required: true },
        gift: { value: '', required: true },
        extra_product: { value: '', required: true },
        extra_plan: { value: '', required: true },
      },
      cart: {
        apply_method: '',
        plan: null,
        product: null,
        identity_validation: null,
        input_validation: null,
        phone_number: null,
        gift: [],
        extra_product: [],
        extra_plan: [],
      },
      step: 0,
      editStep: -1,
    };
  }

  componentDidMount = () => {
    axios.get('/xml/DIMcontent.xml').then((result) => {
      this.setArticleAnchor(Mock.Evaluation);
    });

    const { productInfo } = this.state;

    if (
      this.props.location.state &&
      this.props.location.state.applyMethod &&
      this.props.location.state.applyMethod === 'renewal'
    ) {
      this.setState({
        canEdit: false,
        isRenewal: true,
        applyFlow: [...this.state.originFlow, ...Mock.renewFlow],
        step: 2,
        identityPass: true,
        form: {
          ...this.state.form,
          apply_method: { value: '遠傳門號續約' },
          scid: { value: this.props.location.state.phoneNumber },
          id_number: { value: this.props.location.state.idNumber },
        },
        cart: {
          ...this.state.cart,
          apply_method: '遠傳門號續約',
          phone_number: this.props.location.state.phoneNumber,
          identity_validation: {
            id_number: this.props.location.state.idNumber,
            scid: this.props.location.state.phoneNumber,
            isPass: true,
          },
        },
      });
      let elem = window || document.documentElement;
      if (window.isIE) {
        elem.scroll(0, 0);
      } else {
        elem.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
          cartUpdate: true,
        });
      }
    } else {
      if (!!this.props.flowCart.cart) {
        this.setState({
          isBackward: true,
          cart: this.props.flowCart.cart,
          productInfo: this.props.flowCart.productInfo,
          applyFlow: this.props.flowCart.applyFlow,
          step: this.props.flowCart.step,
          form: this.props.flowCart.form,
        });

        this.forceUpdate();

        setTimeout(() => {
          this.scrollToPosition();
        }, 1500);
      } else {
        this.setState({
          cart: {
            ...this.state.cart,
            product: {
              name: productInfo.name,
              originPrice: productInfo.originPrice,
              salePrice: productInfo.salePrice,
              projectPrice: productInfo.projectPrice,
              color: this.state.form.color.value,
            },
          },
        });
      }
    }

    let unboxing = ['#intro', '#spec-compare', '#internal-function', '#comment'].indexOf(window.location.hash);

    this.setState({
      isRenewalPath: this.props.location.pathname.indexOf('renewal') !== -1,
      currentImage: !this.state.productInfo.images
        ? []
        : Array.isArray(this.state.productInfo.images)
        ? this.state.productInfo.images
        : this.state.productInfo.images[this.state.form.color.value],
      currentNav: unboxing > -1 ? unboxing : 0,
    });

    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', (e) => this.detectStep());

      if (window.innerWidth > 768) window.addEventListener('mousewheel', (e) => this.detectSection(e));
      else window.addEventListener('scroll', (e) => this.detectSection(e));
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('popstate', (e) => this.detectStep());
    if (window.innerWidth > 768) window.removeEventListener('mousewheel', (e) => this.detectSection(e));
    else window.removeEventListener('scroll', (e) => this.detectSection(e));
  };

  detectStep = (e) => {
    let step = this.state.editStep !== -1 ? this.state.editStep : this.state.step;

    if (
      this.props.location.pathname.indexOf('/estore/product/' + this.props.match.params.category + '/detail') > -1 &&
      this.flowRef.current
    ) {
      if (step - 1 >= 0) {
        this.changeFormSection(step - 1);
      } else {
        window.history.go(-1);
      }
    }
    return false;
  };

  detectSection = () => {
    if (
      this.props.location.pathname.indexOf('renewal') !== -1 &&
      this.state.applyFlow[this.state.step].name === 'plan' &&
      this.state.isDiscountInit
    ) {
      setTimeout(() => {
        this.setState({
          isDiscountInit: false,
          currentModal: {
            type: 'discount',
            title: '限定好康優惠',
            content: `<p>恭喜您！<br/>
          獲得今日限定 300 元商品購物金，不限申辦資費方案，趕快記下您的折扣碼，在結帳時使用吧！</p>
          
          <p class="is-text-error">優惠折扣碼 fet6666</p>`,
          },
          modalOpen: true,
        });
      }, 500);
    }
    if (
      this.props.location.pathname.indexOf('/estore/product/detail') === -1 ||
      this.state.form.with_plan.value === 0 ||
      this.state.currentNav !== 0 ||
      this.state.step > 0
    )
      return;

    if (this.flowRef.current) {
      let fistSec = this.flowRef.current.getElementsByTagName('section')[this.state.step];
      let fistRect = fistSec.getBoundingClientRect();
      let scrollY = window.scrollY || document.documentElement.scrollTop;

      if (scrollY > fistRect.top && '#' + fistSec.id !== window.location.hash && fistSec.id !== 'apply') {
        this.props.history.push('#' + fistSec.id);
      }
    }
  };

  CheckboxTabChange = (idx) => {
    this.setState({
      cuurentCheckboxTab: idx,
    });
  };

  changeNavTab = (index) => {
    this.setState({
      currentNav: index,
    });
  };

  inputChange = (n, v, content) => {
    let form = Object.assign(this.state.form);

    // 如果已進行申辦流程，切換申辦方式時跳出 modal
    if (n === 'with_plan') {
      this.props.history.push(v === '0' ? '#device-alone' : this.props.location.pathname);

      if (v === '0') {
        this.setState({
          cart: {
            ...this.state.cart,
            apply_method: '',
            plan: null,
            product: null,
            identity_validation: null,
            input_validation: null,
            phone_number: null,
            gift: [],
            extra_product: [],
            extra_plan: [],
          },
          form: {
            ...this.state.form,
            with_plan: { value: window.location.hash === '#device-alone' ? '0' : '1', required: true },
            input_validation: { value: '', required: true },
            id_number: { value: '', required: true },
            scid: { value: '', required: true },
            apply_method: { value: '' },
            plan: { value: '', required: true },
            phone_number: { value: '', required: true },
            gift: { value: '', required: true },
            extra_product: { value: '', required: true },
            extra_plan: { value: '', required: true },
          },
          editStep: -1,
          step: 0,
          modalOpen: false,
        });

        this.forceUpdate();
      }
    }
    if (
      this.state.applyFlow[this.state.editStep > -1 ? this.state.editStep : this.state.step].type === 'input-validation'
    ) {
      this.setState({
        inputValidationValid: /^09\d{2}-?\d{3}-?\d{3}$/.test(v),
        cart: {
          ...this.state.cart,
          input_validation: {
            phone_number: v,
            isPass: false,
          },
        },
      });
    }
    if (this.state.applyFlow[this.state.step].type === 'identity-validation') {
      this.setState({
        inputValidationValid:
          this.state.form.phone_number.value !== '' &&
          this.state.form.scid.value !== '' &&
          /^09\d{2}-?\d{3}-?\d{3}$/.test(this.state.form.scid.value),
        cart: {
          ...this.state.cart,
          identity_validation: {
            id_number: this.state.form.phone_number.value,
            scid: this.state.form.scid.value,
            isPass: false,
          },
        },
      });
    }

    if (n === 'apply_method' && this.state.editStep !== -1) {
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'change-alert',
          originSelect: form[n].value,
          originFlow: this.state.applyFlow,
          title: '組合方案選項將被清除',
          content: '提醒你，剛才你選擇的組合方案將被清除，請再次確認是否要重新選擇？',
        },
      });
    }

    form[n].value = v;

    // 切換顏色，圖片跟著切換內容
    if (n === 'color') {
      this.setState({
        currentImage: this.state.productInfo.images[v],
        cart: {
          ...this.state.cart,
          apply_method: '',
          plan: null,
          product: null,
          identity_validation: null,
          input_validation: null,
          phone_number: null,
          gift: [],
          extra_product: [],
          extra_plan: [],
        },
      });

      form = {
        ...form,
        input_validation: { value: '', required: true },
        id_number: { value: '', required: true },
        scid: { value: '', required: true },
        apply_method: { value: '' },
        plan: { value: '', required: true },
        phone_number: { value: '', required: true },
        gift: { value: '', required: true },
        extra_product: { value: '', required: true },
        extra_plan: { value: '', required: true },
      };
    }
    // 根據申辦方式切換申辦流程
    if (n === 'apply_method') {
      // 申辦新門號
      this.props.history.push('#apply');
      let flow = [];
      if (v === '申辦新門號') {
        flow = [...this.state.originFlow, ...Mock.newFlow];
      }
      // 攜碼到遠傳
      if (v === '攜碼') {
        flow = [...this.state.originFlow, ...Mock.carryFlow];
      }
      // 遠傳門號續約
      if (v === '遠傳門號續約') {
        flow = [...this.state.originFlow, ...Mock.renewFlow];
      }
      // 表單內容更新，購物車內容同步更新
      this.setState({
        cart: {
          ...this.state.cart,
          apply_method: v,
        },
        applyFlow: flow,
      });
      this.forceUpdate();
    }

    // 將完整資料帶入購物車
    if (!!content) {
      let cart = { ...this.state.cart };

      cart[n] = content;

      this.setState({
        cart: cart,
      });

      this.forceUpdate();
    }

    this.setState({
      form: form,
      cartUpdate: true,
    });

    this.forceUpdate();
  };

  openModal = (modal) => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'notice',
        ...modal,
      },
    });
  };

  // 取消切換申辦方式，還原為既有狀態
  canscelSelect = () => {
    let form = Object.assign(this.state.form);
    let cart = { ...this.state.cart };

    form.apply_method.value = this.state.currentModal.originSelect;
    cart.apply_method = this.state.currentModal.originSelect;

    this.setState({
      modalOpen: false,
      applyFlow: this.state.currentModal.originFlow,
      cart: { ...cart, apply_method: this.state.currentModal.originSelect },
      form: {
        ...form,
        apply_method: { value: this.state.currentModal.originSelect },
      },
    });
  };

  resetFormset = () => {
    // 重置 cart 與 form
    this.setState({
      cart: {
        ...this.state.cart,
        plan: null,
        identity_validation: null,
        input_validation: null,
        phone_number: null,
        gift: [],
        extra_product: [],
        extra_plan: [],
        product: null,
      },
      form: {
        ...this.state.form,
        input_validation: { value: '', required: true },
        id_number: { value: '', required: true },
        scid: { value: '', required: true },
        number: { value: 1, required: true },
        plan: { value: '', required: true },
        phone_number: { value: '', required: true },
        gift: { value: '', required: true },
        extra_product: { value: '', required: true },
        extra_plan: { value: '', required: true },
      },
      editStep: -1,
      step: 0,
      modalOpen: false,
    });
  };

  stepChange = (step) => {
    this.setState({
      editStep: -1,
      step: step,
    });

    this.forceUpdate();

    setTimeout(() => {
      this.scrollToPosition();
    }, 500);
  };

  scrollToPosition = () => {
    // 捲動到對應位置
    let elem = window || document.documentElement;

    if (!this.flowRef.current) {
      if (window.isIE) {
        elem.scroll(0, 0);
      } else {
        elem.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
          cartUpdate: true,
        });
      }
      return;
    }

    let index = this.state.editStep !== -1 ? this.state.editStep : this.state.step;
    let flows = this.flowRef.current.getElementsByClassName('fui-plan-flow');
    let lastFlow = flows[index];
    let prevFlow = flows[index - 1 < 0 ? 0 : index - 1];
    let rect = lastFlow.getBoundingClientRect();
    let prevRect = prevFlow.getBoundingClientRect();
    let scrollY = window.scrollY || document.documentElement.scrollTop;

    if (window.isIE) {
      elem.scroll(0, rect.top - prevRect.height + scrollY - 50);
    } else {
      elem.scrollTo({
        left: 0,
        top: scrollY - prevRect.height + rect.top - 50,
        behavior: 'smooth',
        cartUpdate: true,
      });
    }

    this.props.history.push('#' + this.state.applyFlow[this.state.step].id);

    if (this.props.location.pathname.indexOf('renewal') > -1 && this.state.applyFlow[this.state.step].name === 'plan') {
      setTimeout(() => {
        this.setState({
          currentModal: {
            type: 'discount',
            title: '限定好康優惠',
            content: `<p>恭喜您！<br/>
            獲得今日限定 300 元商品購物金，不限申辦資費方案，趕快記下您的折扣碼，在結帳時使用吧！</p>
            
            <p class="is-text-error">優惠折扣碼 fet6666</p>`,
          },
          modalOpen: true,
        });
      }, 500);
    }
  };

  phoneCheck = () => {
    // 為顯示不同狀態的畫面，不同次點擊切換不同狀態
    if (this.state.identityPass) {
      this.setState({
        currentModal: {
          type: 'discount',
          title: '限定好康優惠',
          content: `<p>恭喜您！<br/>
          獲得今日限定 300 元商品購物金，不限申辦資費方案，趕快記下您的折扣碼，在結帳時使用吧！</p>
          
          <p class="is-text-error">優惠折扣碼 fet6666</p>`,
        },
        modalOpen: true,
      });

      this.setState({
        cart: {
          ...this.state.cart,
          input_validation: {
            phone_number: this.state.form.input_validation.value,
            isPass: /^09\d{2}-?\d{3}-?\d{3}$/.test(this.state.form.input_validation.value),
          },
        },
      });
    } else
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'phoneCheck-alert',
          title: '還未符合續約資格',
          content: `很抱歉，你的身份條件暫時無法續約。請洽遠傳門市或手機直撥 <a href='tel:123' target='_blank'>123客服專線</a> 查詢。`,
          confirmText: '可續約時通知我',
          cancelText: '可續約時通知我',
        },
      });

    this.setState({
      identityPass: !this.state.identityPass,
    });
  };

  doModalEvent = () => {
    this.setState({
      modalOpen: false,
    });
  };

  optVarify = () => {
    this.setState({
      modalOpen: true,
      currentModal: {
        type: 'otpForm',
        title: '驗證手機號碼',
        phone: this.state.form.phone_number.value,
      },
    });
  };

  identityCheck = () => {
    // doValidation
    // 驗證成功
    if (this.state.identityPass)
      this.setState({
        cart: {
          ...this.state.cart,
          identity_validation: {
            id_number: this.state.form.id_number.value,
            scid: this.state.form.scid.value,
            isPass: true,
          },
        },
      });
    // 如果驗證失敗
    else
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'identityCheck-alert',
          title: '還未符合續約資格',
          content: `很抱歉，你的身份條件暫時無法續約。請洽遠傳門市或手機直撥 <a href='tel:123' target='_blank'>123客服專線</a> 查詢。`,
          confirmText: '可續約時通知我',
          cancelText: '查看原合約',
        },
      });

    this.setState({
      identityPass: !this.state.identityPass,
    });
  };

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  changeFormSection = (index) => {
    if (this.state.applyFlow[index].type === 'identity-validation') {
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'change-alert',
          flowIndex: index,
          flowType: this.state.applyFlow[index].type,
          originFlow: this.state.applyFlow,
          title: '組合方案選項將被清除',
          content: '提醒你，剛才你選擇的組合方案將被清除，請再次確認是否要重新選擇？',
        },
      });
    } else {
      this.setState({
        editStep: index,
      });

      this.forceUpdate();
      setTimeout(() => {
        this.scrollToPosition();
        this.props.history.push(`#${this.state.applyFlow[index].id}`);
      }, 500);
    }
  };

  setArticleAnchor = (article) => {
    let list = [];
    let newArticle = '';
    let articleList = article.split(/<h2>/g);

    articleList.forEach((art, i) => {
      let content = '';
      if (art.indexOf('</h2>') > -1) {
        let h2 = art.split(/<\/h2>/g)[0];
        list.push(h2);
        content = `
          <h2 id='product-article-title-${i + 1}'>${h2}</h2>
          ${art}
        `;
      } else {
        content = art;
      }

      newArticle += content;
    });

    this.setState({
      article: newArticle,
      artileAnchor: list,
    });
  };

  valuePreview = (flow) => {
    // 根據區塊名稱顯示個別內容
    if (flow.tabs) {
      return '';
    } else if (flow.name === 'identity_validation') {
      return this.state.form.scid.value;
    } else if (flow.name === 'input_validation') {
      return this.state.form.input_validation.value;
    } else if (flow.name === 'gift') {
      return this.state.cart[flow.name].length ? '贈品 x' + this.state.cart[flow.name].length : '';
    } else {
      return this.state.form[flow.name].value;
    }
  };

  setProductModal = (type) => {
    if (type === 'delivery') {
      this.setState({
        currentModal: {
          type: 'deliveryForm',
          title: '到貨請通知我',
          content: '',
          deliveryFormWithPlan: this.state.form.with_plan.value === 1,
        },
        modalOpen: true,
      });
    }

    if (type === 'reservation') {
      this.setState({
        currentModal: {
          type: 'reservationForm',
          title: '預約單取貨',
        },
        modalOpen: true,
      });
    }
  };

  submit = () => {
    this.props.setFlowCartData({
      cart: this.state.cart,
      form: this.state.form,
      applyFlow: this.state.applyFlow,
      step: this.state.step,
      productInfo: this.state.productInfo,
    });

    this.props.history.push('/estore/order/orderlist', {
      co_type: 'NC',
    });
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);

    this.setState({
      modalOpen: false,
    });

    if (type === 'otpForm') {
      this.identityCheck();
    }
    // 貨到通知送出
  };

  modalConfirm = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    if (type === 'change-alert') {
      const { currentModal } = this.state;
      if (currentModal.flowType && currentModal.flowType === 'identity-validation') {
        this.setState({
          step: currentModal.flowIndex,
          editStep: -1,
          form: {
            ...this.state.form,
            input_validation: { value: '', required: true },
            id_number: { value: '', required: true },
            scid: { value: '', required: true },
            phone_number: { value: '', required: true },
            gift: { value: '', required: true },
            extra_product: { value: '', required: true },
            extra_plan: { value: '', required: true },
          },
          cart: {
            ...this.state.cart,
            phone_number: null,
            identity_validation: null,
            input_validation: null,
            gift: [],
            extra_product: [],
            extra_plan: [],
            product: null,
          },
        });
        this.props.history.push(`#rn`);
      } else {
        this.resetFormset();
      }
    }
    if (type === 'phoneCheck-alert') {
      // this.phoneCheck();
    }
    if (type === 'identityCheck-alert') {
      // this.identityCheck();
    }
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalCancel = (type) => {
    // 預約取貨送出
    console.info('MODAL CANCEL', type);
    if (type === 'change-alert') {
      this.canscelSelect();
    }
    this.setState({
      modalOpen: false,
    });
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { form, deliveryForm, reservationForm, productInfo, currentNav } = this.state;

    return (
      <main>
        <Helmet>
          <title></title>
        </Helmet>
        <NavTab
          pageTitle={productInfo.name}
          tabs={{
            name: 'product-nav-tab',
            default: 0,
            list: [{ label: '購買' }, { label: '介紹及規格' }, { label: '開箱文' }],
          }}
          onChange={this.changeNavTab}
        />
        <Breadcrumb
          color={currentNav === 0 ? 'block' : 'white'}
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '網路門市', link: '/estore' },
            { text: '手機', link: '/estore/product' },
            { text: productInfo.name, link: `/estore/product/detail/${productInfo.slug}` },
          ]}
        />
        <TabPane active={currentNav === 0}>
          <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
            <section className='fui-product-section pb-0'>
              <div className='fui-container'>
                {/* 產品資訊 */}
                <div className='fui-panel'>
                  <div className='fui-replenishment-ribbon'>
                    <span className='text'>補貨中</span>
                  </div>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      {/* 產品圖，若產品數量不足，增加樣式 is-replenishment */}

                      <Slider {...this.state.sldierSetting} className='fui-product-slider'>
                        {this.state.currentImage.length ? (
                          this.state.currentImage.map((img, i) => (
                            <img src={img} alt='' key={`fui-product-slider-${i}`} />
                          ))
                        ) : (
                          <img src={process.env.PUBLIC_URL + '/resources/common/images/no-image@2x.png'} alt='' />
                        )}
                      </Slider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {/* 產品內容 */}
                      <h2>
                        {productInfo.tag
                          ? productInfo.tag.map((tag, i) => (
                              <span className='fui-tag' key={`product-tag-${i}`}>
                                {tag}
                              </span>
                            ))
                          : null}
                        {productInfo.name}
                      </h2>
                      <ColorSelector
                        required={true}
                        name='color'
                        options={productInfo.colors}
                        value={form.color.value}
                        onChange={this.inputChange}
                      />
                      {!this.state.isRenewal ? (
                        <RadioGroup
                          label='我要'
                          name='with_plan'
                          options={productInfo.withPlan}
                          default={form.with_plan.value}
                          onChange={this.inputChange}
                        />
                      ) : null}

                      {/* 實際狀況根據資料判斷 */}
                      {form.with_plan.value === '1' && !this.state.isRenewal ? (
                        <div className='fui-product-action'>
                          <Button btnStyle='primary' size='large' onClick={(e) => this.setProductModal('delivery')}>
                            貨到通知我
                          </Button>
                          <Button btnStyle='primary' size='large' onClick={(e) => this.setProductModal('reservation')}>
                            預約取貨單
                          </Button>
                        </div>
                      ) : null}
                      {form.with_plan.value === '0' && !this.state.isRenewal ? (
                        <div className='price-section'>
                          <div>商品價</div>
                          <h4>
                            <del>${formatNumber(productInfo.originPrice, 0)}</del>
                            <span>${formatNumber(productInfo.salePrice, 0)}</span>
                          </h4>
                          <div>
                            <NumberSelector
                              name='number'
                              value={form.number.value}
                              min={1}
                              max={3}
                              onChange={this.inputChange}
                            />
                            <Button btnStyle='primary' size='large'>
                              立即結帳
                            </Button>
                          </div>
                        </div>
                      ) : null}
                      {productInfo.discount ? (
                        <div className='fui-discount-bar'>
                          <span className='discount-ribbon'>促銷優惠</span>
                          <Link
                            to={productInfo.discount.link}
                            target={productInfo.discount.target}
                            className='fui-discount-link'>
                            <span className='content'>{productInfo.discount.text}</span>
                            <span>
                              <i className='icon-chevron-right'></i>
                            </span>
                          </Link>
                        </div>
                      ) : null}
                      <div className='fui-notice'>
                        {form.with_plan.value === '0' ? (
                          <div className='fui-item'>
                            分期0利率，每期最低 ${this.state.installmentCount}
                            <div
                              role='button'
                              onClick={(e) => this.openModal(this.state.installmentModal)}
                              className='more'>
                              詳情
                            </div>
                          </div>
                        ) : null}
                        <div className='fui-item'>
                          <span dangerouslySetInnerHTML={{ __html: Mock.productNotice.text }}></span>
                          <div role='button' onClick={(e) => this.openModal(this.state.welfareModal)} className='more'>
                            詳情
                          </div>
                        </div>
                        <FcoinMsg
                          content={
                            this.state.isRenewalPath
                              ? `回饋 <strong class='is-text-error'>VIP會員</strong> 遠傳幣 <strong class='is-text-error'>1%</strong> 起`
                              : `回饋遠傳幣 <strong class='is-text-error'>0.3%</strong> 起`
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                {form.with_plan.value === '1' ? (
                  <div className='align-center my-3'>
                    <b className='subtitle down-more'>
                      {this.state.isRenewal ? '往下繼續搭門號申辦流程' : '往下繼續申辦流程'}
                      <br />
                      <i className='icon-chevron-down'></i>
                    </b>
                  </div>
                ) : null}
              </div>
            </section>
            {/* 申辦步驟 */}
            <div className='fui-container'>
              {form.with_plan.value === '1' ? (
                <div className='fui-plan-flow-container' ref={this.flowRef}>
                  {this.state.applyFlow.map((flow, i) =>
                    this.state.step >= i ? (
                      <section
                        id={flow.id}
                        className={`fui-plan-flow ${
                          (this.state.step === i && this.state.editStep === -1) ||
                          (this.state.editStep > -1 && this.state.editStep === i)
                            ? 'is-active'
                            : ''
                        }`}
                        key={`fui-plan-flow-${i}`}>
                        <div className='fui-flow-title'>
                          <div className='content'>
                            <h2>
                              <span>
                                {i + 1}.{flow.title}
                              </span>
                              <span className='selected-value'>{this.valuePreview(flow)}</span>
                            </h2>
                          </div>
                          {this.state.canEdit ||
                          (!this.state.canEdit &&
                            this.state.isRenewal &&
                            flow.name !== 'apply_method' &&
                            flow.name !== 'identity_validation') ? (
                            <div className='extra'>
                              <Button btnStyle='secondary' size='small' onClick={(e) => this.changeFormSection(i)}>
                                {this.state.cart[flow.name] === '' ||
                                !this.state.cart[flow.name] ||
                                (Array.isArray(this.state.cart[flow.name]) && !this.state.cart[flow.name].length)
                                  ? '尚未選擇'
                                  : '修改'}
                              </Button>
                            </div>
                          ) : null}
                        </div>
                        <div className='fui-flow-body'>
                          <h6 className='fui-flow-description'>
                            {flow.name === 'apply_method' ? '' : flow.description}
                          </h6>
                          {/* 根據類別切換表單內容 */}
                          {/* 有 Tab 的產品牌卡，用於加購 */}
                          {flow.type === 'tab-checkbox-card' ? (
                            <div>
                              <Tab name={flow.tabs.name} list={flow.tabs.list} onChange={this.CheckboxTabChange} />
                              {flow.tabs.content.map((tab, i) => (
                                <TabPane key={`tab-checkbox-${i}`} active={i === this.state.cuurentCheckboxTab}>
                                  <CheckboxCardCarousel
                                    {...tab}
                                    value={form[tab.name].value}
                                    onChange={this.inputChange}
                                    modalOpen={this.openModal}
                                  />
                                </TabPane>
                              ))}
                            </div>
                          ) : null}
                          {/* 門號驗證 */}
                          {flow.type === 'identity-validation' ? (
                            <div className='paper fui-validation-input'>
                              <Grid container>
                                <Grid item xs={12} sm={8} md={6}>
                                  <LabelInput
                                    label='遠傳門號'
                                    placeholder='輸入門號'
                                    validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
                                    required={form.scid.required}
                                    name='scid'
                                    validationErrors={{
                                      isDefaultRequiredValue: '請輸入原門號號碼',
                                      matchRegexp: '您輸入了錯誤資訊，請再檢查！',
                                    }}
                                    withValidCheck={
                                      this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                    }
                                    value={form.scid.value}
                                    onChange={this.inputChange}
                                  />
                                  <LabelInput
                                    label='身分證字號'
                                    placeholder='輸入身分證字號'
                                    required={form.id_number.required}
                                    name='id_number'
                                    validations={{
                                      idNumberValid: Validation.idNumberValid,
                                    }}
                                    validationErrors={{
                                      isDefaultRequiredValue: '請輸入身分證字號',
                                      idNumberValid: '您輸入了錯誤資訊，請再檢查！',
                                    }}
                                    withValidCheck={
                                      this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                    }
                                    value={form.id_number.value}
                                    onChange={this.inputChange}
                                  />
                                  {!(
                                    this.state.cart.identity_validation && this.state.cart.identity_validation.isPass
                                  ) ? (
                                    <Button
                                      btnStyle='primary'
                                      size='large'
                                      disabled={form.scid.value === '' || form.id_number.value === ''}
                                      onClick={this.optVarify}>
                                      查詢可續約方案
                                    </Button>
                                  ) : null}
                                </Grid>
                              </Grid>
                            </div>
                          ) : null}
                          {/* 攜入門號驗證 */}
                          {flow.type === 'input-validation' ? (
                            <div className='fui-validation-input'>
                              <Grid container>
                                <Grid item xs={12}>
                                  {flow.tabs ? (
                                    <div>
                                      <Tab
                                        name={flow.tabs.name}
                                        list={flow.tabs.list}
                                        onChange={this.CheckboxTabChange}
                                      />
                                      {flow.tabs.content.map((tab, i) => (
                                        <TabPane
                                          key={`tab-inputvalid-${i}`}
                                          active={i === this.state.cuurentCheckboxTab}>
                                          <div
                                            className={`fui-cards is-simple-step ${
                                              tab.length === 3 ? 'three' : 'four'
                                            }-card no-scrollbar`}>
                                            {tab.map((card, c) => (
                                              <div className='fui-card' key={`input-validation-step-${c}`}>
                                                <div className='fui-card-action'>
                                                  <div className='fui-card-caption'>
                                                    <div className='fui-card-content'>
                                                      <div className='circle-number'>{card.no}</div>
                                                      <h3
                                                        className='fui-card-title'
                                                        dangerouslySetInnerHTML={{ __html: card.title }}></h3>
                                                      <p
                                                        className='fui-card-description'
                                                        dangerouslySetInnerHTML={{ __html: card.description }}></p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </TabPane>
                                      ))}
                                    </div>
                                  ) : (
                                    <div className='fui-cards three-card no-scrollbar'>
                                      {flow.cards.map((card, c) => (
                                        <div className='fui-card' key={`input-validation-step-${c}`}>
                                          <div className='fui-card-action'>
                                            <div className='fui-card-caption'>
                                              <div className='fui-card-content'>
                                                <div className='circle-number'>{card.no}</div>
                                                <h3 className='fui-card-title'>{card.title}</h3>
                                                <p className='fui-card-description'>{card.description}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </Grid>
                                <Grid item xs={12} sm={8} md={6}>
                                  <LabelInput
                                    label='輸入攜入門號'
                                    placeholder='輸入非遠傳門號'
                                    validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
                                    required={form.input_validation.required}
                                    name='input_validation'
                                    validationErrors={{
                                      isDefaultRequiredValue: '您輸入了錯誤資訊，請再檢查！',
                                      matchRegexp: '您輸入了錯誤資訊，請再檢查！',
                                    }}
                                    value={form.input_validation.value}
                                    withValidCheck={
                                      this.state.cart.input_validation && this.state.cart.input_validation.isPass
                                    }
                                    onChange={this.inputChange}
                                  />
                                  <div
                                    className='text-sm is-text-gray100'
                                    dangerouslySetInnerHTML={{ __html: flow.note }}></div>
                                  {!(this.state.cart.input_validation && this.state.cart.input_validation.isPass) ? (
                                    <Button
                                      btnStyle='primary'
                                      size='large'
                                      disabled={!this.state.inputValidationValid}
                                      onClick={this.phoneCheck}>
                                      驗證號碼
                                    </Button>
                                  ) : null}
                                </Grid>
                              </Grid>
                            </div>
                          ) : null}
                          {/* 產品牌卡，用於好禮 */}
                          {flow.type === 'checkbox-card' ? (
                            <CheckboxCardCarousel
                              name={flow.name}
                              {...flow}
                              value={form[flow.name].value}
                              onChange={this.inputChange}
                              modalOpen={this.openModal}
                            />
                          ) : null}
                          {/* 單選 Radio Button，選擇門號 */}
                          {flow.type === 'radio-carousel' ? (
                            <RadioCarousel
                              name={flow.name}
                              {...flow}
                              disabled={this.state.isBackward}
                              search={true}
                              value={form[flow.name].value}
                              onChange={this.inputChange}
                            />
                          ) : null}
                          {/* 單選牌卡，選擇資費方案 */}
                          {flow.type === 'radio-card' ? (
                            <RadioCard
                              name={flow.name}
                              tags={flow.tags}
                              options={flow.options}
                              value={form[flow.name].value}
                              onChange={this.inputChange}
                              modalOpen={this.openModal}
                            />
                          ) : null}
                          {/* 單純的 Radio Button，用於申請方式 */}
                          {flow.type === 'radio' ? (
                            <RadioGroup
                              label=''
                              name={flow.name}
                              description={flow.description}
                              options={flow.options}
                              default={form[flow.name].value}
                              value={form[flow.name].value}
                              onChange={this.inputChange}
                            />
                          ) : null}
                        </div>
                      </section>
                    ) : null
                  )}
                </div>
              ) : null}
            </div>
            {/* 購物車 */}
            <Cart
              canRollback={true}
              afterUpdate={(e) => this.setState({ cartUpdate: false })}
              update={this.state.cartUpdate}
              steps={this.state.applyFlow}
              currentStep={this.state.step}
              openStep={this.state.editStep === -1 ? this.state.step : this.state.editStep}
              product={{ ...this.state.productInfo, color: form.color.value, number: form.number.value }}
              cart={this.state.cart}
              submit={(e) => this.submit(e)}
              stepChange={(e) => this.stepChange(e)}
              editStep={(e) => this.changeFormSection(e)}
            />
          </Formsy>
        </TabPane>
        <TabPane active={currentNav === 1}>
          <FormBanner
            className='is-product-spec'
            title={`${productInfo.name} 的介紹及規格`}
            image={{
              md: '/resources/cbu/estore-product/cbu_banner_bg_1920x240.jpg',
              sm: '/resources/cbu/estore-product/cbu_banner_bg_m_750x320.jpg',
            }}
          />
          <section className='fui-product-section pt-4'>
            <div className='fui-container'>
              {Mock.Spec.map((sp, i) => (
                <div className='fui-panel is-spec-panel' key={`spect-panel-${i}`}>
                  {Array.isArray(sp.value) ? (
                    <div className='spec-group'>
                      <h2 dangerouslySetInnerHTML={{ __html: sp.key }}></h2>
                      {sp.value.map((sp_item, k) => (
                        <div className='spec-item' key={`spec-panel-${i}-item-${k}`}>
                          <div className='spec-title'>{sp_item.key}</div>
                          <div className='spec-content' dangerouslySetInnerHTML={{ __html: sp_item.value }}></div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className='spec-group'>
                      <div className='spec-item' key={`spec-panel-${i}`}>
                        <div className='spec-title'>{sp.key}</div>
                        <div className='spec-content' dangerouslySetInnerHTML={{ __html: sp.value }}></div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </section>
        </TabPane>
        <TabPane active={currentNav === 2}>
          <FormBanner
            className='is-product-spec'
            title={`${productInfo.name} 的開箱文`}
            image={{
              md: '/resources/cbu/estore-product/cbu_banner_bg_1920x240.jpg',
              sm: '/resources/cbu/estore-product/cbu_banner_bg_m_750x320.jpg',
            }}
          />
          <section className='fui-product-section pt-4'>
            <div className='fui-container'>
              <div className='fui-panel is-product-feature'>
                <article>
                  <h2 className='mt-0'>快速導覽</h2>
                  {this.state.artileAnchor.length ? (
                    <div className='mb-3'>
                      {this.state.artileAnchor.map((anchor, i) => (
                        <LinkItem link={`#product-article-title-${i + 1}`} key={`product-article-title-${i + 1}`}>
                          {anchor}
                        </LinkItem>
                      ))}
                    </div>
                  ) : null}
                  <div className='editBbox' dangerouslySetInnerHTML={{ __html: this.state.article }}></div>
                </article>
              </div>
            </div>
          </section>
        </TabPane>

        <EstoreModal
          {...this.state.currentModal}
          open={this.state.modalOpen}
          onSubmit={this.modalSubmit}
          onConfirm={this.modalConfirm}
          onCancel={this.modalCancel}
          onClose={this.modalClose}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    analyticsInitialState: state.analyticsInitialState, // 可加可不加
    flowCart: state.flowCartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      trackPageViewStart,
      setFlowCartData,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Phone));
