import React from 'react';

import { setCartData, trackPageViewStart } from '../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavAnchor from '../components/partials/NavAnchor';

class SecretSpecialoffersShopComEDM extends React.Component {
  render() {
    return (
      <main className='is-estore'>
        <NavAnchor pageTitle='網路門市' />
        <section>
          <div className='fui-container'>
            <img src={'/resources/ebu/ebu-store/img-dm-498@2x.jpg'} width='100%' alt='' />
          </div>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    analyticsInitialState: state.analyticsInitialState, // 可加可不加
    cart: state.cartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      trackPageViewStart,
      setCartData,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SecretSpecialoffersShopComEDM));
