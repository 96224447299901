export const howToCarry = {
  title: '攜碼貼心提醒',
  content: [
    '如果你目前還有電信合約，請盡快攜帶雙證件前往解約，並結清最近一期原電信業者帳單或欠費。',
    '遠傳電信送出攜碼申請後，經原業者電信同意已無合約事實，且確認已結清相關帳單費用後，將寄出攜碼成功簡訊。',
    'SIM卡將在生效日前送達，門號生效前一日，將簡訊通知你可於隔天更換sim卡, 重新開機後啟用。',
    '本訂單需由申辦者本人親自簽收，請您於收貨／取貨時，備妥本人雙證件正本以核對身分。',
    '請於門號開通的 2 天內，完成遠傳心生活會員註冊，遠傳幣回饋點數，將在門號開通後的第3天匯入「遠傳心生活會員帳號」，不另外用簡訊通知。詳情請見<a class="is-text-underline is-text-grayblue" href="//fetnet.net/estore/info/lifecircle.html" target="_blank">【遠傳幣指用指南】</a>。',
  ],
};

export const Order = {
  order_number: 'TLXXXXXXXX',
  payment: '信用卡',
  receive: '宅配到府',
  mobile: '0934-***-890',
  address: '台北市內湖區瑞光*************',
  receipt: '電子發票電子載具',
};

export const complete = {
  title: '即將完成續約',
  content: ["<img src='/resources/cbu/order/failed@2x.png' width='140' />", '再次提醒，確定付款後即完成門號續約。'],
};
export const staging = {
  title: '信用卡分期付款說明',
  content: [
    '手機分期付款僅提供宅配取貨，並使用線上刷卡消費者使用。',
    '分期價除不盡餘額部分，將於第一期帳單收取。',
    '各產品可供分期期數不盡相同，依結帳時系統提供分期數為主；如一張訂單有多項商品，只會出現共同皆可使用之分期數。',
    '提供分期付款銀行如下：',
    '3期0利率 (每期 $1,833)',
    '日盛國際商業銀行、台北富邦、台新國際商業銀行、永旺商銀、永豐商業銀行、玉山商業銀行、合作金庫商業銀行、香港上海匯豐銀行、國泰世華商業銀行、第一商業銀行、華南商業銀行、陽信商業銀行、臺灣中小企業銀行、臺灣新光商業銀行、遠東國際商業銀行、聯邦商業銀行。',
    '6期0利率 (每期 $916)',
    '日盛國際商業銀行、台北富邦、台新國際商業銀行、永旺商銀、永豐商業銀行、玉山商業銀行、合作金庫商業銀行、香港上海匯豐銀行、國泰世華商業銀行、第一商業銀行、華南商業銀行、陽信商業銀行、臺灣中小企業銀行、臺灣新光商業銀行、遠東國際商業銀行、聯邦商業銀行。',
  ],
};
export const contract = {
  title: '月租$688網路門市限定專案合約',
  content: [
    '違反本合約條件需繳交補貼款，專案補貼款：NT$20,000 (按日遞減)，電信優惠補貼款：NT$250/月 (依違約時已享有優惠期間計算)',
    '壹.專案內容',
    '立同意書人同意連續使用遠傳電信服務（下稱本服務）至少30個月(綁約期間)，提前終止服務契約(含：申請退租、欠費被銷號、違規使用門號、一退一租、降轉資費、取消語音或數據資費方案、異動專案等)，願補償下列金額',
    '1.本專案優惠內容自門號開通當天起算，當月優惠未使用完畢者，不得遞延至下個月使用，也不得移轉或折抵現金。綁約期滿後，仍可持續享有優惠內容，若終止服務契約、降轉資費或異動專案時，本專案優惠內容即終止。',
    '2.本專案贈送「行動上網無限傳輸」係指用戶於優惠期間內，當月使用之國內數據上網傳輸量超過資費方案約定的上限後，',
  ],
};
export const secondCardModal = {
  title: '第二證件說明',
};

export const Cart = {
  apply_method: '申辦新門號',
  plan: {
    ribbon: '網路限定方案再送禮券',
    value: '月租$1,399x12個月',
    sticker: '/resources/cbu/estore/5-g.png',
    unit: '月租',
    price: 1399,
    prepay: 0,
    month: '12',
    note: '學生教職限定',
    list: [
      { title: '商品專案價', originPrice: 12000, projectPrice: 10000 },
      { title: '上網傳輸量', text: '合約內不限速吃到飽' },
      { title: '語音優惠', text: '市話 + 網外送120分鐘' },
      { title: '活動優惠', text: '$500遠百禮券 + $500購物金' },
    ],
    tag: ['5G方案', '吃到飽', '月付$1,000以上', '月付$1,399'],
    modal: {
      title: '$1,399/12個月 方案詳情',
      content: [
        {
          商品專案價: '$10,000',
          預繳金: '$1,000',
          上網傳輸量: '合約內不限速吃到飽',
          網內通話: '每通前5分鐘免費',
          網外通話: '60分鐘免費',
          語音優惠: '市話 + 網外送120分鐘',
          活動優惠: '免費送friDay影音30天 + msuic免費聽30天',
        },
        '分期資訊',
        '結帳金額 $30,300.',
        {
          '3期0利率': '$10,000',
          '5期0利率': '$6,060',
          '6期0利率': '$5,050',
          '12期0利率': '$2,525',
        },
        '合約說明',
        '設備補貼款 $10000 元。實際應繳之補貼款以合約未到期之“日”為單位，按合約總日數比例計算。合約未到期之日數自解約當日起算，計算公式：專案補貼款 x(合約未到期日數／合約總日數)=實際應繳專案補貼款（四捨五入計算至整數）。',
        '電信補貼款 $10000元，電信優惠補貼款以實際已享贈送傳輸量補貼優惠(每月電信優惠補貼款優惠)X(合約未到期日數／合約約定日數)，四捨五入計算至整數。',
      ],
    },
  },
  product: {
    name: 'Apple iPhone11 Pro Max 256GB',
    originPrice: 39900,
    salePrice: 38900,
    projectPrice: 25600,
    color: '夜幕綠',
  },
  identity_validation: null,
  input_validation: null,
  avaliable_at: '2021/02/01',
  phone_number: {
    label: '0903-063-482',
    value: '0903-063-482',
    description: '選號費將於第一期帳單進行扣抵',
    price: 1000,
    tag: ['黃金門號'],
  },
  gift: [
    {
      type: 'product',
      image: '/resources/cbu/estore-product/estore-product-thumb-12@2x.jpg',
      value: 'VANTEC Qi 紅色無線快速充電盤',
      meta: 'VANTEC Qi',
      name: 'VANTEC Qi 紅色無線快速充電盤',
      originPrice: 2400,
      productPrice: 0,
      color: '紅色',
      modal: {
        title: 'VANTEC Qi 紅色無線快速充電盤',
        content: [
          '商品規格',
          [
            '3.5mm 超薄無線充電盤',
            'WPC國際 Qi 認證/ NCC 國家認證',
            '支持Qi 無線充電IOS & Android 手機',
            'FOD自動智慧識別金屬異物',
            '輸出 (Output)：5W/ 7.5W / 9W',
          ],
        ],
      },
    },
    {
      type: 'product',
      image: '/resources/cbu/estore-product/estore-product-thumb-14@2x.jpg',
      value: 'MYCELL AirPods Pro 粉沙收納盒',
      meta: 'MYCELL',
      name: 'MYCELL AirPods Pro 粉沙收納盒',
      originPrice: 2400,
      productPrice: 0,
      modal: {
        title: 'VANTEC Qi 紅色無線快速充電盤',
        content: [
          '商品規格',
          [
            '3.5mm 超薄無線充電盤',
            'WPC國際 Qi 認證/ NCC 國家認證',
            '支持Qi 無線充電IOS & Android 手機',
            'FOD自動智慧識別金屬異物',
            '輸出 (Output)：5W/ 7.5W / 9W',
          ],
        ],
      },
    },
  ],
  extra_product: [
    {
      type: 'product',
      image: '/resources/cbu/estore-product/estore-product-thumb-09@2x.jpg',
      value: 'PHILIPS 舒眠抗敏空氣清淨機',
      meta: 'PHILIPS',
      name: 'PHILIPS 舒眠抗敏空氣清淨機',
      productPrice: 5100,
      modal: {
        title: 'PHILIPS 舒眠抗敏空氣清淨機',
        content: [
          '商品規格',
          [
            '3.5mm 超薄無線充電盤',
            'WPC國際 Qi 認證/ NCC 國家認證',
            '支持Qi 無線充電IOS & Android 手機',
            'FOD自動智慧識別金屬異物',
            '輸出 (Output)：5W/ 7.5W / 9W',
          ],
        ],
      },
      number: 1,
    },
    {
      type: 'product',
      image: '/resources/cbu/estore-product/estore-product-thumb-11@2x.jpg',
      value: 'Panasonic 15公升蒸氣烘烤爐',
      meta: 'PANASONIC',
      name: 'Panasonic 15公升蒸氣烘烤爐',
      productPrice: 15000,
      modal: {
        title: 'Panasonic 15公升蒸氣烘烤爐',
        content: [
          '商品規格',
          [
            '3.5mm 超薄無線充電盤',
            'WPC國際 Qi 認證/ NCC 國家認證',
            '支持Qi 無線充電IOS & Android 手機',
            'FOD自動智慧識別金屬異物',
            '輸出 (Output)：5W/ 7.5W / 9W',
          ],
        ],
      },
      number: 1,
    },
  ],
  extra_plan: [
    {
      type: 'friday',
      value: 'friDay影音免費爽看30天',
      meta: '第一個月免費',
      name: 'friDay影音免費爽看30天',
      productPrice: 199,
      day: 30,
      unit: '天',
    },
  ],
  discount: {
    name: '全館滿$3,000折$200',
    amount: 200,
  },
  happygo: {
    number: 200,
  },
  coupon: {
    code: 'A1234',
    amount: 200,
  },
};
