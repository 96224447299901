import React from 'react';
import Link from '../Link';

class ProdPromoCard extends React.Component {
  render() {
    return (
      <div className='fui-card is-prod-promo'>
        <Link to={this.props.link} target={this.props.target} className='fui-card-action'>
          {this.props.ribbon ? <div className='ribbon'>{this.props.ribbon}</div> : null}
          <div className='fui-card-image'>
            <img src={this.props.image} alt={this.props.title} />
          </div>
          <div className='fui-card-caption'>
            <h4 className='fui-card-title'>{this.props.title}</h4>
          </div>
        </Link>
      </div>
    );
  }
}

export default ProdPromoCard;
