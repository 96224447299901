import React from 'react';
import { Grid } from '@material-ui/core';
import Dropdown from '../Dropdown';
import formatNumber from '../../utils/numberFormatter';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class CombineCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.product.option.selected,
    };
  }

  doClick = (link) => {
    if (this.props.onClick) {
      this.props.onClick(link);
    } else {
      this.props.history.push(link);
    }
  };

  optionChange = (item, idx, e) => {
    this.setState({
      selected: item.value,
    });

    if (this.props.onColorChange) this.props.onColorChange(this.state.selected);
  };

  render() {
    return (
      <div className={`fui-card is-product is-combine`}>
        <div className='fui-card-action'>
          {this.props.ribbon ? <div className='ribbon'>{this.props.ribbon}</div> : null}
          <Grid container>
            <Grid item xs={6}>
              <div className='fui-card-content'>
                <div className='fui-card-image'>
                  <img
                    className='lazyload'
                    data-src={
                      this.props.product.image || process.env.PUBLIC_URL + '/resources/common/images/no-image@2x.png'
                    }
                    src={
                      this.props.product.image || process.env.PUBLIC_URL + '/resources/common/images/no-image@2x.png'
                    }
                    alt={this.props.product.title}
                  />
                </div>
                <div className='fui-card-caption'>
                  <div className='fui-card-meta'>{this.props.product.meta}</div>
                  <div className='body'>
                    {this.props.product.tag ? <span className='tag'>{this.props.product.tag}</span> : null}
                    {this.props.product.title}
                  </div>
                </div>
              </div>
              <div className='fui-card-extra'>
                <div className='fui-card-price'>
                  {this.props.product.salePrice ? (
                    <div>
                      <span className='label'>月付</span>
                      {this.props.product.originPrice ? (
                        <del>${formatNumber(this.props.product.originPrice)}</del>
                      ) : null}
                      <b className='price'>{this.props.product.salePrice}</b>
                    </div>
                  ) : null}
                  {this.props.product.productPrice ? (
                    <div>
                      <div className='label'>商品價</div>
                      {this.props.product.originPrice ? (
                        <del>${formatNumber(this.props.product.originPrice)}</del>
                      ) : null}
                      <b className={`price ${this.props.product.originPrice ? 'is-text-error' : ''}`}>
                        ${formatNumber(this.props.product.productPrice)}
                      </b>
                    </div>
                  ) : null}
                  {this.props.product.projectPrice ? (
                    <div>
                      <div className='label'>專案價</div>
                      {this.props.product.originPrice ? (
                        <del className='is-text-gray100'>${formatNumber(this.props.product.originPrice)}</del>
                      ) : null}
                      <b className='price is-text-error'>${formatNumber(this.props.product.projectPrice)} 起</b>
                    </div>
                  ) : null}
                </div>
                {this.props.product.option ? (
                  <div className='option-selector'>
                    <span className='label'>{this.props.product.option.label}</span>
                    <Dropdown
                      className='is-button'
                      list={this.props.product.option.list}
                      label={this.state.selected}
                      arrow={true}
                      hasCheck={false}
                      onChange={(item, idx, e) => this.optionChange(item, idx, e)}
                    />
                  </div>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className='fui-card-content'>
                <div className='fui-card-image rateplan'>
                  <img
                    className='lazyload'
                    data-src={
                      process.env.PUBLIC_URL +
                      `/resources/common/images/${this.props.plan.type === '5g' ? '5g' : '4g'}-logo.png`
                    }
                    src={
                      process.env.PUBLIC_URL +
                      `/resources/common/images/${this.props.plan.type === '5g' ? '5g' : '4g'}-logo.png`
                    }
                    alt={this.props.plan.title}
                  />
                </div>
                <div className='fui-card-plan'>
                  <h4>
                    月付 ${formatNumber(this.props.plan.price)}
                    <br />
                    合約 {this.props.plan.month} 個月
                  </h4>
                </div>
              </div>
              <div className='fui-card-extra align-center is-text-medium'>
                <div role='button' onClick={(e) => this.props.modalOpen(this.props.plan.modal)}>
                  <span className='text'>方案詳情</span>
                  <i className='icon-plus'></i>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className='fui-card-button'>
          <div role='button' className='fui-button is-primary' onClick={(e) => this.props.doClick(this.props)}>
            <span className='text'>{this.props.action.text}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CombineCard);
