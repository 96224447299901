import React from 'react';
import PropTypes from 'prop-types';

const Item = (props) => {
  const renderPrefix = (prefix) => {
    if (!prefix) return '';

    switch (prefix) {
      case 'check':
        return (
          <span className='prefix'>
            <i className='icon-check'></i>
          </span>
        );

      case 'bulleted':
        return (
          <span className='prefix'>
            <i className='bulleted-dot'></i>
          </span>
        );

      case 'number':
        return (
          <span className='prefix'>
            <i className='number'>{props.number}</i>
          </span>
        );

      default:
        return '';
    }
  };

  return (
    <div className={`fui-item ${props.className ? props.className : ''} ${props.disabled ? 'is-disabled' : ''}`}>
      {props.img ? <img src={process.env.PUBLIC_URL + props.img} alt={props.children} /> : ''}
      {renderPrefix(props.prefix)}
      <span className='content' dangerouslySetInnerHTML={{ __html: props.children }}></span>
      {props.icon ? (
        <span className='extra'>
          <i className={`icon-${props.icon}`}></i>
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

Item.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  number: PropTypes.number, // if prefix === number, auto use in map
  prefix: PropTypes.string, // check | bulleted | number
  icon: PropTypes.string,
  children: PropTypes.string.isRequired,
  img: PropTypes.string,
};

export default Item;
