import React from 'react';

import Modal from 'react-modal';

import Breadcrumb from '../components/Breadcrumb';
import FindItemBanner from '../components/partials/banner/FindItemBanner';
import Tab from '../components/tab/Tab';
import TabPane from '../components/tab/TabPane';
import Panel from '../components/panel/Panel';
import PanelFlowContainer from '../components/panelContent/PanelFlowContainer';
import PanelTitle1 from '../components/panelContent/PanelTitle1';
import PanelFlow from '../components/panelContent/PanelFlow';
import PanelInformation from '../components/panelContent/PanelInformation';
import PanelList from '../components/panelContent/PanelList';

import * as Mock from '../mock/Delivery';
import Button from '../components/Button';

class Delivery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      modalOpen: false,
    };
  }

  tabChange = (index) => {
    this.setState({
      currentTab: index,
    });
  };

  render() {
    const { currentTab } = this.state;

    return (
      <main className='delivery-page'>
        <Breadcrumb
          color='white'
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '網路門市', link: '/estore' },
            { text: '商品配送說明', link: '/estore/info/delivery' },
          ]}
        />
        <FindItemBanner
          title='商品配送說明'
          image={{
            md: '/resources/cbu/estore/cbu-tradein-banner-1920x360.jpg',
            sm: '/resources/cbu/estore/cbu-tradein-banner-1920x360.jpg',
          }}
        />
        <section className='ribbon-bg'>
          <div className='fui-container'>
            <div className='p-4 mb-7 is-bg-lightgray70'>
              <span className='is-text-darkgray50'>
                1/22(三) 11:00起，因配合物流業者出貨作業，部分訂單將暫停出貨。1/30(四)起將陸續配送。
              </span>
              <span
                role='button'
                onClick={(e) =>
                  this.setState({
                    modalOpen: true,
                  })
                }
                className='is-text-medium is-underline-link is-text-underline is-text-darkgray50'>
                詳細出貨公告說明
              </span>
            </div>
            <Tab
              name='delivery'
              default={currentTab}
              list={[{ label: '遠傳門市取貨' }, { label: '全家超商取貨' }, { label: '宅配商品配送' }]}
              onChange={this.tabChange}
            />
            <TabPane active={currentTab === 0}>
              <Panel className='is-estore'>
                <h5 className='subtitle'>遠傳門市取貨</h5>
                <PanelFlowContainer>
                  <PanelFlow number={1} title='收到取貨通知，申辦人即可前往指定遠傳門市取貨。' />
                  <PanelFlow number={2} title='備妥身分證及第二證件正本供門市人員查核，並需本人現場親簽申請書。' />
                  <PanelFlow
                    number={3}
                    title='取貨及確認申辦資格後，新申辦門號最快1小時自動開通；攜碼最快1日自動開通。'
                  />
                </PanelFlowContainer>
                <PanelInformation
                  title='注意事項'
                  content={`
                  <ul>
                    <li>本訂單需本人憑雙證件，前往指定遠傳門市取貨。</li>
                    <li>宅配至遠傳門市取貨服務之配送區域，僅限台灣本島。</li>
                    <li>
                      當商品到達您指定的遠傳門市後，將以e-mail或是簡訊方式通知您到門市取貨，請備妥身分證及第二證件正本，由門市服務人員為您完成取貨流程。
                    </li>
                  </ul>
                  `}
                />
              </Panel>
            </TabPane>
            <TabPane active={currentTab === 1}>
              <Panel className='is-estore'>
                <h5 className='subtitle'>全家超商取貨</h5>
                <PanelFlowContainer>
                  <PanelFlow number={1} title='收到取貨通知，申辦人即可前往指定全家超商取貨。'>
                    <img
                      src={process.env.PUBLIC_URL + `/resources/cbu/estore/familymart-logo-horizontal@2x.jpg`}
                      height='60'
                      alt='familymart'
                    />
                  </PanelFlow>
                  <PanelFlow
                    number={2}
                    title='請詳閱申請書，一份請於簽名處以「正楷簽名」後放入寄件包裝袋。另一份請自行留存。'>
                    <PanelList
                      prefix='bulleted'
                      list={[
                        { text: '新申辦：需於行動寬頻申請書簽名' },
                        { text: '攜碼：需於行動寬頻申請書、門號可攜申請書二處簽名' },
                      ]}
                    />
                  </PanelFlow>
                  <PanelFlow number={3} title='告知超商人員，我要寄件，並交付「寄件包裝袋」。'>
                    <PanelList
                      prefix='bulleted'
                      list={[
                        { text: '包裝袋黏貼物流單交給店員，由超商人員刷取「寄件包裝袋上條碼」做為收件確認' },
                        { text: '可A店取貨，B店寄件，寄回遠傳免付運費' },
                      ]}
                    />
                  </PanelFlow>
                  <PanelFlow number={4} title='完成交寄後，新申辦門號最快1小時自動開通；攜碼最快1日自動開通。' />
                </PanelFlowContainer>
                <PanelInformation
                  title='注意事項'
                  content={`
                  <ul>
                    <li>本訂單需本人憑雙證件，前往指定全家超商取貨。</li>
                    <li>選擇全家超商取貨，若逾期未取達2次(含)以上，即不再開放超商取貨服務。</li>
                    <li>若查詢不到您欲前往取貨的門市，可能該門市已暫停提供取貨服務。</li>
                    <li>超商取貨自動開通時間為9點~24點，如在凌晨交寄包裝袋，將於次日自動開通。</li>
                  </ul>
                  `}
                />
              </Panel>
            </TabPane>
            <TabPane active={currentTab === 2}>
              <Panel className='is-estore'>
                <h5 className='subtitle'>宅配商品配貨</h5>
                <PanelFlowContainer>
                  <PanelFlow number={1} title='備妥身分證正本，以利順利簽收包裹，恕無法請他人代收。' />
                  <PanelFlow number={2} title='本人親簽申請書。' />
                  <PanelFlow number={3} title='簽收完成後，新申辦門號最快1小時自動開通；攜碼最快1日自動開通。' />
                  <PanelFlow
                    number={4}
                    title='訂單申購完成後商品最快1日進行宅配，送達時間會因特殊節日/連假及地區不同而有所差異，配達日以實際狀況為主。部分偏遠地區配送時間請見偏遠地區配送：'>
                    <div className='fui-table-scrollbox'>
                      <table>
                        <tbody>
                          <tr>
                            <th>縣、市</th>
                            <th width='50%'>鄉、鎮</th>
                            <th>配送時間</th>
                          </tr>
                          <tr>
                            <th>基隆市</th>
                            <td>平溪區</td>
                            <td>
                              <strong>週一、三、五 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <th rowspan='3'>新北市</th>
                            <td>坪林區</td>
                            <td>
                              <strong>週五 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>石碇區、烏來區</td>
                            <td>
                              <strong>週一 ~ 五 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              三峽區<span>五寮、插角、有木、竹崙、三層坪、九份路、金圳里</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <th rowspan='2'>桃園市</th>
                            <td>
                              大溪鎮<span>復興里（全，含復興路二段）、新峰里（全）、三民里（全）</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              復興鄉<span>澤仁、三民、長興、奎輝、義盛、羅浮、霞雲、高義村、達觀山</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <th rowspan='4'>新竹縣</th>
                            <td>
                              新埔鎮<span>清水里、新北里</span>
                            </td>
                            <td>
                              <strong>週二、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              關西鎮<span>金山里</span>
                            </td>
                            <td>
                              <strong>週二、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              關西鎮<span>玉山里、新富里、童話世界（陀佛世界）</span>
                            </td>
                            <td>
                              <strong>週一、三、五 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              關西鎮<span>錦山里</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <th rowspan='3'>苗栗縣</th>
                            <td>
                              南庄鄉<span>東河、蓬萊村、鹿場、三灣鄉神棹山</span>
                            </td>
                            <td>
                              <strong>週五 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              泰安鄉<span>士林村/象鼻村</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              卓蘭鎮<span>景山里/坪林里</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <th rowspan='2'>台中市</th>
                            <td>
                              烏日區<span>溪尾里慶光路1~69號</span>
                            </td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>台中看守所、監獄、女子監獄、少年觀護所</td>
                            <td>
                              <strong>週一、三、五 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <th rowspan='10'>南投縣</th>
                            <td>
                              鹿谷鄉<span>和雅村、溪頭</span>
                            </td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              仁愛鄉<span>南豐村、大同村、定遠新村</span>
                            </td>
                            <td>
                              <strong>週一、二、四、五 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              仁愛鄉<span>法治村</span>
                            </td>
                            <td>
                              <strong>週四、六 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              仁愛鄉<span>中正村、互助村、新生村</span>
                            </td>
                            <td>
                              <strong>週三、六 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              仁愛鄉<span>春陽村、精英村</span>
                            </td>
                            <td>
                              <strong>週二、五 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              仁愛鄉<span>合作村</span>
                            </td>
                            <td>
                              <strong>週二 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>集集鎮兵整中心</td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              仁愛鄉<span>萬豐村、親愛村、萬大</span>
                            </td>
                            <td>
                              <strong>週四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              仁愛鄉<span>力行村、發祥村、眉溪村、榮興村、翠華村、翠巒村</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              信義鄉<span>潭南村、地利村、人和村、雙龍村</span>
                            </td>
                            <td>
                              <strong>週二、五 配送</strong>
                            </td>
                          </tr>

                          <tr>
                            <th rowspan='5'>雲林縣</th>
                            <td>
                              古坑鄉
                              <span>桂林村（含光山)、山峰村、華山村、華南村、石仔坑、圳頭坑、尖山坑路1~100號</span>
                            </td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              古坑鄉<span>草嶺、樟湖</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              梅山鄉
                              <span>
                                安靖村、雙溪村、過山村、圳北村、圳南村、大楠村、半天村、九芎坑、坑子口、梅圳、永興、水尾、開元后
                              </span>
                            </td>
                            <td>
                              <strong>週二、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              梅山鄉<span>太平村、太興村、仁和村、龍眼村、碧湖村</span>
                            </td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              梅山鄉<span>瑞里村、瑞峰村、太和村</span>
                            </td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <th rowspan='6'>嘉義縣</th>
                            <td>大埔鄉</td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              竹崎鄉<span>光華村、桃源村、白杞村、仁壽村、緞繻村、金獅村、文峰村、塘興村 </span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              番路鄉<span>大湖村、民和村、草山村</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              中埔鄉<span>三層村、東興村、中崙村、澐水村、灣潭村、石硦村、深坑村、瑞豐村</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              阿里山鄉<span>山美村、新美村、達邦村、特富野、里佳村、茶山村、豐山村、來吉村</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>嘉義農場</td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>

                          <tr>
                            <th rowspan='8'>台南市</th>
                            <td>
                              白河區<span>關仔嶺、南寮里、青山里</span>
                            </td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              東山區<span>高原里、東原里、六重溪、白水溪村、姜仔崙村、青山里</span>
                            </td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              六甲區<span>王爺里、大丘里、水流東里、日新山莊</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              六龜區<span>寶來里</span>
                            </td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              七股區<span>三股里、十份里、永吉里、西寮里、頂山里、城內里、大潭里、後港里</span>
                            </td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>北門區</td>
                            <td>
                              <strong>週五 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              山上區<span>明德外役</span>
                            </td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              甲仙區<span>小林里</span>
                            </td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <th rowspan='3'>高雄市</th>
                            <td>田竂區</td>
                            <td>
                              <strong>週一、五 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              田寮區<span>崑山路</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              旗山區<span>中寮一路、中寮二路</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <th rowspan='11'>宜蘭縣</th>
                            <td>
                              大同鄉<span>棲蘭苗圃、大同國中、獨立山、嘉洛工作站、加蘭灣、留茂安、四季、南山</span>
                            </td>
                            <td>
                              <strong>第一、三週週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              大同鄉<span>明池苗圃、土場、寒溪、崙埤、玉蘭、松羅、牛鬪</span>
                            </td>
                            <td>
                              <strong>第二、四週週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              大同鄉<span>其他區域</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              員山鄉
                              <span>
                                中華村、福山植物園、員山路三段、冷水路、粗坑路、長嶺路、再連路、雙埤路、福山街
                              </span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              南澳鄉<span>澳花村、漢本</span>
                            </td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class='ml0'>濱海路2段</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class='ml0'>濱海路3段更新里（路）、梗枋</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class='ml0'>濱海路4段合興里（路）、林岳新村</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class='ml0'>濱海路5段大溪里（路）、龜山路、仁澤新村</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class='ml0'>濱海路6段大里里、蕃薯寮</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class='ml0'>濱海路7段石城里（路）</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan='7'>花蓮縣</td>
                            <td>
                              壽豐鄉<span>月眉村</span>
                            </td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              壽豐鄉<span>鹽寮、水璉村</span>
                            </td>
                            <td>
                              <strong>週一、四 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              秀林鄉<span>文蘭、富世（天祥）、銅門村、池南村、平村、和中村、和仁村、崇德村</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              瑞穗區<span>奇美村</span>
                            </td>
                            <td>
                              <strong>週二、五 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>豐濱鄉</td>
                            <td>
                              <strong>週二、五 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              池上區<span>思源啞口</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              海端區<span>利稻村、霧鹿</span>
                            </td>
                            <td>
                              <strong>週一 配送</strong>
                            </td>
                          </tr>
                          <tr>
                            <th>台東市</th>
                            <td>延平鄉、東河鄉、金峰鄉、大武鄉、達仁鄉</td>
                            <td>
                              <strong>週一、三、五 配送</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </PanelFlow>
                </PanelFlowContainer>
                <PanelInformation title='注意事項'>
                  <ul>
                    <li>宅配服務配送區域僅限台灣本島。</li>
                    <li>宅配箱內含個人資料，需本人簽收，並於申請書上簽完名後交給宅配人員。</li>
                    <li>單買商品無合約訂單，則不限本人親領，可代收。</li>
                  </ul>
                </PanelInformation>
              </Panel>
            </TabPane>
          </div>
        </section>
        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className={'fui-notice-modal'}>
          <div className='fui-modal-content'>
            <div className='fui-modal-head'>
              <h5>農曆春節出貨說明</h5>
            </div>
            <div className='fui-modal-body'>
              <p>適逢1/23(四) ~ 1/29 (三) 農曆春節長假，遠傳網路門市正常營業，唯客服與出貨時間調整如下：</p>
              <p>【網路門市出貨時間】</p>
              <p>
                1/15 (三) ~ 1/22 (三) 進入春節宅配尖峰週，配送日將視物流業者配送狀況，若無法即時配達，請耐心等候。
                <br />
                1/21 (二) 10:00
                前，付款完成之訂單，將依客服人員處理訂單狀況進行配送，請您留意聯絡電話，出貨將以簡訊通知您。
                <br />
                1/22 (三) 11:00起，因配合物流業者出貨作業，部分訂單將暫停出貨，1/30 (四) 起將開始陸續配送
                <br />
                宅配取貨訂單：春節期間全面暫停出貨。1/30 (四) 起將開始陸續配送
                <br />
                遠傳門市取貨：春節期間全面暫停出貨。1/30 (四) 起將開始陸續配送
                <br />
                超商取貨訂單：1/23 (四)~1/26(日) 暫停出貨，1/27 (一) 正常配送，請依到貨簡訊通知前往指定超商門市取貨。
              </p>
            </div>
            <div className='fui-modal-foot'>
              <Button
                btnStyle='primary'
                onClick={(e) =>
                  this.setState({
                    modalOpen: false,
                  })
                }>
                我暸解了
              </Button>
            </div>
          </div>
        </Modal>
      </main>
    );
  }
}

export default Delivery;
