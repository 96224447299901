import { TYPES } from '../action';
import { cartInitialState } from '../state';

export default function cartReducer(state = cartInitialState, action) {
  switch (action.type) {
    case TYPES.UPDATE_CART_DATA:
      return Object.assign({}, state, {
        list: action.cart,
      });

    case TYPES.REMOVE_CART_DATA:
      let removeList = null;
      // console.log('cartReducer', state, action);
      if (Array.isArray(state.list)) {
        removeList = [...state.list];
        removeList.splice(action.index, 1);
      } else {
        removeList = { ...state.list };
        removeList[action.product].splice(action.index, 1);
      }

      return Object.assign({}, state, {
        list: removeList,
      });

    case TYPES.SET_CART_DATA:
      return Object.assign({}, state, {
        list: action.cart,
      });

    default:
      return state;
  }
}
