import React from 'react';
import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import Formsy from 'formsy-react';
import { setFlowCartData } from '../../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select2 from 'react-select';

import FormBanner from '../../components/partials/banner/FormBanner';
import Button from '../../components/Button';
import Link from '../../components/Link';
import Item from '../../components/item/Item';
import Collapse from '../../components/collapse/Collapse';
import Tooltip from '../../components/Tooltip';
import FcoinMsg from '../../components/FcoinMsg';

import ImageUpload from '../../components/form/ImageUpload';
import Checkbox from '../../components/form/Checkbox';
import RadioButtons from '../../components/form/RadioButtons';
import RadioGroup from '../../components/form/RadioGroup';
import LabelInput from '../../components/form/LabelInput';
import Select from '../../components/form/Select';
import TextInput from '../../components/form/TextInput';
import InputGroup from '../../components/form/InputGroup';
import EstoreModal from '../../components/EstoreModal';
import ActivatedDatePicker from '../../components/form/ActivatedDatePicker';

import formatNumber from '../../utils/numberFormatter';
import * as Validation from '../../utils/validation';
import * as Mock from '../../mock/Identity';

class Payment extends React.Component {
  constructor(props) {
    super(props);

    this.body = React.createRef();
    this.creditGroup1 = React.createRef();
    this.creditGroup2 = React.createRef();
    this.validCode = React.createRef();
    this.state = {
      collapseOpen: true,
      contentHeight: 0,
      currentModal: {
        type: 'notice',
        title: '',
        content: '',
      },
      pageInfo: {
        pageTitle: '確認購物明細',
        pageSubTitle: `最後一個步驟了，即將完成申購！`,
        ctaText: '完成申辦',
      },
      modalOpen: false,
      happyGoLogin: false,
      happygo: 128960,
      coupon: 200,
      couponError: '',
      happygoError: '',
      billAddress: {
        city: '台北市',
        area: '中山區',
        address: '樂群二路187號6樓之2',
      },
      donateCompanyOption: [
        { text: '創世基金會', value: '創世基金會' },
        { text: '其他', value: '其他' },
      ],
      bankOptions: [
        { label: '003 交通銀行', value: '003' },
        { label: '004 台灣銀行', value: '004' },
        { label: '005 土地銀行 ', value: '005' },
        { label: '006 合作金庫銀行', value: '006' },
        { label: '007 第一銀行', value: '007' },
      ],
      citizenCardModal: {
        title: '什麼是自然人憑證號碼',
        content: `<img src='/estore/resources/cbu/order/citizen-cert-card@2x.jpg' height='160' class='d-block my-5 mx-auto' />`,
      },
      mobileBarcodeModal: {
        title: '什麼是手機條碼',
        content: `<img src='/estore/resources/cbu/order/mobile-num@2x.jpg' height='160' class='d-block my-5 mx-auto' />`,
      },
      isCreditCardUpdate: false,
      canSubmit: false,
      form: {
        coupon: { value: '' },
        happygo: { value: '' },
        payment: { value: '1', required: true },
        pickup: { value: '', required: true },
        receipt_type: { value: '1', required: true },
        receipt_donate_company: { value: '', required: true },
        receipt_donate_number: { value: '', required: true },
        avaliable_at: { value: '' },
        bank: { value: '', required: true },
        staging: { value: '', required: true },
        credit_number_1: { value: '', required: true },
        credit_number_2: { value: '', required: true },
        credit_number_3: { value: '', required: true },
        credit_number_4: { value: '', required: true },
        credit_year: { value: null, required: true },
        credit_month: { value: null, required: true },
        credit_code: { value: '', required: true },
        store_city: { value: '', required: true },
        store_area: { value: '', required: true },
        store_road: { value: '', required: true },
        store_name: { value: '', required: true },
        is_bill_address: { value: false, required: true },
        delivery_city: { value: false, required: true },
        delivery_area: { value: false, required: true },
        delivery_address: { value: false, required: true },
        receipt_certificate: { value: '', required: true },
        receipt_mobile: { value: '', required: true },
        tax_id_number: { value: '', required: true },
        tax_id_title: { value: '', required: true },
        is_domicile_address: { value: '', required: true },
        domicile_city: { value: null, required: true },
        domicile_area: { value: null, required: true },
        domicile_address: { value: null, required: true },
      },
      nextPageInfo: null,
      submitLoading: false,
    };
  }

  componentDidMount = () => {
    if (this.props.location.state && this.props.location.state.co_type) {
      this.setCoTypeWording();
      this.setState({
        nextPageInfo: {
          co_type: this.props.location.state.co_type,
        },
      });
    }

    this.setState({
      contentHeight: this.body.current.clientHeight,
    });

    this.creditGroupSetting(Array.from(this.creditGroup1.current.getElementsByTagName('input')));
    this.creditGroupSetting(Array.from(this.creditGroup2.current.getElementsByTagName('input')));

    this.setInputFilter(this.validCode.current.getElementsByTagName('input')[0], function (value) {
      return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
    });
  };

  setCoTypeWording = () => {
    /**
      **All case**
      NC_identity
      NC
      NH_identity
      NH
      LC
      LC_identity
      LH
      LH_identity
      DA
     */
    let pageTitle = '確認購物明細';
    let pageSubtitle = '最後一個步驟了，即將完成申購！';
    let ctaText = '完成申辦';

    switch (this.props.location.state.co_type) {
      case 'NH_identity':
        ctaText = '確定付款';
        break;
      case 'NH':
        ctaText = '確定付款';
        break;
      case 'LH':
        ctaText = '確定付款';
        break;
      case 'LH_identity':
        ctaText = '確定付款';
        break;
      case 'DA':
        ctaText = '確定付款';
        break;
    }

    this.setState({
      pageInfo: {
        pageTitle: pageTitle,
        pageSubTitle: pageSubtitle,
        ctaText: ctaText,
      },
    });
  };

  creditGroupSetting = (creditInputs) => {
    creditInputs.map((input, i) => {
      this.setInputFilter(input, function (value) {
        return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
      });

      return input.addEventListener('keyup', (e) => {
        let index = creditInputs.indexOf(e.target);
        // console.log('input:' + index)
        if (e.currentTarget.value.length === 4 && index < 3) {
          creditInputs[index + 1].focus();
        }
      });
    });
  };

  setInputFilter = (textbox, inputFilter) => {
    ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
      textbox.addEventListener(event, function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty('oldValue')) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = '';
        }
      });
    });
  };

  componentDidUpdate = () => {
    window.addEventListener('resize', (e) => {
      this.setState({
        contentHeight: this.body.current.clientHeight,
      });
    });
  };

  openModal = () => {
    this.setState({
      currentModal: {
        type: 'iframe',
        content:
          'https://mp.happygocard.com.tw/mp/login.do?MERCHANT_POS=0129005260&TERMINAL_POS=70625062&RTN_URL=https://www.fetnet.net/estore/shopping/hgLoginCallback&LOGIN_TYPE=3',
      },
      modalOpen: true,
    });
  };

  openCollapse = () => {
    this.setState({
      contentHeight: this.body.current.clientHeight,
      collapseOpen: !this.state.collapseOpen,
    });
  };

  inputChange = (name, value) => {
    let form = Object.assign(this.state.form);

    /** 只適用NP單門號 Start */

    if (name === 'pickup' && form.avaliable_at.value !== '') {
      form['avaliable_at'].value = '';
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'notice',
          title: '貼心提醒',
          content: '親愛的客戶，你已變動【取貨方式】，提醒你記得要重新選擇【門號開通日】喔！',
        },
      });
    }
    /** 只適用NP單門號 End */

    if (name.indexOf('credit_number') > -1 && value === '') {
      this.setState({
        isCreditCardUpdate: true,
      });
    }
    if (name === 'coupon') {
      this.setState({
        couponError: value === '' ? '請輸入優惠券序號' : '',
      });
    }
    if (name === 'happygo') {
      this.setState({
        happygoError: value === '' ? '請輸入優惠券序號' : '',
      });
    }

    form[name].value = value;

    if (name === 'is_domicile_address' && value) {
      form.domicile_city.value = this.state.billAddress.city;
      form.domicile_area.value = this.state.billAddress.area;
      form.domicile_address.value = this.state.billAddress.address;
    }

    if (name === 'is_bill_address' && value) {
      form.delivery_city.value = this.state.billAddress.city;
      form.delivery_area.value = this.state.billAddress.area;
      form.delivery_address.value = this.state.billAddress.address;
    }

    this.setState({
      form,
    });

    this.forceUpdate();
  };

  countAmount = (cart) => {
    let total = 0;
    for (var i in cart) {
      if (cart[i]) {
        switch (i) {
          case 'plan':
            total += cart[i].prepay;
            break;

          case 'gift':
            if (cart[i].length) {
              let giftCount = cart[i].reduce((accr, val) => accr + val.productPrice, 0);
              total += giftCount;
            }
            break;

          case 'product':
            total += cart[i].projectPrice;
            break;

          case 'discount':
            total -= cart[i].amount;
            break;

          case 'extra_product':
            if (cart[i].length) {
              let extraProductCount = cart[i].reduce((accr, val) => accr + val.productPrice * val.number, 0);
              total += extraProductCount;
            }
            break;
        }
      }
    }

    if (this.state.form.coupon.value !== '') {
      total -= this.state.coupon;
    }

    if (this.state.form.happygo.value > 0) {
      total -= (this.state.form.happygo.value / 200) * 50;
    }

    return total;
  };

  submit = () => {
    this.setState({
      submitLoading: true,
    });
    this.forceUpdate();
    setTimeout(() => {
      this.props.history.push('/estore/order/complete');
    }, 3000);
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalConfirm = (type, form) => {
    console.info('MODAL CONFIRM', type, form);
    this.setState({
      modalOpen: false,
    });

    if (type === 'paymentAlert') {
      this.submit();
    }
    // 貨到通知送出
  };

  modalCancel = (type) => {
    // 預約取貨送出
    console.info('MODAL CANCEL', type);
    this.setState({
      modalOpen: false,
    });
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);
    if (type === 'iframe') {
      this.setState({
        happyGoLogin: true,
      });
    }

    this.setState({
      modalOpen: false,
    });

    this.forceUpdate();
  };

  beforeSubmit = () => {
    this.setState({
      currentModal: {
        type: 'paymentAlert',
        ...Mock.complete,
      },
      modalOpen: true,
    });
  };

  render() {
    const { form, currentModal } = this.state;
    const locationState = this.props.location.state;
    const cart = Mock.Cart;

    return (
      <main>
        <FormBanner
          title={locationState && locationState.pageTitle ? locationState.pageTitle : '確認購物明細'}
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className='fui-order-container'>
          <Formsy>
            <div className='fui-container'>
              <h4
                className='is-text-darkgray50'
                dangerouslySetInnerHTML={{
                  __html:
                    locationState && locationState.pageSubtitle
                      ? locationState.pageSubtitle
                      : '最後一個步驟了，即將完成申購！',
                }}></h4>
              <div className='paper pb-3'>
                <h4>結帳資訊</h4>
                <div className='gray-block'>
                  <h5 className='is-text-darkgray50 my-0'>結帳金額</h5>
                  <h1>${formatNumber(this.countAmount(cart))}</h1>
                </div>
                <div className='fui-collapse with-trigger mb-0'>
                  <div
                    className='collapse-body'
                    style={{
                      height: this.state.collapseOpen ? this.state.contentHeight : 0,
                    }}>
                    <div ref={this.body}>
                      <h4 className='is-text-black50'>專案合約資訊</h4>
                      <div className='gray-block'>
                        <div className='fui-order-item'>
                          <div className='pname'>{cart.plan.value}</div>
                          <div className='action'>
                            <Button
                              btnStyle='secondary'
                              size='small'
                              onClick={(e) =>
                                this.setState({
                                  modalOpen: true,
                                  currentModal: {
                                    type: 'notice',
                                    ...Mock.contract,
                                  },
                                })
                              }>
                              查看合約
                            </Button>
                          </div>
                        </div>
                      </div>
                      <h4 className='is-text-black50'>申購清單</h4>
                      <div className='gray-block'>
                        <div className='is-text-darkgray50'>專案商品</div>
                        <div className='fui-order-item'>
                          <div className='pname'>{cart.product.name}</div>
                          <div className='color'>{cart.product.color}</div>
                          <div className='numberic'>{cart.product.number || 1}</div>
                          <div className='price'>
                            <del>${formatNumber(cart.product.originPrice)}</del>
                            <span className='is-text-black50'>${formatNumber(cart.product.projectPrice)}</span>
                          </div>
                        </div>
                      </div>

                      {cart.extra_product.length ? (
                        <div className='gray-block'>
                          <div className='is-text-darkgray50'>加購商品</div>
                          {cart.extra_product.map((ex_prod, i) => (
                            <div className='fui-order-item' key={`fui-extra_product-item-${i}`}>
                              <div className='pname'>{ex_prod.name}</div>
                              <div className='color'>{ex_prod.color || '-'}</div>
                              <div className='numberic'>{ex_prod.number || 1}</div>
                              <div className='price'>
                                {ex_prod.originPrice ? <del>${formatNumber(ex_prod.originPrice)}</del> : null}
                                <span className='is-text-black50'>${formatNumber(ex_prod.productPrice)}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}

                      {cart.extra_plan.length ? (
                        <div className='gray-block'>
                          <div className='is-text-darkgray50'>加值服務</div>
                          {cart.extra_plan.map((ex_plan, i) => (
                            <div className='fui-order-item' key={`fui-extra_plan-item-${i}`}>
                              <div className='pname'>{ex_plan.name}</div>
                              <div className='color'>{ex_plan.color || '-'}</div>
                              <div className='numberic'>{ex_plan.number || 1}</div>
                              <div className='price'>
                                {ex_plan.originPrice ? <del>${formatNumber(ex_plan.originPrice)}</del> : null}
                                <span className='is-text-black50'>${formatNumber(ex_plan.productPrice)}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                      <div className='gray-block'>
                        <div className='is-text-darkgray50'>贈品</div>
                        {cart.gift.map((gift, i) => (
                          <div className='fui-order-item' key={`fui-gift-item-${i}`}>
                            <div className='pname'>{gift.name}</div>
                            <div className='color'>{gift.color || '-'}</div>
                            <div className='numberic'>{gift.number || 1}</div>
                            <div className='price'>
                              {gift.originPrice ? <del>${formatNumber(gift.originPrice)}</del> : null}
                              <span className='is-text-black50'>${formatNumber(gift.productPrice)}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                      {cart.plan.prepay.length ? (
                        <div className='gray-block'>
                          <div className='is-text-darkgray50'>預繳金</div>
                          <div className='fui-order-item'>
                            <div className='pname'>預繳金會依您的資格調整,請依最終結帳訂單金額為主</div>
                            <div className='price'>
                              <span className='is-text-black50'>${formatNumber(Mock.Cart.plan.prepay)}</span>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className='gray-block is-bg-white py-0'>
                        <FcoinMsg
                          content={`回饋 <strong class='is-text-error'>VIP會員</strong> 遠傳幣 <strong class='is-text-error'>1%</strong> 起`}
                          tooltip={true}
                        />
                      </div>

                      <div className='fui-order-count pb-3'>
                        <div className='fui-order-count-item'>
                          <div className='label'>小計</div>
                          <div className='price'>${formatNumber(this.countAmount(cart))}</div>
                        </div>
                        <div className='fui-order-count-item is-text-blue100'>
                          <div className='label'>商品折抵</div>
                          <div className='price'>-${formatNumber(cart.discount)}</div>
                        </div>
                        {form.happygo.value ? (
                          <div className='fui-order-count-item is-text-blue100'>
                            <div className='label'>HAPPY GO 點數折抵</div>
                            <div className='price'>-${formatNumber((form.happygo.value / 200) * 50)}</div>
                          </div>
                        ) : null}
                        {form.coupon.value ? (
                          <div className='fui-order-count-item is-text-blue100'>
                            <div className='label'>優惠券折抵</div>
                            <div className='price'>-${formatNumber(this.state.coupon)}</div>
                          </div>
                        ) : null}
                        <div className='fui-order-count-item'>
                          <div className='label'>結帳金額</div>
                          <div className='price'>
                            <h1 className='is-text-error'>${formatNumber(this.countAmount(cart))}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='collapse-trigger' onClick={this.openCollapse}>
                    <span>{!this.state.collapseOpen ? '展開' : '收合'}明細</span>
                    <span className={`icon-${!this.state.collapseOpen ? 'plus' : 'minus'}`}></span>
                  </div>
                </div>
                <div>
                  <h4 className='mt-5'>想要使用優惠折扣？</h4>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} className='fui-discount-input'>
                      {this.state.happyGoLogin ? (
                        <InputGroup
                          label='HAPPY GO 點數折抵'
                          name='happygo'
                          buttonText='折抵'
                          placeholder='輸入欲折抵點數'
                          value={form.happygo.value}
                          info={`目前累積 ${this.state.happygo - form.happygo.value} 點，200點可折抵50元`}
                          error={this.state.happygoError === '' ? null : this.state.happygoError}
                          onChange={this.inputChange}
                        />
                      ) : (
                        <div className='form-group'>
                          <label>HAPPY GO 點數折抵</label>
                          <div className='fui-happygo-notice is-text-darkgray50'>
                            想查有多少點數嗎？
                            <b className='is-text-black50' role='button' onClick={(e) => this.openModal()}>
                              登入 Happy Go 會員 <i className='icon-chevron-right'></i>
                            </b>
                          </div>
                        </div>
                      )}
                      {form.happygo.value > 0 ? (
                        <span className='discount-label'>-${formatNumber((form.happygo.value / 200) * 50)}</span>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className='fui-discount-input'>
                      <InputGroup
                        className='mb-0'
                        label='輸入優惠券序號'
                        name='coupon'
                        buttonText='折抵'
                        placeholder='輸入優惠序號'
                        value={form.coupon.value}
                        error={this.state.couponError === '' ? null : this.state.couponError}
                        onChange={this.inputChange}
                      />
                      {form.coupon.value.length === 5 ? (
                        <span className='discount-label'>-${formatNumber(this.state.coupon)}</span>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className='paper'>
                {/* <h4>付款及取貨</h4> */}
                {/* 系統判斷是否為 1 元過信用卡交易 */}
                <div className='form-group'>
                  <label>信用卡 (1元過信用卡交易)</label>
                  <Collapse
                    open={true}
                    title='注意事項'
                    content={`
                      <ul>
                        <li>遠傳為確認您為本人申辦門號/續約門號，請使用您個人信用卡進行過卡驗證。</li>
                        <li>接下來系統會執行一筆1元的過卡交易，僅確認您是申辦本人，我們不會向銀行請款，請您放心進行資料驗證。</li>
                        <li>本操作完全由合作銀行進行加密處理，遠傳不會保留您的完整卡號資訊。</li>
                      </ul>
                    `}
                  />
                  <div className='d-block'>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <div
                          className={`form-group ${
                            this.state.isCreditCardUpdate &&
                            (form.credit_number_1.value === '' ||
                              form.credit_number_2.value === '' ||
                              form.credit_number_3.value === '' ||
                              form.credit_number_4.value === '')
                              ? 'is-invalid'
                              : ''
                          }`}>
                          <label className='is-required'>信用卡卡號</label>
                          <div className='credit-input' ref={this.creditGroup1}>
                            <input
                              type='tel'
                              required={form.payment.value === '2' || form.payment.value === '3'}
                              name='credit_number[]'
                              maxLength='4'
                              value={form.credit_number_1.value}
                              onBlur={(e) => this.inputChange('credit_number_1', e.currentTarget.value)}
                              onChange={(e) => this.inputChange('credit_number_1', e.currentTarget.value)}
                            />
                            <span>-</span>
                            <input
                              type='tel'
                              required={form.payment.value === '2' || form.payment.value === '3'}
                              name='credit_number[]'
                              maxLength='4'
                              value={form.credit_number_2.value}
                              onBlur={(e) => this.inputChange('credit_number_2', e.currentTarget.value)}
                              onChange={(e) => this.inputChange('credit_number_2', e.currentTarget.value)}
                            />
                            <span>-</span>
                            <input
                              type='tel'
                              required={form.payment.value === '2' || form.payment.value === '3'}
                              name='credit_number[]'
                              maxLength='4'
                              value={form.credit_number_3.value}
                              onBlur={(e) => this.inputChange('credit_number_3', e.currentTarget.value)}
                              onChange={(e) => this.inputChange('credit_number_3', e.currentTarget.value)}
                            />
                            <span>-</span>
                            <input
                              type='tel'
                              required={form.payment.value === '2' || form.payment.value === '3'}
                              name='credit_number[]'
                              maxLength='4'
                              value={form.credit_number_4.value}
                              onBlur={(e) => this.inputChange('credit_number_4', e.currentTarget.value)}
                              onChange={(e) => this.inputChange('credit_number_4', e.currentTarget.value)}
                            />
                          </div>
                          {this.state.isCreditCardUpdate &&
                          (form.credit_number_1.value === '' ||
                            form.credit_number_2.value === '' ||
                            form.credit_number_3.value === '' ||
                            form.credit_number_4.value === '') ? (
                            <span className='is-text-error mt-1'>請輸入正確資訊！</span>
                          ) : null}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <div className='form-group mb-2'>
                          <label className='is-required'>信用卡有效日期</label>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Select
                                name='credit_month'
                                required={form.payment.value === '2' || form.payment.value === '3'}
                                label={'月'}
                                value={form.credit_month.value}
                                validationErrors={{
                                  isDefaultRequiredValue: '請選擇正確資訊！',
                                }}
                                options={[
                                  { text: '01', value: '01' },
                                  { text: '02', value: '02' },
                                  { text: '03', value: '03' },
                                  { text: '04', value: '04' },
                                  { text: '05', value: '05' },
                                  { text: '06', value: '06' },
                                  { text: '07', value: '07' },
                                  { text: '08', value: '08' },
                                  { text: '09', value: '09' },
                                  { text: '10', value: '10' },
                                  { text: '11', value: '11' },
                                  { text: '12', value: '12' },
                                ]}
                                onChange={this.inputChange}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Select
                                name='credit_year'
                                label={'年'}
                                required={form.payment.value === '2' || form.payment.value === '3'}
                                value={form.credit_year.value}
                                validationErrors={{
                                  isDefaultRequiredValue: '請選擇正確資訊！',
                                }}
                                options={[
                                  { text: '20', value: '20' },
                                  { text: '21', value: '21' },
                                  { text: '22', value: '22' },
                                  { text: '23', value: '23' },
                                  { text: '24', value: '24' },
                                  { text: '25', value: '25' },
                                  { text: '26', value: '26' },
                                  { text: '27', value: '27' },
                                  { text: '28', value: '28' },
                                  { text: '29', value: '29' },
                                  { text: '30', value: '30' },
                                ]}
                                onChange={this.inputChange}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <div className='form-group mb-2'>
                          <label className='is-required'>
                            檢查碼
                            <Tooltip
                              content={<i className='icon-information i-24'></i>}
                              trigger='click'
                              tooltip={`信用卡背面三碼數字`}
                            />
                          </label>
                          <div className='credit-input' ref={this.validCode}>
                            <TextInput
                              type='tel'
                              name='credit_code'
                              required={form.payment.value === '2' || form.payment.value === '3'}
                              maxLength='3'
                              minLength='3'
                              validationErrors={{
                                isDefaultRequiredValue: '請輸入正確資訊！',
                              }}
                              value={form.credit_code.value}
                              onChange={(val) => this.inputChange('credit_code', val)}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <img
                      src={
                        process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-versignsecured.png'
                      }
                      srcSet={
                        process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-versignsecured@2x.png'
                      }
                      alt=''
                      height='32'
                      className='mr-1'
                    />
                    <img
                      src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-jcb.png'}
                      srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-jcb@2x.png'}
                      alt=''
                      height='32'
                      className='mr-1'
                    />
                    <img
                      src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-mastercard.png'}
                      srcSet={
                        process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-mastercard@2x.png'
                      }
                      alt=''
                      height='32'
                      className='mr-1'
                    />
                    <img
                      src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-visa.png'}
                      srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-visa@2x.png'}
                      alt=''
                      height='32'
                      className='mr-1'
                    />

                    <div className='is-text-darkgray50 mt-1'>
                      <small>本付款機制採用SSL 256 bites安全加密機制</small>
                    </div>
                  </div>
                </div>

                {/* 系統判斷是否一般交易 */}
                <RadioButtons
                  label='選擇付款方式'
                  name='payment'
                  selected={form.payment.value}
                  options={[
                    { label: '貨到付款', value: '1' },
                    { label: '信用卡一次付清', value: '2' },
                    { label: '信用卡分期付款', value: '3' },
                  ]}
                  getSelected={(val) => this.inputChange('payment', val)}
                />
                <div
                  className={`pb-5 ${form.payment.value === '2' || form.payment.value === '3' ? 'd-block' : 'd-none'}`}>
                  <Grid container spacing={2} className={'mb-2 ' + (form.payment.value === '3' ? 'd-flex' : 'd-none')}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Select2
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? 'red' : '#202020',
                            background: state.isHovered ? '#E8ECF0' : 'white',
                            height: '42px',
                            padding: '8px 12px',
                          }),
                        }}
                        placeholder='選擇發卡銀行'
                        className='fui-select2'
                        name='bank'
                        options={this.state.bankOptions}
                        value={this.state.bankOptions.filter((opt) => opt.value === form.bank.value)[0]}
                        onChange={(opt) => this.inputChange('bank', opt.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className='form-group'>
                        <Select
                          name='staging'
                          options={[
                            { text: '3期', value: '3' },
                            { text: '6期', value: '6' },
                            { text: '12期', value: '12' },
                            { text: '24期', value: '24' },
                          ]}
                          label='選擇分期期數'
                          value={form.staging.value}
                          onChange={this.inputChange}
                        />
                        <div className='mt-1'>
                          <span
                            role='button'
                            className='d-inlin-block is-underline-link'
                            onClick={(e) =>
                              this.setState({
                                modalOpen: true,
                                currentModal: {
                                  ...Mock.staging,
                                  type: 'notice',
                                },
                              })
                            }>
                            分期說明
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <div className='form-group'>
                        <label className='is-required'>信用卡卡號</label>
                        <div className='credit-input' ref={this.creditGroup2}>
                          <input
                            type='tel'
                            required={form.payment.value === '2' || form.payment.value === '3'}
                            name='credit_number[]'
                            maxLength='4'
                            value={form.credit_number_1.value}
                            onBlur={(e) => this.inputChange('credit_number_1', e.currentTarget.value)}
                            onChange={(e) => this.inputChange('credit_number_1', e.currentTarget.value)}
                          />
                          <span>-</span>
                          <input
                            type='tel'
                            required={form.payment.value === '2' || form.payment.value === '3'}
                            name='credit_number[]'
                            maxLength='4'
                            value={form.credit_number_2.value}
                            onBlur={(e) => this.inputChange('credit_number_2', e.currentTarget.value)}
                            onChange={(e) => this.inputChange('credit_number_2', e.currentTarget.value)}
                          />
                          <span>-</span>
                          <input
                            type='tel'
                            required={form.payment.value === '2' || form.payment.value === '3'}
                            name='credit_number[]'
                            maxLength='4'
                            value={form.credit_number_3.value}
                            onBlur={(e) => this.inputChange('credit_number_3', e.currentTarget.value)}
                            onChange={(e) => this.inputChange('credit_number_3', e.currentTarget.value)}
                          />
                          <span>-</span>
                          <input
                            type='tel'
                            required={form.payment.value === '2' || form.payment.value === '3'}
                            name='credit_number[]'
                            maxLength='4'
                            value={form.credit_number_4.value}
                            onBlur={(e) => this.inputChange('credit_number_4', e.currentTarget.value)}
                            onChange={(e) => this.inputChange('credit_number_4', e.currentTarget.value)}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <div className='form-group mb-2'>
                        <label className='is-required'>信用卡有效日期</label>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Select
                              name='credit_month'
                              required={form.payment.value === '2' || form.payment.value === '3'}
                              label={'月'}
                              value={form.credit_month.value}
                              validationErrors={{
                                isDefaultRequiredValue: '請選擇正確資訊！',
                              }}
                              options={[
                                { text: '01', value: '01' },
                                { text: '02', value: '02' },
                                { text: '03', value: '03' },
                                { text: '04', value: '04' },
                                { text: '05', value: '05' },
                                { text: '06', value: '06' },
                                { text: '07', value: '07' },
                                { text: '08', value: '08' },
                                { text: '09', value: '09' },
                                { text: '10', value: '10' },
                                { text: '11', value: '11' },
                                { text: '12', value: '12' },
                              ]}
                              onChange={this.inputChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Select
                              name='credit_year'
                              label={'年'}
                              required={form.payment.value === '2' || form.payment.value === '3'}
                              value={form.credit_year.value}
                              validationErrors={{
                                isDefaultRequiredValue: '請選擇正確資訊！',
                              }}
                              options={[
                                { text: '20', value: '20' },
                                { text: '21', value: '21' },
                                { text: '22', value: '22' },
                                { text: '23', value: '23' },
                                { text: '24', value: '24' },
                                { text: '25', value: '25' },
                                { text: '26', value: '26' },
                                { text: '27', value: '27' },
                                { text: '28', value: '28' },
                                { text: '29', value: '29' },
                                { text: '30', value: '30' },
                              ]}
                              onChange={this.inputChange}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <div className='form-group mb-2'>
                        <label className='is-required'>
                          檢查碼
                          <Tooltip
                            content={<i className='icon-information i-24'></i>}
                            trigger='click'
                            tooltip={`信用卡背面三碼數字`}
                          />
                        </label>
                        <div className='credit-input' ref={this.validCode}>
                          <TextInput
                            type='tel'
                            name='credit_code'
                            required={form.payment.value === '2' || form.payment.value === '3'}
                            maxLength='3'
                            minLength='3'
                            validationErrors={{
                              isDefaultRequiredValue: '請輸入正確資訊！',
                            }}
                            value={form.credit_code.value}
                            onChange={(val) => this.inputChange('credit_code', val)}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <img
                    src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-versignsecured.png'}
                    srcSet={
                      process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-versignsecured@2x.png'
                    }
                    alt=''
                    height='32'
                    className='mr-1'
                  />
                  <img
                    src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-jcb.png'}
                    srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-jcb@2x.png'}
                    alt=''
                    height='32'
                    className='mr-1'
                  />
                  <img
                    src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-mastercard.png'}
                    srcSet={
                      process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-mastercard@2x.png'
                    }
                    alt=''
                    height='32'
                    className='mr-1'
                  />
                  <img
                    src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-visa.png'}
                    srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-visa@2x.png'}
                    alt=''
                    height='32'
                    className='mr-1'
                  />

                  <div className='is-text-darkgray50 mt-1'>
                    <small>本付款機制採用SSL 256 bites安全加密機制</small>
                  </div>
                </div>
                {/* NP: 選擇 SIM 卡取貨方式, others: 選擇取貨方式 */}
                {form.payment.value === '1' ? (
                  <RadioButtons
                    className='mb-2'
                    label='選擇 SIM 卡取貨方式'
                    name='pickup'
                    selected={form.pickup.value}
                    options={[
                      { label: '全家超商取貨', value: '1' },
                      { label: '宅配', value: '2' },
                    ]}
                    getSelected={(val) => this.inputChange('pickup', val)}
                  />
                ) : (
                  <RadioButtons
                    label='選擇取貨方式'
                    name='pickup'
                    selected={form.pickup.value}
                    options={[
                      { label: '全家超商取貨', value: '1' },
                      { label: '宅配', value: '2' },
                      { label: '門市取貨', value: '3' },
                    ]}
                    getSelected={(val) => this.inputChange('pickup', val)}
                  />
                )}
                {/* <div className='form-group'>
                  <label>選擇 SIM 卡取貨方式</label>
                  <div className='radio-buttons'>
                    <div className='radio-group'>
                      <label className='radio-button'>
                        <p>全家超商取貨</p>
                        <input
                          type='radio'
                          value={1}
                          checked={form.pickup.value === '1'}
                          name='pickup'
                          onChange={() => this.inputChange('pickup', '1')}
                        />
                        <span className='checkmark'></span>

                        {form.pickup.value === '1' ? (
                          
                        ) : null}
                      </label>
                      <label className='radio-button'>
                        <p>宅配</p>
                        <input
                          type='radio'
                          value={2}
                          checked={form.pickup.value === '2'}
                          name='pickup'
                          onChange={() => this.inputChange('pickup', '2')}
                        />
                        <span className='checkmark'></span>
                      </label>
                      {form.payment.value !== '1' ? (
                        <label className='radio-button'>
                          <p>門市取貨</p>
                          <input
                            type='radio'
                            value={3}
                            checked={form.pickup.value === '3'}
                            name='pickup'
                            onChange={() => this.inputChange('pickup', '3')}
                          />
                          <span className='checkmark'></span>
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div> */}

                {form.pickup.value === '1' ? (
                  <div>
                    <div className='pickup-action mt-3'>
                      <Button btnStyle='secondary' size='small'>
                        選擇取貨門市
                      </Button>
                      <div className='is-text-grayblue body-1 mb-1'>
                        <div>已選擇：全家芝玉店 </div>
                        <div>門市地址：台北市士林區士東路266巷5弄16號</div>
                      </div>
                      <Button btnStyle='secondary' size='small'>
                        重新選擇門市
                      </Button>
                    </div>
                    <Collapse
                      open={true}
                      title='注意事項'
                      content={`
                      <ul>
                        <li>若查詢不到您欲前往取貨的門市，可能該門市已暫停提供取貨服務。</li>
                        <li>收到取貨通知，需由【本人攜帶身分證正本】，前往指定全家超商取貨。包裝袋內內含兩份申請書，一份請於簽名處以「正楷簽名」後放入寄件包裝袋，告知超商人員我要寄件，並交付「寄件包裝袋」。</li>
                        <li>超商取貨自動開通時間為9點~24點，如在凌晨交寄包裝袋，將於次日自動開通。</li>
                      </ul>
                    `}
                    />
                  </div>
                ) : null}
                {form.pickup.value === '2' ? (
                  <div>
                    <div className='form-group'>
                      <label>送貨地址 (可本人親簽的地址)</label>
                      <Checkbox
                        name='is_bill_address'
                        label='同帳單地址'
                        checked={form.is_bill_address.value}
                        onChange={this.inputChange}
                      />
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                          <Select
                            disabled={form.is_bill_address.value}
                            name='delivery_city'
                            required={form.pickup.value === '2'}
                            validationErrors={{
                              isDefaultRequiredValue: '請選擇縣/市',
                            }}
                            options={[{ text: '台北市', value: '台北市' }]}
                            label={form.delivery_city.value || '選擇縣/市'}
                            value={form.delivery_city.value}
                            onChange={this.inputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Select
                            disabled={form.is_bill_address.value}
                            name='delivery_area'
                            validationErrors={{
                              isDefaultRequiredValue: '請選擇區域',
                            }}
                            options={[{ text: '中山區', value: '中山區' }]}
                            required={form.pickup.value === '2'}
                            label={form.delivery_area.value || '選擇區域'}
                            value={form.delivery_area.value}
                            onChange={this.inputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInput
                            disabled={form.is_bill_address.value}
                            className='mb-0 w-100'
                            name='delivery_address'
                            type='tel'
                            placeholder='街路巷弄'
                            required={form.pickup.value === '2'}
                            validationErrors={{
                              isDefaultRequiredValue: '請輸入地址！',
                            }}
                            value={form.delivery_address.value}
                            onChange={(val) => this.inputChange('delivery_address', val)}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <Collapse
                      open={true}
                      title='注意事項'
                      content={`
                      <ul>
                        <li>宅配服務配送區域僅限台灣本島。</li>
                        <li>新申辦或攜碼門號，需由申辦者【本人親自簽收】，請您於收貨／取貨時，備妥本人雙證件正本】以核對身分。</li>
                        <li>宅配箱內含兩份申請書，請於一份簽名處以「正楷簽名」後交給宅配人員。一份由客戶自行留存。</li>
                      </ul>
                    `}
                    />
                  </div>
                ) : null}
                {form.pickup.value === '3' ? (
                  <div>
                    <div className='form-group mb-3'>
                      <label>門市店點</label>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                          <Select
                            name='store_city'
                            options={[{ text: '台北市', value: '台北市' }]}
                            label={form.store_city.value || '選擇縣/市'}
                            validationErrors={{
                              isDefaultRequiredValue: '請選擇縣市',
                            }}
                            required={form.pickup.value === '3'}
                            value={form.store_city.value}
                            onChange={this.inputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Select
                            name='store_area'
                            options={[{ text: '中山區', value: '中山區' }]}
                            required={form.pickup.value === '3'}
                            validationErrors={{
                              isDefaultRequiredValue: '請選擇區域',
                            }}
                            label={form.store_area.value || '請選擇區域'}
                            value={form.store_area.value}
                            onChange={this.inputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Select
                            name='store_road'
                            options={[{ text: '北安路', value: '北安路' }]}
                            required={form.pickup.value === '3'}
                            validationErrors={{
                              isDefaultRequiredValue: '請選擇路段',
                            }}
                            label={form.store_road.value || '請選擇街路或鄉里名稱'}
                            value={form.store_road.value}
                            onChange={this.inputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Select
                            name='store_name'
                            options={[{ text: '大直北安直營店', value: '大直北安直營店' }]}
                            required={form.pickup.value === '3'}
                            validationErrors={{
                              isDefaultRequiredValue: '請選擇門市',
                            }}
                            label={form.store_name.value || '請選擇門市'}
                            value={form.store_name.value}
                            onChange={this.inputChange}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <Collapse
                      open={true}
                      title='注意事項'
                      content={`
                      <ul>
                        <li>遠傳門市取貨配送區域，僅限台灣本島。</li>
                        <li>新申辦或攜碼門號需由【本人攜帶身分證正本】，前往指定遠傳門市取貨，由門市服務人員為您完成取貨流程。</li>
                      </ul>
                    `}
                    />
                  </div>
                ) : null}

                {/* NP: 選擇門號開通生效日 */}
                <div className='form-group mt-6'>
                  <label>預約門號開通生效日</label>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className='fui-list'>
                        <Item prefix='bulleted'>請點擊小日曆，開放28天內，預約門號開通日期</Item>
                        <Item prefix='bulleted'>{`建議<span class='is-text-accent'>選擇在原合約到期日的隔天開通</span>，就能無違約金的轉換`}</Item>
                        <Item prefix='bulleted'>內部作業及SIM卡物流作業約需4-5個工作天</Item>
                      </div>
                      <div className='example is-text-grayblue mb-1'>
                        <span className='d-inline-block is-text-mini'>舉例</span>
                        <br />
                        原電信合約1/1到期，可選擇1/2 新合約生效
                      </div>
                      <ActivatedDatePicker
                        placeholder={form.pickup.value === '' ? '請先選擇取貨方式' : '請選擇門號開通日期'}
                        showNotice={(msg) => this.setState({ modalOpen: true, currentModal: msg })}
                        minDate={'2021/3/5'}
                        maxDate={'2021/3/25'}
                        highlightDates={['2021/3/20', '2021/3/21', '2021/3/22', '2021/3/23', '2021/3/24']}
                        type={'preorder'}
                        selected={form.avaliable_at.value}
                        disable={form.pickup.value === ''}
                        disableDate={['2021/03/07', '2021/03/08', '2021/03/14']}
                        onChange={(date) => this.inputChange('avaliable_at', date)}
                      />
                    </Grid>
                  </Grid>
                </div>
                <Collapse
                  open={true}
                  title='注意事項'
                  content={`
                    <ul>
                      <li>請確認該攜碼門號是使用你的姓名與證件申請，並該門號沒有欠費、停話或中止狀態，不然還是會被原電信業者取消攜碼申請喔！</li>
                      <li>門號攜碼預約生效日申請送出後，需等候原電信業者同意，並且在收到SIM卡及申請書包裹後，在一份遠傳申請書上簽名交回，方可於此預約日期上午6點後，使用新的SIM卡進行通話。</li>
                    </ul>
                  `}
                />
                <div className='form-group'>
                  <label>選擇發票格式</label>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Select
                        name='receipt_type'
                        label={form.receipt_type.value === '' ? '請選擇' : form.receipt_type.value}
                        required={true}
                        validationErrors={{
                          isDefaultRequiredValue: '請選擇發票格式',
                        }}
                        value={form.receipt_type.value}
                        options={[
                          { text: '遠傳電信會員載具', value: '1' },
                          { text: '電子發票 - 自然人憑證', value: '2' },
                          { text: '電子發票 - 手機條碼', value: '3' },
                          { text: '電子發票 - 捐贈', value: '4' },
                          { text: '紙本發票 - 三聯式', value: '5' },
                        ]}
                        onChange={this.inputChange}
                      />
                    </Grid>
                    {form.receipt_type.value === '2' ? (
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInput
                          name='receipt_certificate'
                          type='text'
                          required={form.receipt_type.value === '2'}
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入自然人憑證號碼',
                          }}
                          placeholder='請輸入自然人憑證號碼'
                          value={form.receipt_certificate.value}
                          onChange={(val) => this.inputChange('receipt_certificate', val)}
                        />
                        <div className='mt-1'>
                          <span
                            role='button'
                            className='is-underline-link'
                            onClick={(e) =>
                              this.setState({
                                currentModal: { type: 'notice', ...this.state.citizenCardModal },
                                modalOpen: true,
                              })
                            }>
                            什麼是自然人憑證號碼？
                          </span>
                        </div>
                      </Grid>
                    ) : null}
                    {form.receipt_type.value === '3' ? (
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInput
                          name='receipt_certificate'
                          type='text'
                          placeholder='請輸入手機條碼'
                          required={form.receipt_type.value === '3'}
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入手機條碼',
                          }}
                          value={form.receipt_certificate.value}
                          onChange={(val) => this.inputChange('receipt_certificate', val)}
                        />
                        <div className='mt-1'>
                          <span
                            role='button'
                            className='is-underline-link'
                            onClick={(e) =>
                              this.setState({
                                currentModal: { type: 'notice', ...this.state.mobileBarcodeModal },
                                modalOpen: true,
                              })
                            }>
                            什麼是手機條碼？
                          </span>
                        </div>
                      </Grid>
                    ) : null}
                    {form.receipt_type.value === '4' ? (
                      <Grid item xs={12} sm={12} md={12} className='mt-5'>
                        <div className='form-group'>
                          <label>捐贈單位</label>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                              <Select
                                name='receipt_donate_company'
                                label='請選擇捐贈單位'
                                required={form.receipt_type.value === '4'}
                                validationErrors={{
                                  isDefaultRequiredValue: '請選擇捐贈單位',
                                }}
                                options={this.state.donateCompanyOption}
                                value={form.receipt_donate_company.value}
                                onChange={this.inputChange}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <TextInput
                                name='receipt_donate_number'
                                required={form.receipt_type.value === '4'}
                                placeholder='請輸入捐贈碼'
                                validationErrors={{
                                  isDefaultRequiredValue: '請輸入捐贈碼',
                                }}
                                value={form.receipt_donate_number.value}
                                onChange={(v) => this.inputChange('receipt_donate_number', v)}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    ) : null}
                    {form.receipt_type.value === '5' ? (
                      <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                              name='tax_id_number'
                              type='text'
                              placeholder='請輸入統一編碼'
                              required={form.receipt_type.value === '5'}
                              validationErrors={{
                                isDefaultRequiredValue: '請輸入統一編碼',
                              }}
                              value={form.tax_id_number.value}
                              onChange={(val) => this.inputChange('tax_id_number', val)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextInput
                              name='tax_id_title'
                              type='text'
                              placeholder='請輸入統編抬頭'
                              required={form.receipt_type.value === '5'}
                              validationErrors={{
                                isDefaultRequiredValue: '請輸入統編抬頭',
                              }}
                              value={form.tax_id_title.value}
                              onChange={(val) => this.inputChange('tax_id_title', val)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </div>
                {form.receipt_type.value === '5' ? (
                  <div className='form-group'>
                    <label>發票寄送地址</label>
                    <Checkbox
                      name='is_domicile_address'
                      label='同帳單地址'
                      checked={form.is_domicile_address.value}
                      onChange={this.inputChange}
                    />

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Select
                          disabled={form.is_domicile_address.value}
                          name='domicile_city'
                          required={form.receipt_type.value === '3'}
                          validationErrors={{
                            isDefaultRequiredValue: '請選擇縣/市',
                          }}
                          options={[{ text: '台北市', value: '台北市' }]}
                          label={form.domicile_city.value || '選擇縣/市'}
                          value={form.domicile_city.value}
                          onChange={this.inputChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Select
                          disabled={form.is_domicile_address.value}
                          name='domicile_area'
                          validationErrors={{
                            isDefaultRequiredValue: '請選擇區域',
                          }}
                          options={[{ text: '中山區', value: '中山區' }]}
                          required={form.receipt_type.value === '3'}
                          label={form.domicile_area.value || '選擇區域'}
                          value={form.domicile_area.value}
                          onChange={this.inputChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextInput
                          disabled={form.is_domicile_address.value}
                          className='mb-0 w-100'
                          name='domicile_address'
                          type='tel'
                          placeholder='街路巷弄'
                          required={form.receipt_type.value === '3'}
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入地址！',
                          }}
                          value={form.domicile_address.value}
                          onChange={(val) => this.inputChange('delivery_address', val)}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ) : null}

                <Collapse
                  title='注意事項'
                  content={`
                  <ul>
                    <li>建議您使用電子發票，不索取「紙本」發票，環保愛地球。</li>
                    <li>若選擇電子發票，提醒您電子發票一經開立，即不得變更載具之選擇。若要索取紙本發票，請於頁面選擇「索取紙本發票」。</li>
                    <li>電子發票開立後，遠傳會於xx小時內將電子發票上傳至「電子發票整合服務平台」，並以e-mail通知您，電子發票不會主動寄送。有關電子發票作業與「電子發票實施作業要點」，可詳參財政部電子發票整合服務平台網站。</li>
                    <li>如您係使用已歸戶之共通性載具，中獎資訊將由財政部電子發票整合服務平台通知您。</li>
                    <li>另提醒您，依財政部所發佈之法令規定，發票副本僅供參考，不可直接兌獎。</li>
                    <li>若您選擇捐贈發票者，將由遠傳代為捐贈給您指定的慈善機構。</li>
                    <li>若您於訂購時已選擇開立二聯式發票者，恕無法換開三聯式發票；訂購時選擇開立三聯式發票，亦無法換開二聯式發票。</li>
                    <li>若您選擇開立紙本發票者，請於收到紙本發票後自行妥善保管，若有遺失、毀損等，恕不補發。
                    <li>遠傳保有使用及停用電子發票之權利。</li>
                    <li>若索取紙本發票，將待鑑賞期(貨到7天)後再寄出至宅配地址(如遇春節期間或是國定假日則順延寄送)。</li>
                  </ul>
                `}
                />
              </div>
              <div className='page-action'>
                <Button
                  className={this.state.submitLoading ? 'is-api-loading' : ''}
                  btnStyle='primary'
                  size='large'
                  disabled={this.state.canSubmit}
                  onClick={this.beforeSubmit}>
                  {locationState && locationState.ctaText ? locationState.ctaText : '確定付款'}
                </Button>
                <Button btnStyle='secondary' size='large' onClick={(e) => this.props.history.go(-1)}>
                  返回
                </Button>
              </div>
            </div>
          </Formsy>
        </section>

        <EstoreModal
          {...this.state.currentModal}
          open={this.state.modalOpen}
          onSubmit={this.modalSubmit}
          onConfirm={this.modalConfirm}
          onClose={this.modalClose}
          onCancel={this.modalCancel}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.flowCartReducer || state.cartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFlowCartData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
