import React from 'react';
import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import Formsy from 'formsy-react';
import { setFlowCartData } from '../../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import FormBanner from '../../components/partials/banner/FormBanner';
import Button from '../../components/Button';
import NumberSelector from '../../components/form/NumberSelector';
import Checkbox from '../../components/form/Checkbox';
import EstoreModal from '../../components/EstoreModal';
import OrderDetail from '../../components/OrderDetail';
import FcoinMsg from '../../components/FcoinMsg';

import formatNumber from '../../utils/numberFormatter';
import * as Mock from '../../mock/OrderList';

class OrderList extends React.Component {
  constructor(props) {
    super(props);

    this.body = React.createRef();
    this.collapseBody = React.createRef();
    this.state = {
      cart: Mock.Cart,
      modalOpen: false,
      pageInfo: {
        pageTitle: '確認申購清單',
        pageSubTitle: '請確認你的申購清單後，點選下一步確認個人基本資料',
        ctaText: '確認身分資料',
      },
      canSubmit: false,
      currentModal: {
        type: 'notice',
        title: '',
        content: '',
      },
      collapseOpen: false,
      contentHeight: 0,
      form: {
        agree_policy: { value: false, required: true },
        agree_edm: { value: false, required: false },
        combine_bill: { value: false, required: false },
      },
      nextPageInfo: null,
    };
  }

  componentDidMount = () => {
    // 如果有 co_type 根據 co_type 寫入對應文字
    if (this.props.location.state && this.props.location.state.co_type) {
      this.setCoTypeWording();
      this.setState({
        nextPageInfo: {
          co_type: this.props.location.state.co_type,
        },
      });
    }

    let elem = window || document.documentElement;
    elem.scroll(0, 0);

    if (!this.props.cart) {
      this.props.history.go(-1);
    }
  };

  componentDidUpdate = (nextProps) => {
    if (this.props.onChange && (nextProps.open !== this.state.open || this.props.open !== this.state.open)) {
      this.setState({
        collapseOpen: this.props.collapseOpen,
      });
    }
  };

  setCoTypeWording = () => {
    let pageTitle = '確認申購清單';
    let pageSubtitle = '請確認你的申購清單後，點選下一步確認個人基本資料';
    let ctaText = '確認身分資料';
    /**
      **All case**
      NC_identity
      NC
      NH_identity
      NH
      LC
      LC_identity
      LH
      LH_identity
      DA
    */
    switch (this.props.location.state.co_type) {
      case 'LC':
        ctaText = '取得簡訊驗證碼';
        pageSubtitle = '為確保你的個資安全，遠傳電信將在你確認申購清單後，下一步進行手機簡訊身份驗證';
        break;
      case 'LC_identity':
        ctaText = '取得簡訊驗證碼';
        pageSubtitle = '為確保你的個資安全，遠傳電信將在你確認申購清單後，下一步進行手機簡訊身份驗證';
        break;
      case 'LH':
        ctaText = '取得簡訊驗證碼';
        pageSubtitle = '為確保你的個資安全，遠傳電信將在你確認申購清單後，下一步進行手機簡訊身份驗證';
        break;
      case 'LH_identity':
        ctaText = '取得簡訊驗證碼';
        pageSubtitle = '為確保你的個資安全，遠傳電信將在你確認申購清單後，下一步進行手機簡訊身份驗證';
        break;
    }

    this.setState({
      pageInfo: {
        pageTitle: pageTitle,
        pageSubTitle: pageSubtitle,
        ctaText: ctaText,
      },
    });
  };

  inputChange = (name, value) => {
    let form = Object.assign(this.state.form);
    form[name].value = value;

    this.setState({
      form,
    });
  };

  cartUpdate = (name, index, value) => {
    let cart = Object.assign(this.state.cart);
    cart[name][index].number = value;
    this.setState({
      cart: cart,
    });

    this.props.setFlowCartData(cart);
    this.forceUpdate();
  };

  removeCartItem = (type, index) => {
    let newCart = Object.assign(this.state.cart);
    newCart[type].splice(index, 1);

    this.setState({
      cart: newCart,
    });

    this.props.setFlowCartData(newCart);
  };

  countAmount = (cart) => {
    let total = 0;
    for (var i in cart) {
      if (cart[i]) {
        switch (i) {
          case 'plan':
            total += cart[i].prepay;
            break;

          case 'gift':
            if (cart[i].length) {
              let giftCount = cart[i].reduce((accr, val) => accr + val.productPrice, 0);
              total += giftCount;
            }
            break;

          case 'product':
            total += cart[i].projectPrice;
            break;

          case 'discount':
            total -= cart[i].amount;
            break;

          case 'extra_product':
            if (cart[i].length) {
              let extraProductCount = cart[i].reduce((accr, val) => accr + val.productPrice * val.number, 0);
              total += extraProductCount;
            }
            break;
        }
      }
    }

    return total;
  };

  setContentHeight = () => {
    this.setState({
      contentHeight: this.body.current.clientHeight,
    });
  };

  openModal = (e, type, name) => {
    e.preventDefault();

    this.setState({
      currentModal: {
        type: type,
        ...Mock[name],
      },
      modalOpen: true,
    });
  };

  openCollapse = (event) => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });

    this.forceUpdate();
    this.setContentHeight();

    let collapseTop = (
      !this.state.open ? this.collapseBody.current.parentNode : this.collapseBody.current
    ).getBoundingClientRect();
    let scrollY = window.scrollY || document.documentElement.scrollTop;

    if (window.isIE) {
      let elem = window || document.documentElement;
      elem.scroll(0, scrollY + collapseTop.top - 100);
    } else {
      window.scrollTo({
        left: 0,
        top: scrollY + collapseTop.top - 100,
        behavior: 'smooth',
      });
    }

    // [AA Tracking]
    if (window && typeof window.ctrf === 'function')
      window.ctrf(event || {}, this.state.open ? `Tab_${this.props.title}_收合` : `Tab_${this.props.title}_展開`);
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  submit = () => {
    this.props.history.push('/estore/order/identity', this.state.nextPageInfo);
  };

  renderTable = (obj) => {
    let table = '';
    for (var i in obj) {
      table += `
        <tr>
          <th>${i}</th>
          <td>${obj[i]}</td>
        </tr>
      `;
    }
    return (
      <div className='fui-table-response mb-3'>
        <table dangerouslySetInnerHTML={{ __html: table }}></table>
      </div>
    );
  };

  renderList = (list) => {
    return list.map((item, i) => (
      <div className='fui-item' key={`fui-modal-body-item-${Math.random() * 1000}`}>
        <span className='prefix'>
          <i className='bulleted-dot'></i>
        </span>
        <span className='content'>{item}</span>
      </div>
    ));
  };

  renderContent = (content) => {
    if (typeof content === 'string') {
      return <div className='fui-modal-body' dangerouslySetInnerHTML={{ __html: content }}></div>;
    }

    if (Array.isArray(content)) {
      return (
        <div className='fui-modal-body'>
          {content.map((segment, i) =>
            typeof segment === 'string' ? (
              <p dangerouslySetInnerHTML={{ __html: segment }}></p>
            ) : Array.isArray(segment) ? (
              this.renderList(segment)
            ) : typeof segment === 'object' ? (
              this.renderTable(segment)
            ) : null
          )}
        </div>
      );
    }
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalCancel = (type) => {
    // 預約取貨送出
    console.info('MODAL CANCEL', type);
    this.setState({
      modalOpen: false,
    });
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { cart, form, pageInfo } = this.state;
    const locationState = this.props.location.state;

    return (
      <main>
        <FormBanner
          title={pageInfo.pageTitle}
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className='fui-order-container'>
          <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
            <div className='fui-container'>
              <h4
                className='is-text-darkgray50'
                dangerouslySetInnerHTML={{
                  __html: pageInfo.pageSubTitle,
                }}></h4>
              <div className='paper'>
                <h4 className='m-0'>專案合約</h4>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className='gray-block'>
                      <h4>專案名稱</h4>
                      <h1>{cart.plan.value}</h1>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className='gray-block'>
                      <h4>申辦門號</h4>
                      <h1>{cart.phone_number.value}</h1>
                    </div>
                  </Grid>
                </Grid>
                <div className='fui-collapse with-trigger' ref={this.collapseBody}>
                  <div
                    className='collapse-body'
                    style={{
                      height: this.state.collapseOpen ? this.state.contentHeight : 0,
                    }}>
                    <div ref={this.body}>
                      <h4>{Mock.PlanContent.title}</h4>
                      <div>{this.renderContent(Mock.PlanContent.content)}</div>
                    </div>
                  </div>
                  <div className='collapse-trigger' onClick={this.openCollapse}>
                    <span>{!this.state.collapseOpen ? '展開' : '收合'}方案內容</span>
                    <span className={`icon-${!this.state.collapseOpen ? 'plus' : 'minus'}`}></span>
                  </div>
                </div>
              </div>

              <div className='paper'>
                <h4>申購清單</h4>
                <OrderDetail
                  cart={cart}
                  countTitle='小計'
                  canDel={true}
                  fcoin={
                    <FcoinMsg
                      content={`回饋 <strong class='is-text-error'>VIP會員</strong> 遠傳幣 <strong class='is-text-error'> 1%</strong> 起`}
                      tooltip={true}
                    />
                  }
                />
              </div>
              <div className='form-group with-agree mt-5'>
                <Checkbox
                  className='is-agreement'
                  name='agree_policy'
                  checked={form.agree_policy.value}
                  required={form.agree_policy.required}
                  onChange={this.inputChange}>
                  <div>
                    <div>
                      我已詳讀{' '}
                      <div
                        className='d-inline is-text-underline'
                        role='button'
                        onClick={(e) => this.openModal(e, 'notice', 'internetPolicy')}>
                        遠傳行動寬頻業務服務契約
                      </div>
                      、
                      <div
                        className='d-inline is-text-underline'
                        role='button'
                        onClick={(e) => this.openModal(e, 'notice', 'buyingPolicy')}>
                        網路申購合約條款
                      </div>
                      、
                      <div
                        className='d-inline is-text-underline'
                        role='button'
                        onClick={(e) => this.openModal(e, 'notice', 'mobilePolicy')}>
                        行動電話號碼可攜式服務條款
                      </div>
                      、
                      <div
                        className='d-inline is-text-underline'
                        role='button'
                        onClick={(e) => this.openModal(e, 'notice', 'lifePolicy')}>
                        「遠傳生活+」 會員服務條款
                      </div>{' '}
                      並同意契約內容
                    </div>
                    <div className='fui-list'>
                      <div className='fui-item'>
                        <span className='prefix'>
                          <i className='bulleted-dot'></i>
                        </span>
                        <span className='content'>
                          遠傳網路門市擁有接受訂單與否的權利，且同意辦理退貨時，由遠傳電信代為處理發票及銷貨退回證明單，以加速退貨退款作業。
                        </span>
                      </div>
                      <div className='fui-item'>
                        <span className='prefix'>
                          <i className='bulleted-dot'></i>
                        </span>
                        <span className='content'>
                          我已知悉可以至遠傳電信直營門市申請「無線上網免費體驗專案」之服務。
                        </span>
                      </div>
                    </div>
                  </div>
                </Checkbox>
              </div>
              <div className='form-group with-agree'>
                <Checkbox
                  className='is-agreement'
                  name='agree_edm'
                  checked={form.agree_edm.value}
                  label='我同意收到寄來遠傳的各種優惠不漏接 (選填)'
                  onChange={this.inputChange}
                />
              </div>
              <div className='form-group with-agree'>
                <Checkbox
                  className='is-agreement'
                  name='combine_bill'
                  checked={form.combine_bill.value}
                  label='電話費用及小額付費帳單合併 (選填)'
                  onChange={this.inputChange}
                />
              </div>

              <div className='page-action'>
                <Button btnStyle='primary' size='large' disabled={!this.state.canSubmit} onClick={this.submit}>
                  {pageInfo.ctaText}
                </Button>
                <Button btnStyle='secondary' size='large' onClick={(e) => this.props.history.go(-1)}>
                  返回
                </Button>
              </div>
            </div>
          </Formsy>
        </section>

        <EstoreModal
          {...this.state.currentModal}
          open={this.state.modalOpen}
          onSubmit={this.modalSubmit}
          onClose={this.modalClose}
        />
        {/* <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className={
            this.state.currentModal.type === 'alert'
              ? 'fui-alert-modal'
              : this.state.currentModal.type === 'delivery' || this.state.currentModal.type === 'reservation'
              ? 'fui-form-modal'
              : 'fui-notice-modal'
          }>
          <div className='fui-modal-content'>
            <div className='fui-modal-head'>
              <h5>{this.state.currentModal.title}</h5>
            </div>
            <div className='fui-modal-body'>
              <div dangerouslySetInnerHTML={{ __html: this.state.currentModal.content }}></div>
            </div>
            <div className='fui-modal-foot'>
              <Button btnStyle='primary' onClick={(e) => this.setState({ modalOpen: false })}>
                我暸解了
              </Button>
            </div>
          </div>
        </Modal> */}
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.flowCartReducer || state.cartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFlowCartData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
