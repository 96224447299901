import React from 'react';
import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import Formsy from 'formsy-react';
import Slider from 'react-slick';
import { setCartData } from '../../stores/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import NavTab from '../../components/partials/NavTab';
import Breadcrumb from '../../components/Breadcrumb';
import Link from '../../components/Link';
import Button from '../../components/Button';
import Cart from '../../components/Cart';

import RadioButtons from '../../components/form/RadioButtons';
import NumberSelector from '../../components/form/NumberSelector';
import LabelInput from '../../components/form/LabelInput';
import EstoreModal from '../../components/EstoreModal';

import * as Validation from '../../utils/validation';
import formatNumber from '../../utils/numberFormatter';

import axios from 'axios';
import * as Mock from '../../mock/SimCard';

class SimCard extends React.Component {
  constructor(props) {
    super(props);
    this.flowRef = React.createRef();
    this.state = {
      modalOpen: false,
      cartUpdate: false,
      currentModal: {
        type: '',
        title: '',
        content: '',
      },
      productInfo: { ...Mock.Product },
      currentNav: 0,
      cuurentCheckboxTab: 0,
      currentImage: [],
      identityPass: false,
      sldierSetting: {
        dots: true,
        infinite: false,
        arrows: true,
        slidesToShow: 1,
        draggable: true,
        adaptiveHeight: true,
      },
      deliverySubmit: false,
      reservationSubmit: false,
      deliveryForm: {
        type: { value: '新申辦' },
        name: { value: '', required: true },
        contact: { value: '', required: true },
      },
      reservationForm: {
        reserve_no: { value: '', required: true },
        id_number: { value: '', required: true },
      },
      // 使用既有的表單組件，再根據表單的值修改帶入購物車的內容
      form: {
        id_number: { value: '', required: true },
        scid: { value: '', required: true },
        number: { value: 1, required: true },
        phone_number: { value: '', required: true },
      },
      cart: {
        plan: null,
        phone_number: null,
      },
    };
  }

  componentDidMount = () => {
    axios.get('/xml/DIMcontent.xml').then((result) => {
      console.log(result.data);
    });

    this.setState({
      currentImage: !this.state.productInfo.images
        ? []
        : Array.isArray(this.state.productInfo.images)
        ? this.state.productInfo.images
        : this.state.productInfo.images[this.state.form.color.value],
    });
  };

  CheckboxTabChange = (idx) => {
    this.setState({
      cuurentCheckboxTab: idx,
    });
  };

  changeNavTab = (index) => {
    this.setState({
      currentNav: index,
    });
  };

  inputChange = (n, v, content) => {
    let form = Object.assign(this.state.form);

    // 如果已進行申辦流程，切換申辦方式時跳出 modal
    if (n === 'apply_method' && this.state.editStep !== -1) {
      this.setState({
        modalOpen: true,
        currentModal: {
          type: 'alert',
          originSelect: form[n].value,
          originFlow: this.state.applyFlow,
          title: '組合方案選項將被清除',
          content: '提醒你，剛才你選擇的組合方案將被清除，請再次確認是否要重新選擇？',
        },
      });
    }

    form[n].value = v;

    // 切換顏色，圖片跟著切換內容
    if (n === 'color') {
      this.setState({
        currentImage: this.state.productInfo.images[v],
      });
    }

    // 將完整資料帶入購物車
    if (!!content) {
      let cart = { ...this.state.cart };

      cart[n] = content;

      this.setState({
        cart: cart,
      });
      this.forceUpdate();
    }

    this.setState({
      form: form,
      cartUpdate: true,
    });

    this.forceUpdate();
  };

  openModal = (modal) => {
    this.setState({
      modalOpen: true,
      currentModal: { type: 'notice', ...modal },
    });
  };

  scrollToPosition = () => {
    // 捲動到對應位置
    let elem = window || document.documentElement;
    if (!this.flowRef.current) {
      if (window.isIE) {
        elem.scroll(0, 0);
      } else {
        elem.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
          cartUpdate: true,
        });
      }
      return;
    }

    let flows = this.flowRef.current.getElementsByClassName('fui-plan-flow');
    let lastFlow = flows[this.state.editStep !== -1 ? this.state.editStep : this.state.step];
    let prevFlow =
      flows[
        this.state.editStep !== -1 ? (this.state.editStep === 0 ? 0 : this.state.editStep - 1) : this.state.step - 1
      ];
    let rect = lastFlow.getBoundingClientRect();
    let prevRect = prevFlow.getBoundingClientRect();
    let scrollY = window.scrollY || document.documentElement.scrollTop;

    if (window.isIE) {
      elem.scroll(0, rect.top - prevRect.height + scrollY - 50);
    } else {
      elem.scrollTo({
        left: 0,
        top: scrollY - prevRect.height + rect.top - 50,
        behavior: 'smooth',
        cartUpdate: true,
      });
    }
  };

  setProductModal = (type) => {
    if (type === 'delivery') {
      this.setState({
        currentModal: {
          type: 'deliveryForm',
          title: '到貨請通知我',
        },
        modalOpen: true,
      });
    }

    if (type === 'reservation') {
      this.setState({
        currentModal: {
          type: 'reservationForm',
          title: '預約單取貨',
        },
        modalOpen: true,
      });
    }
  };

  modalSubmit = (type, form) => {
    console.info('MODAL SUBMIT', type, form);
    this.setState({
      modalOpen: false,
    });
    // 貨到通知送出
  };

  modalCancel = (type) => {
    // 預約取貨送出
    console.info('MODAL CANCEL', type);
    this.setState({
      modalOpen: false,
    });
  };

  modalClose = (type) => {
    // 預約取貨送出
    console.info('MODAL CLOSE', type);
    this.setState({
      modalOpen: false,
    });
  };

  addToCart = () => {
    this.props.setCartData([
      ...this.props.cart.list,
      {
        ...this.state.productInfo,
        number: this.state.form.number.value,
      },
    ]);
  };

  render() {
    const { form, deliveryForm, reservationForm, productInfo, currentNav } = this.state;

    return (
      <main>
        <NavTab pageTitle={productInfo.name} />
        <Breadcrumb
          color={currentNav === 0 ? 'block' : 'white'}
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '網路門市', link: '/estore' },
            { text: '手機', link: '/estore/product' },
            { text: productInfo.name, link: `/estore/product/detail/${productInfo.slug}` },
          ]}
        />

        <section className='fui-product-section'>
          <div className='fui-container'>
            {/* 產品資訊 */}
            <div className='fui-panel'>
              <Formsy>
                <div className='fui-replenishment-ribbon'>
                  <span className='text'>補貨中</span>
                </div>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    {/* 產品圖，若產品數量不足，增加樣式 is-replenishment */}
                    <Slider {...this.state.sldierSetting} className='fui-product-slider'>
                      {this.state.currentImage.length ? (
                        this.state.currentImage.map((img, i) => (
                          <img src={img} alt='' key={`fui-product-slider-${i}`} />
                        ))
                      ) : (
                        <img src={process.env.PUBLIC_URL + '/resources/common/images/no-image@2x.png'} alt='' />
                      )}
                    </Slider>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    {/* 產品內容 */}
                    <h2>
                      {productInfo.tag
                        ? productInfo.tag.map((tag, i) => (
                            <span className='fui-tag' key={`product-tag-${i}`}>
                              {tag}
                            </span>
                          ))
                        : null}
                      {productInfo.name}
                    </h2>
                    <div className='price-section'>
                      <div>商品價</div>
                      <h4>
                        <del>${formatNumber(productInfo.originPrice, 0)}</del>
                        <span>${formatNumber(productInfo.salePrice, 0)}</span>
                      </h4>
                      <div className='mb-2'>
                        <NumberSelector
                          name='number'
                          value={form.number.value}
                          min={1}
                          max={3}
                          maxMessage='庫存不足'
                          onChange={this.inputChange}
                        />
                      </div>
                      <div className='fui-product-action'>
                        <Button btnStyle='primary' size='large'>
                          立即購買
                        </Button>
                        <Button
                          btnStyle='secondary'
                          size='large'
                          disabled={this.state.hasCart}
                          onClick={this.addToCart}>
                          加入購物車
                        </Button>
                      </div>
                      <div className='fui-product-action'>
                        <Button btnStyle='primary' size='large' onClick={(e) => this.setProductModal('delivery')}>
                          貨到通知我
                        </Button>
                      </div>
                    </div>

                    {productInfo.discount ? (
                      <div className='fui-discount-bar'>
                        <span className='discount-ribbon'>促銷優惠</span>
                        <Link
                          to={productInfo.discount.link}
                          target={productInfo.discount.target}
                          className='fui-discount-link'>
                          <span className='content'>{productInfo.discount.text}</span>
                          <span>
                            <i className='icon-chevron-right'></i>
                          </span>
                        </Link>
                      </div>
                    ) : null}
                    {productInfo.notice && productInfo.notice.length ? (
                      <div className='fui-notice'>
                        {productInfo.notice.map((no, i) => (
                          <div className='fui-item' key={`product-notice-${i}`}>
                            {no.text}
                            {no.link ? (
                              <Link to={no.link} target={no.target} className='more'>
                                詳情
                              </Link>
                            ) : (
                              <div role='button' onClick={(e) => this.openModal(no.modal)} className='more'>
                                詳情
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </Formsy>
            </div>
            <div className='fui-panel  is-spec-panel'>
              {Mock.Spec.map((sp, i) => (
                <div key={`spect-panel-${i}`}>
                  {Array.isArray(sp.value) ? (
                    <div className='spec-group'>
                      <h2 dangerouslySetInnerHTML={{ __html: sp.key }}></h2>
                      {sp.value.map((sp_item, k) => (
                        <div className='spec-item' key={`spec-panel-${i}-item-${k}`}>
                          <div className='spec-title'>{sp_item.key}</div>
                          <div className='spec-content' dangerouslySetInnerHTML={{ __html: sp_item.value }}></div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className='spec-group'>
                      <h2 dangerouslySetInnerHTML={{ __html: sp.key }}></h2>
                      <div className='spec-content mb-5' dangerouslySetInnerHTML={{ __html: sp.value }}></div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className='fui-panel is-sim-card-feature'>
              <h2>網路流量怎麼算</h2>
              <h4>1 GB 流量相當於以下任一行為的使用時間</h4>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <div className='fui-sim-card-item'>
                    <div className='image'>
                      <img
                        src={process.env.PUBLIC_URL + '/resources/cbu/estore-product/roaming-ig@2x.png'}
                        alt='roaming-ig'
                      />
                    </div>
                    <div className='content'>
                      <h4>FB/IG 發文打卡</h4>
                      <div className='description is-text-darkgray50'>使用約 10 小時</div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className='fui-sim-card-item'>
                    <div className='image'>
                      <img
                        src={process.env.PUBLIC_URL + '/resources/cbu/estore-product/roaming-call@2x.png'}
                        alt='roaming-ig'
                      />
                    </div>
                    <div className='content'>
                      <h4>Line 語音電話</h4>
                      <div className='description is-text-darkgray50'>使用約 16.5 小時</div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className='fui-sim-card-item'>
                    <div className='image'>
                      <img
                        src={process.env.PUBLIC_URL + '/resources/cbu/estore-product/roaming-browse@2x.png'}
                        alt='roaming-ig'
                      />
                    </div>
                    <div className='content'>
                      <h4>瀏覽網頁</h4>
                      <div className='description is-text-darkgray50'>使用約 44 小時</div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className='fui-sim-card-item'>
                    <div className='image'>
                      <img
                        src={process.env.PUBLIC_URL + '/resources/cbu/estore-product/roaming-video@2x.png'}
                        alt='roaming-ig'
                      />
                    </div>
                    <div className='content'>
                      <h4>Youtube 影片</h4>
                      <div className='description is-text-darkgray50'>使用約 5 小時</div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className='fui-sim-card-item'>
                    <div className='image'>
                      <img
                        src={process.env.PUBLIC_URL + '/resources/cbu/estore-product/roaming-music@2x.png'}
                        alt='roaming-ig'
                      />
                    </div>
                    <div className='content'>
                      <h4>音樂串流</h4>
                      <div className='description is-text-darkgray50'>使用約 24 小時</div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className='fui-sim-card-item'>
                    <div className='image'>
                      <img
                        src={process.env.PUBLIC_URL + '/resources/cbu/estore-product/roaming-navi@2x.png'}
                        alt='roaming-ig'
                      />
                    </div>
                    <div className='content'>
                      <h4>地圖導航</h4>
                      <div className='description is-text-darkgray50'>使用約 100 小時</div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className='text-sm is-text-gray100'>
                ＊各上網行為用量參考基準如下：FB 打卡+上傳照片 (1張) 每次約2MB、上網瀏覽網頁每頁約1.5MB、Google
                Map導航每小時約10MB、觀看Youtube影片每小時約350MB為例，實際檔案大小依使用狀況而有所變動。
              </div>
            </div>
          </div>
        </section>

        <Cart />

        <EstoreModal
          {...this.state.currentModal}
          open={this.state.modalOpen}
          onSubmit={this.modalSubmit}
          onClose={this.modalClose}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cartReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCartData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SimCard);
