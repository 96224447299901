export const Spec = [
  {
    key: '產品特點',
    value: [
      '此2公尺<font style="color:#EF1C21;font-weight:bold;">USB 2.0</font>連接線可透過Lighting連接線',
      '讓你的iPhone、iPod或iPad連接你電腦的連接埠，以進行同步充電',
      '或連接到Apple USB電源轉接器，從牆上插座便利地進行充電',
    ],
  },
  {
    key: '產品規格',
    value: [
      {
        key: '尺寸',
        value: `112 x 112 x 3.5 mm`,
      },
      {
        key: '產品材質',
        value: `TPU`,
      },
      {
        key: '重量 (g/kg)',
        value: `88g`,
      },
      {
        key: '保固',
        value: `12個月`,
      },
      {
        key: '產地',
        value: `中國`,
      },
    ],
  },
];

export const productNotice = {
  text: `本產品僅含本島運送，不含壁掛安裝費用。部分偏遠地區將需額外加收費用，費用由廠商電話聯絡時確認。辦理新申辦/攜碼訂單，將先收到SIM卡及申請書，成功啟用2-3週後，商品將由廠商另行配送；申辦續約於續約成功2-3週後`,
};

export const Product = {
  type: 'accessory',
  slug: 'vantec-qi-red-wireless-chargers',
  name: 'VANTEC Qi 紅色無線快速充電盤',
  images: {
    紅: [
      '/resources/cbu/estore-product/estore-product-thumb-12.jpg',
      '/resources/cbu/estore-product/estore-product-thumb-12.jpg',
    ],
    黑: [
      '/resources/cbu/estore-product/estore-product-thumb-12.jpg',
      '/resources/cbu/estore-product/estore-product-thumb-12.jpg',
    ],
    白: [
      '/resources/cbu/estore-product/estore-product-thumb-12.jpg',
      '/resources/cbu/estore-product/estore-product-thumb-12.jpg',
    ],
  },
  colors: [
    {
      label: '紅',
      value: '紅',
      color: '#e14c41',
    },
    {
      label: '黑',
      value: '黑',
      color: '#464645',
    },
    {
      label: '白',
      value: '白',
      color: '#ececec',
    },
  ],
  discount: {
    text: '自4/24至5/31，買iPhone指定機款即可$0帶走台塑生醫面膜。',
    link: '#',
    target: '_self',
  },
  originPrice: 1299,
  salePrice: 1000,
  storage: 3,
  notice: [
    {
      text: '此產品為拆封九成五新或原廠整新非全新品',
      modal: {
        title: '備註',
        content: [
          '福利AA品',
          '本產品為拆封九成五新或原廠整新非全新品，機身可能有輕微細紋，不影響功能。外盒可能有些許磨損或使用痕跡，規格及盒內配件以實際出貨為主，請不介意之消費者再進行購買。',
          '請您耐心等候，為保障您退換貨權益，請於安裝SIM卡前先行檢查商品外觀及隨盒配件是否完整。同一筆訂單中的商品會一起出貨，故可能因為訂單中加購的商品缺貨而導致訂單延遲出貨，下單前請務必再次確認商品型號/顏色/資費方案及加購商品是否正確。',
        ],
      },
    },
  ],
};
