import { combineReducers } from 'redux';
import helpCenterReducer from './helpCenter';
import formReducer from './form';
import analyticsReducer from './analytics';
import emmaReducer from './emma';
import cartReducer from './cart';
import flowCartReducer from './flowCart';
import memberReducer from './member';

export const reducers = combineReducers({
  helpCenterReducer,
  formReducer,
  analyticsReducer,
  emmaReducer,
  cartReducer,
  flowCartReducer,
  memberReducer,
});
