import React, { Component } from 'react';
import Link from './Link';
import PropTypes from 'prop-types';

export class LoadMore extends Component {
  render() {
    return (
      <div className={`load-more ${this.props.className}`}>
        {this.props.load ? (
          <div onClick={this.props.click} className='expand'>
            {this.props.moreLabel}
            <i className={`icon-plus`}></i>
          </div>
        ) : this.props.noMoreLabel ? (
          <p className='expand no-more'>
            <i className={`icon-no-more`} />
            {this.props.noMoreLabel}
          </p>
        ) : null}
      </div>
    );
  }
}

LoadMore.propTypes = {
  className: PropTypes.string,
  moreLabel: PropTypes.string,
  noMoreLabel: PropTypes.string,
  load: PropTypes.bool,
  click: PropTypes.func,
};
export default LoadMore;
