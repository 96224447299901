import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@babel/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducers from './stores/reducers';
import './sass/main.sass';
import './sass/en.sass';

import App from './App';
import * as serviceWorker from './serviceWorker';

window.variablePrefix = window.innerWidth > 768 ? 'P_' : 'M_';

if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (search, this_len) {
    if (this_len === undefined || this_len > this.length) {
      this_len = this.length;
    }
    return this.substring(this_len - search.length, this_len) === search;
  };
}

if (!String.prototype.includes) {
  String.prototype.includes = function () {
    return String.prototype.indexOf.apply(this, arguments) !== -1;
  };
}

HTMLElement.prototype.removeElement = function () {
  let child = Array.from(this.children);
  let _parentElement = this.parentNode;
  for (var i in child) {
    let ch = child[i];
    // console.log(ch)
    if (ch.children.length) {
      // console.log('remove children: ', ch.children)
      ch.removeElement(ch);
    } else {
      ch.parentNode.removeChild(ch);
    }
  }

  if (_parentElement) {
    _parentElement.removeChild(this);
  }
};

HTMLElement.prototype.prependHtml = function (element) {
  const div = document.createElement('header');
  div.className = 'fui-header is-ie is-fixed';
  div.innerHTML = element.innerHTML;
  element.removeElement();
  this.insertBefore(div, this.firstChild);
};

const store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
