export const Spec = [
  {
    key: '產品規格',
    value: [
      {
        key: '有效期限',
        value: `啟用後15日內`,
      },
      {
        key: '網路流量',
        value: `首 10GB 高速上網流量，後降速 128Kbps 吃到飽`,
      },
      {
        key: '指定網路',
        value: `歐洲國家依當地合作電信商為準`,
      },
      {
        key: '適用地區',
        value: `【西歐】法國、荷蘭、比利時、聖馬丁、法屬圭亞那、愛爾蘭共和國、瓜德羅普島、馬提尼克、直布羅陀、聖巴泰勒米、盧森堡、法屬安的列斯、馬約特島<br/>
                【中歐】德國、瑞士、奧地利、匈牙利、波蘭、克羅地亞、列支敦士登<br/>
                【南歐】義大利、西班牙、葡萄牙、希臘、馬耳他、塞浦路斯<br/>
                【北歐】冰島、芬蘭、瑞典、挪威、丹麥、愛沙尼亞<br/>
                【東歐】立陶宛、捷克共和國、拉脫維亞、羅馬尼亞、保加利亞、斯洛文尼亞、斯洛伐克
              `,
      },
      {
        key: '重要提醒',
        value: `本產品於美國不涵蓋地區為「關島」及「塞班」、加拿大不涵蓋地區為「黃刀鎮」`,
      },
    ],
  },
];

export const Product = {
  type: 'sim-card',
  slug: 'japan-5days-internet-card',
  name: '歐洲+土耳其 15 日上網吃到飽 (加贈杜拜流量)',
  images: [
    '/resources/cbu/estore-product/estore-wireless-card-thumb-l@2x.jpg',
    '/resources/cbu/estore-product/estore-wireless-card-thumb-l@2x.jpg',
  ],
  discount: {
    text: '申辦加贈機場接送，還有RIMOWA行李箱租賃優惠等你搶！',
    link: '#',
    target: '_self',
  },
  originPrice: 1299,
  salePrice: 999,
  notice: [
    {
      text: '分期0利率，每期最低 $74',
      modal: {
        title: '信用卡分期付款',
        content: [
          '分期資訊',
          '結帳金額 $30,300',
          {
            '3期0利率': '$10,000',
            '5期0利率': '$6,000',
            '6期0利率': '$5,000',
            '12期0利率': '$2,520',
          },
          [
            '手機分期付款僅提供宅配取貨，並使用線上刷卡供銷費者使用。',
            '分期價除不盡餘額部分，將於第一期帳單收取。',
            '各產品可供分期期數不盡相同，依結帳時系統提供分期數為主；如一張訂單有多項商品，只會出現共同皆可使用之分期數。',
          ],
        ],
      },
    },
    {
      text: '此產品為拆封九成五新或原廠整新非全新品',
      modal: {
        title: '備註',
        content: [
          '福利AA品',
          '本產品為拆封九成五新或原廠整新非全新品，機身可能有輕微細紋，不影響功能。外盒可能有些許磨損或使用痕跡，規格及盒內配件以實際出貨為主，請不介意之消費者再進行購買。',
          '請您耐心等候，為保障您退換貨權益，請於安裝SIM卡前先行檢查商品外觀及隨盒配件是否完整。同一筆訂單中的商品會一起出貨，故可能因為訂單中加購的商品缺貨而導致訂單延遲出貨，下單前請務必再次確認商品型號',
        ],
      },
    },
  ],
};
