import React from 'react';
import Collapse from '../collapse/Collapse';

import PropTypes from 'prop-types';

class PanelInformation extends React.Component {
  render() {
    return (
      <div className={`fui-panel-information ${this.props.className}`}>
        <Collapse title={this.props.title} date={this.props.date} content={this.props.content}>
          {this.props.children}
        </Collapse>
      </div>
    );
  }
}

PanelInformation.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  content: PropTypes.string,
};

export default PanelInformation;
