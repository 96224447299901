export const products = {
  phone: [
    {
      type: 'product',
      link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
      ribbon: '網路限定優惠',
      image: '/resources/cbu/estore/640x480.jpg',
      tag: '5G手機',
      meta: 'APPLE',
      title: 'iPhone 11 Pro Max 256GB',
      salePrice: '$1,399',
      projectPrice: '$10,500',
    },
    {
      type: 'product',
      link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
      ribbon: '新機上市',
      meta: 'APPLE',
      image: '/resources/cbu/estore/640x480.jpg',
      title: 'Apple iPhone SE (2020) 128GB',
      salePrice: '$1,399',
      projectPrice: '$10,500',
    },
    {
      type: 'product',
      link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
      ribbon: '新機上市',
      meta: 'SAMSUNG',
      image: '/resources/cbu/estore/old.jpg',
      title: 'Samsung Galaxy A21s ',
      salePrice: '$1,399',
      projectPrice: '$10,500',
    },
    {
      type: 'promo-product',
      link: '/',
      image: '/resources/cbu/find-product/396x476_3.jpg',
      title: 'Concern 睛舒壓時尚氣壓眼部按摩器 $0元',
    },
    {
      type: 'promo-product',
      link: '/',
      image: '/resources/cbu/find-product/396x476_4.jpg',
      title: '好康1+1: 手機 + 3C家電',
    },
    {
      type: 'product',
      link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
      image: '/resources/cbu/estore/extra_large_phone.jpg',
      tag: '5G手機',
      meta: 'APPLE',
      title: 'iPhone 11 Pro Max 256GB',
      salePrice: '$1,399',
      projectPrice: '$10,500',
    },
    {
      type: 'product',
      link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
      meta: 'APPLE',
      image: '/resources/cbu/find-product/480x480.png',
      title: 'Apple iPhone SE (2020) 128GB',
      salePrice: '$1,399',
      projectPrice: '$10,500',
    },
    {
      type: 'product',
      link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
      meta: 'SAMSUNG',
      image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
      title: 'Samsung Galaxy A21s ',
      salePrice: '$1,399',
      projectPrice: '$10,500',
    },
    {
      type: 'promo-product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-04@2x.jpg',
      title: 'Concern 睛舒壓時尚氣壓眼部按摩器 $0元',
    },
    {
      type: 'promo-product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-05@2x.jpg',
      title: '好康1+1: 手機 + 3C家電',
    },
    {
      type: 'product',
      link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
      image: '/resources/cbu/estore/extra_large_phone.jpg',
      tag: '5G手機',
      meta: 'APPLE',
      title: 'iPhone 11 Pro Max 256GB',
      salePrice: '$1,399',
      projectPrice: '$10,500',
    },
    {
      type: 'product',
      link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
      meta: 'APPLE',
      image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
      title: 'Apple iPhone SE (2020) 128GB',
      salePrice: '$1,399',
      projectPrice: '$10,500',
    },
    {
      type: 'product',
      link: '/estore/product/phone/detail/iphone-11-pro-max-256gb',
      meta: 'SAMSUNG',
      image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
      title: 'Samsung Galaxy A21s ',
      salePrice: '$1,399',
      projectPrice: '$10,500',
    },
    {
      type: 'promo-product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-04@2x.jpg',
      title: 'Concern 睛舒壓時尚氣壓眼部按摩器 $0元',
    },
    {
      type: 'promo-product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-05@2x.jpg',
      title: '好康1+1: 手機 + 3C家電',
    },
  ],
  computer: [
    {
      type: 'product',
      link: '/',
      ribbon: '網路限定優惠',
      image: '/resources/cbu/find-product/estore-product-thumb-06@2x.jpg',
      meta: 'APPLE',
      title: 'Apple iPad Pro 4G版11吋128GB 2020',
      salePrice: '$1,399',
      projectPrice: '$0',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-07@2x.jpg',
      tag: '福利A品',
      meta: 'SAMSUNG',
      title: 'Samsung Galaxy Tab S6 Lite Wi-Fi版 ',
      salePrice: '$1,399',
      projectPrice: '$0',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-08@2x.jpg',
      meta: 'APPLE',
      title: 'Apple iPad Pro 4G版11吋128GB 2020',
      salePrice: '$1,399',
      projectPrice: '$0',
    },
    {
      type: 'promo-product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-04@2x.jpg',
      title: 'Concern 睛舒壓時尚氣壓眼部按摩器 $0元',
    },
    {
      type: 'promo-product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-05@2x.jpg',
      title: '好康1+1: 手機 + 3C家電',
    },
    {
      type: 'product',
      link: '/',
      ribbon: '網路限定優惠',
      image: '/resources/cbu/find-product/estore-product-thumb-06@2x.jpg',
      meta: 'APPLE',
      title: 'Apple iPad Pro 4G版11吋128GB 2020',
      salePrice: '$1,399',
      projectPrice: '$0',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-07@2x.jpg',
      tag: '福利A品',
      meta: 'SAMSUNG',
      title: 'Samsung Galaxy Tab S6 Lite Wi-Fi版 ',
      salePrice: '$1,399',
      projectPrice: '$0',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-08@2x.jpg',
      meta: 'APPLE',
      title: 'Apple iPad Pro 4G版11吋128GB 2020',
      salePrice: '$1,399',
      projectPrice: '$0',
    },
    {
      type: 'product',
      link: '/',
      ribbon: '網路限定優惠',
      image: '/resources/cbu/find-product/estore-product-thumb-06@2x.jpg',
      meta: 'APPLE',
      title: 'Apple iPad Pro 4G版11吋128GB 2020',
      salePrice: '$1,399',
      projectPrice: '$0',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-07@2x.jpg',
      tag: '福利A品',
      meta: 'SAMSUNG',
      title: 'Samsung Galaxy Tab S6 Lite Wi-Fi版 ',
      salePrice: '$1,399',
      projectPrice: '$0',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-08@2x.jpg',
      meta: 'APPLE',
      title: 'Apple iPad Pro 4G版11吋128GB 2020',
      salePrice: '$1,399',
      projectPrice: '$0',
    },
    {
      type: 'product',
      link: '/',
      ribbon: '網路限定優惠',
      image: '/resources/cbu/find-product/estore-product-thumb-06@2x.jpg',
      meta: 'APPLE',
      title: 'Apple iPad Pro 4G版11吋128GB 2020',
      salePrice: '$1,399',
      projectPrice: '$0',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-07@2x.jpg',
      tag: '福利A品',
      meta: 'SAMSUNG',
      title: 'Samsung Galaxy Tab S6 Lite Wi-Fi版 ',
      salePrice: '$1,399',
      projectPrice: '$0',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-08@2x.jpg',
      meta: 'APPLE',
      title: 'Apple iPad Pro 4G版11吋128GB 2020',
      salePrice: '$1,399',
      projectPrice: '$0',
    },
  ],
  '3c': [
    {
      type: 'product',
      link: '/',
      ribbon: '網路限定優惠',
      image: '/resources/cbu/find-product/396x476_1.jpg',
      meta: 'APPLE',
      title: 'Apple Watch',
      originPrice: '$5,600',
      productPrice: '$5,100',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-10@2x.jpg',
      tag: '福利A品',
      meta: 'Iris Ohyama',
      title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
      productPrice: '$1,399',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-11@2x.jpg',
      meta: 'Panasonic',
      title: 'Panasonic 15公升蒸氣烘烤爐',
      productPrice: '$15,0000',
    },
    {
      type: 'promotion',
      link: '/',
      title: '遠傳 x 健身工廠推出健身工廠健康專案',
      action: {
        text: '了解更多',
        link: '',
        target: '_self',
      },
    },
    {
      type: 'promo-product',
      link: '/',
      image: '/resources/cbu/find-product/396x476_2.jpg',
      title: 'Concern 睛舒壓時尚氣壓眼部按摩器 $0元',
    },
    {
      type: 'product',
      link: '/',
      ribbon: '網路限定優惠',
      image: '/resources/cbu/find-product/estore-product-thumb-09@2x.jpg',
      meta: 'PHILIPS',
      title: 'PHILIPS 舒眠抗敏空氣清淨機',
      originPrice: '$5,600',
      productPrice: '$5,100',
      // projectPrice: '$0'
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-10@2x.jpg',
      tag: '福利A品',
      meta: 'Iris Ohyama',
      title: 'Iris Ohyama 雙氣旋智能除蹣清淨機',
      productPrice: '$1,399',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-11@2x.jpg',
      meta: 'Panasonic',
      title: 'Panasonic 15公升蒸氣烘烤爐',
      productPrice: '$15,0000',
    },
  ],
  accessories: [
    {
      type: 'product',
      link: '/estore/product/detail/vantec-qi-red-wireless-chargers',
      ribbon: '網路限定優惠',
      image: '/resources/cbu/find-product/estore-product-thumb-12@2x.jpg',
      meta: 'VANTEC Qi',
      title: 'VANTEC Qi 紅色無線快速充電盤',
      productPrice: '$1,290',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-13@2x.jpg',
      meta: 'APPLE',
      title: '原廠 EarPods 耳機 - Lightning線',
      productPrice: '$990',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-14@2x.jpg',
      meta: 'MYCELL',
      title: 'MYCELL AirPods Pro 粉沙收納盒',
      productPrice: '$390',
    },
    {
      type: 'promotion',
      link: '/',
      title: '遠傳 x 健身工廠推出健身工廠健康專案',
      action: {
        text: '了解更多',
        link: '',
        target: '_self',
      },
    },
    {
      type: 'promo-product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-15@2x.jpg',
      title: '想要生活更有趣？更多精選配件都在這！',
    },
    {
      type: 'product',
      link: '/',
      ribbon: '網路限定優惠',
      image: '/resources/cbu/find-product/estore-product-thumb-12@2x.jpg',
      meta: 'VANTEC Qi',
      title: 'VANTEC Qi 紅色無線快速充電盤',
      productPrice: '$1,290',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-13@2x.jpg',
      meta: 'APPLE',
      title: '原廠 EarPods 耳機 - Lightning線',
      productPrice: '$990',
    },
    {
      type: 'product',
      link: '/',
      image: '/resources/cbu/find-product/estore-product-thumb-14@2x.jpg',
      meta: 'MYCELL',
      title: 'MYCELL AirPods Pro 粉沙收納盒',
      productPrice: '$390',
    },
    {
      type: 'wireless',
      link: '/estore/product/detail/japan-5days-internet-card',
      image: '/resources/cbu/find-product/estore-wireless-card-thumb-01@2x.jpg',
      meta: '飆網不降速 插卡即用',
      title: '日本5日不降速上網卡',
      originPrice: '$1,299',
      productPrice: '$999',
    },
    {
      type: 'wireless',
      link: '/estore/product/detail/japan-5days-internet-card',
      image: '/resources/cbu/find-product/estore-wireless-card-thumb-01@2x.jpg',
      meta: '飆網不降速 插卡即用',
      title: '日本5日不降速上網卡',
      originPrice: '$1,299',
      productPrice: '$999',
    },
    {
      type: 'wireless',
      link: '/',
      image: '/resources/cbu/find-product/estore-wireless-card-thumb-01@2x.jpg',
      meta: '飆網不降速 插卡即用',
      title: '日本5日不降速上網卡',
      originPrice: '$1,299',
      productPrice: '$999',
    },
    {
      type: 'wireless',
      link: '/',
      image: '/resources/cbu/find-product/estore-wireless-card-thumb-02@2x.jpg',
      meta: '30日內2GB飆網',
      title: '遠遊卡 韓國30天吃到飽',
      productPrice: '$1,299',
    },
    {
      type: 'wireless',
      link: '/',
      image: '/resources/cbu/find-product/estore-wireless-card-thumb-03@2x.jpg',
      meta: '30日內2GB飆網',
      title: '遠遊卡 香港30天吃到飽',
      productPrice: '$1,299',
    },
    {
      type: 'wireless',
      link: '/',
      image: '/resources/cbu/find-product/estore-wireless-card-thumb-04@2x.jpg',
      meta: '30日內2GB飆網',
      title: '遠遊卡 美國30天吃到飽',
      productPrice: '$1,299',
    },
  ],
};

export const navTab = {
  pageTitle: '找商品',
  tabs: {
    name: 'find-product-tab',
    list: [
      { label: '手機', link: '/estore/product/phone' },
      { label: '平板電腦', link: '/estore/product/computer' },
      { label: '智慧生活', link: '/estore/product/3c' },
      { label: '配件', link: '/estore/product/accessories' },
    ],
  },
  buttons: [
    {
      text: '新申辦',
      btnStyle: 'primary',
      link: '/',
    },
    {
      text: '推薦續約',
      btnStyle: 'secondary',
      link: '/',
    },
  ],
};

export const banner = {
  phone: {
    image: {
      md: '/resources/cbu/find-product/cbu-estore-category-kv-01-1920x360.jpg',
      sm: '/resources/cbu/find-product/estore-banner-m-1@2x.jpg',
    },
    title: '探索...',
  },
  computer: {
    image: {
      md: '/resources/cbu/find-product/cbu-estore-category-kv-02-1920x360.jpg',
      sm: '/resources/cbu/find-product/estore-banner-m-2@2x.jpg',
    },
    title: '探索...',
  },
  '3c': {
    image: {
      md: '/resources/cbu/find-product/cbu-estore-category-kv-03-1920x360.jpg',
      sm: '/resources/cbu/find-product/estore-banner-m-3@2x.jpg',
    },
    title: '探索...',
  },
  accessories: {
    image: {
      md: '/resources/cbu/find-product/cbu-estore-category-kv-04-1920x360.jpg',
      sm: '/resources/cbu/find-product/estore-banner-m-4@2x.jpg',
    },
    title: '探索...',
  },
};

export const searchList = {
  phone: [
    { text: '主打新機', link: '主打新機' },
    { text: '遠傳獨家', link: '遠傳獨家' },
    { text: '5G手機正夯', link: '5G手機正夯' },
    { text: '平價入門機', link: '平價入門機' },
    { text: '0元手機', link: '0元手機' },
    { text: '福利品', link: '福利品' },
  ],
  computer: [
    { text: '熱銷平板', link: '熱銷平板' },
    { text: '輕薄筆電', link: '輕薄筆電' },
  ],
  '3c': [
    { text: '智慧家電', link: '智慧家電' },
    { text: '影音享受', link: '影音享受' },
    { text: '娛樂生活', link: '娛樂生活' },
    { text: '智慧穿戴', link: '智慧穿戴' },
    { text: '遠傳大無限', link: '遠傳大無限' },
    { text: '健身票券', link: '健身票券' },
  ],
  accessories: [
    { text: '耳機/喇叭', link: '耳機/喇叭' },
    { text: '充電配備', link: '充電配備' },
    { text: '保護殼/貼', link: '保護殼/貼' },
    { text: '記憶卡', link: '記憶卡' },
    { text: '出國上網卡', link: '出國上網卡' },
    { text: '其他', link: '其他' },
  ],
};

export const breadcrumb = [
  { text: '首頁', link: '/' },
  { text: '找商品', link: '/estore/product' },
  { text: '找手機', link: '/estore/product/phone' },
];

export const moreCards = {
  title: '您曾瀏覽過',
  cards: [
    {
      type: 'product',
      image: '/resources/cbu/estore/extra_large_phone.jpg',
      meta: 'Apple',
      tag: '5G手機',
      link: '#',
      target: '_self',
      title: 'iPhone 11 Pro Max 256GB',
      salePrice: '$1,399',
      projectPrice: '$10,500',
    },
    {
      type: 'product',
      image: '/resources/cbu/find-product/estore-product-thumb-02@2x.jpg',
      meta: 'Apple',
      link: '#',
      target: '_self',
      title: 'Apple iPhone SE (2020) 128GB',
      salePrice: '$1,399',
      projectPrice: '$10,500',
    },
    {
      type: 'product',
      image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
      meta: 'SAMSUNG',
      link: '#',
      target: '_self',
      title: 'Samsung Galaxy A21s ',
      salePrice: '$1,399',
      projectPrice: '$10,500',
    },
    {
      type: 'product',
      image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
      meta: 'SAMSUNG',
      link: '#',
      target: '_self',
      title: 'Samsung Galaxy A21s ',
      salePrice: '$1,399',
      projectPrice: '$10,500',
    },
    {
      type: 'product',
      image: '/resources/cbu/find-product/estore-product-thumb-03@2x.jpg',
      meta: 'SAMSUNG',
      link: '#',
      target: '_self',
      title: 'Samsung Galaxy A21s ',
      salePrice: '$1,399',
      projectPrice: '$10,500',
    },
  ],
};

export const faq = {
  title: '常見問題',
  tabs: [{ label: '申辦門號' }, { label: '購物' }, { label: '售後' }],
  collapseList: [
    [
      {
        title: '網路門市申辦攜碼移入生效時間與實體門市之差異?',
        content: '網路門市申辦攜碼移入生效時間與實體門市之差異?',
      },
      {
        title: '我是否可以幫他人在網路門市申辦門號？',
        content: '我是否可以幫他人在網路門市申辦門號？',
      },
      {
        title: '如何查詢申辦進度？',
        content: '如何查詢申辦進度？',
      },
    ],
  ],
};
